import React from 'react';
import Location from "./Location";
import { Button, ButtonToolbar, Well } from 'react-bootstrap';

class AddMultiLocation extends React.Component {
    addMoreLocationBlock = () => {
        const location_details = this.props.location_details.concat([{
            address: {
                address_line_1: '',
                address_line_2: '',
                address_line_3: '',
                parish: '',
                city: '',
                pincode: ''
            },
            name: '',
            max_profile_allowed: '',
            contact_number: "",
        }])
        this.props.addMoreLocationBlock(location_details)
    }

    removeLocationBlock = () => {
        const location_details = this.props.location_details.slice(0, -1)
        this.props.removeLocationBlock(location_details)
    }

    handleLocationChange = (event, idx) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        const location_details = this.props.location_details.map((location_detail, sidx) => {
            if (idx !== sidx) return location_detail;

            if (name === 'name' || name === 'contact_number' || name === 'max_profile_allowed') {
                location_detail[name] = value;
            } else {
                location_detail['address'][name] = value;
            }

            return location_detail;
        });

        this.props.handleLocationChange(location_details)
    }
    render() {
        const { location_details } = this.props
        return (
            <React.Fragment>
                <Well bsSize="small">
                    {location_details && location_details.map((location_detail, idx) => (
                        <Location idx={idx} key={idx}
                            location_detail={location_detail}
                            handleLocationChange={this.handleLocationChange}
                            validator={this.props.validator} />
                    ))}
                </Well>
                <ButtonToolbar>
                    {location_details.length < 4 && <Button bsStyle="success" bsSize="xsmall" onClick={this.addMoreLocationBlock} className="pull-right"><i className="fa fa-plus"></i> Add More</Button>}
                    {location_details.length > 1 && <Button bsStyle="danger" bsSize="xsmall" onClick={this.removeLocationBlock} className="pull-right"><i className="fa fa-times"></i> Remove</Button>}
                </ButtonToolbar>
            </React.Fragment>

        )
    }
}
export default AddMultiLocation