import _ from 'lodash';

export const setSessionToken = (data) => {
    const stringValue = JSON.stringify({
        access_token: data.access_token,
        refresh_token: data.refresh_token,
        access_expires_at: data.access_expires_at,
        refresh_expires_at: data.refresh_expires_at
    });

    const encryptedValue = stringValue;
    localStorage.setItem('_session', encryptedValue);
}

export const getSessionToken = () => {
    const encryptedValue = localStorage.getItem('_session');

    if (encryptedValue) {
        const stringValue = encryptedValue;
        return JSON.parse(stringValue);
    }

    return false;
}

export const checkSessionToken = () => {
    if (localStorage.getItem('_session')) {
        return true;
    }

    return false;
}

export const removeSessionToken = () => {
    localStorage.removeItem('_session');
}

export const setUser = (data) => {
    const stringValue = JSON.stringify(data);

    const encryptedValue = stringValue;
    localStorage.setItem('_user', encryptedValue);
}

export const getUser = () => {
    const encryptedValue = localStorage.getItem('_user');

    if (encryptedValue) {
        const stringValue = encryptedValue;
        return JSON.parse(stringValue);
    }

    return false;
}

export const checkUser = () => {
    if (localStorage.getItem('_user')) {
        return true;
    }

    return false;
}

export const removeUser = () => {
    localStorage.removeItem('_user');
}

export const hasPermission = (user, rights) => {
    if (_.isEmpty(user) || _.isEmpty(user.rights)) {
        return false;
    }

    return rights.some(right => user.rights.includes(right));
}

export const hasRole = (user, role_slug) => {
    if (_.isEmpty(user) || _.isEmpty(user.role_slug)) {
        return false;
    }
    return role_slug.some(role => user.role_slug === role);
}