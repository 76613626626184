import React from 'react';
import { connect } from 'react-redux';
import { Content } from '../../components/Elements';
import AirlineCardSwipeCount from '../../components/AuthorityAccessDashboard/AirlineCardSwipeCount.js';
import AirlineUsersCount from '../../components/AuthorityAccessDashboard/AirlineUsersCount.js';
import AirlineCardSwipeGraph from '../../components/AuthorityAccessDashboard/AirlineCardSwipeGraph.js';
import * as helperUitls from '../../utils/helpers';
import { Helmet } from 'react-helmet';

class AuthorityAccessDashboardPage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const user = this.props.user;
        const userOnboardVisibiltyPermission = ['super-admin', 'business-development-head', 'business-development-executive', 'finance-head', 'admin', 'service'];

        return <Content title="Authority Access Dashboard">
            <Helmet>
                <title>Authority Access Dashboard </title>
            </Helmet>

            <div className="row">
                <div className="col-sm-2">
                    {helperUitls.hasRole(user, userOnboardVisibiltyPermission) && <AirlineCardSwipeCount />}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    {helperUitls.hasRole(user, userOnboardVisibiltyPermission) && <AirlineUsersCount />}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    {helperUitls.hasRole(user, userOnboardVisibiltyPermission) && <AirlineCardSwipeGraph />}
                </div>
            </div>

        </Content>;
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.admin,
});

export default connect(mapStateToProps, null)(AuthorityAccessDashboardPage);
