import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { Link } from "react-router-dom";
import { ButtonToolbar, Button } from 'react-bootstrap';
import * as PatientAction from '../../actions/patientActions';
import PatientService from '../../services/patientService';
import { Helmet } from 'react-helmet';
import * as HELPER from '../../utils/helpers';
import DataTable from '../../components/DataTable';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Notification from '../../utils/notification';
import moment from "moment";
import _ from "lodash";

class ListPatientPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            request_csv: false,
            onboard_date_text: '',
            sortBy: { sortField: "created_at", sortOrder: "desc" },
            filter: {
                start_created_date: null,
                end_created_date: null,
                search: '',
            }
        };
    }

    componentDidMount() {
        this.props.paginate({ sortBy: this.state.sortBy });
    }

    columns = () => {
        return [{
            dataField: 'full_name',
            text: 'Name ',
            sort: false
        }, {
            dataField: 'contact_number',
            text: 'Contact Number',
            sort: false
        }, {
            dataField: 'email',
            text: 'Email',
            sort: false
        }, {
            dataField: 'created_at',
            text: 'Onboard Date',
            sort: false,
            formatter: (cell) => {
                return HELPER.getDateFormat(cell);
            }
        }, {
            dataField: 'actions',
            text: 'Actions',
            isDummyField: true,
            formatter: this.actionFormatter
        }];
    };

    actionFormatter = (cell, row) => {
        return <ButtonToolbar>
            {HELPER.hasPermission(this.props.admin, ['patient-view']) && <Link className="btn btn-xs btn-info" to={'/patient/' + row.patient_id}><i className="fa fa-eye" aria-hidden="true"></i></Link>}
        </ButtonToolbar>;
    }

    handleFilterSubmit = () => {
        let filter = this.state.filter;
        this.props.paginate({ sortBy: this.state.sortBy, filter });
    }

    handleFilterReset = () => {
        this.setState(prevState => ({
            onboard_date_text: '',
            filter: {
                ...prevState.filter,
                start_created_date: null,
                end_created_date: null
            }
        }), () => {
            this.props.paginate({ sortBy: this.state.sortBy, filter: this.state.filter });
        })
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {

        if (type === 'search') {
            page = 1;
        }

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                search: searchText,
            }
        }), () => {
            let filter = this.state.filter;
            this.props.paginate({ page, sizePerPage, sortBy: { sortField: "created_at", sortOrder: "desc" }, searchText: this.state.filter.search, filter });
        })
    }

    handleOnboardDateApply = (_evt, picker) => {
        this.setState({
            onboard_date_text: picker.startDate.format('DD MMM YYYY') + ' - ' + picker.endDate.format('DD MMM YYYY'),
            filter: {
                ...this.state.filter,
                start_created_date: picker.startDate.format('YYYY-MM-DD'),
                end_created_date: picker.endDate.format('YYYY-MM-DD')
            }
        })
    }

    handleRequestForCSV = () => {
        this.setState({
            request_csv: true
        });

        PatientService.exportCsv(this.state.filter).then(() => {
            this.setState({
                request_csv: false
            });
            Notification.show('success', { message: 'You will receive csv to your registered email id!' });
        }).catch(error => {
            this.setState({
                request_csv: false
            });
            let errorData = error.response.data;
            Notification.show('error', errorData);
        })
    }

    render() {
        let { data, meta, loading } = this.props;

        return <React.Fragment>
            <Helmet>
                <title>List Patients</title>
            </Helmet>
            <Content title="Patients">
                <Box>
                    <Box.Body>
                        <form>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Onboard Date</label>
                                        <DateRangePicker
                                            onApply={this.handleOnboardDateApply}
                                            autoApply={true}
                                            maxDate={moment()}
                                        >
                                            <div class="input-group">
                                                <input type="text" class="form-control" value={this.state.onboard_date_text} placeholder="DD MMM YYYY - DD MMM YYYY" />
                                                <div class="input-group-addon">
                                                    <i class="fa fa-calendar"></i>
                                                </div>
                                            </div>
                                        </DateRangePicker>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </Box.Body>
                    <Box.Foot>
                        <ButtonToolbar className="pull-right">
                            {<Button bsStyle="success" onClick={this.handleFilterSubmit}>Search</Button>}
                            {<Button bsStyle="default" onClick={this.handleFilterReset}>Reset</Button>}
                        </ButtonToolbar>
                    </Box.Foot>
                </Box>
                <Box>
                    <Box.Head title="List Patients">
                        <ButtonToolbar className="pull-right">
                            {!_.isEmpty(data) && <Button bsStyle="warning" disabled={this.state.request_csv} onClick={this.handleRequestForCSV}>{this.state.request_csv ? "Loading..." : "Request For CSV"}</Button>}
                        </ButtonToolbar>
                    </Box.Head>
                    <Box.Body>
                        <DataTable
                            loading={loading}
                            columns={this.columns()}
                            data={data}
                            page={parseInt(meta.current_page)}
                            sizePerPage={parseInt(meta.per_page)}
                            totalSize={parseInt(meta.total)}
                            onTableChange={this.handleTableChange}
                        />
                    </Box.Body>
                </Box>
            </Content>
        </React.Fragment>;
    }
}

const mapStateToProps = (state) => ({
    ...state.patient_list,
    admin: state.auth.admin
});

const mapActionsToProps = ({
    paginate: PatientAction.list
})

export default connect(mapStateToProps, mapActionsToProps)(ListPatientPage);
