import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { FormGroup, ControlLabel, FormControl, Col, Button, ButtonToolbar } from 'react-bootstrap';
import * as PathologyLimsTestAction from '../../actions/pathologyLimsTestActions';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from 'react-helmet';
import Alert from '../../components/Alert';
import PathologyLimsTestService from '../../services/pathologyLimsTestService';
// import { PATHOLOGY_LIMS_TEST_CATEGORIES } from '../../utils/config';

class EditPathologyLimsTestPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            category: '',
            lower_limit: '',
            upper_limit: '',
            unit_of_measurement: '',
            alternate_result: '',
            alternate_result_flag: '',
            type: '',
            status: '',
            flag: true,
            activeCategory :[],
        };

        this.validator = new SimpleReactValidator();
        this.handleChange = this.handleChange.bind(this); 
        this.validateAndSubmit = this.validateAndSubmit.bind(this);
    

        const { match: { params } } = this.props;
        const id = params.id;
        this.fetchPathologyLimsTest(id);
    }
    componentDidMount() {
        PathologyLimsTestService.getAllPathologyCategory()
        .then(resp => {
            let data = resp.data.data;
            this.setState({
                activeCategory: data
            })
        })
        .catch(error => {
           console.log(error);
           this.setState({ activeCategory: [] });
        });   
    }

    fetchPathologyLimsTest = (id) =>{
            PathologyLimsTestService.fetchPathologyLimsTest(id)
            .then(resp => {
                let data = resp.data;
                this.setState({...data});
            })
            .catch(error => {
               console.log(error);
            });

    }

    handleChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        if (value === 'Profile') {
            this.setState({
                [name]: value,
                category: ''
            });
        } else {
            this.setState({
                [name]: value
            });
        }
    }

    validateAndSubmit(e) {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        const postData = {
            name: this.state.name, 
            category:this.state.category,
            lower_limit:this.state.lower_limit,
            upper_limit:this.state.upper_limit,
            unit_of_measurement:this.state.unit_of_measurement,
            alternate_result:this.state.alternate_result,
            alternate_result_flag:this.state.alternate_result_flag,
            type:this.state.type,
            status: this.state.status,
        };

        this.props.pathologyLimsTestUpdate(this.state.id, postData, this.props.history);
    }

    render() {
        const {activeCategory} = this.state;
        return (
            <div>
                <Helmet>
                    <title>Edit Lims Test</title>
                </Helmet>
                <Content title="Edit Lims Test">
                    <Box>
                        <Box.Body>
                            <Alert />
                            {this.state.flag &&
                                <form onSubmit={this.validateAndSubmit}>
                                    {this.state.activeCategory &&
                                        <FormGroup className="row" controlId="">
                                            <Col sm={2}>
                                                <ControlLabel>Test Category <span>*</span></ControlLabel>
                                            </Col>
                                            <Col sm={6}>
                                            <select className="form-control" value={this.state.category} id="category" name="category" onChange={this.handleChange}>
                                                <option value="">Select Category</option>
                                                {activeCategory.map((category) => (
                                                    <option key={category.id} value={category.name}>
                                                        {category.name}
                                                    </option>
                                                ))}
                                                </select>
                                                {this.validator.message('category', this.state.category, 'required')}
                                            </Col>
                                        </FormGroup>
                                    }

                                    <FormGroup className="row" controlId="">
                                        <Col sm={2}>
                                            <ControlLabel>Test Name <span>*</span></ControlLabel>
                                        </Col>
                                        <Col sm={6}>
                                            <FormControl
                                                name='name'
                                                type="text"
                                                value={this.state.name}
                                                placeholder="Enter Test Name"
                                                onChange={this.handleChange}
                                                autoComplete="none"
                                            />
                                            {this.validator.message('name', this.state.name, 'required|string')}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Lower Limit</ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='lower_limit'
                                            type="text"
                                            value={this.state.lower_limit}
                                            placeholder="Enter Lower Limit"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('lower limit', this.state.lower_limit, 'string')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Upper Limit</ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='upper_limit'
                                            type="text"
                                            value={this.state.upper_limit}
                                            placeholder="Enter Upper Limit"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('upper_limit', this.state.upper_limit, 'string')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Unit of Measurement</ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='unit_of_measurement'
                                            type="text"
                                            value={this.state.unit_of_measurement}
                                            placeholder="Enter Unit of Measurement"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('unit_of_measurement', this.state.unit_of_measurement, 'string')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Alternate Result</ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='alternate_result'
                                            type="text"
                                            value={this.state.alternate_result}
                                            placeholder="Enter Alternate Result"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                        <span className="small"> Note: Add Alternate Result Separated by "/" slash. Eg Positive/Negative</span>
                                        {this.validator.message('alternate_result', this.state.alternate_result, 'string')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Alternate Result Flag</ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='alternate_result_flag'
                                            type="text"
                                            value={this.state.alternate_result_flag}
                                            placeholder="Enter Alternate Result Flag"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('alternate_result_flag', this.state.alternate_result_flag, 'string')}
                                    </Col>
                                </FormGroup>
                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Type <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl name="type" componentClass="select" value={this.state.type} onChange={this.handleChange} placeholder="Select Test Type">
                                            <option value="" disabled={true}>select type</option>
                                            <option value="text">Text</option>
                                            <option value="alpha-numeric">Alpha Numeric</option>
                                            <option value="select">Drop-down</option>
                                        </FormControl>
                                        {this.validator.message('status', this.state.status, 'required')}
                                    </Col>
                                    </FormGroup>

                                    <FormGroup className="row" controlId="">
                                        <Col sm={2}>
                                            <ControlLabel>Status <span>*</span></ControlLabel>
                                        </Col>
                                        <Col sm={6}>
                                            <FormControl name="status" componentClass="select" value={this.state.status} onChange={this.handleChange} placeholder="Select Test Type">
                                                <option value="1">Active</option>
                                                <option value="0">Inactive</option>
                                            </FormControl>
                                        </Col>
                                    </FormGroup>

                                    <FormGroup className="row" controlId="">
                                        <Col sm={6} md={6}>
                                            <ButtonToolbar>
                                                <Button type='submit' bsStyle="primary">Update</Button>
                                                <a href="/lims-tests" className="btn btn-info">Back</a>
                                            </ButtonToolbar>
                                        </Col>
                                    </FormGroup>
                                </form>
                            }
                        </Box.Body>
                    </Box>
                </Content>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    pathologyLimsTest: state.pathologyLimsTest
});

const mapActionsToProps = ({
    pathologyLimsTestUpdate: PathologyLimsTestAction.pathologyLimsTestUpdate
});

export default connect(mapStateToProps, mapActionsToProps)(EditPathologyLimsTestPage);
