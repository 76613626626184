import React from "react";
import * as CONFIG from "../../utils/config";
import { ControlLabel, FormControl, Radio } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import OwnerUserService from "../../services/ownerUserService";
import moment from 'moment';
import Notification from "../../utils/notification";
import { personNameRegex } from '../../utils/helpers'

const sendNewPasswordEmail = (email) => {
    OwnerUserService.resendEmail(email)
        .then(resp => {
            Notification.show('success', resp.data);
        })
        .catch(error => {
            if (error.response && error.response.status) {
                let errorData = error.response.data;
                Notification.show('error', errorData);
            } else {
                Notification.show('error', { message: "Something Went Wrong, Try Again" });
            }
        });
}

const Owner = ({ idx, owner, handleOwnerChange, validator }) => {
    return (
        <React.Fragment>
            <div className="box">
                <div className="box-body">
                    <h4>Personal Details
                    {(owner && owner.email && !owner.is_active) && <span className="pull-right"><Link className="btn btn-success" title="Resend Verification Email" onClick={() => sendNewPasswordEmail(owner.email)}><i className="fa fa-envelope"></i></Link></span>}
                    </h4>
                    <hr />
                    <div className="row form-group">
                        <div className="col-xs-3">
                            <ControlLabel>Select Name Title: <span>*</span></ControlLabel>
                            <FormControl name="name_title" componentClass="select" value={owner.name_title} onChange={(e) => handleOwnerChange(e, idx)}
                                placeholder="Select Title">
                                <option value="">Select Title</option>
                                {CONFIG.NAMETITLE.map((value) => {
                                    return <option value={value}>{value}</option>
                                })}
                            </FormControl>
                            {validator.message('name_title', owner.name_title, 'required')}
                        </div>

                        <div className="col-xs-4">
                            <ControlLabel>First Name: <span>*</span></ControlLabel>
                            <FormControl
                                name='first_name'
                                type="text"
                                value={owner.first_name}
                                placeholder="Enter your first name"
                                onChange={(e) => handleOwnerChange(e, idx)}
                                autoComplete="none"
                            />
                            {validator.message('first_name', owner.first_name, ['required', 'string', { regex: personNameRegex() }])}
                        </div>

                        <div className="col-xs-4">
                            <ControlLabel>Last Name: <span>*</span></ControlLabel>
                            <FormControl
                                name='last_name'
                                type="text"
                                value={owner.last_name}
                                placeholder="Enter your last name"
                                onChange={(e) => handleOwnerChange(e, idx)}
                                autoComplete="none"
                            />
                            {validator.message('last_name', owner.last_name, ['required', 'string', { regex: personNameRegex() }])}
                        </div>
                    </div>

                    <div className="row form-group">
                        <div className="col-xs-5">
                            <ControlLabel>Email: <span>*</span></ControlLabel>
                            <FormControl
                                name='email'
                                type="email"
                                value={owner.email}
                                placeholder="Enter your email"
                                onChange={(e) => handleOwnerChange(e, idx)}
                                autoComplete="none"
                            />
                            {validator.message('email', owner.email, 'required|email')}
                        </div>

                        <div className="col-xs-3">
                            <ControlLabel>Contact Number: <span>*</span></ControlLabel>
                            <FormControl
                                name='contact_number'
                                type="number"
                                value={owner.contact_number}
                                placeholder="Enter your contact number"
                                onChange={(e) => handleOwnerChange(e, idx)}
                            />
                            {validator.message('contact_number', owner.contact_number, 'phone')}
                        </div>

                        <div className="col-xs-3">
                            <ControlLabel>Alternate Number/Landline Number:</ControlLabel>
                            <FormControl
                                name='alt_contact_number'
                                type="number"
                                value={owner.alt_contact_number}
                                placeholder="Enter your Alternate Number/Landline Number"
                                onChange={(e) => handleOwnerChange(e, idx)}
                            />
                            {validator.message('alternate_number', owner.alt_contact_number, 'phone')}
                        </div>
                    </div>

                    <div className="row form-group">
                        <div className="col-xs-3">
                            <ControlLabel>Gender: <span>* </span></ControlLabel>
                            <div className="radio">
                                <Radio name={'gender-' + idx} value='male' checked={owner.gender === 'male'} onChange={(e) => handleOwnerChange(e, idx)} inline> Male </Radio>
                                <Radio name={'gender-' + idx} value='female' checked={owner.gender === 'female'} onChange={(e) => handleOwnerChange(e, idx)} inline> Female </Radio>
                                <Radio name={'gender-' + idx} value='other' checked={owner.gender === 'other'} onChange={(e) => handleOwnerChange(e, idx)} inline> Other </Radio>
                            </div>
                        </div>

                        <div className="col-xs-4">
                            <ControlLabel>Date of Birth:</ControlLabel>
                            <FormControl
                                name='date_of_birth'
                                type="date"
                                value={owner.date_of_birth}
                                onChange={(e) => handleOwnerChange(e, idx)}
                                min='1800-01-01'
                                max={moment().format('YYYY-MM-DD')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Owner;
