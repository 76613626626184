import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function emergencyHospitalReducer(state = initialState.pagination, { type, ...payload }) {
    switch (type) {
        case types.EMERGENCY_HOSPITAL_LIST_REQUEST:
            return {
                ...state,
                ...payload,
                loading: true
            };
        case types.EMERGENCY_HOSPITAL_LIST_SUCCESS:
            return {
                ...state,
                data: payload.data,
                meta: payload.meta,
                loading: false
            };
        case types.EMERGENCY_HOSPITAL_LIST_FAILED:
            return {
                ...state,
                ...initialState.pagination,
                loading: false
            };
        default:
            return state;
    }
}