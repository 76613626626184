import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { Link } from "react-router-dom";
import { FormControl, Alert, Col, FormGroup } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import * as CardAction from '../../actions/cardActions';
import CardService from '../../services/cardService';
import { Helmet } from 'react-helmet';
import * as HELPER from '../../utils/helpers';
import _ from "lodash";
import Notification from '../../utils/notification';
import AssignReIssueCard from '../../components/AssignReissueCard';

class ListReIssueCardPage extends React.Component {

    constructor(props) {
        super(props);

        this.props.paginate({ sortBy: { sortField: 'updated_at', sortOrder: 'desc' } });
        this.state = {
            disabled: true,
            requestId: [],
            filter: {},
            data:{},
            listing_section: true,
            assign_card_section: false
        };
    }

    columns = () => {
        return [{
            dataField: "status",
            formatter: this.checkBoxFormatter
        }, {
            dataField: 'patient_name',
            text: 'Patient Name',
        }, {
            dataField: 'service_provider_name',
            text: 'Service Provider Name',
        }, {
            dataField: 'user_location_name',
            text: 'User Location Name',
        }, {
            dataField: 'is_assigned',
            text: 'Is Assigned',
            formatter: cell => {
                return cell ? 'Yes' : 'No';
            }
        }, {
            dataField: 'status',
            text: 'Status',
            sort: true,
            formatter: cell => {
                return _.capitalize(cell);
            }
        }, {
            dataField: 'created_at',
            text: 'Created Date',
            sort: true,
            formatter: cell => {
                return HELPER.getDateFormat(cell);
            }
        }, {
            dataField: 'updated_at',
            text: 'Updated Date',
            sort: true,
            formatter: cell => {
                return HELPER.getDateFormat(cell);
            }
        },{
            dataField: 'actions',
            text: 'Action',
            formatter: this.actionFormatter
        }];
    };

    actionFormatter = (cell, row) => {
        if( row.status === 'pending'){ 
            return <Link className="btn btn-primary" onClick={this.assignBtnClick.bind(this, row)}>Assign Card</Link> 
        }
        return;
    }

    assignBtnClick = (data) => {
        this.setState({
            data:data,
            assign_card_section:true,
            listing_section:false 
        });
    }

    handleCancelBtnClick = () => {
        this.setState({
            data:{},
            assign_card_section:false,
            listing_section:true 
        });
    }

    handleAssignedSubmit = () => {
        this.setState({
            data:{},
            assign_card_section:false,
            listing_section:true 
        });

        this.props.paginate({ sortBy: { sortField: 'updated_at', sortOrder: 'desc' } });
    }

    checkBoxFormatter = (cell, row) => {
        if (cell !== 'delivered' && cell !== 'completed' && cell !== 'pending') {
            return (
                <React.Fragment>
                    <input type="checkbox" id={"checkbox" + row.request_id} name="request_id" value={row.request_id} onClick={this.handleCheckboxChange} />
                    <label for={"checkbox" + row.request_id}></label>
                </React.Fragment>);
        }
    }

    handleChange = e => {
        if (e.target.name === 'action_type') {
            if (!_.isEmpty(e.target.value)) {
                if (!_.isEmpty(this.state.requestId)) {
                    this.setState({
                        disabled: false
                    });
                }
                this.setState({
                    action_type: e.target.value
                });
            } else {
                this.setState({
                    disabled: true,
                    action_type: ''
                });
            }
        } else {
            // let name = e.target.name;
            let value = e.target.value;

            this.setState({
                filter: {
                    status: value
                }
            });
        }
    }

    handleCheckboxChange = e => {
        if (e.target.name === 'request_id') {
            if (e.target.checked) {
                this.setState({
                    requestId: this.state.requestId.concat(e.target.value)
                });
                if (!_.isEmpty(this.state.action_type)) {
                    this.setState({
                        disabled: false
                    });
                }
            } else {
                const requestId = this.state.requestId.filter(function (item) {
                    return item.search(e.target.value) === -1;
                });
                this.setState({ all_checked: false, requestId: requestId });
                if (_.isEmpty(requestId)) {
                    this.setState({
                        disabled: true
                    });
                }
            }
        }
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {

        if (type === 'search') {
            page = 1;
        }

        let filter = this.state.filter;

        this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText, filter });
    }

    handleFilterSubmit = () => {
        let filter = this.state.filter;

        this.props.paginate({ sortBy: { sortField: 'updated_at', sortOrder: 'desc' }, filter });
    }

    handleReset = () => {
        this.setState({
            status_type: '',
            filter: {}
        });

        this.props.paginate({ sortBy: { sortField: 'updated_at', sortOrder: 'desc' } });
    }

    handleSubmitAction = () => {
        let postData = {
            'request_ids': this.state.requestId,
            'status': this.state.action_type
        };

        CardService.processReIssueCard(postData)
            .then(resp => {
                this.setState({
                    requestId: [],
                    action_type: ''
                });
                var checkboxes = document.getElementsByName("request_id");
                Notification.show('success', { message: resp.data.message });
                for (var i = 0, n = checkboxes.length; i < n; i++) {
                    checkboxes[i].checked = false;
                }
                this.props.paginate({ sortBy: { sortField: 'updated_at', sortOrder: 'desc' } });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    Notification.show('error', { message: errorData.message });
                } else {
                    Notification.show('error', { message: 'Something Went Wrong, Try Again' });
                }
            });
    }

    render() {
        let { data, meta, alert } = this.props;

        return <div>
            <Helmet>
                <title>List Re-Issue Card Requests</title>
            </Helmet>
            { this.state.listing_section && <Content title="Re-Issue Card Requests">
                <Box>
                    <Box.Body>
                        <FormGroup>
                            <Col sm={3}>
                                <FormControl name="status" componentClass="select" value={this.state.status} onChange={this.handleChange}>
                                    <option value="">Select Status</option>
                                    <option value="pending">Pending</option>
                                    <option value="assigned">Assigned</option>
                                    <option value="dispatched">Dispatched</option>
                                    <option value="delivered">Delivered</option>
                                </FormControl>
                            </Col>
                            <div className="col-md-12 btn-toolbar">
                                <button type="submit" className="btn btn-primary  pull-right" onClick={this.handleFilterSubmit}>Search</button>
                                <button type="reset" className="btn btn-danger  pull-right" onClick={this.handleReset}> <i className="fa fa-repeat"></i> Reset</button>
                            </div>
                        </FormGroup>
                    </Box.Body>
                </Box>
                <Box>
                    <Box.Body>
                        {alert && alert.message && <Alert bsStyle={alert.type}>{alert.message}</Alert>}
                        {HELPER.hasPermission(this.props.admin, ['duplicate-request-action']) && <FormGroup className="" controlId="">
                            <Col sm={3}>
                                <FormControl name="action_type" componentClass="select" value={this.state.action_type} onChange={this.handleChange}>
                                    <option value="" selected disabled>Select Action</option>
                                    <option value="dispatch_card">Dispatch Card</option>
                                    <option value="deliver_card">Delivered Card</option>
                                </FormControl>
                            </Col>
                            <Col sm={6}>
                                <button type="submit" onClick={this.handleSubmitAction} className="btn btn-primary" disabled={this.state.disabled}>Submit</button>
                            </Col>
                        </FormGroup>}
                        <ToolkitProvider
                            search
                            keyField="id"
                            data={data}
                            columns={this.columns()}
                        >
                            {
                                props => [
                                    <div className="pull-right"><Search.SearchBar {...props.searchProps} delay={1000} /></div>,
                                    <BootstrapTable
                                        {...props.baseProps}
                                        remote
                                        onTableChange={this.handleTableChange}
                                        pagination={paginationFactory({
                                            page: parseInt(meta.current_page),
                                            sizePerPage: parseInt(meta.per_page),
                                            totalSize: parseInt(meta.total),
                                        })}
                                        rowStyle={this.rowStyle}
                                    />
                                ]
                            }
                        </ToolkitProvider>

                    </Box.Body>
                </Box> 
            </Content>}
            {this.state.assign_card_section && <AssignReIssueCard
                patient={this.state.data}
                handleCancelBtnClick={this.handleCancelBtnClick}
                handleSubmit={this.handleAssignedSubmit}
                />
            }
        </div>;
    }
}

const mapStateToProps = (state) => ({
    ...state.reissue_card_request,
    alert: state.alert,
    admin: state.auth.admin
});

const mapActionsToProps = ({
    paginate: CardAction.listReIssueCardRequest
})

export default connect(mapStateToProps, mapActionsToProps)(ListReIssueCardPage);
