import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function nav(state = initialState.nav, { type, ...payload }) {
  switch (type) {
    case types.UPDATE_NAV:
      return {
        ...payload
      };
    default:
      return state;
  }
}