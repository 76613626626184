export const LOGOUT = 'LOGOUT';
export const RESET_ALL = 'RESET_ALL';

export const ALERT_CLEAR = 'ALERT_CLEAR';
export const ALERT_SUCCESS = 'ALERT_SUCCESS';
export const ALERT_ERROR = 'ALERT_ERROR';
export const ALERT_WARNING = 'ALERT_WARNING';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const PATIENT_LIST_REQUEST = 'PATIENT_LIST_REQUEST';
export const PATIENT_LIST_SUCCESS = 'PATIENT_LIST_SUCCESS';
export const PATIENT_LIST_FAILED = 'PATIENT_LIST_FAILED';

export const PHARMACY_LIST_REQUEST = 'PHARMACY_LIST_REQUEST';
export const PHARMACY_LIST_SUCCESS = 'PHARMACY_LIST_SUCCESS';
export const PHARMACY_LIST_FAILED = 'PHARMACY_LIST_FAILED';

export const ADD_PHARMACY = 'ADD_PHARMACY';
export const DELETE_PHARMACY = 'DELETE_PHARMACY';
export const FETCH_PHARMACY = 'FETCH_PHARMACY';
export const UPDATE_PHARMACY = 'UPDATE_PHARMACY';


export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const DOCTOR_LIST_REQUEST = 'DOCTOR_LIST_REQUEST';
export const DOCTOR_LIST_SUCCESS = 'DOCTOR_LIST_SUCCESS';
export const DOCTOR_LIST_FAILED = 'DOCTOR_LIST_FAILED';

export const COMMUNITY_LIST_REQUEST = 'COMMUNITY_LIST_REQUEST';
export const COMMUNITY_LIST_SUCCESS = 'COMMUNITY_LIST_SUCCESS';
export const COMMUNITY_LIST_FAILED = 'COMMUNITY_LIST_FAILED';

export const ADD_COMMUNITY = 'ADD_COMMUNITY';

export const ADD_DOCTOR = 'ADD_DOCTOR';
export const DELETE_DOCTOR = 'DELETE_DOCTOR';
export const FETCH_DOCTOR = 'FETCH_DOCTOR';
export const UPDATE_DOCTOR = 'UPDATE_DOCTOR';

export const DRUG_LIST_REQUEST = 'DRUG_LIST_REQUEST';
export const DRUG_LIST_SUCCESS = 'DRUG_LIST_SUCCESS';
export const DRUG_LIST_FAILED = 'DRUG_LIST_FAILED';

export const ADD_DRUG = 'ADD_DRUG';
export const DELETE_DRUG = 'DELETE_DRUG';
export const FETCH_DRUG = 'FETCH_DRUG';
export const UPDATE_DRUG = 'UPDATE_DRUG';

export const ADD_PATIENT = 'ADD_PATIENT';
export const DELETE_PATIENT = 'DELETE_PATIENT';
export const FETCH_PATIENT = 'FETCH_PATIENT';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';

export const UPDATE_NAV = 'UPDATE_NAV';

export const ADMINUSER_LIST_REQUEST = 'ADMINUSER_LIST_REQUEST';
export const ADMINUSER_LIST_SUCCESS = 'ADMINUSER_LIST_SUCCESS';
export const ADMINUSER_LIST_FAILED = 'ADMINUSER_LIST_FAILED';

export const ADD_ADMINUSER = 'ADD_ADMINUSER';
export const DELETE_ADMINUSER = 'DELETE_ADMINUSER';
export const FETCH_ADMINUSER = 'FETCH_ADMINUSER';
export const UPDATE_ADMINUSER = 'UPDATE_ADMINUSER';

export const PRESCRIPTION_LIST_REQUEST = 'PRESCRIPTION_LIST_REQUEST';
export const PRESCRIPTION_LIST_SUCCESS = 'PRESCRIPTION_LIST_SUCCESS';
export const PRESCRIPTION_LIST_FAILED = 'PRESCRIPTION_LIST_FAILED';
export const PRESCRIPTION_ORDERS = "PRESCRIPTION_ORDERS";

export const NURSE_LIST_REQUEST = 'NURSE_LIST_REQUEST';
export const NURSE_LIST_SUCCESS = 'NURSE_LIST_SUCCESS';
export const NURSE_LIST_FAILED = 'NURSE_LIST_FAILED';

export const ADD_NURSE = 'ADD_NURSE';
export const DELETE_NURSE = 'DELETE_NURSE';
export const FETCH_NURSE = 'FETCH_NURSE';
export const UPDATE_NURSE = 'UPDATE_NURSE';

export const RECEPTIONIST_LIST_REQUEST = 'RECEPTIONIST_LIST_REQUEST';
export const RECEPTIONIST_LIST_SUCCESS = 'RECEPTIONIST_LIST_SUCCESS';
export const RECEPTIONIST_LIST_FAILED = 'RECEPTIONIST_LIST_FAILED';

export const ADD_RECEPTIONIST = 'ADD_RECEPTIONIST';
export const DELETE_RECEPTIONIST = 'DELETE_RECEPTIONIST';
export const FETCH_RECEPTIONIST = 'FETCH_RECEPTIONIST';
export const UPDATE_RECEPTIONIST = 'UPDATE_RECEPTIONIST';

export const PATIENT_SEARCH_SUCCESS = 'PATIENT_SEARCH_SUCCESS';
export const PATIENT_SEARCH_FAILED = 'PATIENT_SEARCH_FAILED';

export const GENERIC_DRUG_LIST_REQUEST = 'GENERIC_DRUG_LIST_REQUEST';
export const GENERIC_DRUG_LIST_SUCCESS = 'GENERIC_DRUG_LIST_SUCCESS';
export const GENERIC_DRUG_LIST_FAILED = 'GENERIC_DRUG_LIST_FAILED';

export const ADD_GENERIC_DRUG = 'ADD_GENERIC_DRUG';
export const DELETE_GENERIC_DRUG = 'DELETE_GENERIC_DRUG';
export const FETCH_GENERIC_DRUG = 'FETCH_GENERIC_DRUG';
export const UPDATE_GENERIC_DRUG = 'UPDATE_GENERIC_DRUG';

export const FETCH_DRUG_SKU_PRICE = 'FETCH_DRUG_SKU_PRICE';
export const UPDATE_DRUG_SKU_PRICE = 'UPDATE_DRUG_SKU_PRICE';

export const CARD_MANAGEMENT_LIST_REQUEST = 'CARD_MANAGEMENT_LIST_REQUEST';
export const CARD_MANAGEMENT_LIST_SUCCESS = 'CARD_MANAGEMENT_LIST_SUCCESS';
export const CARD_MANAGEMENT_LIST_FAILED = 'CARD_MANAGEMENT_LIST_FAILED';

export const REISSUE_CARD_LIST_REQUEST = 'REISSUE_CARD_LIST_REQUEST';
export const REISSUE_CARD_LIST_SUCCESS = 'REISSUE_CARD_LIST_SUCCESS';
export const REISSUE_CARD_LIST_FAILED = 'REISSUE_CARD_LIST_FAILED';

export const GENERATE_PATIENT_CARDS = 'GENERATE_PATIENT_CARDS';

export const UERS_PAYMENT_LIST_REQUEST = 'UERS_PAYMENT_LIST_REQUEST';
export const UERS_PAYMENT_LIST_SUCCESS = 'UERS_PAYMENT_LIST_SUCCESS';
export const UERS_PAYMENT_LIST_FAILED = 'UERS_PAYMENT_LIST_FAILED';

export const LIST_USER_ACCOUNT = "LIST_USER_ACCOUNT";
export const REQUEST_USER_ACCOUNT = "REQUEST_USER_ACCOUNT";

export const REQUEST_COMMISSIONS = "REQUEST_COMMISSIONS";
export const LIST_COMMISSIONS = "LIST_COMMISSIONS";
export const LIST_COMMISSIONS_CLEAR = "LIST_COMMISSIONS_CLEAR";

export const RADIOLOGY_LIST_REQUEST = 'RADIOLOGY_LIST_REQUEST';
export const RADIOLOGY_LIST_SUCCESS = 'RADIOLOGY_LIST_SUCCESS';
export const RADIOLOGY_LIST_FAILED = 'RADIOLOGY_LIST_FAILED';

export const ADD_RADIOLOGY = 'ADD_RADIOLOGY';
export const DELETE_RADIOLOGY = 'DELETE_RADIOLOGY';
export const FETCH_RADIOLOGY = 'FETCH_RADIOLOGY';
export const UPDATE_RADIOLOGY = 'UPDATE_RADIOLOGY';

export const PATHOLOGY_LIST_REQUEST = "PATHOLOGY_LIST_REQUEST";
export const PATHOLOGY_LIST_SUCCESS = "PATHOLOGY_LIST_SUCCESS";
export const PATHOLOGY_LIST_FAILED = "PATHOLOGY_LIST_FAILED";

export const ADD_PATHOLOGY = "ADD_PATHOLOGY";
export const DELETE_PATHOLOGY = "DELETE_PATHOLOGY";
export const FETCH_PATHOLOGY = "FETCH_PATHOLOGY";
export const UPDATE_PATHOLOGY = "UPDATE_PATHOLOGY";

export const PATHOLOGY_TEST_LIST_REQUEST = "PATHOLOGY_TEST_LIST_REQUEST";
export const PATHOLOGY_TEST_LIST_SUCCESS = "PATHOLOGY_TEST_LIST_SUCCESS";
export const PATHOLOGY_TEST_LIST_FAILED = "PATHOLOGY_TEST_LIST_FAILED";
export const PATHOLOGY_TEST_LIST_CLEAR = "PATHOLOGY_TEST_LIST_CLEAR";

export const ADD_PATHOLOGY_TEST = "ADD_PATHOLOGY_TEST";
export const DELETE_PATHOLOGY_TEST = "DELETE_PATHOLOGY_TEST";
export const FETCH_PATHOLOGY_TEST = "FETCH_PATHOLOGY_TEST";
export const UPDATE_PATHOLOGY_TEST = "UPDATE_PATHOLOGY_TEST";

export const RADIOLOGY_TEST_LIST_REQUEST = "RADIOLOGY_TEST_LIST_REQUEST";
export const RADIOLOGY_TEST_LIST_SUCCESS = "RADIOLOGY_TEST_LIST_SUCCESS";
export const RADIOLOGY_TEST_LIST_FAILED = "RADIOLOGY_TEST_LIST_FAILED";
export const RADIOLOGY_TEST_LIST_CLEAR = "RADIOLOGY_TEST_LIST_CLEAR";

export const ADD_RADIOLOGY_TEST = "ADD_RADIOLOGY_TEST";
export const DELETE_RADIOLOGY_TEST = "DELETE_RADIOLOGY_TEST";
export const FETCH_RADIOLOGY_TEST = "FETCH_RADIOLOGY_TEST";
export const UPDATE_RADIOLOGY_TEST = "UPDATE_RADIOLOGY_TEST";

export const CARD_BATCH_LIST_RESET = 'CARD_BATCH_LIST_RESET';
export const CARD_BATCH_LIST_REQUEST = 'CARD_BATCH_LIST_REQUEST';
export const CARD_BATCH_LIST_SUCCESS = 'CARD_BATCH_LIST_SUCCESS';
export const CARD_BATCH_LIST_FAILED = 'CARD_BATCH_LIST_FAILED';

export const ROLE_LIST_REQUEST = 'ROLE_LIST_REQUEST';
export const ROLE_LIST_SUCCESS = 'ROLE_LIST_SUCCESS';
export const ROLE_LIST_FAILED = 'ROLE_LIST_FAILED';

export const ADD_TRIAL_ACCOUNT = "ADD_TRIAL_ACCOUNT";
export const DELETE_TRIAL_ACCOUNT = "DELETE_TRIAL_ACCOUNT";
export const FETCH_TRIAL_ACCOUNT = "FETCH_TRIAL_ACCOUNT";
export const UPDATE_TRIAL_ACCOUNT = "UPDATE_TRIAL_ACCOUNT";

export const LIST_SERVICE_PROVIDER_INVOICES = "LIST_SERVICE_PROVIDER_INVOICES";
export const LIST_SERVICE_PROVIDER_STATEMENTS = "LIST_SERVICE_PROVIDER_STATEMENTS";
export const RESET_SERVICE_PROVIDER_LIST = "RESET_SERVICE_PROVIDER_LIST";

export const CPT_LIST_REQUEST = 'CPT_LIST_REQUEST';
export const CPT_LIST_SUCCESS = 'CPT_LIST_SUCCESS';
export const CPT_LIST_FAILED = 'CPT_LIST_FAILED';
export const ICD_LIST_REQUEST = 'ICD_LIST_REQUEST';
export const ICD_LIST_SUCCESS = 'ICD_LIST_SUCCESS';
export const ICD_LIST_FAILED = 'ICD_LIST_FAILED';
//export const SERVICE_PROVIDER_ORDER_LIST = 'SERVICE_PROVIDER_ORDER_LIST';
export const AMBULANCE_LIST_REQUEST = 'AMBULANCE_LIST_REQUEST';
export const AMBULANCE_LIST_SUCCESS = 'AMBULANCE_LIST_SUCCESS';
export const AMBULANCE_LIST_FAILED = 'AMBULANCE_LIST_FAILED';
export const EMERGENCY_HOSPITAL_LIST_REQUEST = 'EMERGENCY_HOSPITAL_LIST_REQUEST';
export const EMERGENCY_HOSPITAL_LIST_SUCCESS = 'EMERGENCY_HOSPITAL_LIST_SUCCESS';
export const EMERGENCY_HOSPITAL_LIST_FAILED = 'EMERGENCY_HOSPITAL_LIST_FAILED';
export const COVID_CENTER_LIST_REQUEST = 'COVID_CENTER_LIST_REQUEST';
export const COVID_CENTER_LIST_SUCCESS = 'COVID_CENTER_LIST_SUCCESS';
export const COVID_CENTER_LIST_FAILED = 'COVID_CENTER_LIST_FAILED';

export const AIRLINE_LIST_REQUEST = 'AIRLINE_LIST_REQUEST';
export const AIRLINE_LIST_SUCCESS = 'AIRLINE_LIST_SUCCESS';
export const AIRLINE_LIST_FAILED = 'AIRLINE_LIST_FAILED';

export const ACTIVITY_LOG_REQUEST = 'ACTIVITY_LOG_REQUEST';
export const ACTIVITY_LOG_SUCCESS = 'ACTIVITY_LOG_SUCCESS';
export const ACTIVITY_LOG_FAILED = 'ACTIVITY_LOG_FAILED';

export const REFERRAL_REPORT_REQUEST = 'REFERRAL_REPORT_REQUEST';
export const REFERRAL_REPORT_SUCCESS = 'REFERRAL_REPORT_SUCCESS';
export const REFERRAL_REPORT_FAILED = 'REFERRAL_REPORT_FAILED';


export const TEST_RESULT_REPORT_REQUEST = 'TEST_RESULT_REPORT_REQUEST';
export const TEST_RESULT_REPORT_SUCCESS = 'TEST_RESULT_REPORT_SUCCESS';
export const TEST_RESULT_REPORT_FAILED = 'TEST_RESULT_REPORT_FAILED';