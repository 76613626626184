import React from "react";
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { SelectDetail, Filter } from '../../components/ActivityLogComponents';
import { Helmet } from 'react-helmet';
import DataTable from '../../components/DataTable';
import PrescriptionService from '../../services/prescriptionService';
import Notification from "../../utils/notification";
import * as LoaderAction from "../../actions/loaderAction";
import LocationService from "../../services/locationService";
import * as ActivityLogAction from '../../actions/activityLogAction';
import moment from "moment";
import { getDateTimeFormat } from '../../utils/helpers'
import Error404 from '../dashboard/Error404Page'

const pageTitle = {
    'covid-center': "Covid Activity Log",
    'authority-access': "Authority Access Activity Log"
}
class ActivityLogPage extends React.Component {
    constructor(props) {
        super(props)
        const { params: { service_provider_type } } = this.props.match;

        this.state = {
            service_providers: [],
            provider_locations: [],
            activity_logs: [],
            isSubmitted: false,
            filter: {
                service_provider_id: '',
                user_location_id: ''
            },
            service_provider_type: service_provider_type
        }
    }
    componentDidUpdate() {
        const { params: { service_provider_type } } = this.props.match;
        if (service_provider_type !== this.state.service_provider_type) {
            window.location.reload()
        }
    }
    columns = () => {
        return [{
            dataField: 'timestamp',
            text: 'Timestamp',
            formatter: (cell, row) => {
                return getDateTimeFormat(cell)
            },
            sort: false
        }, {
            dataField: 'actor_name',
            text: 'Staff Name',
        }, {
            dataField: 'entity_id',
            text: 'Patient Code',
        }, {
            dataField: 'event_label',
            text: 'Action',
            sort: false
        }, {
            dataField: 'ip_address',
            text: 'IP address',
            sort: false
        }]
    }

    componentDidMount() {
        if (['covid-center', 'authority-access'].includes(this.state.service_provider_type)) {
            this.props.loaderShow();
            let serviceProvider = this.state.service_provider_type === "authority-access" ? "airline" : this.state.service_provider_type
            PrescriptionService.getAllServiceProviders(serviceProvider).then(resp => {
                this.props.loaderHide();
                this.setState({
                    service_providers: resp.data.data
                })
            }).catch(error => {
                this.props.loaderHide()
                Notification.show('error', error.response.data)
            })
        }
    }
    onChange = (e) => {
        let target = e.target;
        if (target.name === 'service_provider') {
            if (target.value === '') {
                this.setState({ provider_locations: [] })
            } else {
                this.props.loaderShow()
                LocationService.getLocationByServiceProviderId(target.value).then(resp => {
                    this.props.loaderHide();
                    this.setState({
                        filter: {
                            service_provider_id: target.value,
                            user_location_id: ''
                        },
                        provider_locations: resp.data,
                    })
                }).catch(error => {
                    this.props.loaderHide()
                    Notification.show('error', error.response.data)
                })
            }
        }

        if (target.name === 'provider_locations') {
            if (!this.state.filter.service_provider_id) {
                Notification.show('error', { message: 'Kindly select service provider' })
            } else {
                let { filter } = this.state;
                filter['user_location_id'] = target.value
                this.setState({ filter });
            }
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        if (!this.state.filter.service_provider_id || !this.state.filter.user_location_id) {
            Notification.show('error', { message: 'Kindly fill required details' });
            return false
        } else {
            const filter = {
                service_provider_id: this.state.filter.service_provider_id,
                user_location_id: this.state.filter.user_location_id,
            }
            this.setState({ isSubmitted: true, filter })
            this.props.paginate(this.state.service_provider_type, { filter: filter });
        }
    }
    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        if (type === 'search') {
            page = 1;
        }
        this.props.paginate(this.state.service_provider_type, { page, sizePerPage, sortby: { sortField, sortOrder }, searchText, filter: this.state.filter });
    }
    handleEvent = (_event, picker) => {
        let { filter } = this.state
        filter.start_date = (picker.startDate._d) ? moment(picker.startDate._d).format('YYYY-MM-DD') : '';
        filter.end_date = (picker.endDate._d) ? moment(picker.endDate._d).format('YYYY-MM-DD') : '';
        filter.show_start_date = picker.startDate._d;
        filter.show_end_date = picker.endDate._d;
        this.props.paginate(this.state.service_provider_type, { filter })
    }
    resetFilter = (e) => {
        this.setState({
            filter: {
                service_provider_id: '',
                user_location_id: '',
                start_date: '',
                end_date: '',
                show_start_date: new Date(),
                show_end_date: new Date(),
            },
            isSubmitted: false,
            provider_locations: []
        });
    }
    resetDate = (e) => {
        e.preventDefault()
        let { filter } = this.state
        filter.start_date = ''
        filter.end_date = ''
        filter.show_start_date = new Date()
        filter.show_end_date = new Date()
        this.setState({
            filter, isSubmitted: true
        });
        this.props.paginate(this.state.service_provider_type, { filter })
    }

    render() {
        const { data, meta, loading } = this.props.activity_log;
        const { filter, service_provider_type } = this.state;
        if (!['covid-center', 'authority-access'].includes(this.state.service_provider_type)) {
            return <Error404 />;
        } else {
            return <React.Fragment>
                <Helmet>
                    <title>{pageTitle[service_provider_type]}</title>
                </Helmet>
                <Content title={pageTitle[service_provider_type]}>
                    <Box>
                        <Box.Body>
                            <SelectDetail service_providers={this.state.service_providers}
                                provider_locations={this.state.provider_locations}
                                onChange={this.onChange}
                                onSubmit={this.onSubmit}
                                onReset={this.resetFilter}
                                selected_provider={filter.service_provider_id}
                                selected_location={filter.user_location_id}
                            />
                        </Box.Body>
                    </Box>
                    {this.state.isSubmitted && <React.Fragment>
                        <Box box={"solid"}>
                            <Box.Body>
                                <Filter
                                    show_start_date={filter.show_start_date}
                                    show_end_date={filter.show_end_date}
                                    start_date={filter.start_date}
                                    end_date={filter.end_date}
                                    handleEvent={this.handleEvent}
                                    resetDate={this.resetDate} />
                            </Box.Body>
                        </Box>
                        <Box>

                            <Box.Body>
                                <DataTable
                                    loading={loading}
                                    columns={this.columns()}
                                    data={data}
                                    page={parseInt(meta.current_page)}
                                    sizePerPage={parseInt(meta.per_page)}
                                    totalSize={parseInt(meta.total)}
                                    onTableChange={this.handleTableChange}
                                    noDataIndication="No Record Found"
                                />
                            </Box.Body>
                        </Box>
                    </React.Fragment>}
                </Content>
            </React.Fragment>;
        }
    }
}
const mapActionsToProps = ({
    loaderHide: LoaderAction.loaderHide,
    loaderShow: LoaderAction.loaderShow,
    paginate: ActivityLogAction.fetchActivityLogs
})
const mapStateToProps = (state) => ({
    activity_log: state.activity_logs
})
export default connect(mapStateToProps, mapActionsToProps)(ActivityLogPage)