import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { FormGroup, ControlLabel, FormControl, Col, Grid, Table } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from 'react-helmet';
import Alert from '../../components/Alert';
import RoleManagementService from '../../services/roleManagementService';
import Notification from '../../utils/notification';
import _ from 'lodash';

class EditRolePage extends React.Component {
    constructor(props) {
        super(props);
        const { match: { params } } = this.props;
        this.state = {
            id: params.id,
            display_name: '',
            name: '',
            role_permissions: null,
            permissions: '',
            new_role_permission_ids: null,
            status: ''
        };
        this.validator = new SimpleReactValidator();
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckedData = this.handleCheckedData.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const getRole = () => {
            return new Promise((resolve, reject) => {
                RoleManagementService.view(this.state.id).then(resp => {
                    this.setState({
                        name: resp.data.name,
                        display_name: resp.data.display_name,
                        role_permissions: resp.data.permissions,
                        status: resp.data.status
                    })
                    resolve()
                }).catch(error => {
                    console.log(error)
                    resolve()
                })
            })
        }

        const getAllPermissions = () => {
            return new Promise((resolve, _reject) => {
                RoleManagementService.getPermissions().then(resp => {
                    this.setState({
                        permissions: resp.data.data
                    })
                    resolve()
                }).catch(error => {
                    console.log(error)
                    resolve()
                })
            })
        }

        Promise.all([getRole(), getAllPermissions()])
            .then(result => {
                _.forEach(this.state.role_permissions, (permission) => {
                    document.getElementById('permission-' + permission.name).checked = true;
                })
                this.setState({
                    new_role_permission_ids: _.map(this.state.role_permissions, 'permission_id')
                })
            })
    }

    renderPermissions = (role_permissions, permissions) => {
        return (
            <FormGroup className="row" controlId="">
                <Col sm={2}>
                    <ControlLabel>Permissions: <span>*</span></ControlLabel>
                </Col>
                <Table responsive bordered>
                    <tbody>
                        {role_permissions && permissions.map((permission, index) => (
                            <tr key={index}>
                                <td><input type="checkbox" id={"permission-" + permission.name} name="permissions" value={permission.permission_id} onClick={e => this.handleCheckedData(e, index)} />{permission.display_name}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </FormGroup>
        )
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleCheckedData = (e, _index) => {
        let options = [...this.state.new_role_permission_ids];
        if (e.target.checked === true) {
            options.push(e.target.value)
            this.setState({
                new_role_permission_ids: options
            })
        }
        else if (e.target.checked === false) {
            options = options.filter(option => option !== e.target.value)
            this.setState({
                new_role_permission_ids: options
            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        if (this.state.new_role_permission_ids === '') {
            Notification.show('error', { message: 'Select atleast one role.' });
        }
        else {
            const postData = {
                permission: this.state.new_role_permission_ids,
                name: this.state.name,
                display_name: this.state.display_name,
                status: this.state.status
            }
            RoleManagementService.update(postData, this.state.id).then(resp => {
                Notification.show('success', resp.data);
                this.props.history.push('/roles');
            }).catch(error => {
                Notification.show('error', error.response.data);
            })
        }
    }

    render() {
        return <div>
            <Helmet>
                <title>Edit Role</title>
            </Helmet>
            <Content title="Edit Role">
                <Box>
                    <Box.Head title="Edit Role" backTo="/roles" />
                    <Box.Body>
                        <Alert />
                        <Grid>
                            <form onSubmit={this.handleSubmit}>
                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Name: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='name'
                                            type="text"
                                            value={this.state.name}
                                            placeholder="Enter role"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('name', this.state.name, 'required|string')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Display Name: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='display_name'
                                            type="text"
                                            value={this.state.display_name}
                                            placeholder="Enter Display Name"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('display_name', this.state.display_name, 'required|string')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Status: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl name="status" componentClass="select" value={this.state.status} onChange={this.handleChange}>
                                            <option value="true">Active</option>
                                            <option value="false">Inactive</option>
                                        </FormControl>
                                        {this.validator.message('status', this.state.status, 'required')}
                                    </Col>
                                </FormGroup>

                                {this.state.permissions && this.renderPermissions(this.state.role_permissions, this.state.permissions)}

                                <FormGroup className="row" controlId="">
                                    <Col sm={6} md={6}>
                                        <button type='submit' className='btn btn-primary'>Submit</button>
                                    </Col>
                                </FormGroup>
                            </form>
                        </Grid>
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
});

const mapActionsToProps = ({
})

export default connect(mapStateToProps, mapActionsToProps)(EditRolePage);
