import * as types from '../actions/actionTypes';
import initialState from './initialState';
//import { statement } from '@babel/template';

export default function pharmacy(state = initialState.pharmacy, { type, ...payload }) {
    switch (type) {
        case types.ADD_PHARMACY:
            return {
                ...state,
                ...payload
            };
        case types.DELETE_PHARMACY:
            return {
                ...state,
                ...payload
            };
        case types.FETCH_PHARMACY:
            return {
                ...state,
                ...payload
            };
        case types.UPDATE_PHARMACY:
            return {
                ...state,
                ...payload
            }; 
        default:
            return state;
    }
}