import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { FormGroup, ControlLabel, FormControl, Col, Button, ButtonToolbar } from 'react-bootstrap';
import * as PathologyLimsCategoryAction from '../../actions/pathologyLimsCategoryActions';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from 'react-helmet';
import Alert from '../../components/Alert';
import PathologyLimsCategoryService from '../../services/pathologyLimsCategoryService';

class EditPathologyLimsCategoryPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            category: '',
            status: '',
        };

        this.validator = new SimpleReactValidator();
        this.handleChange = this.handleChange.bind(this); 
        this.validateAndSubmit = this.validateAndSubmit.bind(this);
    

        const { match: { params } } = this.props;
        const id = params.id;
        this.fetchPathologyLimsCategory(id);
    }

    fetchPathologyLimsCategory = (id) =>{
            PathologyLimsCategoryService.fetchPathologyLimsCategory(id)
            .then(resp => {
                let data = resp.data;
                this.setState({...data});
            })
            .catch(error => {
               console.log(error);
            });

    }

    handleChange(e) {
        const name = e.target.name;
        const value = e.target.value;
            this.setState({
                [name]: value
            });
    }

    validateAndSubmit(e) {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        const postData = {
            name:this.state.name,
            status: this.state.status,
        };

        this.props.pathologyLimsCategoryUpdate(this.state.id, postData, this.props.history);
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Edit Lims Category</title>
                </Helmet>
                <Content title="Edit Lims Category">
                    <Box>
                        <Box.Body>
                            <Alert />
                                <form onSubmit={this.validateAndSubmit}>
                                    <FormGroup className="row" controlId="">
                                        <Col sm={2}>
                                            <ControlLabel>Category <span>*</span></ControlLabel>
                                        </Col>
                                        <Col sm={6}>
                                            <FormControl
                                                name='name'
                                                type="text"
                                                value={this.state.name}
                                                placeholder="Enter Category"
                                                onChange={this.handleChange}
                                                autoComplete="none"
                                            />
                                            {this.validator.message('name', this.state.name, 'required|string')}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row" controlId="">
                                        <Col sm={2}>
                                            <ControlLabel>Status <span>*</span></ControlLabel>
                                        </Col>
                                        <Col sm={6}>
                                            <FormControl name="status" componentClass="select" value={this.state.status} onChange={this.handleChange} placeholder="Select Test Type">
                                                <option value="1">Active</option>
                                                <option value="0">Inactive</option>
                                            </FormControl>
                                        </Col>
                                    </FormGroup>

                                    <FormGroup className="row" controlId="">
                                        <Col sm={6} md={6}>
                                            <ButtonToolbar>
                                                <Button type='submit' bsStyle="primary">Update</Button>
                                                <a href="/lims-categories" className="btn btn-info">Back</a>
                                            </ButtonToolbar>
                                        </Col>
                                    </FormGroup>
                                </form>
                        </Box.Body>
                    </Box>
                </Content>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
});

const mapActionsToProps = ({
    pathologyLimsCategoryUpdate: PathologyLimsCategoryAction.pathologyLimsCategoryUpdate
});

export default connect(mapStateToProps, mapActionsToProps)(EditPathologyLimsCategoryPage);
