import * as types from './actionTypes';
import IcdService from '../services/icdService';

export function list({page, sizePerPage, sortBy, searchText, filter}) {
    return dispatch => {
        dispatch({ type: types.ICD_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText } });
        dispatch({ type: types.ALERT_CLEAR, isloading: true});
        IcdService.list({page, sizePerPage, sortBy, searchText, filter})
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.ICD_LIST_SUCCESS, ...data });
                dispatch({ type: types.ALERT_CLEAR });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}