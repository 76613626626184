import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { ButtonToolbar, Label } from 'react-bootstrap';
import * as AdminUserAction from '../../actions/adminuserActions';
import { Helmet } from 'react-helmet';
import Alert from '../../components/Alert';
import AuthService from "../../services/authService";
import Notification from "../../utils/notification";
import * as HELPER from '../../utils/helpers';
import DataTable from '../../components/DataTable';
import AdminUserService from '../../services/adminuserService';
import ActivateDeactivateModal from '../../components/ActivateDeactivateUserModal';
import { getUser } from '../../utils/auth';

class ListAdminUserPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            admin_id: '',
            name: '',
            status: '',
            show: false
        }

        this.handleClose = this.handleClose.bind(this);
        this.handleActivateDeactivateAdmin = this.handleActivateDeactivateAdmin.bind(this);
    }

    handleClose() {
        this.setState({
            admin_id: '',
            name: '',
            status: '',
            show: false
        })
    }

    componentDidMount() {
        this.props.paginate({});
    }

    columns = () => {
        return [{
            dataField: 'admin_id',
            text: '# Code'
        }, {
            dataField: 'full_name',
            text: 'Full Name',
            sort: true
        }, {
            dataField: 'email',
            text: 'Email',
            sort: true
        }, {
            dataField: 'contact_number',
            text: 'Contact No.',
            sort: true
        }, {
            dataField: 'role_names',
            text: 'Role',
            sort: false,
        }, {
            dataField: 'status',
            text: 'Status',
            sort: false,
            formatter: cell => {
                return cell ? <Label bsStyle="success">Active</Label> : <Label>Inactive</Label>
            }
        }, {
            dataField: 'actions',
            text: 'Actions',
            isDummyField: true,
            formatter: this.actionFormatter
        }];
    };


    actionFormatter = (cell, row) => {
        return <ButtonToolbar>
            {HELPER.hasPermission(this.props.admin, ['admin-view']) && <Link className="btn btn-sm btn-info" to={'/admin/' + row.admin_id}><i className="fa fa-eye" aria-hidden="true"></i></Link>}
            {HELPER.hasPermission(this.props.admin, ['admin-edit']) && <Link className="btn btn-sm btn-warning" to={'/admin/' + row.admin_id + '/edit'}><i className="fa fa-pencil"></i></Link>}
            {HELPER.hasPermission(this.props.admin, ['admin-send-email']) && row.is_email_verified === 0 && <Link className="btn btn-sm btn-success" onClick={this.sendNewPasswordEmail.bind(this, row.email)}><i className="fa fa-envelope"></i></Link>}
            {HELPER.hasRole(this.props.admin, ['super-admin']) && getUser().admin_id !== row.admin_id && row.is_email_verified === 1 && <Link className="btn btn-sm btn-success" onClick={this.toggleAction.bind(this, row.admin_id, row.full_name, row.status)}><i class={row.status === 0 ? 'fa fa-toggle-off' : 'fa fa-toggle-on'}></i></Link>
            }
        </ButtonToolbar>;
    }

    async toggleAction(id, fullName, status) {
        this.setState({
            admin_id: id,
            name: fullName,
            status: status,
            show: true
        })
    }

    async handleActivateDeactivateAdmin() {
        try {
            console.log(JSON.stringify(getUser()));
            console.log(getUser());
            console.log(getUser().admin_id);
            let data = {
                id: this.state.admin_id,
                status: this.state.status === 1 ? 'inactive' : 'active'
            }
            await AdminUserService.superadminuserupdate(data);
            Notification.show('success', { message: this.state.status === 1 ? 'User Deactivated Successfully' : 'User Activated Successfully' });
            this.setState({
                show: false
            })
            this.props.paginate({});
        } catch(error) {
            if (error.response && error.response.status) {
                let errorData = error.response.data;
                Notification.show('error', errorData);
            } else {
                Notification.show('error', { message: "Something Went Wrong, Try Again" });
            }
        }
    }

    sendNewPasswordEmail(email) {
        AuthService.resendEmail(email)
            .then(resp => {
                Notification.show('success', resp.data);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    Notification.show('error', errorData);
                } else {
                    Notification.show('error', { message: "Something Went Wrong, Try Again" });
                }
            });
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        if (type === 'search') {
            page = 1;
        }
        this.props.paginate({ page, sizePerPage, sortby: { sortField, sortOrder }, searchText });
    }


    render() {
        let { data, meta, loading } = this.props.adminuser_list;

        return (
        <>
            <div>
                <Helmet>
                    <title>List Admin Users</title>
                </Helmet>
                <Content title="Admin Users">
                    <Box>
                        <Box.Body>
                            <Alert />
                            {HELPER.hasPermission(this.props.admin, ['admin-add']) && <Link to="/admins/add" className="btn btn-success"><span className="fa fa-plus"></span> Add Admin User</Link>}
                            <DataTable
                                loading={loading}
                                columns={this.columns()}
                                data={data}
                                page={parseInt(meta.current_page)}
                                sizePerPage={parseInt(meta.per_page)}
                                totalSize={parseInt(meta.total)}
                                onTableChange={this.handleTableChange}
                            />
                        </Box.Body>
                    </Box>
                </Content>
            </div>
            <ActivateDeactivateModal 
                show={this.state.show}
                handleModal={this.handleClose}
                onSubmit={this.handleActivateDeactivateAdmin}
                status={this.state.status}
                name={this.state.name}
            />
        </>
        )
    }
}

const mapStateToProps = (state) => ({
    adminuser_list: state.adminuser_list,
    admin: state.auth.admin
});

const mapActionsToProps = ({
    paginate: AdminUserAction.list,
})

export default connect(mapStateToProps, mapActionsToProps)(ListAdminUserPage);
