import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { ButtonToolbar } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Helmet } from 'react-helmet';
import Alert from '../../components/Alert';
import * as CptActon from '../../actions/cptActions';

class ListCpt extends React.Component {
    componentDidMount() {
        this.props.paginate({ sortBy: { sortField: 'updated_at', sortOrder: 'desc' } });
    }

    columns = (data) => {
        return [
            {
                dataField: 'cpt_code',
                text: 'CPT code',
                sort: true
            }, {
                dataField: 'short_description',
                text: 'Short Description',
            }, {
                dataField: 'medium_description',
                text: 'Medium Description',
                sort: true
            }, {
                dataField: 'full_description',
                text: 'Full Description',
            }, {
                dataField: 'status',
                text: 'Status',
            },
            {
                dataField: 'actions',
                text: 'Actions',
                isDummyField: true,
                formatter: this.actionFormatter
            }
        ];
    };

    actionFormatter = (cell, row) => {
        return <ButtonToolbar>
            <Link className="btn btn-info" to={'/cpts/' + row.cpt_code}><i className="fa fa-eye" aria-hidden="true"></i></Link>
            {/* <Link className="btn btn-warning" to={'/cpts/' + row.cpt_code + '/edit'}><i className="fa fa-pencil"></i></Link> */}
        </ButtonToolbar>;
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {

        if (type === 'search') {
            page = 1;
        }

        this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText });
    }

    render() {
        let { data, meta } = this.props.cpt_list;

        return <div>
            <Helmet>
                <title>List CPT</title>
            </Helmet>
            <Content title="CPT">
                <Box>
                    <Box.Body>
                        <Alert />
                        <span className="mr-2"><Link to="/cpts/add" className="btn btn-success"><span className="fa fa-plus"></span> Add CPT</Link></span>

                        <ToolkitProvider
                            search
                            keyField="id"
                            data={data}
                            columns={this.columns()}
                        >
                            {
                                props => [
                                    <div className="pull-right"><Search.SearchBar {...props.searchProps} delay={1000} /></div>,
                                    <BootstrapTable
                                        {...props.baseProps}
                                        remote
                                        onTableChange={this.handleTableChange}
                                        pagination={paginationFactory({
                                            page: parseInt(meta.current_page),
                                            sizePerPage: parseInt(meta.per_page),
                                            totalSize: parseInt(meta.total)
                                        })
                                        }
                                    />
                                ]
                            }
                        </ToolkitProvider>

                    </Box.Body>
                </Box>
            </Content>
        </div >;
    }
}

const mapStateToProps = (state) => ({
    cpt_list: state.cpt,
    alert: state.alert,
    admin: state.auth.admin
});

const mapActionsToProps = ({
    paginate: CptActon.list,
})

export default connect(mapStateToProps, mapActionsToProps)(ListCpt);