import React from 'react';
import { Alert as BAlert } from 'react-bootstrap';
import { connect } from 'react-redux';

class Alert extends React.Component {
    render() {
        const { alert } = this.props;

        return (<React.Fragment>
            {alert && alert.message &&
                <BAlert bsStyle={alert.type}>
                    <strong>{alert.message}</strong>
                    {alert.errors && Object.keys(alert.errors).map((key) => (
                        <span><br />{alert.errors[key].join()}</span>
                    ))}
                </BAlert>
            }
        </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    alert: state.alert
});

export default connect(mapStateToProps, null)(Alert);
