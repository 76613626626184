import React from 'react';
import CountBox from './CountBox';
import { Box } from '../../components/Elements';
import DashboardService from '../../services/dashboardService';

class TrailProviderCount extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pharmacy_count: '...',
            pathology_count: '...',
            radiology_count: '...',
        }
    }

    componentDidMount() {
        DashboardService.getTrialProviderCount().then(res => {
            this.setState({
                pharmacy_count: res.data.pharmacy_count,
                pathology_count: res.data.pathology_count,
                radiology_count: res.data.radiology_count,
            });
        });
    }

    render() {
        return (
            <Box box={"solid box-warning"}>
                <Box.Head title="Number Of Trial Users" />
                <Box.Body>
                    <div className="row">
                        <div className="col-sm-3">
                            <CountBox title="Pharmacy" count={this.state.pharmacy_count} />
                        </div>
                        <div className="col-sm-3">
                            <CountBox title="Pathology" count={this.state.pathology_count} />
                        </div>
                        <div className="col-sm-3">
                            <CountBox title="Radiology" count={this.state.radiology_count} />
                        </div>
                    </div>
                </Box.Body>
            </Box>
        );
    }
}

export default TrailProviderCount;
