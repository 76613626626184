import axios from 'axios';
import AuthService from './../services/authService';
import Notification from './../utils/notification';
import * as authUtils from './auth';

export default function (store, history) {
    let isRefreshing = false;
    let refreshSubscribers = [];

    const errorHandler = error => {
        const originalRequest = error.config;

        if (error.response && error.response.status) {
            if (error.response.status === 401) {
                let error_data = error.response.data;

                if (error_data.error_type === 'expired_access_token') {
                    if (!isRefreshing) {
                        isRefreshing = true;

                        return new Promise((resolve, reject) => {
                            AuthService.refreshToken().then((response) => {
                                let data = response.data;
                                authUtils.setSessionToken(data);
                                originalRequest.headers['Authorization'] = 'Bearer ' + response.data.access_token;

                                isRefreshing = false;
                                onRefreshed(response.data.access_token);
                                refreshSubscribers = [];
                                
                                return resolve(axios.request(originalRequest));
                            }).catch((error) => {
                                isRefreshing = false;
                                refreshSubscribers = [];

                                return reject(error);
                            })
                        })
                    } else {
                        return new Promise((resolve) => {
                            subscribeTokenRefresh(token => {
                                // replace the expired token and retry
                                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                                resolve(axios.request(originalRequest));
                            })
                        })
                    }
                }

                authUtils.removeSessionToken();
                authUtils.removeUser();
                store.dispatch({ type: 'LOGOUT' });
                store.dispatch({ type: 'RESET_ALL' });
                history.push('/login')

                return Promise.reject(error);
            } else {
                return Promise.reject(error);
            }
        } else {
            Notification.show('warning', { message: 'No Internet Connection, Try Again !' });
            return Promise.reject(error);
        }
    };

    const subscribeTokenRefresh = (cb) => {
        refreshSubscribers.push(cb);
    };

    const onRefreshed = (token) => {
        refreshSubscribers.map(cb => cb(token));
    };

    axios.interceptors.response.use(response => response, errorHandler);
}
