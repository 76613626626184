import React, { Component } from 'react'
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import queryString from 'query-string';
import * as AuthAction from '../../actions/authActions';
import { FormGroup, FormControl } from 'react-bootstrap';
import Alert from '../../components/Alert';
import ReCAPTCHABlock from "../../components/ReCAPTCHABlock";
import { Helmet } from 'react-helmet';

class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);
    const queryPrarams = queryString.parse(this.props.location.search);

    this.state = {
      email: queryPrarams.email,
      token: queryPrarams.token,
      password: '',
      confirm_password: ''
    };

    this.validator = new SimpleReactValidator();
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.recaptchaRef = React.createRef();
  }

  handleInputChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: value
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return false;
    } else if (this.state.password === this.state.confirm_password) {
      this.recaptchaRef.current.execute();
    } else {
      this.props.displaycustomemessage('failed', 'The new password and confirm password does not match');
    }
  }

  onSubmit() {
    this.props.resetPassword(this.state.email, this.state.token, this.state.password, this.state.confirm_password, this.props.history);
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Reset Password</title>
        </Helmet>
        <ReCAPTCHABlock ref={this.recaptchaRef} onSubmit={this.onSubmit} />
        <div className="login-box">
          <div className="login-logo">
            <a href="/"><img src={'/images/daisy-logo.svg'} alt="Logo" /></a>
          </div>
          <div className="login-box-body">
            <p className="login-box-msg"><strong>Reset Your Password</strong></p>
            <Alert />
            <form onSubmit={this.handleSubmit}>
              <FormGroup controlId="email" >
                <FormControl type="email" className="myEdit" id="email" name="email" placeholder="Your Email" value={this.state.email} disabled/>
              </FormGroup>
              <FormGroup className="has-feedback" controlId="password" >
                <FormControl type="password" className="myEdit" id="password" name="password" placeholder="Enter Your New Password" value={this.state.password} onChange={this.handleInputChange} maxLength="15" />
                <span className="tooltip-custom">
                  <i className="fa fa-info-circle" aria-hidden="true"></i>
                  <span className="tooltiptext">
                    Choose a password between 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.
                  </span>
                </span>
                {this.validator.message('password', this.state.password, 'required|min:8')}
              </FormGroup>
              <FormGroup controlId="confirm_password" >
                <FormControl className="myEdit" type="password" id="confirm_password" name="confirm_password" placeholder="Confirm New Password" value={this.state.confirm_password} onChange={this.handleInputChange} maxLength="15" />
                {this.validator.message('confirm_password', this.state.confirm_password, 'required|min:8')}

              </FormGroup>
              <div className="row">
                <div className="col-xs-6 pull-right">
                  <button type="submit" className="btn btn-primary btn-block btn-flat">Reset Password</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({

});

const mapActionsToProps = ({
  displaycustomemessage: AuthAction.displaycustomemessage,
  resetPassword: AuthAction.resetPassword
})

export default connect(mapStateToProps, mapActionsToProps)(ResetPasswordPage);
