import axios from 'axios';
import { apiRoute, getApiHeader } from '../utils/helpers';

class ownerUserService {

    static updateOwnerProfileStatus(serviceProviderId, userId, user_profile_id) {

        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute(`/v1/service_providers/${serviceProviderId}/user-profile/${user_profile_id}`), requestOptions);
    }

    static updateOwnerUsers(serviceProviderId, postData) {

        let paramsdata = JSON.stringify(postData);

        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute(`/v1/service_providers/${serviceProviderId}/owners`), paramsdata, requestOptions);
    }

    static resendEmail(email) {
        let paramsdata = JSON.stringify({
            email: email
        });
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute("/v1/users/re-verify"), paramsdata, requestOptions);
    }
}

export default ownerUserService;