import React from 'react';
import { Content, Box, BackButton } from '../../components/Elements';
import { Helmet } from 'react-helmet';
import * as HELPER from "../../utils/helpers";
import { FormControl, Col, FormGroup } from 'react-bootstrap';
import UserAccountService from '../../services/userAccountService';
import DoctorInfo from '../../components/DoctorInfo';
import PharmacyInfo from '../../components/PharmacyInfo';
import RadiologyInfo from '../../components/RadiologyInfo';
import PathologyInfo from '../../components/PathologyInfo';
import SettleAccount from '../../components/SettleAccount';
import Invoice from '../../components/Invoice';
import BillStatements from '../../components/BillStatements';
import _ from "lodash";

class UserAccountInfo extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.match.params
    this.state = {
      service_provider_id: id,
      data: '',
      selected_action: 'settle_account',
    }
  }

  componentDidMount() {
    UserAccountService.show(this.state.service_provider_id).then(resp => {
      this.setState({
        data: resp.data
      })
    }).catch(error => {
      console.log(error.response)
    })
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  render() {
    const { data, selected_action } = this.state
    return (
      data && <div>
        <Helmet>
          <title>View User Account</title>
        </Helmet>
        <Content title="Account Details">
          <Box.Head>
            <BackButton backTo="/service-providers/accounts" />
          </Box.Head>
          {/* Service Provider Info */}
          {data.service_provider_type === "doctor" && <DoctorInfo data={data} />}
          {data.service_provider_type === "pharmacy" && <PharmacyInfo data={data} />}
          {data.service_provider_type === "radiology" && <RadiologyInfo data={data} />}
          {data.service_provider_type === "pathology" && <PathologyInfo data={data} />}

          {/* Commission Info */}
          <Box>
            <Box.Head title="Commision Detail"></Box.Head>
            <Box.Body>
              <FormGroup>
                <Col sm={3}>
                  <b>Type : </b><span>{_.startCase(data.commission_type)}</span>
                </Col>
                {data.commission_type === "revenue_percentage" && <>
                  <Col sm={3} >
                    <b>Percentage Value : </b><span>{data.commission_rate} %</span>
                  </Col>
                </>}

                {data.commission_type === "pay_per_use" && <>
                  <Col sm={3}>
                    <b>Per transaction cost : </b><span>{data.commission_rate + " " + data.currency}</span>
                  </Col></>
                }
              </FormGroup>
            </Box.Body>
          </Box>

          {/* Acc Info */}
          <Box>
            <Box.Head title="Account Info"></Box.Head>
            <Box.Body>
              <FormGroup>
                <Col sm={3}>
                  <b>Unbilled Amount : </b><span>{data.user_account.amount_unbilled}</span>
                </Col>
                <Col sm={3} >
                  <b>Due Amount : </b><span>{data.user_account.amount_due}</span>
                </Col>
                <Col sm={3}>
                  <b>Overdue Amount : </b><span>{data.user_account.amount_over_due}</span>
                </Col>
                <Col sm={3}>
                  <b>Due Date : </b><span>{HELPER.getDateFormat(data.user_account.due_date)}</span>
                </Col>
              </FormGroup>
            </Box.Body>
          </Box>
          {/*select action*/}
          <Box>
            <Box.Body>
              <FormGroup float="center">
                <Col sm={2} xsOffset={3} >
                  <Box.Head title="Select Action :" />
                </Col>
                <Col sm={3} >
                  <FormControl name="selected_action" componentClass="select"
                    value={selected_action}
                    onChange={this.handleChange}>
                    <option value="settle_account" defaultValue>Settle Account</option>
                    <option value="view_invoice">View Invoice</option>
                    <option value="view_statement">View Statement</option>
                  </FormControl>
                </Col>
              </FormGroup>
            </Box.Body>
          </Box>
          {/* render selected action */}
          {selected_action === "settle_account" && <SettleAccount service_provider_id={this.state.service_provider_id} />}
          {selected_action === "view_invoice" && <Invoice service_provider_id={this.state.service_provider_id} />}
          {selected_action === "view_statement" && <BillStatements service_provider_id={this.state.service_provider_id} />}
        </Content>
      </div>
    )
  }
}

export default UserAccountInfo