import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function trial_account(state = initialState.trial_account, { type, ...payload }) {
    switch (type) {
        case types.ADD_TRIAL_ACCOUNT:
            return {
                ...state,
                ...payload
            };
        case types.DELETE_TRIAL_ACCOUNT:
            return {
                ...state,
                ...payload
            };
        case types.FETCH_TRIAL_ACCOUNT:
            return {
                ...state,
                ...payload
            };
        case types.UPDATE_TRIAL_ACCOUNT:
            return {
                ...state,
                ...payload
            }; 
        default:
            return state;
    }
}