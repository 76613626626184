import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function user_account(state = initialState.pagination, { type, ...payload }) {
  switch (type) {
    case types.LIST_USER_ACCOUNT:
      return {
        ...state,
        ...payload
      };
    case types.LIST_SERVICE_PROVIDER_INVOICES:
      return {
        ...state,
        ...payload
      };
    case types.LIST_SERVICE_PROVIDER_STATEMENTS:
      return {
        ...state,
        ...payload
      };
    case types.RESET_SERVICE_PROVIDER_LIST:
      return {
        ...state,
        ...initialState.pagination
      };
    default:
      return state;
  }
}
