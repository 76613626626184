import * as types from './actionTypes';
import ReferralService from "../services/referralService";
import Notification from '../utils/notification';

export function list({page, sizePerPage, sortBy, searchText, filter}) {
    return dispatch => {
        dispatch({ type: types.TEST_RESULT_REPORT_REQUEST, request: { page, sizePerPage, sortBy, searchText, filter } });

        ReferralService.getTestResultReportLst({page, sizePerPage, sortBy, searchText, filter})
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.TEST_RESULT_REPORT_SUCCESS, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER ' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}