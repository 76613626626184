import React from 'react';
import { connect } from 'react-redux';
import { Content } from '../../components/Elements';
import PatientCount from '../../components/CovidDashboard/PatientCount';
import CovidTestUploadedCount from '../../components/CovidDashboard/CovidTestUploadedCount';
import CovidCenterCount from '../../components/CovidDashboard/CovidCenterCount.js';
import CovidLocationCount from '../../components/CovidDashboard/CovidLocationCount.js';
import CovidTestGraph from '../../components/CovidDashboard/CovidTestGraph';
import CovidTestCount from '../../components/CovidDashboard/CovidTestCount';
import CovidResultMaleGraph from '../../components/CovidDashboard/CovidResultMaleGraph';
import CovidResultFemaleGraph from '../../components/CovidDashboard/CovidResultFemaleGraph';
import CovidAllTestGraph from '../../components/CovidDashboard/CovidAllTestGraph';
import * as helperUitls from '../../utils/helpers';
import { Helmet } from 'react-helmet';

class CovidDashboardPage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const user = this.props.user;

        const userOnboardVisibiltyPermission = ['super-admin', 'business-development-head', 'business-development-executive', 'finance-head', 'admin', 'service'];

        return <Content title="Covid Dashboard">
            <Helmet>
                <title>Covid Dashboard</title>
            </Helmet>

            <div className="row">
                <div className="col-sm-4">
                    {helperUitls.hasRole(user, userOnboardVisibiltyPermission) && <PatientCount />}
                </div>
                <div className="col-sm-4">
                    {helperUitls.hasRole(user, userOnboardVisibiltyPermission) && <CovidCenterCount />}
                </div>
                <div className="col-sm-4">
                    {helperUitls.hasRole(user, userOnboardVisibiltyPermission) && <CovidLocationCount />}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6">
                    {helperUitls.hasRole(user, userOnboardVisibiltyPermission) && <CovidTestUploadedCount />}
                </div>
                <div className="col-sm-6">
                    {helperUitls.hasRole(user, userOnboardVisibiltyPermission) && <CovidTestCount />}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    {helperUitls.hasRole(user, userOnboardVisibiltyPermission) && <CovidTestGraph />}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6">
                    {helperUitls.hasRole(user, userOnboardVisibiltyPermission) && <CovidResultMaleGraph />}
                </div>
                <div className="col-sm-6">
                    {helperUitls.hasRole(user, userOnboardVisibiltyPermission) && <CovidResultFemaleGraph />}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    {helperUitls.hasRole(user, userOnboardVisibiltyPermission) && <CovidAllTestGraph />}
                </div>
            </div>

        </Content >;
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.admin,
});

export default connect(mapStateToProps, null)(CovidDashboardPage);
