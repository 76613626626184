import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { FormGroup, FormControl, Glyphicon } from 'react-bootstrap';
import * as authActions from '../../actions/authActions';
import { connect } from 'react-redux';
import Alert from '../../components/Alert';
import ReCAPTCHABlock from "../../components/ReCAPTCHABlock";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();

        this.state = {
            username: '',
            password: ''
        };

        this.recaptchaRef = React.createRef();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.verifySubmit = this.verifySubmit.bind(this);
        this.verifiedSubmit = this.verifiedSubmit.bind(this);
    }
    componentDidMount() {
        document.body.classList.add('login-page');
    }
    componentWillUnmount() {
        document.body.classList.remove('login-page');
    }

    handleInputChange(evt) {
        let name = evt.target.name;
        let value = evt.target.value;
        this.setState({
            [name]: value
        });
    }

    verifySubmit(evt) {
        evt.preventDefault();
        this.recaptchaRef.execute();
    }

    verifiedSubmit() {
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        this.props.login(this.state.username, this.state.password, this.props.history)
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Login</title>
                </Helmet>
                <ReCAPTCHABlock ref={el => this.recaptchaRef = el} onSubmit={this.verifiedSubmit} />
                <div className="login-box">
                    <div className="login-logo">
                        <a href="/"><img src={'/images/daisy-logo.svg'} alt="Logo" /></a>
                    </div>
                    <div className="login-box-body">
                        <p className="login-box-msg">Please sign in to your account below.</p>
                        <Alert />
                        <form onSubmit={this.verifySubmit}>
                            <FormGroup className="has-feedback" controlId="username">
                                <FormControl type="text" id="username" name="username" placeholder="Email" value={this.state.username} onChange={this.handleInputChange} autoComplete="off" />
                                <Glyphicon glyph="envelope" className="form-control-feedback" />
                                {this.validator.message('username', this.state.username, 'required')}
                            </FormGroup>

                            <FormGroup className="has-feedback" controlId="password">
                                <FormControl type="password" id="password" name="password" placeholder="Password" value={this.state.password} onChange={this.handleInputChange} autoComplete="off" />
                                <Glyphicon glyph="lock" className="form-control-feedback" />
                                {this.validator.message('password', this.state.password, 'required')}
                            </FormGroup>

                            <div className="row">
                                <div className="col-xs-6">
                                </div>
                                <div className="col-xs-6">
                                    <button type="submit" className="btn btn-primary btn-block btn-flat">Login to Dashboard</button>
                                </div>
                            </div>
                        </form>
                        <Link to="/forget-password">I forgot my password</Link>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapActionsToProps = ({
    login: authActions.login
})

export default connect(null, mapActionsToProps)(LoginPage);
