import React from "react";
import { connect } from "react-redux";
import { Content, Box } from "../../components/Elements";
import {
	FormGroup,
	ControlLabel,
	FormControl,
	Col,
	Button,
	ButtonToolbar,
} from "react-bootstrap";
import Alert from "../../components/Alert";
import * as PatientAction from "../../actions/patientActions";
import * as CardAction from "../../actions/cardActions";
import PatientService from "../../services/patientService";
import CorporateService from "../../services/corporateService";
import * as Authaction from "../../actions/authActions";
import SimpleReactValidator from "simple-react-validator";
import { Helmet } from "react-helmet";
import * as HELPER from "../../utils/helpers";
import _ from "lodash";
import Notification from "../../utils/notification";
import * as LostReissueComponents from '../../components/EmployeeLostAndReissue'

class SearchEmployeePage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			first_name: "",
			last_name: "",
			date_of_birth: "",
			place_of_birth: "",
			patient_id: "",
			generate_card: false,
			patients: '',
			selected_card: '',
			deactiveBlock: false,
			client_error: '',
			corporates: [],
			corporate_locations: [],
		};

		this.validator = new SimpleReactValidator();
		this.validator2 = new SimpleReactValidator();
		this.cardValidator = new SimpleReactValidator()
		this.handleChange = this.handleChange.bind(this);
		this.handleReIssueCardSubmit = this.handleReIssueCardSubmit.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.getCorporates();
	}

	componentDidMount() {
		this.props.navaction("patient_search");
	}

	getCorporates = () => {
		CorporateService.getCorporateList().then(resp => {
			let data = resp.data;
			if (data.message) {
				Notification.show('info', data);
				return false
			}

			this.setState({
				corporates: data,
			})
		}).catch(error => {
			Notification.show('error', error.response.data)
		})

	}

	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name
		if (name == "corporate") {
			if (value) {
				const locations = this.state.corporates.filter(corporate => corporate.corporateId == value)
				this.setState({
					corporate_locations: locations[0].location,
					[name]: value
				});
			} else {
				this.setState({
					corporate_locations: [],
					corporate_location: "",
					[name]: value
				});
			}
		}
		else {
			this.setState({
				[name]: value
			});
		}
	}

	handleSubmit = action => {
		if (!this.validator.allValid()) {
			this.validator.showMessages();
			this.forceUpdate();
			return false;
		}

		const postData = {
			corporate: this.state.corporate,
			employee_id: this.state.employee_id,
			first_name: this.state.first_name,
			last_name: this.state.last_name,
			date_of_birth: this.state.date_of_birth,
			place_of_birth: this.state.place_of_birth,
		};

		if (action === "lost-reissue-card") {
			CorporateService.employeeSearch(postData)
				.then(resp => {
					let { data } = resp;

					if (_.isEmpty(data)) {
						this.setState({
							patient: []
						});
						Notification.show("error", { message: "Employee not found" });
						return false;
					}

					this.setState({
						patient: data,
						patients: data,
						patient_id: data.patient_id,
						generate_card: true,
						selected_card: '',
						deactiveBlock: false,
						action_type: ''
					});
				})
				.catch(error => {
					if (error.response && error.response.status) {
						let errorData = error.response.data.message;
						Notification.show("error", { message: errorData });
					} else {
						Notification.show("error", {
							message: "Something Went Wrong, Try Again"
						});
					}
				});
		} else if (action === "edit-profile") {
			CorporateService.getEmployeeId(postData).then(resp => {
				this.props.history.push("/patient/" + resp.data.patient_id + "/edit",{ corp: "corporate" })
			}).catch(error => {
				if (error.response && error.response.status) {
					let errorData = error.response.data.message;
					Notification.show("error", { message: errorData });
				} else {
					Notification.show("error", {
						message: "Something Went Wrong, Try Again"
					});
				}
			});
		}
	};

	handleReIssueCardSubmit = e => {
		e.preventDefault();

		if (!this.validator2.allValid()) {
			this.validator2.showMessages();
			this.forceUpdate();
			return false;
		}
		if (_.isEmpty(this.state.corporate) || _.isEmpty(this.state.corporate_location)) {
			Notification.show('error', { message: 'Corporate & Corporate Location is required' });
			return false;
		}

		let data = {
			service_provider_id: this.state.corporate,
			user_location_id: this.state.corporate_location,
			patient_id: this.state.patient.patient_id,
			platform: "pooja-loyalty",
			service_provider_name: "",
			user_location_name: "",
		};

		this.setState({
			generate_card: false,
			first_name: "",
			last_name: "",
			date_of_birth: "",
			place_of_birth: "",
			selected_card: '',
			employee_id: "",
			corporate: "",
			corporate_location: "",
			corporate_locations: [],
		});
		this.props.reIssueCardRequest(data, this.props.history);
	};

	onSelectCard = (card) => {
		this.setState({ selected_card: card, patient: card })
	}

	onPlatformSubmit = (action_type) => {
		if (!this.cardValidator.allValid()) {
			this.cardValidator.showMessages();
			this.forceUpdate();
			return false;
		}
		if (_.isEmpty(this.state.selected_card)) {
			Notification.show('error', { message: 'Kindly select card' });
			return false;
		}
		this.setState({ action_type: action_type, deactiveBlock: false });
		if (action_type === "deactivate") {
			this.setState({ deactiveBlock: true });
		}
	}

	handleCancel = () => {
		this.setState({ generate_card: false, action_type: "", selected_card: "", deactiveBlock: false })
	}

	handleBack = () => {
		this.setState({ selected_card: "", deactiveBlock: false, action_type: "" })
	}
	render() {
		const { patient, patients, corporates, corporate_locations } = this.state;
		return (
			<div>
				<Helmet>
					<title>Search Employee</title>
				</Helmet>
				<Content title="Search Employee">
					<Box box="solid">
						<Box.Body>
							<Alert />

							<FormGroup className="row" controlId="">
								<Col sm={2}>
									<ControlLabel>
										Corporate: <span>*</span>
									</ControlLabel>
								</Col>
								<Col sm={4}>
									<FormControl name="corporate" componentClass="select" value={this.state.corporate} onChange={this.handleChange}>
										<option value="">Select</option>
										{corporates.map((corporate) => {
											return <option value={corporate.corporateId}>{corporate.corporateName}</option>
										})}
									</FormControl>
									{this.validator.message(
										"corporate",
										this.state.corporate,
										'required'
									)}
								</Col>

								<Col sm={2}>
									<ControlLabel>
										Location: <span>*</span>
									</ControlLabel>
								</Col>
								<Col sm={4}>
									<FormControl name="corporate_location" componentClass="select" value={this.state.corporate_location} onChange={this.handleChange}>
										<option value="">Select</option>
										{corporate_locations.map((location) => {
											return <option value={location.id}>{location.address}</option>
										})}
									</FormControl>
									{this.validator.message(
										"corporate_location",
										this.state.corporate_location,
										'required'
									)}
								</Col>
							</FormGroup>


							<FormGroup className="row" controlId="">

								<Col sm={2}>
									<ControlLabel>
										Employee ID: <span>*</span>
									</ControlLabel>
								</Col>
								<Col sm={4}>
									<FormControl
										name="employee_id"
										type="text"
										placeholder="Employee ID"
										onChange={this.handleChange}
										autoComplete="none"
										value={this.state.employee_id}
									/>
									{this.validator.message(
										"employee_id",
										this.state.employee_id,
										['required', 'string']
									)}
								</Col>

								<Col sm={2}>
									<ControlLabel>
										First Name: <span>*</span>
									</ControlLabel>
								</Col>
								<Col sm={4}>
									<FormControl
										name="first_name"
										type="text"
										placeholder="First Name"
										onChange={this.handleChange}
										autoComplete="none"
										value={this.state.first_name}
									/>
									{this.validator.message(
										"first_name",
										this.state.first_name,
										['required', 'string', { regex: HELPER.personNameRegex() }]
									)}
								</Col>

							</FormGroup>

							<FormGroup className="row" controlId="">

								<Col sm={2}>
									<ControlLabel>
										Last Name: <span>*</span>
									</ControlLabel>
								</Col>
								<Col sm={4}>
									<FormControl
										name="last_name"
										type="text"
										placeholder="Last Name"
										onChange={this.handleChange}
										autoComplete="none"
										value={this.state.last_name}
									/>
									{this.validator.message(
										"last_name",
										this.state.last_name,
										['required', 'string', { regex: HELPER.personNameRegex() }]
									)}
								</Col>

								<Col sm={2}>
									<ControlLabel>Date Of Birth: *</ControlLabel>
								</Col>
								<Col sm={4}>
									<FormControl
										name="date_of_birth"
										type="date"
										onChange={this.handleChange}
										value={this.state.date_of_birth}
									/>
									{this.validator.message(
										"date_of_birth",
										this.state.date_of_birth,
										"required"
									)}
								</Col>

							</FormGroup>

							<FormGroup className="row" controlId="">

								<Col sm={2}>
									<ControlLabel>Place Of Birth: *</ControlLabel>
								</Col>
								<Col sm={4}>
									<FormControl
										name="place_of_birth"
										type="text"
										placeholder="Place of Birth"
										onChange={this.handleChange}
										value={this.state.place_of_birth}
									/>
									{this.validator.message(
										"place_of_birth",
										this.state.place_of_birth,
										"required"
									)}
								</Col>

							</FormGroup>
						</Box.Body>
					</Box>
					<FormGroup className="row " controlId="">
						<Col sm={12}>
							<ButtonToolbar>
								{HELPER.hasPermission(this.props.admin, ['lost-and-re-issue-card']) &&
									<Button
										bsStyle="primary"
										type="button"
										onClick={this.handleSubmit.bind(this, "lost-reissue-card")}
									>
										Corporate Gift Card Re-Issuance
									</Button>
								}

								{HELPER.hasPermission(this.props.admin, ['patient-edit']) &&
									<Button
										className="ml-3"
										bsStyle="primary"
										type="button"
										onClick={this.handleSubmit.bind(this, "edit-profile")}
									>
										Edit Profile
									</Button>
								}
							</ButtonToolbar>
						</Col>
					</FormGroup>

					{this.state.generate_card && !_.isEmpty(this.state.patient) &&
						<Box box="solid">
							<Box.Head title="Lost & Re-Issue Card"></Box.Head>
							<Box.Body>
								{(this.state.action_type !== "reissue" && this.state.patients && this.state.deactiveBlock === false) && <React.Fragment>
									<LostReissueComponents.SelectCard patients={patients} onSelect={this.onSelectCard} onPatientSubmit={this.onPlatformSubmit} selectedCard={this.state.selected_card} />
								</React.Fragment>}

								{this.state.action_type === "reissue" && this.state.generate_card && this.state.selected_card && <LostReissueComponents.SelectPlatform
									patient={patient}
									onChange={this.handleChange}
									handleChange={this.handleChange}
									handleReIssueCard={this.handleReIssueCardSubmit}
									handleCancel={this.handleCancel}
									handleBack={this.handleBack}
									validator={this.validator2}
									clientError={this.state.client_error}
								/>
								}

							</Box.Body>
						</Box>}
				</Content>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	admin: state.auth.admin
});

const mapActionsToProps = {
	navaction: Authaction.navaction,
	reIssueCardRequest: CardAction.reIssueCardRequest,
};

export default connect(mapStateToProps, mapActionsToProps)(SearchEmployeePage);

