import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { FormGroup, ControlLabel, FormControl, Col, Grid } from 'react-bootstrap';
import * as PathologyLimsCategoryAction from '../../actions/pathologyLimsCategoryActions';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from 'react-helmet';
import Alert from '../../components/Alert';

class AddPathologyLimsCategoryPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            status: '',
        };

        this.validator = new SimpleReactValidator();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        this.props.pathologyLimsCategoryAdd(this.state, this.props.history);
    }

    render() {
        return <div>
            <Helmet>
                <title>Add Lims Category</title>
            </Helmet>
            <Content title="Add Lims Category">
                <Box>
                    <Box.Head title="" backTo="/lims-categories" />
                    <Box.Body>
                        <Alert />
                        <Grid>
                            <form onSubmit={this.handleSubmit}>
                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Category <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='name'
                                            type="text"
                                            value={this.state.name}
                                            placeholder="Enter Category Name"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('name', this.state.name, 'required|string')}
                                    </Col>
                                </FormGroup>                             

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Status: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl name="status" componentClass="select" value={this.state.status} onChange={this.handleChange} placeholder="Select Test Type">
                                            <option value="" disabled={true}>Select Status</option>
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </FormControl>
                                        {this.validator.message('status', this.state.status, 'required')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={6} md={6}>
                                        <button type='submit' className='btn btn-primary'>Add</button>
                                    </Col>
                                </FormGroup>
                            </form>
                        </Grid>
                    </Box.Body>
                </Box>
            </Content>
        </div >;
    }
}

const mapStateToProps = (state) => ({
});

const mapActionsToProps = ({
    pathologyLimsCategoryAdd: PathologyLimsCategoryAction.pathologyLimsCategoryAdd
})

export default connect(mapStateToProps, mapActionsToProps)(AddPathologyLimsCategoryPage);
