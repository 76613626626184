import React, { Component } from "react";

class PatientReportDetails extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { formData } = this.props;
    return (
      <div>
        <div className="add-lab-report">
          <form >
            <div className="row">
              <div className="col-md-3">
                <fieldset className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    className="form-control"
                    value={formData.name}
                    disabled={true}
                  />
                </fieldset>
              </div>

              <div className="col-md-3">
                <fieldset className="form-group">
                  <label>Phone</label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="Phone"
                    className="form-control"
                    value={formData.phone}
                    disabled={true}
                  />
                </fieldset>
              </div>

              <div className="col-md-3">
                <fieldset className="form-group">
                  <label>Doctor</label>
                  <input
                    type="text"
                    name="doctor"
                    id="doctor"
                    placeholder="Doctor"
                    className="form-control"
                    value={formData.doctor}
                    disabled={true}
                  />
                </fieldset>
              </div>

              <div className="col-md-3">
                <fieldset className="form-group">
                  <label>Sample Collected At</label>
                  <input
                    className="form-control"
                    value={formData.sample_collected_at}
                    id="sample_collected_at"
                    name="sample_collected_at"
                    disabled={true}
                  />
                </fieldset>
              </div>

              <div className="col-md-3">
                <fieldset className="form-group">
                  <label>Address</label>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    placeholder="Address"
                    className="form-control"
                    value={formData.address}
                    disabled={true}
                  />
                </fieldset>
              </div>

              <div className="col-md-3">
                <fieldset className="form-group">
                  <label>Age</label>
                  <input
                    type="text"
                    name="age"
                    id="age"
                    placeholder="Age"
                    className="form-control"
                    value={formData.age}
                    disabled={true}
                  />
                </fieldset>
              </div>

              <div className="col-md-3">
                <fieldset className="form-group">
                  <label>Doctor Contact</label>

                  <input
                    type="text"
                    name="doctor_contact"
                    id="doctor_contact"
                    placeholder="Doctor Contact"
                    className="form-control"
                    value={formData.doctor_contact}
                    disabled={true}
                  />
                </fieldset>
              </div>

              <div className="col-md-3">
                <fieldset className="form-group">
                  <label>Sample Collected On</label>
                  <input
                    type="date"
                    name="sample_collected_on"
                    id="sample_collected_on"
                    className="form-control"
                    value={formData.sample_collected_on}
                    disabled={true}
                  />
                </fieldset>
              </div>

              <div className="col-md-3">
                <fieldset className="form-group">
                  <label>Height</label>
                  <input
                    type="text"
                    name="height"
                    id="height"
                    className="form-control"
                    value={formData.patient_height}
                    disabled={true}
                  />
                </fieldset>
              </div>

              <div className="col-md-3">
                <fieldset className="form-group">
                  <label>Weight</label>
                  <input
                    type="text"
                    name="weight"
                    id="weight"
                    className="form-control"
                    value={formData.patient_weight}
                    disabled={true}
                  />
                </fieldset>
              </div>

              <div className="col-md-3">
                <fieldset className="form-group">
                  <label>BMI</label>
                  <input
                    type="text"
                    name="bmi"
                    id="bmi"
                    className="form-control"
                    value={formData.patient_bmi}
                    disabled={true}
                  />
                </fieldset>
              </div>
              <div className="col-md-3">
                <fieldset className="form-group">
                  <label>Created On</label>
                  <input
                    type="date"
                    name="created_on"
                    id="created_on"
                    className="form-control"
                    value={formData.created_on}
                    disabled={true}
                  />
                </fieldset>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default PatientReportDetails;
