import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Box, Content } from "../../components/Elements";
import * as PrescriptionAction from "../../actions/prescriptionActions";
import * as Helper from '../../utils/helpers';

class ViewPrescriptionPage extends React.Component {
  constructor(props) {
    super(props);

    const { match: { params } } = this.props;
    const id = params.id;

    this.props.fetchOrders(id);
  }
  getRowsOrders = order => {
    return (
      <Box>
        <Box.Head title={"Order #" + order.prescription_order_code} />
        <Box.Body>
          <section>
            <div className="row">
              <div className="col-md-6">
                <p><strong>Order Date: </strong> {Helper.getDateTimeFormat(order.created_at)}</p>
                <p><strong>Order Status: </strong> {order.status}</p>
              </div>
              <div className="col-md-6">
                <p><strong>Pharmacy Id: </strong> {order.pharmacy.pharmacy_code}</p>
                <p><strong>Pharmacy Name: </strong> {order.pharmacy.pharmacy_name}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Drug Name</th>
                      <th>Generic Name</th>
                      <th>Unit Dispatched</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.items.map(item => {
                      return (
                        <tr>
                          <td>{item.drug_name}</td>
                          <td>{item.drug_generic_name}</td>
                          <td>{item.unit_given}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </Box.Body>
      </Box>
    );
  };
  
  render() {
    const { orders, prescription } = this.props;

    let prescriptionDetails;
    if (prescription) {
      prescriptionDetails = (
        <Box>
          <Box.Head title={"Prescription #" + prescription.prescription_id} backTo='/prescriptions' />
          <Box.Body>
            <section>
              {prescription.service_provider_info &&
                <div className="row">
                  <div className="col-md-6">
                    <p><strong>Prescription Date: </strong> {Helper.getDateFormat(prescription.prescription_date)} </p>
                    {/* <p><strong>Prescription Status: </strong> {prescription.status}</p> */}
                  </div>
                  <div className="col-md-6">
                    <p><strong>Doctor Id: </strong> {prescription.service_provider_id}</p>
                  <p><strong>Doctor Name: </strong> {prescription.user_profile_info.full_name}</p>
                  </div>
                </div>
              }
              {prescription.prescription_medicines && <div className="row">
                <div className="col-md-12 table-responsive">
                  <h4> Medicines </h4>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Drug Name</th>
                        <th>Generic Name</th>
                        <th>Drug Number</th>
                        <th>Total Unit</th>
                        <th>Remaining Unit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {prescription.prescription_medicines.map(
                        medicine => {
                          return (
                            <tr>
                              <td>{medicine.drug_name ? medicine.drug_name : '--'}</td>
                              <td>{medicine.drug_generic_name ? medicine.drug_generic_name : '--'}</td>
                              <td>{medicine.drug_number ? medicine.drug_number : '--'}</td>
                              <td>{medicine.total_unit ? medicine.total_unit : '--'}</td>
                              <td>{medicine.remaining_unit ? medicine.remaining_unit : '--'}</td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>}

              {prescription.prescription_pathology && <div className="row">
                <div className="col-md-12 table-responsive">
                  <h4> Pathology Tests </h4>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Test Name</th>
                        <th>Test Category</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {prescription.prescription_pathology.map(
                        test => {
                          return (
                            <tr>
                              <td>{test.test_name ? test.test_name : '--'}</td>
                              <td>{test.test_category ? test.test_category : '--'}</td>
                              <td>{test.test_description ? test.test_description : '--'}</td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>}

              {prescription.prescription_radiology && <div className="row">
                <div className="col-md-12 table-responsive">
                  <h4> Radiology Tests </h4>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Test Name</th>
                        <th>Test Category</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {prescription.prescription_radiology.map(
                        test => {
                          return (
                            <tr>
                              <td>{test.test_name ? test.test_name : '--'}</td>
                              <td>{test.test_category ? test.test_category : '--'}</td>
                              <td>{test.test_description ? test.test_description : '--'}</td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>}



            </section>
          </Box.Body>
        </Box>
      );
    }

    let orderDetails;
    if (orders) {
      orderDetails = (
        orders.map(order => {
          return this.getRowsOrders(order);
        })
      );
    }

    return (
      <div>
        <Helmet>
          <title>View Prescription</title>
        </Helmet>
        <Content title="Prescriptions">
          {prescriptionDetails}
          {orderDetails}
        </Content>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    prescription: state.prescription_view,
    orders: state.prescription_view.orders,
  };
};

const mapActionsToProps = ({
  fetchOrders: PrescriptionAction.fetchPrescriptionOrders
});

export default connect(mapStateToProps, mapActionsToProps)(ViewPrescriptionPage);