import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { ButtonToolbar, Modal, FormGroup, ControlLabel, FormControl, Col, Button } from 'react-bootstrap';
import * as DrugAction from '../../actions/drugActions';
import { Helmet } from 'react-helmet';
import Alert from '../../components/Alert';
import * as HELPER from '../../utils/helpers';
import DrugService from '../../services/drugService';
import Notification from "../../utils/notification";
import * as LoaderAction from '../../actions/loaderAction';
import SimpleReactValidator from 'simple-react-validator';
import moment from "moment";
import DrugPriceService from '../../services/drugPriceService';
import DataTable from '../../components/DataTable';


class ListDrugPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            viewModal: false,
            pricePercentage: '',
            priceUpdateDate: ''
        }

        this.props.paginate();
        this.downloadMasterData = this.downloadMasterData.bind(this);
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validator = new SimpleReactValidator();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    columns = (data) => {
        return [{
            dataField: 'id',
            text: 'number',
            hidden: true
        }, {
            dataField: 'name',
            text: 'Name',
            sort: true
        }, {
            dataField: 'generic_drug.name',
            text: 'Generic Name',
            sort: true
        }, {
            dataField: 'dosage_form',
            text: 'Dosage Form',
            sort: true,
            formatter: this.dosageFormatter
        }, {
            dataField: 'strength',
            text: 'Strength',
            sort: true
        }, {
            dataField: 'unit_of_measurement',
            text: 'UoM',
            sort: true
        }, {
            formatter: this.statusFormatter,
            text: 'Status',
        }, {
            dataField: 'actions',
            text: 'Actions',
            isDummyField: true,
            formatter: this.actionFormatter
        }
        ];
    };

    statusFormatter = (cell, row) => {
        if (row.status === 1) {
            return 'Active';
        }
        else if (row.status === 0) {
            return 'Inactive';
        }
    }

    dosageFormatter = (cell, row) => {
        return cell ? cell.label : '';
    }


    actionFormatter = (_cell, row) => {
        return <ButtonToolbar>
            {HELPER.hasPermission(this.props.admin, ['products-view']) && <Link className="btn btn-sm btn-info" to={'/drugs/' + row.id}><i className="fa fa-eye" aria-hidden="true"></i></Link>}
            {HELPER.hasPermission(this.props.admin, ['products-edit']) && <Link className="btn btn-sm btn-warning" to={'/drugs/' + row.id + '/edit'}><i className="fa fa-pencil" aria-hidden="true"></i></Link>}
        </ButtonToolbar>;
    }

    deleteItem = (id) => {
        if (id) {
            this.props.deleteDrug(id);
        }
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {

        if (type === 'search') {
            page = 1;
        }

        this.props.paginate(page, sizePerPage, { sortField, sortOrder }, searchText);
    }

    downloadMasterData = (e) => {
        e.preventDefault();
        this.props.showLoader();
        DrugService.exportDrugMaster()
            .then(resp => {
                let data = resp.data;
                this.props.hideLoader();
                window.open(data.url, "_blank")
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    Notification.show("error", errorData);
                } else {
                    Notification.show('error', { message: "Something Went Wrong, Try Again" });
                }
            });
        }

        closeModal() {
            this.setState({ viewModal: false, pricePercentage: '', priceUpdateDate: ''  });
          }
        
        showModal() {
            this.setState({ viewModal: true });
        }

        handleChange(event) {

            const target = event.target;
            const value = target.value;
            const name = target.name;
    
            this.setState({
                [name]: value
            });
        }

        handleSubmit(e) {
            e.preventDefault();
            if (!this.validator.allValid()) {
                this.validator.showMessages();
                this.forceUpdate();
                return false;
            } else {
    
                const postData = {
                    amount_percentage: this.state.pricePercentage,
                    price_update_date: this.state.priceUpdateDate,
                };
                this.UpdateDrugPrices(postData);
                this.closeModal();
            }
        }

        UpdateDrugPrices(postData)
        {
            DrugPriceService.updateAllDrugPrices(postData).then(resp => {
                Notification.show('success', { message: "Price Updated Successfully." });
            }).catch(error => {
                Notification.show("error", error);
            });
        }




    render() {
        let { data, meta, loading } = this.props.drug_list;

        return <div>
            <Helmet>
                <title>List Drugs</title>
            </Helmet>
            <Content title="Drugs">
                <Box>
                    <Box.Head title="List Drugs">
                        <ButtonToolbar className="pull-right">
                            {HELPER.hasPermission(this.props.admin, ['products-add']) && <Link to="/drugs/add" className="btn btn-success"><span className="fa fa-plus"></span> Add Drug</Link>}
                            {<Link onClick={this.downloadMasterData} className="btn btn-info"> Export CSV</Link>}
                            {<Link onClick={this.showModal} className="btn btn-warning"> Update Drug Prices</Link>}
                        </ButtonToolbar>
                    </Box.Head>
                    <Box.Body>
                        <Alert />
                        
                        <DataTable
                            loading={loading}
                            columns={this.columns()}
                            data={data}
                            page={parseInt(meta.current_page)}
                            sizePerPage={parseInt(meta.per_page)}
                            totalSize={parseInt(meta.total)}
                            onTableChange={this.handleTableChange}
                        />

                    </Box.Body>
                    <div>
                        <Modal show={this.state.viewModal} onHide={this.closeModal}>
                            <Modal.Header>
                                <Modal.Title>All Drugs Price Update</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form onSubmit={this.handleSubmit}>
                                    <FormGroup className="row" controlId="">
                                        <Col sm={5}>
                                            <ControlLabel>Update Drug Prices By %: <span>*</span></ControlLabel>
                                        </Col>
                                        <Col sm={7}>
                                            <FormControl
                                                name='pricePercentage'
                                                type="text"
                                                value={this.state.pricePercentage}
                                                onChange={this.handleChange}
                                                autoComplete="none"
                                            />
                                            {this.validator.message('pricePercentage', this.state.pricePercentage, 'required')}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row" controlId="">
                                        <Col sm={5}>
                                            <ControlLabel>Price Update Date : <span>*</span></ControlLabel>
                                        </Col>
                                        <Col sm={7}>
                                            <FormControl
                                                name='priceUpdateDate'
                                                type="date"
                                                value={this.state.priceUpdateDate}
                                                onChange={this.handleChange}
                                                autoComplete="none"
                                                min={moment().add(1, 'days').format('YYYY-MM-DD')}
                                            />
                                            {this.validator.message('Price Update Date', this.state.priceUpdateDate, 'required')}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row" controlId="">
                                        <Col sm={6} md={6}>
                                            <Button type='submit' bsStyle='success'>Update</Button>
                                        </Col>
                                        <Col sm={6} md={6}>
                                            <Button onClick={this.closeModal}>Cancel</Button>
                                        </Col>
                                    </FormGroup>
                                </form>
                            </Modal.Body>
                        </Modal>
                    </div>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    drug_list: state.drugs_list,
    alert: state.alert,
    admin: state.auth.admin
});

const mapActionsToProps = ({
    paginate: DrugAction.list,
    deleteDrug: DrugAction.deletedrug,
    showLoader: LoaderAction.loaderShow,
    hideLoader: LoaderAction.loaderHide,
})

export default connect(mapStateToProps, mapActionsToProps)(ListDrugPage);
