import { ALERT_SUCCESS, ALERT_ERROR, ALERT_CLEAR, ALERT_WARNING } from '../actions/actionTypes';
import initialState from './initialState';
import Notification from './../utils/notification';

export default function alert(state = initialState.alert, { type, message, ...payload }) {
  switch (type) {
    case ALERT_SUCCESS:
      Notification.show('success', { message, ...payload });
      return {
        status: 'success',
        type: 'success',
        isloading: false,
      };
    case ALERT_ERROR:
      Notification.show('error', { message, ...payload });
      return {
        status: 'error',
        type: 'danger',
        isloading: false,
      };

    case ALERT_WARNING:
      Notification.show('warning', { message, ...payload });
      return {
        status: 'error',
        type: 'warning',
        isloading: false,
      };
    case ALERT_CLEAR:
      return {
        ...initialState.alert,
        ...payload
      };
    default:
      return state
  }
}