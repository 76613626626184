import * as types from './actionTypes';
import TrialAccountService from '../services/trialAccountService';
import Notification from '../utils/notification';
import * as HELPER from '../utils/helpers';

export function createTrialAccount(postData, history, admin) {
    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR, isloading: true });
        TrialAccountService.createTrialAccount(postData)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.ALERT_SUCCESS });
                if (HELPER.hasRole(admin, ['call-center'])) {
                    history.push("/dashboard");
                } else {
                    history.push("/trial/accounts");
                }
                Notification.show('success', data);
            }).catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_CLEAR });
                    Notification.show('error', errorData);
                }
            });
    }
}

export function list({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: types.REQUEST_USER_ACCOUNT, request: { page, sizePerPage, sortBy, searchText } });
        dispatch({ type: types.ALERT_CLEAR });

        TrialAccountService.list({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.LIST_USER_ACCOUNT, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function fetchTrialAccount(id) {

    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        TrialAccountService.fetchTrialAccount(id)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.FETCH_TRIAL_ACCOUNT, ...data });
                dispatch({ type: types.ALERT_CLEAR, isloading: false });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_WARNING, message: error.message });
                }
            });
    }
}