import React from 'react';
import { Box } from '../Elements';
import BarGraphBox from './BarGraphBox';
import DashboardService from '../../services/dashboardService';

class RadiologyPrescriptionOrdersCreatedGraph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            view: "month",
            legendLabelString: "Number of Orders",
            xAxesLabels: [],
            yAxesCounts: [],
            legendColor: '#00c0ef',
        }
    }

    componentDidMount() {
        this.getData(this.state.view);
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.getData(this.state.view);
        }
    }

    getData = (view) => {
        let start_date = this.props.start_date;
        let end_date = this.props.end_date;

        DashboardService.getRadiologyOrderCreatedGraph({ start_date, end_date, view }).then(res => {
            this.setState({
                xAxesLabels: res.data.x_axis_label,
                yAxesCounts: res.data.counts,
                view: view
            });
        });
    }

    handleView = (view) => {
        this.getData(view);
    }

    render() {

        return (
            <Box box={"solid box-info"}>
                <Box.Head title="Radiology Order Created" />
                <Box.Body>
                    <BarGraphBox
                        legendLabelString={this.state.legendLabelString}
                        xAxesLabels={this.state.xAxesLabels}
                        yAxesCounts={this.state.yAxesCounts}
                        legendColor={this.state.legendColor}
                        view={this.state.view}
                        handleViewChange={this.handleView}
                    />
                </Box.Body>
            </Box>
        )
    }
}

export default RadiologyPrescriptionOrdersCreatedGraph;