import axios from 'axios';
import { apiRoute, getApiHeader } from '../utils/helpers';

class CorporateService {
    static getCorporateList() {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/corporate-list'), requestOptions);
    }

    static employeeSearch(employeeData) {
        employeeData = JSON.stringify(employeeData);

        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute("/v1/employee/search"), employeeData, requestOptions);
    }

    static getEmployeeId(postData) {
        let paramsdata = JSON.stringify(postData);

        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute("/v1/employee/search-id"), paramsdata, requestOptions);
    }
}

export default CorporateService;
