import * as types from './actionTypes';
import PathologyLimsTestService from '../services/pathologyLimsTestService';

export function limslist({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: types.PATHOLOGY_TEST_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText } });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });
        PathologyLimsTestService.limslist({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.PATHOLOGY_TEST_LIST_SUCCESS, ...data });
                dispatch({ type: types.ALERT_CLEAR });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function pathologyLimsTestAdd(statedata, history) {
    return dispatch => {
        dispatch({ type: types.ADD_PATHOLOGY_TEST });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        PathologyLimsTestService.pathologyLimsTestAdd(statedata, history)
            .then(resp => {
                let data = resp.data;
                if(data === true){
                    dispatch({ type: types.ADD_PATHOLOGY_TEST, ...data });   
                    dispatch({ type: types.ALERT_SUCCESS, message: 'Pathology Lims Test added successfully' });                
                }else{
                    dispatch({ type: types.ALERT_ERROR, message: 'Pathology Lims Test already Exists' });
                }
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                    history.push('/lims-tests');
                }, 2500);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function pathologyLimsTestUpdate(pathologyTest_id, statedata, history) {

    return dispatch => {
        PathologyLimsTestService.pathologyLimsTestUpdate(pathologyTest_id, statedata)
            .then(resp => {
                let data = resp.data;
                if(data === true){
                    dispatch({ type: types.UPDATE_PATHOLOGY_TEST, ...data });
                    dispatch({ type: types.ALERT_SUCCESS, message: 'Pathology Lims Test updated successfully' });
                }else{
                    dispatch({ type: types.ALERT_ERROR, message: 'Pathology Lims Test already Exists' });
                }
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                    history.push('/lims-tests');
                }, 2500);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}