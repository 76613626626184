import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function drug_sku_price(state = initialState.drug_sku_price, { type, ...payload }) {
    switch (type) {
        case types.FETCH_DRUG_SKU_PRICE:
            return {
                ...state,
                ...payload
            };
        case types.UPDATE_DRUG_SKU_PRICE:
            return {
                ...state,
                ...payload
            }; 
        default:
            return state;
    }
}