import * as types from './actionTypes';
import ActivityLogService from '../services/activityLogService';

export function fetchActivityLogs(service_provider_type, { page, sizePerPage, sortBy, searchText, filter }) {
    service_provider_type = service_provider_type == "authority-access" ? "airline" : service_provider_type;
    return dispatch => {
        dispatch({ type: types.ACTIVITY_LOG_REQUEST, request: { service_provider_type, page, sizePerPage, sortBy, searchText, filter } });
        ActivityLogService.fetchActivityLogs(service_provider_type, { page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.ACTIVITY_LOG_SUCCESS, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                    dispatch({ type: types.ACTIVITY_LOG_FAILED, ...errorData });

                } else {
                    dispatch({ type: types.ALERT_ERROR, isloading: false, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}