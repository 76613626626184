import React from 'react';
import CountBox from './CountBox';
import { Box } from '../Elements';
import CovidDashboardService from '../../services/covidDashboardService';

class CovidCenterCount extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            covid_center_count: '...',
        }
    }

    componentDidMount() {
        CovidDashboardService.getCovidCenterCount().then(res => {
            this.setState({
                covid_center_count: res.data.covid_center_count,
            });
        });
    }

    render() {
        return (
            <Box box={"solid box-warning"}><Box.Head title="Covid Centers" />
                <Box.Body>
                    <div className="row">
                        <div className="col-sm-5">
                            <CountBox title="Total" count={this.state.covid_center_count} />
                        </div>
                    </div>     
                </Box.Body>
            </Box>
        );
    }
}

export default CovidCenterCount;
