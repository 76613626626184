import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { FormGroup, ControlLabel, FormControl, Col, Button, ButtonToolbar } from 'react-bootstrap';
import * as RadiologyTestAction from '../../actions/radiologyTestActions';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from 'react-helmet';
import Alert from '../../components/Alert';
import { RADIOLOGY_TEST_CATEGORIES } from '../../utils/config';

class EditRadiologyTestPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: '',
            test_name: '',
            test_category: '',
            test_description: '',
            price: '',
            test_status: '',
            flag: 'null'
        };

        this.validator = new SimpleReactValidator();

        const { match: { params } } = this.props;
        const id = params.id;

        this.props.fetchRadiologyTest(id);

        this.handleChange = this.handleChange.bind(this)
        this.validateAndSubmit = this.validateAndSubmit.bind(this)
    }


    static getDerivedStateFromProps(props, state) {

        if (state.flag === "null") {
            return {
                flag: 'true'
            }
        }
        if (state.flag === 'true') {
            return {
                id: props.radiologyTest.id,
                test_name: props.radiologyTest.test_name,
                test_category: props.radiologyTest.test_category,
                test_description: props.radiologyTest.test_description,
                price: props.radiologyTest.price.price,
                test_status: props.radiologyTest.test_status,
                flag: 'false'
            };
        } else {
            return state;
        }

    }

    handleChange(e) {
        const name = e.target.name
        const value = e.target.value

        this.setState({
            [name]: value
        });

    }

    validateAndSubmit(e) {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        const postData = {
            test_name: this.state.test_name,
            test_category: this.state.test_category,
            test_description: this.state.test_description,
            test_status: this.state.test_status
        };

        this.props.radiologyTestUpdate(this.state.id, postData, this.props.history);
    }

    render() {
        return <div>
            <Helmet>
                <title>Edit Radiology Test</title>
            </Helmet>
            <Content title="Edit Radiology Test">
                <Box>
                    <Box.Head title="Edit Radiology Test Form"></Box.Head>
                    <Box.Body>
                        <Alert />
                        {this.state && this.state.id &&
                            <form onSubmit={this.validateAndSubmit}>

                                <FormGroup className='row' controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Test category: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl name="test_category" componentClass="select" value={this.state.test_category} onChange={this.handleChange} placeholder="Select Test category">
                                            <option value="" disabled={true}>select test category</option>
                                            {RADIOLOGY_TEST_CATEGORIES.map((value) => {
                                                return <option key={value} value={value}>{value}</option>
                                            })}
                                        </FormControl>
                                        {this.state.test_type === 'Test' ? this.validator.message('test_category', this.state.test_category, 'required') : ''}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Test Name: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='test_name'
                                            type="text"
                                            value={this.state.test_name}
                                            placeholder="Enter test name"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('test_name', this.state.test_name, 'required|string')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Test description: </ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='test_description'
                                            componentClass="textarea"
                                            value={this.state.test_description}
                                            placeholder="Enter test description"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('test_description', this.state.test_description, 'string')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Test Price: </ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='price'
                                            type="number"
                                            value={this.state.price}
                                            placeholder="Enter test price"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                            readOnly={true}
                                        />
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Status:  <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl name="test_status" componentClass="select" value={this.state.test_status} onChange={this.handleChange} placeholder="Select Test Type">
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </FormControl>
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={6} md={6}>
                                        <ButtonToolbar>
                                            <Button type='submit' bsStyle="primary">Save</Button>
                                            <a href="/pathology/tests" className="btn btn-info">Back</a>
                                        </ButtonToolbar>
                                    </Col>
                                </FormGroup>
                            </form>
                        }
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    radiologyTest: state.radiologyTest
});

const mapActionsToProps = ({
    fetchRadiologyTest: RadiologyTestAction.fetchRadiologyTest,
    radiologyTestUpdate: RadiologyTestAction.radiologyTestUpdate
});

export default connect(mapStateToProps, mapActionsToProps)(EditRadiologyTestPage);
