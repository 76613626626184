import React from "react";
import { Helmet } from 'react-helmet';
import { Content, Box } from '../../components/Elements';
import Alert from '../../components/Alert';
import { Table } from 'react-bootstrap';
import { getDateFormat } from '../../utils/helpers';
import { capitalize, isEmpty } from 'lodash';
import * as loaderActions from '../../actions/loaderAction';
import { connect } from 'react-redux';
import PatientService from "../../services/patientService";
import MergePatientModal from "../../components/PatientAlias/MergePatientModal";
import AddPatientModal from "../../components/PatientAlias/AddPatientModal";
import NewRecord from "../../components/PatientAlias/NewRecord";
import * as PatientAliasComponent from '../../components/PatientAlias/Components';
import Notification from "../../utils/notification";

class ViewPatientPage extends React.Component {
    state = {
        new_data: {
            patient_id: this.props.match.params.patientId
        },
        matched_records: '',
        showMergePatientModal: false,
        showAddPatientModal: false,
        old_data: '',
        merge_data: {}
    }

    componentDidMount() {
        const { match: { params: { patientId } } } = this.props;
        this.props.loaderShow();
        Promise.all([
            PatientService.fetchpatient(patientId).then(function (response) {
                return response.data
            }),
            PatientService.fetchPatientAlias(patientId).then(function (response) {
                return response.data
            })
        ]).then(([patient, matched_records]) => {
            if (!isEmpty(patient) && patient.status !== "offline") {
                Notification.show('error', { message: 'Invalid patient id.' });
                setTimeout(() => {
                    this.props.history.push('/patient-requests')
                }, 900);
            }
            this.setState({
                new_data: patient,
                matched_records: matched_records.data
            })
            this.props.loaderHide();
        }).catch(error => {
            this.props.loaderHide();
            Notification.show('error', error.response.data)
        });
    }
    handleMergePatientModal = () => this.setState({ showMergePatientModal: !this.state.showMergePatientModal })

    handleAddPatientModal = () => this.setState({ showAddPatientModal: !this.state.showAddPatientModal });

    onRecordView = (record) => {
        let { merge_data } = this.state
        if (!isEmpty(merge_data))
            merge_data = {};

        this.setState({ merge_data, old_data: { ...record } }, () => {
            var elmnt = document.getElementById("patient-match-container");
            elmnt.scrollIntoView({ behavior: 'smooth', block: 'start' });
        })
    }
    handleMergeData = (property, data) => {
        let { merge_data } = this.state
        merge_data[property] = data[property];
        this.setState({ merge_data })
    }
    handleMergeSubmit = (e) => {
        e.preventDefault();
        if (isEmpty(this.state.merge_data)) {
            Notification.show('error', { message: 'Kindly complete the data merge' });
            return false;
        } else {
            const postData = {
                merge_data: this.state.merge_data,
                new_data: this.state.new_data,
                old_data: this.state.old_data,
                action: 'merge'
            }
            this.setState({ showMergePatientModal: false, });
            this.props.loaderShow()
            PatientService.mergePatientAlias(postData).then(resp => {
                this.props.loaderHide();
                Notification.show('success', resp.data);
                setTimeout(() => {
                    this.props.history.push('/patient-requests')
                }, 1000);
            }).catch(err => {
                this.props.loaderHide()
                Notification.show('error', err.response.data);
            })
        }
    }
    createNewPatient = (e) => {
        e.preventDefault();
        this.setState({ showAddPatientModal: false, });
        this.props.loaderShow()
        PatientService.createNewPatient(this.state.new_data.patient_id).then(resp => {
            this.props.loaderHide()
            Notification.show('success', resp.data);
            this.props.history.push('/patient-requests');
        }).catch(err => {
            this.props.loaderHide()
            Notification.show('error', err.response.data);
        })
    }
    render() {
        const { new_data, old_data, merge_data, matched_records } = this.state;

        return <React.Fragment>
            <Helmet>
                <title>Patient Alias</title>
            </Helmet>
            <Content title="Patient Alias">
                <Box>
                    <Box.Body>
                        <Box.Head title="New Patient"><button onClick={this.handleAddPatientModal} className="btn btn-info pull-right">Add as New Patient</button></Box.Head>
                        <Alert />
                        {new_data && <NewRecord patient={new_data} />}
                    </Box.Body>
                </Box>

                {/* Matched Records */}
                {matched_records && <Box>
                    <Box.Head>Matched Records</Box.Head>
                    <Box.Body>
                        <Table responsive striped>
                            <thead>
                                <tr>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Maiden's Name</th>
                                    <th>Date Of Birth</th>
                                    <th>Place Of Birth</th>
                                    <th>Contact Number</th>
                                    <th>Action</th>
                                </tr>
                                {matched_records.map(record => (<tr>
                                    <td>{record.first_name}</td>
                                    <td>{record.last_name}</td>
                                    <td>{record.maiden_name}</td>
                                    <td>{getDateFormat(record.date_of_birth)}</td>
                                    <td>{capitalize(record.place_of_birth)}</td>
                                    <td>{record.contact_number}</td>
                                    <td><button onClick={() => this.onRecordView(record)}>View</button></td>
                                </tr>))}
                            </thead>
                        </Table>
                    </Box.Body>
                </Box>}

                {new_data && old_data && <div id="patient-match-container"> <Box>
                    <Box.Head>Patient Details Matching</Box.Head>
                    <Box.Body >

                        <PatientAliasComponent.PatientDetailsMatching
                            old_data={old_data}
                            new_data={new_data}
                            merge_data={merge_data}
                            handleMergeData={this.handleMergeData}
                            onSubmit={this.handleMergePatientModal} />

                    </Box.Body>
                </Box></div>}
            </Content>
            <MergePatientModal show={this.state.showMergePatientModal} handleModal={this.handleMergePatientModal} handleSubmit={this.handleMergeSubmit} />
            <AddPatientModal show={this.state.showAddPatientModal} handleModal={this.handleAddPatientModal} onSubmit={this.createNewPatient} />
        </React.Fragment>
    }
}
const mapActionsToProps = ({
    loaderShow: loaderActions.loaderShow,
    loaderHide: loaderActions.loaderHide,
})
export default connect(null, mapActionsToProps)(ViewPatientPage)