import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Content, Box } from '../../components/Elements';
import { Button, ButtonToolbar } from 'react-bootstrap';
import * as PharmacyAction from '../../actions/pharmacyAction';
import { Helmet } from 'react-helmet';
import * as HELPER from '../../utils/helpers';
import { capitalize } from 'lodash';
import DataTable from '../../components/DataTable';

class ListPharmacyPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: {},
        };
    }

    componentDidMount() {
        this.props.paginate({});
    }

    columns = () => {
        return [
            {
                dataField: "service_provider_id",
                text: "Provider Id",
                hidden: false,
                sort: true
            },
            {
                dataField: "billing_name",
                text: "Billing Name",
                sort: false
            },
            {
                dataField: "created_at",
                text: "Onboard date",
                sort: false,
                formatter: cell => {
                    return HELPER.getDateFormat(cell);
                }
            },
            {
                dataField: "status",
                text: "Status",
                sort: false,
                formatter: cell => {
                    return capitalize(cell);
                }
            },
            {
                dataField: "actions",
                text: "Actions",
                isDummyField: true,
                formatter: this.actionFormatter
            }
        ];
    };

    actionFormatter = (_cell, row) => {
        return <ButtonToolbar>
            {HELPER.hasPermission(this.props.admin, ['pharmacy-view']) && <Link className="btn btn-info" to={'/pharmacies/' + row.service_provider_id}><i className="fa fa-eye" aria-hidden="true"></i></Link>}
            {HELPER.hasPermission(this.props.admin, ['pharmacy-edit']) && <Link className="btn btn-warning" to={'/pharmacies/' + row.service_provider_id + '/edit'}><i className="fa fa-pencil"></i></Link>}
        </ButtonToolbar>;
    }

    handleTableChange = (type, { page, sizePerPage, sortField , sortOrder, searchText }) => {
        if (type === 'search') {
            page = 1;
        }
        let filter = this.state.filter;
        this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText, filter });
    }

    handleChange = (event) => {
        let filter = this.state.filter;
        filter[event.target.name] = event.target.value ? event.target.value : undefined;

        this.setState({
            filter: filter
        });
    }

    submitSearch = (event) => {
        event.preventDefault();

        const { filter } = this.state;
        this.props.paginate({ filter });
    }

    resetSearch = () => {
        this.setState({
            filter: {}
        });
        const filter = {};
        this.props.paginate({ filter });
    }

    render() {
        let { data, meta, loading } = this.props;

        return <div>
            <Helmet>
                <title>List Pharmacies</title>
            </Helmet>
            <Content title="Pharmacies">
                <Box box="solid">
                    <Box.Body>
                        <form onSubmit={this.submitSearch} onReset={this.resetSearch}>
                            <div className="row">
                                <div className="col-md-3 form-group">
                                    <label>Status</label>
                                    <select name="status" className="form-control" onChange={this.handleChange}>
                                        <option value="">All</option>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </select>
                                </div>
                                <div className="col-md-12 btn-toolbar">
                                    <Button bsStyle="btn btn-primary  pull-right" type="submit"><i className="fa fa-search"></i> Search</Button>
                                    <Button bsStyle="btn btn-danger  pull-right" type="reset"><i className="fa fa-repeat"></i> Reset</Button>
                                </div>
                            </div>
                        </form>
                    </Box.Body>
                </Box>
                <Box>
                    <Box.Body>
                        {HELPER.hasPermission(this.props.admin, ['pharmacy-add']) && <Link to="/pharmacy/add" className="btn btn-success"><span className="fa fa-plus"></span> Add Pharmacy</Link>}
                        <DataTable
                            loading={loading}
                            columns={this.columns()}
                            data={data}
                            page={parseInt(meta.current_page)}
                            sizePerPage={parseInt(meta.per_page)}
                            totalSize={parseInt(meta.total)}
                            onTableChange={this.handleTableChange}
                        />
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    ...state.pharmacy_list,
    admin: state.auth.admin
});

const mapActionsToProps = ({
    paginate: PharmacyAction.list
})

export default connect(mapStateToProps, mapActionsToProps)(ListPharmacyPage);
