import React from 'react';
import { Modal } from 'react-bootstrap';

const ActivateDeactivateModal = ({ show, handleModal, onSubmit, status, name }) => {
    return <Modal show={show} onHide={handleModal} centered backdrop="static">
        <Modal.Header>
            <Modal.Title>Activate or Deactivate confirmation?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure, you want to {status === 0 ? 'activate' : 'deactivate'} {name} ?</Modal.Body>
        <Modal.Footer>
            <button type="button" class="btn btn-secondary wd-100" onClick={handleModal}>No</button>
            <button type="button" class="btn btn-primary wd-100" onClick={onSubmit}>Yes</button>
        </Modal.Footer>
    </Modal>
}
export default ActivateDeactivateModal