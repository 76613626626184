import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class UserAccountService {
    static list({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText, filter)
        };

        return axios.get(apiRoute('/v1/service-providers/accounts'), requestOptions);
    }

    static show(service_provider_id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/service-providers/' + service_provider_id + '/accounts'), requestOptions);
    }

    static makePayment(postData) {
        const requestOptions = {
            headers: getApiHeader()
        };
        const data = JSON.stringify(postData)
        return axios.post(apiRoute('/v1/users/payments'), data, requestOptions);

    }

    static serviceProviderInvoices(service_provider_id, { page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText, filter)
        };

        return axios.get(apiRoute('/v1/service-providers/' + service_provider_id + '/invoices'), requestOptions);
    }

    static serviceProviderStatements(service_provider_id, { page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText, filter)
        };

        return axios.get(apiRoute('/v1/service-providers/' + service_provider_id + '/statements'), requestOptions);
    }

    static viewServiceProviderStatements(service_provider_id, statement_id) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/service-providers/' + service_provider_id + '/statements/' + statement_id), requestOptions);
    }

    static viewServiceProviderInvoices(service_provider_id, invoice_id) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/service-providers/' + service_provider_id + '/invoices/' + invoice_id), requestOptions);
    }
}

export default UserAccountService;