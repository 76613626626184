import React from 'react';
import CountBox from './CountBox';
import DashboardService from '../../services/dashboardService';

class PathologyCount extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            count: '...'
        }
    }

    componentDidMount() {
        DashboardService.getAmountReceviableRadiology().then(res => {
            this.setState({
                count: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(res.data.amount_receviable_radiology),
            });
        });
    }

    render() {
        return (
            <div className={this.props.className}>
                <CountBox title="Radiology" count={this.state.count} />
            </div>
        );
    }
}

export default PathologyCount;
