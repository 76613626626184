import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Content, Box } from '../../components/Elements';
import { Button, ButtonToolbar } from 'react-bootstrap';
import * as DoctorAction from '../../actions/doctorActions';
import { Helmet } from 'react-helmet';
import * as HELPER from '../../utils/helpers';
import DoctorService from '../../services/doctorService'
import Notification from "../../utils/notification";
import DataTable from '../../components/DataTable';

class ListDoctorPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: {},
        };
    }

    componentDidMount() {
        this.props.paginate({});
    }

    columns = () => {
        return [
            {
                dataField: "service_provider_id",
                text: "Provider Id",
                hidden: false,
                sort: true
            },
            {
                dataField: "full_name",
                text: "Doctor Name",
                sort: false
            },
            {
                dataField: "email",
                text: "Email",
                sort: false
            },
            {
                dataField: "contact_number",
                text: "Contact No.",
                sort: false
            },
            {
                dataField: "status",
                text: "Status",
                sort: false,
                formatter: cell => {
                    return cell === 1 ? "Active" : "Inactive";
                }
            },
            {
                dataField: "actions",
                text: "Actions",
                isDummyField: true,
                formatter: this.actionFormatter
            }
        ];
    };

    actionFormatter = (cell, row) => {
        return <ButtonToolbar>
            {HELPER.hasPermission(this.props.admin, ['doctor-view']) && <Link className="btn btn-info" to={'/doctors/' + row.service_provider_id}><i className="fa fa-eye" aria-hidden="true"></i></Link>}
            {HELPER.hasPermission(this.props.admin, ['doctor-edit']) && <Link className="btn btn-warning" to={'/doctors/' + row.service_provider_id + '/edit'}><i className="fa fa-pencil"></i></Link>}
            {HELPER.hasPermission(this.props.admin, ['doctor-send-email']) && row.status === 0 && <Link className="btn btn-success" title="Resend Verification Email" onClick={this.sendNewPasswordEmail.bind(this, row.email)}><i className="fa fa-envelope"></i></Link>}
        </ButtonToolbar>;
    }

    sendNewPasswordEmail(email) {
        DoctorService.resendEmail(email)
            .then(resp => {
                Notification.show('success', resp.data);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    Notification.show('error', errorData);
                } else {
                    Notification.show('error', { message: "Something Went Wrong, Try Again" });
                }
            });
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        if (type === 'search') {
            page = 1;
        }
        let filter = this.state.filter;
        this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText, filter });
    }

    handleChange = (event) => {
        let filter = this.state.filter;
        filter[event.target.name] = event.target.value ? event.target.value : undefined;

        this.setState({
            filter: filter
        });
    }

    submitSearch = (evt) => {
        evt.preventDefault();
        const { filter } = this.state;
        this.props.paginate({ filter });
    }

    resetSearch = () => {
        this.setState({
            filter: {}
        })
        const filter = {};
        this.props.paginate({ filter });
    }

    render() {
        let { data, meta, loading } = this.props.doctor_list;

        return <div>
            <Helmet>
                <title>List Doctors</title>
            </Helmet>
            <Content title="Doctors">
                <Box box="solid">
                    <Box.Body>
                        <form onSubmit={this.submitSearch} onReset={this.resetSearch}>
                            <div className="row">
                                <div className="col-md-3 form-group">
                                    <label>Status</label>
                                    <select name="status" className="form-control" onChange={this.handleChange}>
                                        <option value="">All</option>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </select>
                                </div>
                                <div className="col-md-12 btn-toolbar">
                                    <Button className="btn pull-right" bsStyle="primary" type="submit"><i className="fa fa-search"></i> Search</Button>
                                    <Button className="btn pull-right" bsStyle="danger" type="reset"><i className="fa fa-repeat"></i> Reset</Button>
                                </div>
                            </div>
                        </form>
                    </Box.Body>
                </Box>

                <Box>
                    <Box.Body>
                        {HELPER.hasPermission(this.props.admin, ['doctor-add']) && <Link to="/doctors/add" className="btn btn-success"><span className="fa fa-plus"></span> Add Doctor</Link>}
                        <DataTable
                            loading={loading}
                            columns={this.columns()}
                            data={data}
                            page={parseInt(meta.current_page)}
                            sizePerPage={parseInt(meta.per_page)}
                            totalSize={parseInt(meta.total)}
                            onTableChange={this.handleTableChange}
                        />
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    doctor_list: state.doctor_list,
    alert: state.alert,
    admin: state.auth.admin
});

const mapActionsToProps = ({
    paginate: DoctorAction.list,
})

export default connect(mapStateToProps, mapActionsToProps)(ListDoctorPage);