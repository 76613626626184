import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class PathologyLimsTestService {
    static limslist({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText, filter)
        };

        return axios.get(apiRoute('/v1/pathology/lims-tests'), requestOptions);
    }

    static pathologyLimsTestAdd(statedata) {
        let paramsdata = JSON.stringify(statedata);

        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute('/v1/pathology/lims-tests'), paramsdata, requestOptions);
    }

    static fetchPathologyLimsTest(id) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/pathology/lims-tests/' + id), requestOptions);

    }

    static pathologyLimsTestUpdate(id, postData) {
        let paramsdata = JSON.stringify(postData);
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.put(apiRoute('/v1/pathology/lims-tests/' + id), paramsdata, requestOptions);
    }

    static addLimsTestProfile(statedata) {
        let paramsdata = JSON.stringify(statedata);

        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute('/v1/pathology/lims-profile-test'), paramsdata, requestOptions);
    }

    static updateLimsTestProfileStatus(statedata) {
        let paramsdata = JSON.stringify(statedata);

        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.patch(apiRoute('/v1/pathology/lims-profile-test/status'), paramsdata, requestOptions);
    }

    static getAllPathologyCategory() {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/pathology/lims-categories/active'), requestOptions);
    }

    static exportLimsListReport({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText, filter)
        };
        return axios.get(apiRoute('/v1/pathology/lims-tests/result/export'), requestOptions);
    }

    static updateLimsTestCsv(statedata) {
        let paramsdata = JSON.stringify(statedata);

        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute('/v1/pathology/lims-tests/upload-csv'), statedata, requestOptions);
    }

}

export default PathologyLimsTestService;

