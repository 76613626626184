import axios from 'axios';
import { apiRoute, getApiHeader, createPaginateQueryString } from '../utils/helpers';

class ReferralService {

    static getReferralById(id) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/pathologies/lims-referrals/' + id), requestOptions);
    }

    static getReferralReportLst(page, sizePerPage, sortBy, searchText, filter) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText, filter)
        };
        return axios.get(apiRoute('/v1/pathologies/lims-referrals'), requestOptions);
    }
    
    static getTestResultReportLst({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText, filter)
        };
        return axios.get(apiRoute('/v1/pathologies/lims-referrals-test/result'), requestOptions);
    }

    static exportTestResultReport({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText, filter)
        };
        return axios.get(apiRoute('/v1/pathologies/lims-referrals-test/result/export'), requestOptions);
    }

    static exportReferralsListReport({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText, filter)
        };
        return axios.get(apiRoute('/v1/pathologies/lims-referrals/result/export'), requestOptions);
    }

    static getLimsTestList() {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.get(apiRoute('/v1/pathologies/lims-tests'), requestOptions);
    }
}

export default ReferralService;
