import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { FormGroup, ControlLabel, FormControl, Col, Grid } from 'react-bootstrap';
import * as GenericDrugAction from '../../actions/genericdrugActions';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from 'react-helmet';
import Alert from '../../components/Alert';

class AddGenericDrugPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
        };

        this.validator = new SimpleReactValidator();

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        this.props.generic_drug_add(this.state, this.props.history);
    }

    render() {
        return <div>
            <Helmet>
                <title>Add Generic Drug</title>
            </Helmet>
            <Content title="Generic Drugs">
                <Box>
                    <Box.Head title="Add Generic Drug" backTo="/genericdrugs" />
                    <Box.Body>
                        <Alert />
                        <Grid>
                            <form onSubmit={this.handleSubmit}>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Name: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='name'
                                            type="text"
                                            value={this.state.name}
                                            placeholder="Enter generic drug name"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('name', this.state.name, 'required|string')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={6} md={6}>
                                        <button type='submit' className='btn btn-primary'>Add</button>
                                    </Col>
                                </FormGroup>
                            </form>
                        </Grid>
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    generic_drugs: state.drugs,
    admin: state.auth.admin
});

const mapActionsToProps = ({
    generic_drug_add: GenericDrugAction.generic_drug_add
})

export default connect(mapStateToProps, mapActionsToProps)(AddGenericDrugPage);
