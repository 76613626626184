import * as types from './actionTypes';
import PathologyLimsCategoryService from '../services/pathologyLimsCategoryService';

export function limsCategorylist({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: types.PATHOLOGY_TEST_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText } });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });
        PathologyLimsCategoryService.limsCategorylist({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.PATHOLOGY_TEST_LIST_SUCCESS, ...data });
                dispatch({ type: types.ALERT_CLEAR });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function pathologyLimsCategoryAdd(statedata, history) {
    return dispatch => {
        dispatch({ type: types.ADD_PATHOLOGY_TEST });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        PathologyLimsCategoryService.pathologyLimsCategoryAdd(statedata, history)
            .then(resp => {
                let data = resp.data;
                if(data === true){
                    dispatch({ type: types.ADD_PATHOLOGY_TEST, ...data });   
                    dispatch({ type: types.ALERT_SUCCESS, message: 'Lims Category Added successfully' });                
                }else{
                    dispatch({ type: types.ALERT_ERROR, message: 'Lims Category already Exists' });
                }
               
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                    history.push('/lims-categories');
                }, 2500);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function pathologyLimsCategoryUpdate(id, statedata, history) {
    return dispatch => {
        PathologyLimsCategoryService.pathologyLimsCategoryUpdate(id, statedata)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.UPDATE_PATHOLOGY_TEST, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: 'Lims Category updated successfully' });
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                    history.push('/lims-categories');
                }, 2500);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}