import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { FormGroup, ControlLabel, FormControl, Col, Grid, Table } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from 'react-helmet';
import Alert from '../../components/Alert';
import RoleManagementService from '../../services/roleManagementService';
import Notification from '../../utils/notification';
import _ from 'lodash';

class AddRolePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            display_name: '',
            permissions: '',
            status: ''
        };
        this.validator = new SimpleReactValidator();
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckedData = this.handleCheckedData.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        RoleManagementService.getPermissions().then(resp => {
            this.setState({
                permissions: resp.data.data
            })
        }).catch(e => {
            Notification.show('error', e.response.data)
        })
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleCheckedData = (index) => {
        const { permissions } = this.state
        const options = permissions.slice();

        if (options[index]) {
            options[index].checked = !options[index].checked;
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const options = this.state.permissions.slice();
        const selectedData = options.filter(option => option.checked)

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        if (selectedData === '') {
            Notification.show('error', { message: 'Select atleast one permission.' });
        }
        else {
            const postData = {
                permission: _.map(selectedData, 'permission_id'),
                name: this.state.name,
                display_name: this.state.display_name,
                status: this.state.status
            }
            RoleManagementService.create(postData).then(resp => {
                Notification.show('success', resp.data);
                this.props.history.push('/roles');
            }).catch(error => {
                Notification.show('error', error.response.data);
            })
        }
    }

    render() {
        const { permissions } = this.state
        return <div>
            <Helmet>
                <title>Add Role</title>
            </Helmet>
            <Content title="Add Role">
                <Box>
                    <Box.Head title="Add Role" backTo="/roles" />
                    <Box.Body>
                        <Alert />
                        <Grid>
                            <form onSubmit={this.handleSubmit}>
                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Name: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='name'
                                            type="text"
                                            value={this.state.name}
                                            placeholder="Enter role"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('name', this.state.name, 'required|string')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Display Name: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='display_name'
                                            type="text"
                                            value={this.state.display_name}
                                            placeholder="Enter Display Name"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('display_name', this.state.display_name, 'required|string')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Status: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl name="status" componentClass="select" value={this.state.status} onChange={this.handleChange}>
                                            <option value="" disabled selected>Select Status</option>
                                            <option value="true">Active</option>
                                            <option value="false">Inactive</option>
                                        </FormControl>
                                        {this.validator.message('status', this.state.status, 'required')}
                                    </Col>
                                </FormGroup>

                                {permissions && <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Permissions: <span>*</span></ControlLabel>
                                    </Col>
                                    <Table responsive bordered>
                                        <tbody>
                                            {permissions.map((permission, index) => (
                                                <tr key={index}>
                                                    <td><input type="checkbox" id={index} onClick={() => this.handleCheckedData(index)} name="permissions" checked={permission.checked} />{"   " + permission.display_name}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </FormGroup>}

                                <FormGroup className="row" controlId="">
                                    <Col sm={6} md={6}>
                                        <button type='submit' className='btn btn-primary'>Add</button>
                                    </Col>
                                </FormGroup>
                            </form>
                        </Grid>
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
});

const mapActionsToProps = ({
})

export default connect(mapStateToProps, mapActionsToProps)(AddRolePage);
