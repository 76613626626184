import React from 'react';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import { Table } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import * as AdminUserAction from '../../actions/adminuserActions';
import { Box, Content } from '../../components/Elements';
import _ from 'lodash';
import { getDateFormat } from '../../utils/helpers';

class ViewAdminUserPage extends React.Component {

    constructor(props) {
        super(props);

        const { match: { params } } = this.props;
        const id = params.id;
        this.props.fetchadminuser(id);

    }


    render() {

        const { admin_user } = this.props;

        let details;

        if (admin_user && admin_user.admin_id) {

            details = <Table responsive striped>
                <tbody>
                    <tr>
                        <th>Name</th>
                        <td>{admin_user.full_name}</td>
                    </tr>
                    <tr>
                        <th>Email</th>
                        <td>{admin_user.email}</td>
                    </tr>
                    <tr>
                        <th>Contact Number</th>
                        <td>{admin_user.contact_number ? admin_user.contact_number : 'NULL'}</td>
                    </tr>
                    <tr>
                        <th>Date of Birth</th>
                        <td>{getDateFormat(admin_user.date_of_birth)}</td>
                    </tr>
                    <tr>
                        <th>Gender</th>
                        <td>{_.capitalize(admin_user.gender)}</td>
                    </tr>
                    <tr>
                        <th>TRN Number</th>
                        <td>{admin_user.trn_number}</td>
                    </tr>
                    <tr>
                        <th>Role</th>
                        <td>{!_.isEmpty(admin_user.roles) ? admin_user.roles.display_name : '--'}</td>
                    </tr>
                    <tr>
                        <th>Status</th>
                        <td>{admin_user.status === 1 ? 'Active' : 'Inactive'}</td>
                    </tr>
                </tbody>
            </Table>;
        }

        return <div>
            <Helmet>
                <title>View Admin User</title>
            </Helmet>
            <Content title="Admin User">

                <Box>
                    <Box.Head title="Admin User Details" />
                    <div>
                        {details}
                    </div>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    admin_user: state.admin_user,
    admin: state.auth.admin
});

const mapActionsToProps = ({
    fetchadminuser: AdminUserAction.fetchadminuser
});

export default connect(mapStateToProps, mapActionsToProps)(ViewAdminUserPage);