import React from "react";
import { connect } from "react-redux";
import { Content, Box } from "../../components/Elements";
import { Button, ButtonToolbar } from "react-bootstrap";
import * as ReferralReportListAction from "../../actions/referralReportListAction";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import * as HELPER from "../../utils/helpers";
import DataTable from '../../components/DataTable';
import { capitalize } from 'lodash';
import ReferralService from '../../services/referralService';

class ReferralReportList extends React.Component {
    constructor(props) {
        super(props);
        this.props.paginate();
        this.state = {
            filter: {
                status: "",
            }
        }
    }

    actionFormatter = (cell, row) => {
        return <ButtonToolbar>
            <Link className="btn btn-sm btn-info" to={'/referral-report/' + row.id}><i className="fa fa-eye" aria-hidden="true"></i></Link>
        </ButtonToolbar>;
    }

    columns = () => {
        return [
            {
                dataField: "date_received",
                text: "Date Received",
                sort: false,
                formatter: this.dateFormatter,
            },
            {
                dataField: "pathology_id",
                text: "Pathology Id",
                sort: false,
            },
            {
                dataField: "pathology_name",
                text: "Pathology Name",
                sort: false,
            },
            {
                dataField: "patient_name",
                text: "Patient Name",
                sort: false,
            },
            {
                dataField: "referred_doctor",
                text: "Referred Doctor",
                sort: false,
            },
            {
                dataField: "sample_collected_at",
                text: "Sample Collected At",
                sort: false,
            },
            {
                dataField: "first_verified",
                text: "First Verified",
                sort: false,
            },
            {
                dataField: "second_verified",
                text: "Second Verified",
                sort: false,
            },
            {
                dataField: "status",
                text: "Status",
                formatter: this.statusFormatter,
                style: { width: "120px" }
            },
            {
                dataField: "action",
                text: "Action",
                isDummyField: true,
                formatter: this.actionFormatter,
            },
        ];
    };

    statusFormatter = (cell, row) => {
        if (!cell) {
            return 'NA';
        }
        return capitalize(cell)
    }

    dateFormatter = (cell, row) => {
        return HELPER.getDateFormat(cell);
    }

    handleTableChange = (_type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        this.setState({
            page: page,
            sizePerPage: sizePerPage,
            sortField: sortField,
            sortOrder: sortOrder,
            searchText: searchText,
        });
        this.props.paginate(page, sizePerPage, { sortField, sortOrder }, searchText, this.state.filter);
    }

    handleExport = () => {
        ReferralService.exportReferralsListReport({ sortBy: this.state.sortBy, filter: this.state.filter, searchText: this.state.searchText }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', "ReferralsListReport.csv")
            document.body.appendChild(link)
            link.click()
            link.remove()
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    resetSearch = () => {
        this.setState({
            filter: {
                status: "",
            }
        }, () => {
            this.props.paginate(1, 25, "", this.state.searchText, this.state.filter);
        });
    }

    handleSearch = (e) => {
        e.preventDefault();
        this.props.paginate(this.state.page, this.state.sizePerPage, this.state.sortField + ":" + this.state.sortOrder, this.state.searchText, this.state.filter);
    }

    render() {
        console.log(this.props.referral_report);
        let { data, meta, loading } = this.props.referral_report;
        return <div>
            <Helmet>
                <title>Referral Reports</title>
            </Helmet>
            <Content title="Referral Reports">
                <Box box="solid">
                    <Box.Body>
                        <div className="row">
                            <div className="col-md-3 form-group">
                                <label>Status</label>
                                <select name="status" className="form-control" value={this.state.filter.status} onChange={e => this.setState({ filter: { ...this.state.status, status: e.target.value } })}>
                                    <option value=''>Select</option>
                                    <option value='new' key={1}>New</option>
                                    <option value='draft' key={2}>Draft</option>
                                    <option value='completed' key={2}>Completed</option>
                                </select>
                            </div>
                            <div className="col-md-12 btn-toolbar">
                                <Button className="btn pull-right" bsStyle="primary" type="submit" onClick={this.handleSearch}><i className="fa fa-search"></i> Search</Button>
                                <Button className="btn pull-right" bsStyle="danger" type="reset" onClick={this.resetSearch}><i className="fa fa-repeat"></i> Reset</Button>
                            </div>
                        </div>
                    </Box.Body>
                </Box>
                <Box>
                    <Box.Head>
                        <button type="button" className="btn btn-primary" onClick={this.handleExport}><span className="fa fa-download"> Export as CSV</span></button>
                    </Box.Head>
                    <Box.Body>
                        <DataTable
                            key="referral_list"
                            loading={loading}
                            columns={this.columns()}
                            data={data}
                            page={parseInt(meta.current_page)}
                            sizePerPage={parseInt(meta.per_page)}
                            totalSize={parseInt(meta.total)}
                            onTableChange={this.handleTableChange}
                        />
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}

function mapStateToProps(state) {
    return {
        referral_report: state.referral_report,
        admin: state.auth.admin
    };
};

const mapActionsToProps = ({
    paginate: ReferralReportListAction.list
});

export default connect(mapStateToProps, mapActionsToProps)(ReferralReportList);
