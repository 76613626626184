import React from 'react';
import { Bar } from 'react-chartjs-2';

export default function BarGraphBox({ yScaleLabel, datasetLabels, datasetCounts, view, handleViewChange }) {

  const data = {
    labels: datasetLabels,
    datasets: datasetCounts
  };
  
  return (
    <>
      <div className="graph-title-head pull-right">
        <button className={view === "month" ? "btn btn-primary" : "btn btn-outline-primary"} onClick={e => handleViewChange("month")} >Month</button>
        <button className={view === "week" ? "btn btn-primary" : "btn btn-outline-primary"} onClick={e => handleViewChange("week")}>Week</button>
        <button className={view === "day" ? "btn btn-primary" : "btn btn-outline-primary"} onClick={e => handleViewChange("day")}>Day</button>
      </div>
      <div className="graph-content">
        <Bar
          width={100}
          height={30}
          data={data}
          options={{
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  precision: 0
                },
                scaleLabel: {
                  display: true,
                  labelString: yScaleLabel
              },
              }]
            }
          }}
        />
      </div>
    </>
  )
}


