import * as types from "./actionTypes";

export function loaderShow() {
  return dispatch => {
    dispatch({ type: types.ALERT_CLEAR, isloading: true });
  };
}

export function loaderHide(message) {
  return dispatch => {
    dispatch({ type: types.ALERT_CLEAR, isloading: false });
  };
}
