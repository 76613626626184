import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { FormGroup, FormControl, Col, Button, Checkbox } from 'react-bootstrap';
import * as DrugPriceAction from '../../actions/drugPriceAction';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from 'react-helmet';
import Alert from '../../components/Alert';
import _ from 'lodash';
import * as HELPER from '../../utils/helpers';


class DrugPricePage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            search_drug_name: '',
            drugSkuData: null,
            flag: 'null'
        };

        this.validator = new SimpleReactValidator({
            validators: {
                futureStartDate: {
                    message: 'The future start date must be greater than today date',
                    rule: (val, params) => {
                        return (new Date(params) > new Date()) ? true : false;
                    },
                    required: true
                }
            }
        });

        this.handleChange = this.handleChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
    }

    static getDerivedStateFromProps(props, state) {

        if (state.flag === 'true') {
            return {
                flag: 'update'
            };
        } else if (state.flag === 'update') {
            return {
                drugSkuData: props.drug_price.data.map(value => {
                    return {
                        is_update: 'no',
                        drug_name: value.drug_name,
                        drug_sku_id: value.id,
                        manufacturer_name: value.manufacturer_name,
                        ndc_code: value.ndc_code ? value.ndc_code : '-',
                        current_price: value.current_price ? value.current_price.price : '-',
                        current_start_date: value.current_price ? value.current_price.start_date : '-',
                        future_price: value.future_price ? value.future_price.price : '',
                        future_start_date: value.future_price ? value.future_price.date : '',
                    }
                }),
                flag: 'updated'
            };
        } else {
            return state;
        }

    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }

    onChange(index, e) {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        let drugSkus = this.state.drugSkuData;

        drugSkus[index][name] = value;

        this.setState({
            drugSkuData: drugSkus
        });
    }

    handleSearch(e) {
        e.preventDefault();

        this.setState({
            flag: 'true'
        });

        this.props.fetchDrugSku(this.state.search_drug_name)
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        let postData = {
            drug_sku_prices: _.compact(this.state.drugSkuData.map(value => {

                if (value.is_update === 'yes') {
                    return {
                        drug_sku_id: value.drug_sku_id,
                        future_price: value.future_price,
                        future_start_date: value.future_start_date,
                    }
                } else {
                    return false;
                }
            })
            )
        };

        this.props.updateDrugSkuPrice(postData);
    }

    handleCheckbox = (index, evt) => {

        const target = evt.target;
        const name = target.name;
        let checked = target.checked;

        let drugSkus = this.state.drugSkuData;

        drugSkus[index][name] = (checked) ? 'yes' : 'no';

        this.setState({
            drugSkuData: drugSkus
        });
    }

    render() {
        return <div>
            <Helmet>
                <title>Drug Price</title>
            </Helmet>
            <Content title="Drug Price">
                <Box>
                    <Box.Head title="Drug Price Form"></Box.Head>
                    <Box.Body>
                        <Alert />
                        <form onSubmit={this.handleSearch} >
                            <FormGroup className="row" controlId="">
                                <Col sm={3}>
                                    <FormControl
                                        name='search_drug_name'
                                        type="text"
                                        value={this.state.search_drug_name}
                                        placeholder="Search drug name"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                        required='required'
                                    />
                                </Col>
                                <Col sm={3}>
                                    <Button type='submit' bsStyle='default'>Search</Button>
                                </Col>
                            </FormGroup>
                        </form>
                    </Box.Body>
                </Box>
                {this.state.drugSkuData && <Box box="solid">
                    <Box.Body>
                        <form onSubmit={this.handleSubmit}>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Drug Name</th>
                                        <th>NDC Code</th>
                                        <th>Current Price</th>
                                        <th>Price Updated On</th>
                                        <th>New Price</th>
                                        <th>New Start Date</th>
                                    </tr></thead>
                                <tbody>
                                    {this.state.drugSkuData && this.state.drugSkuData.map((data, index) => (
                                        <tr>
                                            <td>
                                                {HELPER.hasPermission(this.props.admin, ['drug-price-edit']) && <FormGroup>
                                                    <Checkbox name="is_update" onChange={(evt) => this.handleCheckbox(index, evt)}></Checkbox>
                                                </FormGroup>}
                                            </td>
                                            <td>
                                                {data.drug_name ? data.drug_name : ''}
                                            </td>
                                            <td>
                                                {data.ndc_code ? data.ndc_code : ''}
                                            </td>
                                            <td>
                                                {data.current_price}
                                            </td>
                                            <td>
                                                {HELPER.getDateFormat(data.current_start_date)}
                                            </td>
                                            <td>
                                                <FormControl
                                                    name='future_price'
                                                    type="number"
                                                    value={data.future_price}
                                                    autoComplete="none"
                                                    onChange={(e) => this.onChange(index, e)}
                                                    disabled={data.is_update === 'no' ? true : false}
                                                />
                                                {data.is_update === 'yes' && this.validator.message('future_price', data.future_price, 'required|numeric')}
                                            </td>
                                            <td>
                                                <FormControl
                                                    name='future_start_date'
                                                    type="date"
                                                    value={data.future_start_date}
                                                    autoComplete="none"
                                                    min={new Date(Date.now() + 1 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10)}
                                                    max="2999-12-31"
                                                    onChange={(e) => this.onChange(index, e)}
                                                    disabled={data.is_update === 'no' ? true : false}
                                                />
                                                {data.is_update === 'yes' && this.validator.message('future_start_date', data.future_start_date, 'futureStartDate:' + new Date(data.future_start_date))}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {!_.isEmpty(this.state.drugSkuData) &&
                                <div>
                                    <Button type='submit'>Save</Button>
                                </div>
                            }
                        </form>

                        {!_.isNull(this.state.drugSkuData) && _.isEmpty(this.state.drugSkuData) &&
                            <div className="alert alert-warning"> No Drug Sku Found</div>
                        }
                    </Box.Body>
                </Box>}
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    drug_price: state.drug_price,
    admin: state.auth.admin
});

const mapActionsToProps = ({
    fetchDrugSku: DrugPriceAction.fetchDrugSku,
    updateDrugSkuPrice: DrugPriceAction.updateDrugSkuPrice
});

export default connect(mapStateToProps, mapActionsToProps)(DrugPricePage);