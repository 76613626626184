import * as types from '../actions/actionTypes';
import * as authUtils from '../utils/auth';

const initialState = {
  checked: true,
  isAuthenticated: authUtils.checkSessionToken(),
  admin: authUtils.getUser()
}

export default function auth(state = initialState, { type, ...payload }) {
  switch (type) {
    case types.LOGIN_REQUEST:
      return {
        checked: true,
        isAuthenticated: false
      };
    case types.LOGIN_SUCCESS:
      return {
        checked: true,
        isAuthenticated: true,
        ...payload
      };
    case types.LOGIN_FAILED:
      return {
        checked: true,
        isAuthenticated: false
      };
    case types.LOGOUT:
      return {
        checked: true,
        isAuthenticated: false
      };
    case types.FORGOT_PASSWORD_SUCCESS:
      return {
        checked: true,
        isAuthenticated: false
      };
    case types.RESET_PASSWORD_SUCCESS: {
      return {
        checked: true,
        isAuthenticated: false,
      }
    }
    default:
      return state;
  }
}