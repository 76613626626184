import * as types from './actionTypes';
import UserAccountService from '../services/userAccountService';

export function list({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: types.LIST_USER_ACCOUNT, request: { page, sizePerPage, sortBy, searchText, filter } });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        UserAccountService.list({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.LIST_USER_ACCOUNT, ...data });
                dispatch({ type: types.ALERT_CLEAR, isloading: false });
            })
            .catch(error => {
                dispatch({ type: types.RESET_SERVICE_PROVIDER_LIST });
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function resetList() {
    return dispatch => {
        dispatch({ type: types.RESET_SERVICE_PROVIDER_LIST });
    }
}

export function invoiceList(service_provider_id, { page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: types.LIST_SERVICE_PROVIDER_INVOICES, request: { service_provider_id, page, sizePerPage, sortBy, searchText, filter } });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        UserAccountService.serviceProviderInvoices(service_provider_id, { page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.LIST_SERVICE_PROVIDER_INVOICES, ...data });
                dispatch({ type: types.ALERT_CLEAR, isloading: false });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function statementList(service_provider_id, { page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: types.LIST_SERVICE_PROVIDER_STATEMENTS, request: { service_provider_id, page, sizePerPage, sortBy, searchText, filter } });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        UserAccountService.serviceProviderStatements(service_provider_id, { page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.LIST_SERVICE_PROVIDER_STATEMENTS, ...data });
                dispatch({ type: types.ALERT_CLEAR, isloading: false });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}