import React from "react";
import { Tabs, Tab } from 'react-bootstrap';
import PatientRequest from "../../components/PatientAlias/PatientRequest";
import { Helmet } from 'react-helmet';
import { Content, Box } from '../../components/Elements';
import Alert from '../../components/Alert';
import * as PatientAction from '../../actions/patientActions';
import { connect } from 'react-redux';

class RequestPage extends React.Component {
    state = {
        currentKey: 'new_request'
    }
    onTabChange = key => {
        if (key === "new_request") {
            this.props.paginatePatientAlias({});
        } else if (key === "past_record") {
            this.props.paginateAliasRecords({})
        }
        this.setState({ currentKey: key });
    }
    componentDidMount() {
        this.props.paginatePatientAlias({});
    }

    render() {
        return <React.Fragment>
            <Helmet>
                <title>Patient Alias</title>
            </Helmet>
            <Content title="Patient Alias">
                <Box>
                    <Box.Body>
                        <Alert />
                        <Tabs activeKey={this.state.currentKey} className="mb-3" onSelect={this.onTabChange}>
                            <Tab eventKey="new_request" title="New Request">
                                <PatientRequest type="new_request"
                                    patients={this.props.patients}
                                    paginate={this.props.paginatePatientAlias} />
                            </Tab>
                            <Tab eventKey="past_record" title="Past Record">
                                <PatientRequest type="past_record"
                                    patients={this.props.patients}
                                    paginate={this.props.paginateAliasRecords} />
                            </Tab>
                        </Tabs>
                    </Box.Body>
                </Box>
            </Content>

        </React.Fragment>
    }
}
const mapStateToProps = (state) => ({
    patients: state.patient_list,
});

const mapActionsToProps = ({
    paginatePatientAlias: PatientAction.fetchPatientAliasRequest,
    paginateAliasRecords: PatientAction.paginateAliasRecords,
})
export default connect(mapStateToProps, mapActionsToProps)(RequestPage)