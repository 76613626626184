import axios from 'axios';
import { apiRoute, getApiHeader, createPaginateQueryString } from '../utils/helpers';

class CovidDashboardService {

    /**
     * 
     */
    static getPatientsOnboardByCovidCenterCount() {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/count/onboard-covid-patients'), requestOptions);
    }

    /**
     * 
     */
    static testUploadedCount() {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/count/covid-test-uploaded'), requestOptions);
    }

    /**
     * 
     */
    static getCovidCenterCount() {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/count/onboard-covid-centers'), requestOptions);
    }

    /**
     * 
     */
    static getCovidCenterLocationCount() {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/count/covid-center-location'), requestOptions);
    }

    /**
    * 
    */
     static getCovidTest({ view, start_date, end_date }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: {
                start_date: start_date,
                end_date: end_date,
                group_by: view
            }
        };

        return axios.get(apiRoute('/v1/graph/covid-test'), requestOptions);
    }

    /**
     *
     */
     static covidTestCount() {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/count/covid-test'), requestOptions);
    }

    /**
    *
    */
      static getCovidTestGender({ gender, view, start_date, end_date }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: {
                start_date: start_date,
                end_date: end_date,
                group_by: view
            }
        };

        return axios.get(apiRoute('/v1/graph/covid-test/' + gender), requestOptions);
    }

    /**
    *
    */
     static getAllCovidTest({ view, start_date, end_date, service_provider_id, user_location_id }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: {
                start_date: start_date,
                end_date: end_date,
                group_by: view,
                serviceProvider: service_provider_id,
                userLocation: user_location_id === "all" ? "" : user_location_id
            }
        };

        return axios.get(apiRoute('/v1/graph/all-covid-test/'), requestOptions);
    }
}

export default CovidDashboardService;