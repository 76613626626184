import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { FormGroup, ControlLabel, FormControl, Col, Button, ButtonToolbar, Radio } from 'react-bootstrap';
import covidCenterService from '../../services/covidCenterService';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from 'react-helmet';
import * as CONFIG from "../../utils/config";
import _ from 'lodash';
import Notification from '../../utils/notification';
import * as HELPER from '../../utils/helpers';
import EditMultiOwner from "../../components/Owner/EditMultiOwner";
import EditMultiLocation from "../../components/Location/EditMultiLocation";
import * as LoaderAction from '../../actions/loaderAction';
import EditCommission from '../../components/Commission/EditCommission';
import CreatableSelect from 'react-select/creatable';
import CommunityService from '../../services/communityService';

class EditPage extends React.Component {
    constructor(props) {
        super(props);

        const {
            match: { params }
        } = this.props;
        const serviceProviderId = params.serviceProviderId;
        this.state = {
            service_provider_id: serviceProviderId,
            address_line_1: "",
            address_line_2: "",
            address_line_3: "",
            city: "",
            pincode: "",
            parish: "",
            trn_checked: '',
            is_trn_applicable: false,
            trn_number: '',
            license_number: '',
            other_degree_name: '',
            other_specialization_name: '',
            location_details: '',
            flag: true,
            owners: '',
            no_commission: "",
            commission_rate: "",
            commission_type: "",
            currency: "",
            communities: [],
            selectedParisCommunity: []
        };

        this.validator = new SimpleReactValidator({
            validators: {
                birthdate: {
                    message: 'The date of birth must be less than todays date',
                    rule: (val, params) => {
                        return (new Date(val) > new Date(params) ? false : true)
                    },
                    required: true
                }
            }
        });
        this.getCommunity()
    }

    getCommunity = () => {
        CommunityService.list().then(resp => {
            this.setState({
                communities: resp.data.data,
            }, () => {
                this.setSelectedParisCommunity(this.state.parish)
            })
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        const serviceProviderId = params.serviceProviderId;
        this.props.showLoader();
        covidCenterService.get(serviceProviderId).then(resp => {
            const covid_center = resp.data
            this.setState({
                service_provider_id: covid_center.service_provider_id,
                billing_name: covid_center.billing_name,
                trn_number: covid_center.trn_number,
                is_trn_applicable: covid_center.is_trn_applicable,
                trn_checked: covid_center.is_trn_applicable === true ? "checked" : '',
                license_number: covid_center.license_number,
                address_line_1: covid_center.billing_address ? covid_center.billing_address.address_line_1 : '',
                address_line_2: covid_center.billing_address ? covid_center.billing_address.address_line_2 : '',
                address_line_3: covid_center.billing_address ? covid_center.billing_address.address_line_3 : '',
                city: covid_center.billing_address ? covid_center.billing_address.city : '',
                pincode: covid_center.billing_address ? covid_center.billing_address.pincode : '',
                parish: covid_center.billing_address ? covid_center.billing_address.parish : '',
                location_details: covid_center.locations,
                owners: covid_center.owners,
                no_commission: covid_center.no_commission,
                commission_rate: covid_center.commission_rate,
                commission_type: covid_center.commission_type,
                currency: covid_center.currency,
            }, () => {
                this.setSelectedParisCommunity(this.state.parish)
            })
            this.props.hideLoader();
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        if (name === "is_trn_applicable") {
            if (event.target.checked) {
                this.setState({
                    is_trn_applicable: true,
                    trn_checked: 'checked'
                });
            } else {
                this.setState({
                    is_trn_applicable: false,
                    trn_checked: ''
                });
            }
        }

        if (name === "parish") {
            this.setSelectedParisCommunity(value)
            this.setCommunity(null)
        }
    }

    handleCommissionChange = (key, value) => {
        if (key === "no_commission") {
            value = !this.state.no_commission
        }
        this.setState({
            [key]: value
        }
        )
    }

    validateAndSubmit = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        if (this.state.is_trn_applicable === true) {
            if (_.isEmpty(this.state.trn_number)) {
                Notification.show('error', { message: "Please enter TRN Number" });
                return false;
            }
        }

        if (_.isEmpty(this.state.commission_type) && !this.state.no_commission) {
            Notification.show('error', { message: "Please fill Commission details" });
            return false;
        }

        let postData = {
            trn_number: this.state.trn_number,
            license_number: this.state.license_number,
            is_trn_applicable: this.state.is_trn_applicable,
            billing_address: {
                address_line_1: this.state.address_line_1,
                address_line_2: this.state.address_line_2,
                address_line_3: this.state.address_line_3,
                city: this.state.city,
                pincode: this.state.pincode,
                parish: this.state.parish
            },
            billing_name: this.state.billing_name,
            no_commission: this.state.no_commission,
        };

        if (this.state.no_commission === false) {
            postData['commission_type'] = this.state.commission_type
            postData['commission_rate'] = this.state.commission_rate

            if (this.state.commission_type === "pay_per_use") {
                postData['currency'] = this.state.currency
            }
        } else {
            postData['commission_rate'] = 0
        }

        this.props.showLoader();
        covidCenterService.update(this.state.service_provider_id, postData).then(resp => {
            this.props.hideLoader();
            Notification.show('success', { message: 'Details has been updated successfully' });
            this.props.history.push('/covid-centers/' + resp.data.service_provider_id)
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }

    setSelectedParisCommunity = (parish) => {
        if (parish) {
            let selectedCommunities = _.filter(this.state.communities, (items) => items.parish_name == parish)
            selectedCommunities = selectedCommunities.map((items) => {
                return { 'label': items.community_name, 'value': items.community_name };
            })
            this.setState({
                selectedParisCommunity: selectedCommunities
            });
        } else {
            this.setState({
                selectedParisCommunity: []
            });
        }
    }

    setCommunity = (community) => {
        this.setState({ city: community ? community.value : '' });
    }

    createCommunity = (community) => {
        let selectedParisCommunity = this.state.selectedParisCommunity;
        selectedParisCommunity.push({ label: community, value: community });
        this.setState({ city: community, selectedParisCommunity: selectedParisCommunity });
    }

    render() {
        return <div>
            <Helmet>
                <title>Edit Covid Center</title>
            </Helmet>
            <Content title="Edit Covid Center">
                <Box>
                    <Box.Head title="Edit Covid Center Form"></Box.Head>
                    <Box.Body>
                        {this.state.service_provider_id &&
                            <div>
                                <ul className="nav nav-tabs">
                                    <li className="active"><a data-toggle="tab" href="#basic_details"> Billing Details</a></li>
                                    <li className=""><a data-toggle="tab" href="#locations"> Location </a></li>
                                    <li className=""><a data-toggle="tab" href="#owners"> Owners Details </a></li>
                                </ul>
                                <div className="tab-content">
                                    <div id="basic_details" className="tab-pane fade in active">
                                        <form onSubmit={this.validateAndSubmit}>
                                            <h4>Billing Details</h4>
                                            <hr />

                                            <div className="row form-group">
                                                <div className="col-xs-5">
                                                    <ControlLabel>TRN Number: </ControlLabel>
                                                    <FormControl
                                                        name='trn_number'
                                                        type="text"
                                                        value={this.state.trn_number}
                                                        placeholder="Enter your TRN number"
                                                        onChange={this.handleChange}
                                                        autoComplete="none"
                                                    />
                                                    {this.state.is_trn_applicable ? this.validator.message('trn_number', this.state.trn_number, 'required|max:15,string') : ''}
                                                </div>

                                                <div className="col-xs-6">
                                                    <ControlLabel>Bill Name: <span>*</span></ControlLabel>
                                                    <FormControl
                                                        name='billing_name'
                                                        type="text"
                                                        value={this.state.billing_name}
                                                        placeholder="Enter your bill name"
                                                        onChange={this.handleChange}
                                                        autoComplete="none"
                                                    />
                                                    {this.validator.message('billing_name', this.state.billing_name, 'required|string|max:100,string')}
                                                </div>
                                            </div>

                                            <div className="row form-group">
                                                <div className="col-xs-4">
                                                    <ControlLabel>Address Line 1: *</ControlLabel>
                                                    <FormControl
                                                        name='address_line_1'
                                                        type="text"
                                                        value={this.state.address_line_1}
                                                        placeholder="Enter your address line"
                                                        onChange={this.handleChange}
                                                        autoComplete="none"
                                                    />
                                                    {this.validator.message('address_line_1', this.state.address_line_1, 'required|string|max:255,string')}
                                                </div>

                                                <div className="col-xs-4">
                                                    <ControlLabel>Address Line 2:</ControlLabel>
                                                    <FormControl
                                                        name='address_line_2'
                                                        type="text"
                                                        value={this.state.address_line_2}
                                                        placeholder="Enter your address line"
                                                        onChange={this.handleChange}
                                                        autoComplete="none"
                                                    />
                                                    {this.validator.message('address_line_2', this.state.address_line_2, 'string|max:255,string')}
                                                </div>

                                                <div className="col-xs-3">
                                                    <ControlLabel>Address Line 3:</ControlLabel>
                                                    <FormControl
                                                        name='address_line_3'
                                                        type="text"
                                                        value={this.state.address_line_3}
                                                        placeholder="Enter your address line"
                                                        onChange={this.handleChange}
                                                        autoComplete="none"
                                                    />
                                                    {this.validator.message('address_line_3', this.state.address_line_3, 'string|max:255,string')}
                                                </div>
                                            </div>

                                            <div className="row form-group">
                                                <div className="col-xs-3">
                                                    <ControlLabel>Parish: *</ControlLabel>
                                                    <FormControl name="parish" componentClass="select" value={this.state.parish} onChange={this.handleChange} placeholder="Select Parish">
                                                        <option value=''>Select Parish</option>
                                                        {CONFIG.PARISH.map((value) => {
                                                            return <option value={value}>{value}</option>
                                                        })}
                                                    </FormControl>
                                                    {this.validator.message('parish', this.state.parish, 'required')}
                                                </div>

                                                <div className="col-xs-4">
                                                    <ControlLabel>Community: *</ControlLabel>
                                                    <CreatableSelect key={this.state.selectedParisCommunity.length} isClearable options={this.state.selectedParisCommunity} value={{ value: this.state.city, label: this.state.city }} onChange={(newValue) => this.setCommunity(newValue)} onCreateOption={this.createCommunity} />
                                                    {this.validator.message('community', this.state.city, 'required|string|max:100,string')}
                                                </div>

                                                <div className="col-xs-4">
                                                    <ControlLabel>Zip Code: </ControlLabel>
                                                    <FormControl
                                                        name='pincode'
                                                        type="text"
                                                        value={this.state.pincode}
                                                        placeholder="Enter your Zip Code"
                                                        onChange={this.handleChange}
                                                        autoComplete="none"
                                                    />
                                                    {this.validator.message('pincode', this.state.pincode, 'alpha_num|max:7')}
                                                </div>
                                            </div>

                                            <div className="row form-group">
                                                <div className="col-xs-3">
                                                    <ControlLabel>Is TRN applicable: &nbsp;&nbsp;</ControlLabel>
                                                    <input type="checkbox" id='is_trn_applicable' name="is_trn_applicable" value={this.state.is_trn_applicable} onChange={this.handleChange} checked={this.state.trn_checked} />
                                                    <label htmlFor='is_trn_applicable'>&nbsp;&nbsp;Yes</label>
                                                </div>

                                                <div className="col-xs-4">
                                                    <ControlLabel>Registration Number: *</ControlLabel>
                                                    <FormControl
                                                        name='license_number'
                                                        type="text"
                                                        value={this.state.license_number}
                                                        placeholder="Enter your Registration Number"
                                                        onChange={this.handleChange}
                                                        autoComplete="none"
                                                    />
                                                    {this.validator.message('registration_number', this.state.license_number, 'required')}
                                                </div>
                                            </div>


                                            {(<>
                                                <h4>Commission Details</h4>
                                                <hr />
                                                <EditCommission
                                                    no_commission={this.state.no_commission}
                                                    commission_type={this.state.commission_type}
                                                    commission_rate={this.state.commission_rate}
                                                    currency={this.state.currency}
                                                    handleCommissionChange={this.handleCommissionChange}
                                                    validator={this.validator}
                                                />
                                            </>)}

                                            <FormGroup className="row" controlId="">
                                                <Col sm={4} md={4}>
                                                    <ButtonToolbar>
                                                        <Button bsStyle="primary" type='submit'>Submit</Button>
                                                        <button type="reset" className="btn btn-btn btn-danger"><i className="fa fa-repeat"></i> Reset</button>
                                                    </ButtonToolbar>
                                                </Col>
                                            </FormGroup>
                                        </form>
                                    </div>
                                    <div id="locations" className="tab-pane fade">
                                        <h4>Location Details</h4>
                                        <hr />
                                        {this.state.location_details && <EditMultiLocation
                                            location_details={this.state.location_details}
                                            history={this.props.history}
                                            type='covid-centers'
                                            service_provider_id={this.state.service_provider_id} />}
                                    </div>

                                    <div id="owners" className="tab-pane fade">
                                        <h4>Owners Details</h4>
                                        <hr />
                                        {this.state.owners && <EditMultiOwner
                                            serviceProviderId={this.state.service_provider_id}
                                            owners={this.state.owners}
                                            showLoader={this.props.showLoader}
                                            hideLoader={this.props.hideLoader}
                                            type='covid-centers'
                                            history={this.props.history} />}
                                    </div>
                                </div>
                            </div>
                        }
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}
const mapActionsToProps = ({
    showLoader: LoaderAction.loaderShow,
    hideLoader: LoaderAction.loaderHide,
});

export default connect(null, mapActionsToProps)(EditPage);
