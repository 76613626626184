import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import * as CardActions from '../../actions/cardActions';
import { Helmet } from 'react-helmet';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import SimpleReactValidator from 'simple-react-validator';
import { Button, ButtonToolbar } from 'react-bootstrap';
import _ from "lodash";
import * as HELPER from '../../utils/helpers';
import CardService from "../../services/cardService";
import Notification from "../../utils/notification";
import * as LoaderAction from "../../actions/loaderAction";

class RequestCardPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            number_of_cards: '',
        };

        this.props.paginateCardBatch({});

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validator = new SimpleReactValidator();
    }

    columns = () => {
        return [{
            dataField: 'batch_id',
            text: 'Batch Id',
            sort: true
        }, {
            dataField: 'request_count',
            text: 'Request Number',
            sort: true
        }, {
            dataField: 'created_at',
            text: 'Requested On',
            sort: true,
            formatter: cell => {
                return HELPER.getDateTimeFormat(cell);
            }
        }, {
            dataField: 'card_batch_type',
            text: 'Batch Type',
            sort: true,
            formatter: cell => {
                return _.capitalize(cell);
            }
        }, {
            dataField: 'status',
            text: 'Status',
            sort: true,
            formatter: this.statusFormatter
        }, {
            dataField: 'actions',
            text: 'Actions',
            isDummyField: true,
            formatter: this.actionFormatter
        }
        ];
    };

    statusFormatter = (cell, row) => {
        switch (cell) {
            case 0:
                return <span className="label label-warning">pending</span>;
            case 1:
                return <span className="label label-info">processing</span>;
            case 2:
                return <span className="label label-success">completed</span>;
            case 3:
                return <span className="label label-danger">failed</span>;
            default:
                return 'invalid';
        }
    }

    actionFormatter = (_cell, row) => {
        return <ButtonToolbar>
            {HELPER.hasPermission(this.props.admin, ['card-request-email']) && row.status === 2 && <Button onClick={this.resendEmail.bind(this, row.batch_id)} title="resend email"><i className="fa fa-envelope-o" aria-hidden="true"></i></Button>}
        </ButtonToolbar>;
    }

    resendEmail = (batch_id, batch_type) => {
        if (batch_type === 'reissue') {
            this.props.resendEmailReissueCardBatch(batch_id);
        } else {
            this.props.resendEmailCardBatch(batch_id);
        }
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        this.props.loaderShow();
        CardService.requestCardBatch(this.state.number_of_cards)
            .then(resp => {
                this.props.loaderHide();
                this.setState({
                    number_of_cards: ''
                });
                Notification.show("success", resp.data);
                this.props.paginateCardBatch({});
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    Notification.show("error", errorData);
                } else {
                    Notification.show('error', { message: "Something Went Wrong, Try Again" });
                }
            });
    }

    resetRequest = () => {
        this.setState({
            number_of_cards: ''
        });
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        if (type === 'search') {
            page = 1;
        }

        this.props.paginateCardBatch({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText });
    }

    render() {
        let { data, meta } = this.props.cardbatches.response;

        return <div>
            <Helmet>
                <title>Request Patient Card</title>
            </Helmet>
            <Content title="Request Patient Card">
                {HELPER.hasPermission(this.props.admin, ['generate-card-request']) && <Box box="solid">
                    <Box.Body>
                        <form onSubmit={this.handleSubmit} onReset={this.resetRequest}>
                            <div className="row">
                                <div className="col-md-4 form-group">
                                    <label>Request for generate cards</label>
                                    <input name="number_of_cards" className="form-control" type="number" min="1" value={this.state.number_of_cards} onChange={this.handleChange} placeholder="Enter the number of cards to generate"></input>
                                    {this.validator.message('number_of_cards', this.state.number_of_cards, 'required|numeric|min:1,num')}
                                </div>
                                <div className="col-md-12 btn-toolbar">
                                    <button type="submit" className="btn btn-primary  pull-right">Submit</button>
                                    <button type="reset" className="btn btn-danger  pull-right"> <i className="fa fa-repeat"></i> Reset</button>
                                </div>
                            </div>
                        </form>
                    </Box.Body>
                </Box>}

                <Box box="solid">
                    <Box.Body>
                        {data && <ToolkitProvider
                            search
                            keyField="id"
                            data={data}
                            columns={this.columns()}
                        >
                            {
                                props => [
                                    <BootstrapTable
                                        {...props.baseProps}
                                        remote
                                        onTableChange={this.handleTableChange}
                                        pagination={paginationFactory({
                                            page: parseInt(meta.current_page),
                                            sizePerPage: parseInt(meta.per_page),
                                            totalSize: parseInt(meta.total),
                                        })}
                                        rowStyle={this.rowStyle}
                                    />
                                ]
                            }
                        </ToolkitProvider>}

                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    cardbatches: state.cardBatchList,
    admin: state.auth.admin
});

const mapActionsToProps = ({
    // requestCardBatch: CardActions.requestCardBatch,
    paginateCardBatch: CardActions.listCardBatch,
    resendEmailCardBatch: CardActions.resendEmailCardBatch,
    resendEmailReissueCardBatch: CardActions.resendEmailReissueCardBatch,
    loaderShow: LoaderAction.loaderShow,
    loaderHide: LoaderAction.loaderHide,
})

export default connect(mapStateToProps, mapActionsToProps)(RequestCardPage);
