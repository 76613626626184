import axios from 'axios';
import { apiRoute, getApiHeader, createPaginateQueryString} from '../utils/helpers';

class CommunityService {

    static list() {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/community-list'), requestOptions);
    }

    static newCommunitylist({page, sizePerPage, sortBy, searchText, filter}) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText, filter)
        };

        return axios.get(apiRoute('/v1/newcommunitylist'), requestOptions);
        
    }

    static communityadd(statedata){
        let paramsdata = JSON.stringify(statedata);
        
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute('/v1/newAddcommunity'), paramsdata, requestOptions);
    }

}

export default CommunityService;