import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function radiologyTest(state = initialState.radiologyTest, { type, ...payload }) {
    switch (type) {
        case types.ADD_RADIOLOGY_TEST:
            return {
                ...state,
                ...payload
            };
        case types.DELETE_RADIOLOGY_TEST:
            return {
                ...state,
                ...payload
            };
        case types.FETCH_RADIOLOGY_TEST:
            return {
                ...state,
                ...payload
            };
        case types.UPDATE_RADIOLOGY_TEST:
            return {
                ...state,
                ...payload
            }; 
        default:
            return state;
    }
}