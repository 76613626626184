import { NotificationManager } from 'react-notifications';
import _ from 'lodash';

class Notification {
    static show(type, response) {
        if (_.isEmpty(response.message)) return;

        const { message, title } = this.formatMessage(response);

        this.clearAll();

        switch (type) {
            case 'success':
                NotificationManager.success(message, title, 5000);
                break;
            case 'error':
                NotificationManager.error(message, title, 10000);
                break;
            case 'warning':
                NotificationManager.warning(message, title, 5000);
                break;
            case 'info':
                NotificationManager.info(message, title, 5000);
                break;
            default:
                break;
        }
    }

    static clearAll() {
        NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
    }

    static formatMessage(response) {

        let message = '';
        let title = response.message;

        if (response.errors) {
            /* eslint-disable array-callback-return */
            Object.keys(response.errors).map((key) => {
                message = message + response.errors[key].join() + '\n';
            });
        }

        return { message, title };
    }
}

export default Notification;