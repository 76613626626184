import React from "react";
import { Helmet } from 'react-helmet';
import { Content, Box, BackButton } from '../../components/Elements';
import Alert from '../../components/Alert';
import { Table } from 'react-bootstrap';
import { getDateFormat } from '../../utils/helpers';
import { capitalize, isEmpty } from 'lodash';
import PatientService from "../../services/patientService";
import * as loaderActions from '../../actions/loaderAction';
import { connect } from 'react-redux';
import Notification from "../../utils/notification";
import NewRecord from "../../components/PatientAlias/NewRecord";

class ViewPatientPastRecord extends React.Component {
    state = {
        patient_id: this.props.match.params.patientId,
        record_id: this.props.match.params.recordId,
        record: {}
    }

    componentDidMount() {
        this.props.loaderShow();
        PatientService.fetchPatientRecord(this.state.patient_id, this.state.record_id).then(resp => {
            this.setState({ record: resp.data })
            this.props.loaderHide();
        }).catch(error => {
            this.props.loaderHide();
            Notification.show('error', error.response.data)
        });
    }
    render() {
        const { record } = this.state;
        return <React.Fragment>
            <Helmet>
                <title>Past Records</title>
            </Helmet>
            <Content title="Past Records">
                <Box>
                    <Box.Head title={record.action === 'new-record' ? "New Patient" : "Request Details"} > <BackButton backTo="/patient-requests" />
                    </Box.Head>
                    {(!isEmpty(this.state.record) && isEmpty(record.metadata.old2)) && <NewRecord patient={record.metadata.new}/> }
                    {(!isEmpty(this.state.record) && !isEmpty(record.metadata.old2)) &&
                        <Box.Body>
                            <Alert />
                            <Table responsive striped>
                                <thead>
                                    <th></th>
                                    <th>New Data</th>
                                    <th>Old Patient 1 Data</th>
                                    <th>Old Patient 2 Data</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>First Name</th>
                                        <td>{record.metadata.new.first_name} </td>
                                        <td>{record.metadata.old1.first_name} </td>
                                        <td>{record.metadata.old2.first_name} </td>
                                    </tr>
                                    <tr>
                                        <th>Last Name</th>
                                        <td>{record.metadata.new.last_name} </td>
                                        <td>{record.metadata.old1.last_name} </td>
                                        <td>{record.metadata.old2.last_name} </td>
                                    </tr>
                                    <tr>
                                        <th>Maiden Name</th>
                                        <td>{record.metadata.new.maiden_name} </td>
                                        <td>{record.metadata.old1.maiden_name} </td>
                                        <td>{record.metadata.old2.maiden_name} </td>
                                    </tr>
                                    <tr>
                                        <th>Date Of Birth</th>
                                        <td>{getDateFormat(record.metadata.new.date_of_birth)}</td>
                                        <td>{getDateFormat(record.metadata.old1.date_of_birth)}</td>
                                        <td>{getDateFormat(record.metadata.old2.date_of_birth)}</td>
                                    </tr>
                                    <tr>
                                        <th>Place Of Birth</th>
                                        <td>{record.metadata.new.place_of_birth ? capitalize(record.metadata.new.place_of_birth) : '-'}</td>
                                        <td>{record.metadata.old1.place_of_birth ? capitalize(record.metadata.old1.place_of_birth) : '-'}</td>
                                        <td>{record.metadata.old2.place_of_birth ? capitalize(record.metadata.old2.place_of_birth) : '-'}</td>
                                    </tr>
                                    <tr>
                                        <th>Contact Number</th>
                                        <td>{record.metadata.new.contact_number}</td>
                                        <td>{record.metadata.old1.contact_number}</td>
                                        <td>{record.metadata.old2.contact_number}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Box.Body>}
                    <Box.Foot>
                        <span><h4><b>Action: </b> {record.action === 'new-record' ? 'Add as a new patient' : 'Merged records with old existing data'}</h4> </span>
                    </Box.Foot>
                </Box>
            </Content >
        </React.Fragment >
    }
}
const mapActionsToProps = ({
    loaderShow: loaderActions.loaderShow,
    loaderHide: loaderActions.loaderHide,
})
export default connect(null, mapActionsToProps)(ViewPatientPastRecord)