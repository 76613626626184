import * as types from './actionTypes';
import AssociateDoctorService from '../services/associateDoctorService';

export function list({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: types.DOCTOR_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText } });
        AssociateDoctorService.list({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.DOCTOR_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}