import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { Button, ButtonToolbar, Alert } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import * as DrugAction from '../../actions/drugActions';
import { Helmet } from 'react-helmet';
import * as HELPER from '../../utils/helpers';
import DrugService from '../../services/drugService';

class ListOtherDrugsPage extends React.Component {

    constructor(props) {
        super(props);

        this.props.paginate({ sortBy: { sortField: 'updated_at', sortOrder: 'desc' } });
        this.state = {
            filter: {}
        };
    }

    columns = () => {
        return [{
            dataField: 'provider_name',
            text: 'Provider Name'
        }, {
            dataField: 'drug_name',
            text: 'Drug Name',
        }, {
            dataField: 'dosage_form',
            text: 'Dosage Form',
            sort: true,
            formatter: this.dosageFormatter
        }, {
            dataField: 'created_at',
            text: 'Date',
            sort: true,
            formatter: cell => {
                return HELPER.getDateFormat(cell);
            }
        }, {
            dataField: 'actions',
            text: 'Actions',
            isDummyField: true,
            formatter: this.actionFormatter,
            hidden: !HELPER.hasRole(this.props.admin, ['super-admin', 'admin', 'business-development-head'])
        }];
    };

    dosageFormatter = (cell, row) => {
        return cell ? cell.label : '';
    }

    actionFormatter = (_cell, row) => {
        return HELPER.hasRole(this.props.admin, ['super-admin', 'admin', 'business-development-head']) ?  <ButtonToolbar>
            <Button bsStyle="danger" size="xm" title="Delete" onClick={(e) => { if (window.confirm('Are you sure you want to delete "' + row.drug_name + '" ?')) this.deleteItem(row.id) }}> <i className="fa fa-trash" aria-hidden="true"></i> </Button>
        </ButtonToolbar> : "";
    }

    deleteItem = (id) => {
        if (id) {
            this.props.deleteOtherDrug(id);
        }
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {

        if (type === 'search') {
            page = 1;
        }

        let filter = this.state.filter;

        this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText, filter });
    }

    handleExport = () => {
        DrugService.exportOtherDrugListReport({ sortBy: this.state.sortBy, filter: this.state.filter, searchText: this.state.searchText }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', "OtherDrugReport.csv")
            document.body.appendChild(link)
            link.click()
            link.remove()
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    render() {
        let { data, meta, alert } = this.props;

        return <div>
            <Helmet>
                <title>List Other Drugs</title>
            </Helmet>
            <Content title="Other Drugs">
                <Box>
                    <Box.Head>
                        <button type="button" className="btn btn-primary" onClick={this.handleExport}><span className="fa fa-download"> Export as CSV</span></button>
                    </Box.Head>
                    <Box.Body>
                        {alert && alert.message && <Alert bsStyle={alert.type}>{alert.message}</Alert>}
                        <ToolkitProvider
                            search
                            keyField="id"
                            data={data}
                            columns={this.columns()}
                        >
                            {
                                props => [
                                    <div className="pull-right"><Search.SearchBar {...props.searchProps} delay={1000} /></div>,
                                    <BootstrapTable
                                        {...props.baseProps}
                                        remote
                                        onTableChange={this.handleTableChange}
                                        pagination={paginationFactory({
                                            page: parseInt(meta.current_page),
                                            sizePerPage: parseInt(meta.per_page),
                                            totalSize: parseInt(meta.total),
                                        })}
                                        rowStyle={this.rowStyle}
                                    />
                                ]
                            }
                        </ToolkitProvider>

                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    ...state.drugs_list,
    alert: state.alert,
    admin: state.auth.admin
});

const mapActionsToProps = ({
    paginate: DrugAction.otherDrugList,
    deleteOtherDrug: DrugAction.deleteOtherDrug
})

export default connect(mapStateToProps, mapActionsToProps)(ListOtherDrugsPage);
