import React from "react";
import DataTable from '../DataTable';
import { getDateFormat } from '../../utils/helpers';
import { ButtonToolbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash'

class PatientRequest extends React.Component {
    columns = () => {
        return [{
            dataField: 'full_name',
            text: 'New Patient Name',
        }, {
            dataField: 'created_by_user_profile',
            text: 'Onboarded By Dr',
            sort: true
        }, {
            dataField: 'onboarded_date',
            text: 'Onboarded Date',
            formatter: cell => getDateFormat(cell),
            sort: true
        }, {
            dataField: 'action_date',
            text: 'Action Date',
            hidden: this.props.type === "new_request",
            formatter: cell => getDateFormat(cell),
        }, {
            dataField: 'actions',
            text: 'Actions',
            isDummyField: true,
            formatter: this.actionFormatter
        }];
    };

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        if (type === 'search') {
            page = 1;
        }
        this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText });
    }
    actionFormatter = (_cell, row) => {
        return <ButtonToolbar>
            {this.props.type === "new_request" && <Link className="btn btn-sm btn-info" to={'/patients/' + row.patient_id}><i className="fa fa-eye" aria-hidden="true"></i></Link>}
            {this.props.type === "past_record" && <Link className="btn btn-sm btn-info" to={`/patients/${row.patient_id}/records/${row.record_id}`}><i className="fa fa-eye" aria-hidden="true"></i></Link>}
        </ButtonToolbar>;

    }

    render() {
        let { data, meta, loading } = this.props.patients
        return <DataTable
            loading={loading}
            columns={this.columns()}
            data={data}
            page={parseInt(meta.current_page)}
            sizePerPage={parseInt(meta.per_page)}
            totalSize={parseInt(meta.total)}
            onTableChange={this.handleTableChange}
            noDataIndication="No Record Found"
        />
    }
}
export default PatientRequest