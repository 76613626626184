import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import * as CardActions from '../../actions/cardActions';
import { Helmet } from 'react-helmet';
import * as HELPER from '../../utils/helpers';
import DataTable from '../../components/DataTable';
import { Label } from 'react-bootstrap';

class ListCardPage extends React.Component {

    constructor(props) {
        super(props);

        this.props.paginate({});
    }

    columns = () => {
        return [{
            dataField: 'card_id',
            text: 'Card Number',
            sort: true
        }, {
            dataField: 'code_id',
            text: 'Patient Code',
            sort: true
        }, {
            dataField: 'expiry_at',
            text: 'Expires at',
            sort: true,
            formatter: cell => {
                return HELPER.getDateTimeFormat(cell);
            }
        }, {
            dataField: 'created_at',
            text: 'Created on',
            sort: true,
            formatter: cell => {
                return HELPER.getDateTimeFormat(cell);
            }
        }, {
            dataField: 'is_assigned',
            text: 'Is Assigned',
            sort: true,
            formatter: cell => {
                return cell ? <Label bsStyle="success">Assigned</Label> : <Label>UnAssigned</Label>
            }
        }, {
            dataField: 'status',
            text: 'Status',
            sort: true,
            formatter: cell => {
                return cell === 1 ? <Label bsStyle="success">Active</Label> : <Label>Inactive</Label>
            }
        },
        ];
    };

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {

        if (type === 'search') {
            page = 1;
        }

        this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText });
    }

    render() {
        let { data, meta, loading } = this.props.card_management_list;

        return <div>
            <Helmet>
                <title>Patient Card</title>
            </Helmet>
            <Content title="Patient Card">
                <Box>
                    <Box.Body>
                        <DataTable
                            loading={loading}
                            columns={this.columns()}
                            data={data}
                            page={parseInt(meta.current_page)}
                            sizePerPage={parseInt(meta.per_page)}
                            totalSize={parseInt(meta.total)}
                            onTableChange={this.handleTableChange}
                        />
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    card_management_list: state.card_management_list
});

const mapActionsToProps = ({
    paginate: CardActions.list,
})

export default connect(mapStateToProps, mapActionsToProps)(ListCardPage);
