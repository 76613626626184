import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { ButtonToolbar, Modal, FormGroup, ControlLabel, FormControl, Col, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import * as PathologyTestAction from '../../actions/pathologyTestActions';
import { Helmet } from 'react-helmet';
import Alert from '../../components/Alert';
import * as HELPER from "../../utils/helpers";
import PathologyTestService from '../../services/pathologyTestService';
import * as LoaderAction from '../../actions/loaderAction';
import SimpleReactValidator from 'simple-react-validator';
import moment from "moment";
import Notification from "../../utils/notification";

class ListPathologyTestsPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            viewModal: false,
            pricePercentage: '',
            priceUpdateDate: ''
        }

        this.props.paginate({});
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validator = new SimpleReactValidator();
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    columns = (data) => {
        return [{
            dataField: 'id',
            text: 'number',
            hidden: true
        }, {
            dataField: 'test_name',
            text: 'Test Name',
            sort: true
        }, {
            dataField: 'test_type',
            text: 'Test Type',
            sort: false
        }, {
            dataField: 'test_category',
            text: 'Test category',
            sort: false
        }, {
            dataField: 'test_description',
            text: 'Description',
            sort: false,
        }, {
            dataField: 'price.price',
            text: 'Price',
            sort: true
        }, {
            formatter: this.statusFormatter,
            text: 'Status',
        }, {
            dataField: 'actions',
            text: 'Actions',
            isDummyField: true,
            formatter: this.actionFormatter
        }
        ];
    };

    statusFormatter = (cell, row) => {
        if (row.test_status === 1) {
            return 'Active';
        }
        else if (row.test_status === 0) {
            return 'Inactive';
        }
    }

    dosageFormatter = (cell, row) => {
        return cell ? cell.label : '';
    }


    actionFormatter = (cell, row) => {
        return <ButtonToolbar>
            {HELPER.hasPermission(this.props.admin, ['pathology-tests-view']) && <Link className="btn btn-info" to={'/pathology/test/' + row.id}><i className="fa fa-eye" aria-hidden="true"></i></Link>}
            {HELPER.hasPermission(this.props.admin, ['pathology-tests-edit']) && <Link className="btn btn-warning" to={'/pathology/test/' + row.id + '/edit'}><i className="fa fa-pencil" aria-hidden="true"></i></Link>}
        </ButtonToolbar>;
    }

    deleteItem = (id) => {
        if (id) {
            //this.props.deleteDrug(id);
        }
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        if (type === "search") {
            page = 1;
        }

        this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText });
    };

    downloadMasterData = (e) => {
        e.preventDefault();
        this.props.showLoader();
        PathologyTestService.exportPathologyTestMaster()
            .then(resp => {
                let data = resp.data;
                this.props.hideLoader();
                window.open(data.url, "_blank")
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    Notification.show("error", errorData);
                } else {
                    Notification.show('error', { message: "Something Went Wrong, Try Again" });
                }
            });
    }

    closeModal() {
        this.setState({ viewModal: false, pricePercentage: '', priceUpdateDate: '' });
    }

    showModal() {
        this.setState({ viewModal: true });
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        } else {

            const postData = {
                amount_percentage: this.state.pricePercentage,
                price_update_date: this.state.priceUpdateDate,
            };

            this.updateAllPathologyTestPrices(postData);
            this.closeModal();
        }
    }

    updateAllPathologyTestPrices(postData) {
        PathologyTestService.updateAllPathologyTestPrices(postData).then(resp => {
            Notification.show('success', { message: "Price Updated Successfully." });
        }).catch(error => {
            Notification.show("error", error);
        });
    }


    render() {
        let { data, meta } = this.props.pathologytest_list;

        return <div>
            <Helmet>
                <title>Pathology Test List</title>
            </Helmet>
            <Content title="Pathology Test List">
                <Box>
                    <Box.Body>
                        <Alert />
                        <span className="mr-2">{HELPER.hasPermission(this.props.admin, ['pathology-tests-add']) && <Link to="/pathology/test/add" className="btn btn-success"><span className="fa fa-plus"></span> Add Test</Link>}</span>
                        <span className="mr-2">{<Link onClick={this.downloadMasterData} className="btn btn-info"> Export CSV</Link>}</span>
                        <span className="mr-2">{<Link onClick={this.showModal} className="btn btn-warning"> Update Test Prices</Link>}</span>
                        <ToolkitProvider
                            search
                            keyField="id"
                            data={data}
                            columns={this.columns()}
                        >
                            {
                                props => [
                                    <div className="pull-right"><Search.SearchBar {...props.searchProps} delay={1000} /></div>,
                                    <BootstrapTable
                                        {...props.baseProps}
                                        remote
                                        onTableChange={this.handleTableChange}
                                        pagination={paginationFactory({
                                            page: parseInt(meta.current_page),
                                            sizePerPage: parseInt(meta.per_page),
                                            totalSize: parseInt(meta.total)
                                        })}
                                    />
                                ]
                            }
                        </ToolkitProvider>

                    </Box.Body>
                    <div>
                        <Modal show={this.state.viewModal} onHide={this.closeModal}>
                            <Modal.Header>
                                <Modal.Title>All Test Price Update</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form onSubmit={this.handleSubmit}>
                                    <FormGroup className="row" controlId="">
                                        <Col sm={5}>
                                            <ControlLabel>Update Test Prices By %: <span>*</span></ControlLabel>
                                        </Col>
                                        <Col sm={7}>
                                            <FormControl
                                                name='pricePercentage'
                                                type="text"
                                                value={this.state.pricePercentage}
                                                onChange={this.handleChange}
                                                autoComplete="none"
                                            />
                                            {this.validator.message('pricePercentage', this.state.pricePercentage, 'required')}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row" controlId="">
                                        <Col sm={5}>
                                            <ControlLabel>Price Update Date : <span>*</span></ControlLabel>
                                        </Col>
                                        <Col sm={7}>
                                            <FormControl
                                                name='priceUpdateDate'
                                                type="date"
                                                value={this.state.priceUpdateDate}
                                                onChange={this.handleChange}
                                                autoComplete="none"
                                                min={moment().add(1, 'days').format('YYYY-MM-DD')}
                                            />
                                            {this.validator.message('Price Update Date', this.state.priceUpdateDate, 'required')}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row" controlId="">
                                        <Col sm={6} md={6}>
                                            <Button type='submit' bsStyle='success'>Update</Button>
                                        </Col>
                                        <Col sm={6} md={6}>
                                            <Button onClick={this.closeModal}>Cancel</Button>
                                        </Col>
                                    </FormGroup>
                                </form>
                            </Modal.Body>
                        </Modal>
                    </div>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    pathologytest_list: state.pathologytest_list,
    alert: state.alert,
    admin: state.auth.admin
});

const mapActionsToProps = ({
    paginate: PathologyTestAction.list,
    showLoader: LoaderAction.loaderShow,
    hideLoader: LoaderAction.loaderHide,
    //deleteDrug: pathologyTestAction.deletedrug
})

export default connect(mapStateToProps, mapActionsToProps)(ListPathologyTestsPage);
