import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function card_management_list(state = initialState.pagination, { type, ...payload }) {
    switch (type) {
        case types.CARD_MANAGEMENT_LIST_REQUEST:
            return {
                ...state,
                ...payload,
                loading: true
            };
        case types.CARD_MANAGEMENT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload.data,
                meta: payload.meta,
            };
        case types.CARD_MANAGEMENT_LIST_FAILED:
            return {
                ...state,
                ...initialState.pagination,
                loading: false,
            };
        default:
            return state;
    }
}