import React from 'react';
import { Table } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import * as DrugAction from '../../actions/drugActions';
import { Box, Content } from '../../components/Elements';
import _ from 'lodash';
import Alert from '../../components/Alert';


class ViewDrugPage extends React.Component {

    constructor(props) {
        super(props);

        const { match: { params } } = this.props;
        const id = params.id;

        this.props.fetchDrug(id);
    }


    render() {

        const { drugs } = this.props;

        let details;

        if (drugs && drugs.id) {

            details = <Table responsive striped bordered>
                <tbody>
                    <tr>
                        <th>Name</th>
                        <td>{drugs.name}</td>
                    </tr>
                    <tr>
                        <th>Generic Name</th>
                        <td>{drugs.generic_drug.name}</td>
                    </tr>
                    <tr>
                        <th>Dosage Form</th>
                        <td>{drugs.dosage_form.label}</td>
                    </tr>
                    <tr>
                        <th>Strength</th>
                        <td>{drugs.strength}</td>
                    </tr>
                    <tr>
                        <th>Unit_Of_Measurement</th>
                        <td>{drugs.unit_of_measurement}</td>
                    </tr>
                    <tr>
                        <th>Drug SKUS</th>
                        <td>
                            <Table responsive bordered>
                                <thead>
                                    <tr>
                                        <th>NDC Code</th>
                                        <th>Package Size</th>
                                        <th>Minimum Sale Quantity</th>
                                        <th>Manufacturer Name</th>
                                        <th>Drug Coverages</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {drugs.drug_skus.map((drug_skus) => (
                                        <tr>
                                            <td>{drug_skus.ndc_code ? drug_skus.ndc_code : '-'}</td>
                                            <td>{drug_skus.package_size ? drug_skus.package_size : '-'}</td>
                                            <td>{drug_skus.min_sell_quantity ? drug_skus.min_sell_quantity : '-'}</td>
                                            <td>{drug_skus.manufacturer_name ? drug_skus.manufacturer_name : '-'}</td>
                                            <td>{drug_skus.drug_coverages ? drug_skus.drug_coverages.map((value) => {
                                                return <label className=''>{_.toUpper(value) + '\u00A0\u00A0'}</label>;
                                            }) : '-'}</td>
                                            <td>{drug_skus.price ? drug_skus.price : '-'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </td>
                    </tr>
                    <tr>
                        <th>Status</th>
                        <td>{drugs.status && drugs.status === 1 ? 'Active' : 'Inactive'}</td>
                    </tr>
                </tbody>
            </Table>;
        }

        return <div>
            <Helmet>
                <title>View Drug</title>
            </Helmet>
            <Content title="Drugs">
                <Box>
                    <Box.Head title="View Drug" backTo="/drugs"></Box.Head>
                    <Box.Body>
                        <Alert />
                        {details}
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    drugs: state.drugs
});

const mapActionsToProps = ({
    fetchDrug: DrugAction.fetchdrug
});

export default connect(mapStateToProps, mapActionsToProps)(ViewDrugPage);