import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class PatientService {
    static list({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText, filter)
        };

        return axios.get(apiRoute('/v1/patients'), requestOptions);
    }

    static patientadd(formData) {

        let paramsdata = JSON.stringify({
            first_name: formData.first_name,
            middle_name: formData.middle_name,
            last_name: formData.last_name,
            maiden_name: formData.maiden_name,
            email: formData.email,
            contact_number: formData.contact_number,
            alt_contact_number: formData.alternate_contact,
            gender: formData.gender,
            card_number: formData.card_number,
            healthcard_number: formData.healthcard_number,
            date_of_birth: formData.date_of_birth,
            height: formData.height,
            weight: formData.weight,
            blood_pressure: formData.blood_pressure
        });

        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute('/v1/patients'), paramsdata, requestOptions);
    }


    static fetchpatient(id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/patients/' + id), requestOptions);
    }

    static fetchPatientAlias(id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/patients/' + id + '/alias'), requestOptions);
    }

    static deletepatient(id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.delete(apiRoute('/v1/patients/' + id), requestOptions);

    }

    static patientupdate(data, patientId) {
        const requestOptions = {
            headers: getApiHeader()
        };
        let paramsdata = JSON.stringify(data);

        return axios.patch(apiRoute('/v1/patients/' + patientId), paramsdata, requestOptions);
    }

    static searchPatient(statedata) {
        let paramsdata = JSON.stringify({
            first_name: statedata.first_name,
            middle_name: statedata.middle_name,
            last_name: statedata.last_name,
            maiden_name: statedata.maiden_name,
            date_of_birth: statedata.date_of_birth,
            place_of_birth: statedata.place_of_birth,
            contact: statedata.contact,
        });

        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute("/v1/patients/search"), paramsdata, requestOptions);
    }

    static patientServiceProviders(statedata) {
        let paramsdata = JSON.stringify(statedata);

        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute("/v1/patients/service_providers"), paramsdata, requestOptions);
    }

    static mergePatientAlias(mergeData) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute("/v1/patients/alias/merge"), mergeData, requestOptions);
    }

    static createNewPatient(patient_id) {
        const data = {
            status: 'active'
        }
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.put(apiRoute(`/v1/patients/${patient_id}/status`), data, requestOptions);
    }

    static getPatientId(postData) {
        let paramsdata = JSON.stringify(postData);

        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute("/v1/patients/search-id"), paramsdata, requestOptions);
    }

    static exportCsv(postData) {
        let paramsData = JSON.stringify(postData);

        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute("/v1/patients/exports/csv"), paramsData, requestOptions);
    }

    static fetchPatientAliasRequest({ page, sizePerPage, sortBy, searchText }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText)
        };

        return axios.get(apiRoute('/v1/patients/alias/requests'), requestOptions);
    }

    static paginateAliasRecords({ page, sizePerPage, sortBy, searchText }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText)
        };

        return axios.get(apiRoute('/v1/patients/alias/records'), requestOptions);
    }

    static fetchPatientRecord(patientId, recordId) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute(`/v1/patients/${patientId}/records/${recordId}`), requestOptions);
    }
    
    static getActiveCards(statedata) {
        let paramsdata = JSON.stringify(statedata);

        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute("/v1/patients/active-cards"), paramsdata, requestOptions);
    }

    static getServiceProviders(patient_code, selected_platform) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute(`/v1/patients/${patient_code}/service-providers?platform=${selected_platform}`), requestOptions);
    }

    static getPoojaLoyaltyPatient(postData) {
        let paramsdata = JSON.stringify(postData);

        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute("/v1/patients/search/pooja-loyalty"), paramsdata, requestOptions);
    }

    static updateContactPoojaLoyaltyPatient(postData) {
        let paramsdata = JSON.stringify(postData);

        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute("/v1/patients/pooja-loyalty/contact"), paramsdata, requestOptions);
    }

    static deactivateCard(patient_code) {
        let paramsdata = JSON.stringify({
            patient_code: patient_code,
        });

        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute("/v1/patients/deactivate-card"), paramsdata, requestOptions);
    }

    static getServiceProvidersByParish(platform, parish) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute(`/v1/patients/service-providers/${platform}/${parish}`), requestOptions);
    }

    static sendCardReissueSms(patient_id, platform) {
        let paramsdata = JSON.stringify({
            patient_id: patient_id,
            platform: platform
        });
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute("/v1/patients/reissue-card-sms"), paramsdata, requestOptions);
    }
}

export default PatientService;
