import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { ButtonToolbar } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import * as PathologyLimsTestAction from '../../actions/pathologyLimsTestActions';
import { Helmet } from 'react-helmet';
import Alert from '../../components/Alert';
import * as HELPER from "../../utils/helpers";
import PathologyTestService from '../../services/pathologyTestService';
import * as LoaderAction from '../../actions/loaderAction';
import SimpleReactValidator from 'simple-react-validator';
import Notification from "../../utils/notification";
import PathologyLimsTestService from '../../services/pathologyLimsTestService';

class ListPathologyLimsTestsPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            viewModal: false,
            pricePercentage: '',
            priceUpdateDate: '',
            uploadCsv:false,
        }

        this.props.paginate({ sortBy: { sortField: 'id', sortOrder: 'desc' } });
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validator = new SimpleReactValidator();
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    columns = (data) => {
        return [{
            dataField: 'id',
            text: 'number',
            hidden: true
        },
        {
            dataField: 'category',
            text: 'Category',
        },
        {
            dataField: 'name',
            text: 'Name',
        },
        {
            dataField: 'lower_limit',
            text: 'Lower Limit',
        },
        {
            dataField: 'upper_limit',
            text: 'Upper Limit',
        },
        {
            dataField: 'unit_of_measurement',
            text: 'Unit of Measurement',
        },
        {
            dataField: 'alternate_result',
            text: 'Alternate Result',
        },
        {
            dataField: 'status',
            text: 'Status',
            formatter: this.statusFormatter
        },
        {
            dataField: 'actions',
            text: 'Actions',
            isDummyField: true,
            formatter: this.actionFormatter
        }
        ];
    };

    statusFormatter = (cell, row) => {
        if (row.status === 1) {
            return 'Active';
        }
        else if (row.status === 0) {
            return 'Inactive';
        }
    }

    dosageFormatter = (cell, row) => {
        return cell ? cell.label : '';
    }


    actionFormatter = (cell, row) => {
        return <ButtonToolbar>
            {HELPER.hasPermission(this.props.admin, ['pathology-lims-test-edit']) && <Link className="btn btn-warning" to={'/lims-tests/' + row.id + '/edit'} title="Edit"><i className="fa fa-pencil" aria-hidden="true"></i></Link>}
            {row.category === "Profiles" && <Link className="btn btn-info" to={'/lims-tests/' + row.id + '/add-test'} title="Add Sub Test"><i className="fa fa-plus" aria-hidden="true"></i></Link>}
        </ButtonToolbar>;
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        if (type === "search") {
            page = 1;
        }

        this.props.paginate({ page, sizePerPage, sortBy: { sortField: 'id', sortOrder: 'desc' }, searchText });
    };

    downloadMasterData = (e) => {
        e.preventDefault();
        this.props.showLoader();
        PathologyTestService.exportPathologyTestMaster()
            .then(resp => {
                let data = resp.data;
                this.props.hideLoader();
                window.open(data.url, "_blank")
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    Notification.show("error", errorData);
                } else {
                    Notification.show('error', { message: "Something Went Wrong, Try Again" });
                }
            });
    }

    closeModal() {
        this.setState({ viewModal: false, pricePercentage: '', priceUpdateDate: '' });
    }

    showModal() {
        this.setState({ viewModal: true });
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        } else {

            const postData = {
                amount_percentage: this.state.pricePercentage,
                price_update_date: this.state.priceUpdateDate,
            };

            this.updateAllPathologyTestPrices(postData);
            this.closeModal();
        }
    }

    updateAllPathologyTestPrices(postData) {
        PathologyTestService.updateAllPathologyTestPrices(postData).then(resp => {
            Notification.show('success', { message: "Price Updated Successfully." });
        }).catch(error => {
            Notification.show("error", error);
        });
    }

    handleExport = () => {
        PathologyLimsTestService.exportLimsListReport({ sortBy: this.state.sortBy, filter: this.state.filter, searchText: this.state.searchText }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', "LimsTestList.csv")
            document.body.appendChild(link)
            link.click()
            link.remove()
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }
    handleCsv = () =>{
        this.setState({
            uploadCsv:true,
        })
    }
    handleFileChange = (event)=>{
        const file = event.target.files[0];
        this.callApi(file);
    }

    callApi = (file) =>{
        const formData = new FormData();
        formData.append('file', file);
        PathologyLimsTestService.updateLimsTestCsv(formData)
            .then(resp => {
                if (resp.data.invalid_data) {
                    const url = window.URL.createObjectURL(new Blob([resp.data.invalid_data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', "LimsListReport.csv");
                    link.click()                
                    document.body.appendChild(link)
                }
                Notification.show('success', { message: resp.data.invalid_data ? "LIMS CSV uploaded successfully, but some records are not proper so please check your CSV file " : "Lims Updated Successfully." });
                setTimeout(() => {
                    this.setState({
                        uploadCsv:false,
                    })
                    window.location.reload();
                }, 2500);
               
            })
            .catch(error => {
                Notification.show("error", error.response.data);
                setTimeout(() => {
                    this.setState({
                        uploadCsv:false,
                    })
                }, 2500);
            });
    }


    render() {
        let { data, meta } = this.props.pathologytest_list;

        return <div>
            <Helmet>
                <title>Lims Test List</title>
            </Helmet>
            <Content title="Lims Test List">
                <Box>
                <Box.Head>
                    <span className="mr-2"><button type="button" className="btn btn-primary" onClick={this.handleExport}><span className="fa fa-download"> Export as CSV</span></button></span>
                    <span className="mr-2"><button type="button" className="btn btn-warning" onClick={this.handleCsv}><span className="fa fa-upload"> Upload as CSV</span></button></span>
                    <span className="mr-2">{this.state.uploadCsv &&
                    <input type="file" accept=".csv,.CSV" onChange={this.handleFileChange} style={{display:'inline-block'}}/>
                    }</span>
                </Box.Head>
                    <Box.Body>
                        <Alert />
                        <span className="mr-2">{HELPER.hasPermission(this.props.admin, ['pathology-lims-test-add']) && <Link to="/lims-tests/add" className="btn btn-success"><span className="fa fa-plus"></span> Add Test</Link>}</span>
                        <ToolkitProvider
                            search
                            keyField="id"
                            data={data}
                            columns={this.columns()}
                        >
                            {
                                props => [
                                    <div className="pull-right"><Search.SearchBar {...props.searchProps} delay={1000} /></div>,
                                    <BootstrapTable
                                        {...props.baseProps}
                                        remote
                                        onTableChange={this.handleTableChange}
                                        pagination={paginationFactory({
                                            page: parseInt(meta.current_page),
                                            sizePerPage: parseInt(meta.per_page),
                                            totalSize: parseInt(meta.total)
                                        })}
                                    />
                                ]
                            }
                        </ToolkitProvider>

                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    pathologytest_list: state.pathologytest_list,
    alert: state.alert,
    admin: state.auth.admin
});

const mapActionsToProps = ({
    paginate: PathologyLimsTestAction.limslist,
    showLoader: LoaderAction.loaderShow,
    hideLoader: LoaderAction.loaderHide,
})

export default connect(mapStateToProps, mapActionsToProps)(ListPathologyLimsTestsPage);
