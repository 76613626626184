import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import * as CommissionAction from "../../actions/commissionAction";
import { Helmet } from 'react-helmet';
import * as HELPER from "../../utils/helpers";
import { Link } from 'react-router-dom';
import CommissionService from '../../services/commissionService'
import * as LoaderAction from '../../actions/loaderAction';
import { Tabs, Tab } from 'react-bootstrap';

class ListCommissionPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            filter: {
                service_provider_type: "doctor"
            }
        }
        this.props.paginate({ filter: this.state.filter });
    }

    columns = () => {
        return [
            {
                dataField: "prescription_id",
                text: "Prescription Id",
                sort: false
            },
            {
                dataField: "prescription_date",
                text: "Prescription Date",
                sort: false,
                formatter: this.dateFormatter
            },
            {
                dataField: "order_id",
                text: "Order ID",
                sort: false
            },
            {
                dataField: "order_date",
                text: "Order Date",
                sort: false,
                formatter: this.dateFormatter
            },
            {
                dataField: "receiver_service_provider.id",
                text: "Doctor ID",
                sort: false
            },
            {
                dataField: "receiver_service_provider.name",
                text: "Provider Name (Doctor)",
                sort: false
            },
            {
                dataField: "giver_service_provider.id",
                text: "Provider ID",
                sort: false
            },
            {
                dataField: "giver_service_provider.name",
                text: "Provider Name",
                sort: false
            },
            {
                dataField: "order_amount",
                text: "Order Value",
                sort: false
            },
            {
                dataField: "giver_commission",
                text: "Gross Commission Value",
                sort: false
            },
            {
                dataField: "receiver_commission",
                text: "Consultancy Fees",
                sort: false
            },
            {
                dataField: "commission_earned",
                text: "Net Commission Earned",
                sort: false
            },
            {
                dataField: "commission_type",
                text: "Commission Type",
                sort: false
            }
        ];
    };

    dateFormatter = (cell, row) => {
        return HELPER.getDateFormat(cell);
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText, filter }) => {
        if (type === "search") {
            page = 1;
        }
        this.setState({
            searchText: searchText
        })

        this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText, filter: this.state.filter });
    };

    exportData = (e) => {
        e.preventDefault();
        this.props.showLoader();
        CommissionService.exportCsv(this.state.searchText)
            .then(resp => {
                let data = resp.data;
                this.props.hideLoader();
                window.open(data.url, "_blank")
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    Notification.show("error", errorData);
                } else {
                    Notification.show('error', { message: "Something Went Wrong, Try Again" });
                }
            });
    }

    setKey = key => {
        let { filter } = this.state
        if (key === "doctors") {
            filter.service_provider_type = "doctor"
        } else if (key === "others") {
            filter.service_provider_type = "pharmacy,radiology,pathology"
        }
        this.setState({
            filter: filter
        })
        this.props.paginate({ filter: filter })
    }

    getTable = () => {
        let { data, meta } = this.props.commission;
        return (<div className="table-responsive">
            <ToolkitProvider
                search
                keyField="id"
                data={data}
                columns={this.columns()}
                exportCSV={{
                    fileName: 'commission.csv',
                }}
            >
                {props => [
                    <div className="pull-right">
                        <Search.SearchBar {...props.searchProps} delay={1000} />
                    </div>,
                    <BootstrapTable
                        {...props.baseProps}
                        remote
                        noDataIndication="No Record Found"
                        onTableChange={this.handleTableChange}
                        pagination={paginationFactory({
                            page: parseInt(meta.current_page),
                            sizePerPage: parseInt(meta.per_page),
                            totalSize: parseInt(meta.total)
                        })}
                    />
                ]}
            </ToolkitProvider>
        </div>)
    }

    render() {
        let { data } = this.props.commission;

        return (data &&
            <div>
                <Helmet>
                    <title>Commission Reports</title>
                </Helmet>
                <Content title="Commission Reports">
                    {HELPER.hasPermission(this.props.admin, ['commissions-list']) && <Box>
                        <Box.Body>
                            {HELPER.hasPermission(this.props.admin, ['export-csv']) && <span className="mr-2">{<Link to="#" onClick={this.exportData} className="btn btn-default"> Export CSV</Link>}</span>}
                            <Tabs defaultActiveKey="doctors" onSelect={(k) => this.setKey(k)}>
                                <Tab eventKey="doctors" title="Doctor's Commission">
                                    <Tab.Content>
                                        {this.getTable()}
                                    </Tab.Content>
                                </Tab>
                                <Tab eventKey="others" title="Other's Commission">
                                    <Tab.Content>
                                        {this.getTable()}
                                    </Tab.Content>
                                </Tab>
                            </Tabs>

                        </Box.Body>
                    </Box>}
                </Content>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    commission: state.commission,
    admin: state.auth.admin
});

const mapActionsToProps = {
    paginate: CommissionAction.list,
    showLoader: LoaderAction.loaderShow,
    hideLoader: LoaderAction.loaderHide,
};

export default connect(mapStateToProps, mapActionsToProps)(ListCommissionPage);
