import React from 'react';
import { ControlLabel, FormControl, Radio, Well } from 'react-bootstrap';

class AddCommission extends React.Component {

    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.props.handleCommissionChange(name, value)
    }
    render() {
        const { commission_details, validator, type = "" } = this.props
        console.log("Type:", type);
        validator.purgeFields();
        return (
            <React.Fragment>
                <div className="commission-details">
                    <div className="row form-group">
                        <div className="col-xs-12">
                            <div className="col-sm-3">
                                <ControlLabel>No Commission: </ControlLabel>
                            </div>
                            <div className="col-sm-9">
                                <input type="checkbox" id='no_commission' name="no_commission" value={commission_details.no_commission} onChange={this.handleChange} />
                                <label htmlFor='no_commission'>&nbsp;&nbsp;Yes</label>
                            </div>
                        </div>
                    </div>
                    <fieldset disabled={commission_details.no_commission}>
                        <div className="row form-group">
                            <div className="col-xs-12">
                                <div className="col-sm-3">
                                    <ControlLabel>Select Commission Type: </ControlLabel>
                                </div>
                                <div className="col-sm-9">
                                    <Radio name='commission_type' value='revenue_percentage' checked={commission_details.commission_type === 'revenue_percentage'} disabled={type == "ambulance"} onChange={this.handleChange} inline>Revenue Percentage</Radio>
                                    <Radio name='commission_type' value='pay_per_use' checked={commission_details.commission_type === 'pay_per_use'} onChange={this.handleChange} inline>Pay Per Use</Radio>
                                </div>
                            </div>


                            {commission_details.commission_type === 'revenue_percentage' && <div className="col-xs-12  pd-2">
                                <div className="col-sm-3">
                                    <ControlLabel>Enter Commission Percentage:</ControlLabel>
                                </div>
                                <div className="col-sm-4">
                                    <FormControl
                                        name='commission_percentage'
                                        type="number"
                                        value={commission_details.commission_percentage}
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                        min="0" max="100" step="0.01"
                                    />
                                    {validator.message('commission_percentage', commission_details.commission_percentage, 'required|numeric|min:1|max:100')}
                                </div>
                            </div>}

                            {commission_details.commission_type === 'pay_per_use' && <div className="col-xs-12  pd-2">
                                <div className="col-sm-3">
                                    <ControlLabel>Enter Cost for per use:</ControlLabel>
                                </div>
                                <div className="col-sm-3">
                                    <FormControl
                                        name='cost_per_use'
                                        type="number"
                                        value={commission_details.cost_per_use}
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {validator.message('cost_per_use', commission_details.cost_per_use, 'required|numeric')}
                                </div>
                                <div className="col-sm-2">
                                    <select className='form-control' name="currency" value={commission_details.currency} onChange={this.handleChange}>
                                        <option >Select</option>
                                        <option value="JMD">Jamaican Dollar</option>
                                        <option value="JMDC">Jamaican Cent</option>
                                    </select>
                                    {validator.message('currency', commission_details.currency, 'required')}
                                </div>
                            </div>}
                        </div>
                    </fieldset>
                </div>
            </React.Fragment >
        )
    }
}
export default AddCommission