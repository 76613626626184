import * as types from './actionTypes';
import PatientService from '../services/patientService';

export function list({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: types.PATIENT_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText } });
        dispatch({ type: types.ALERT_CLEAR });

        PatientService.list({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.PATIENT_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function fetchPatientAliasRequest({ page, sizePerPage, sortBy, searchText }) {
    return dispatch => {
        dispatch({ type: types.PATIENT_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText } });
        dispatch({ type: types.ALERT_CLEAR });

        PatientService.fetchPatientAliasRequest({ page, sizePerPage, sortBy, searchText })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.PATIENT_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function paginateAliasRecords({ page, sizePerPage, sortBy, searchText }) {
    return dispatch => {
        dispatch({ type: types.PATIENT_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText } });
        dispatch({ type: types.ALERT_CLEAR });

        PatientService.paginateAliasRecords({ page, sizePerPage, sortBy, searchText })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.PATIENT_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function patientadd(formData, history) {
    return dispatch => {
        dispatch({ type: types.ADD_PATIENT });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        PatientService.patientadd(formData, history)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.ADD_PATIENT, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: 'Patient added successfully' });
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                    history.push('/patient/' + data.id);
                }, 2500);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function fetchpatient(id) {

    return dispatch => {
        PatientService.fetchpatient(id)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.FETCH_PATIENT, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}


export function patientupdate(statedata, patientId, history) {
    return dispatch => {

        PatientService.patientupdate(statedata, patientId)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.UPDATE_PATIENT, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: 'Patient updated successfully' });
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                    history.push('/patient/' + data.patient_id);
                }, 3000);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function deletepatient(id) {

    return dispatch => {
        PatientService.deletepatient(id)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.DELETE_PATIENT, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: data.message });

                PatientService.list(1, 5, null, null)
                    .then(resp => {
                        let data = resp.data;
                        dispatch({ type: types.PATIENT_LIST_SUCCESS, ...data });
                        setTimeout(() => { dispatch({ type: types.ALERT_CLEAR }); }, 2000);
                    })
                    .catch(error => {
                        if (error.response && error.response.status) {
                            let errorData = error.response.data;
                            dispatch({ type: types.ALERT_ERROR, ...errorData });
                        } else {
                            dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                        }
                    });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function searchPatient(statedata) {
    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        PatientService.searchPatient(statedata)
            .then(resp => {
                let data = resp;
                dispatch({ type: types.PATIENT_SEARCH_SUCCESS, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: data.message });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.PATIENT_SEARCH_FAILED, data: {} });
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({
                        type: types.ALERT_ERROR,
                        message: "Something Went Wrong, Try Again"
                    });
                }
            });
    };
}
