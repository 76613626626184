import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { Button } from 'react-bootstrap';
import * as AssociateDoctorAction from '../../actions/associateDoctorActions';
import { Helmet } from 'react-helmet';
import DataTable from '../../components/DataTable';

class ListAssociateDoctorPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: {},
        };
    }

    componentDidMount() {
        this.props.paginate({});
    }

    columns = () => {
        return [
            {
                dataField: "service_provider_id",
                text: "Provider Id",
                hidden: false
            },
            {
                dataField: "service_provider_name",
                text: "Doctor Name",
                sort: false
            },
            {
                dataField: "full_name",
                text: "Associate Doctor Name",
                sort: false
            },
            {
                dataField: "email",
                text: "Email",
                sort: false
            },
            {
                dataField: "contact_number",
                text: "Contact No.",
                sort: false
            },
            {
                dataField: "status",
                text: "Status",
                sort: false,
                formatter: cell => {
                    return cell === 1 ? "Active" : "Inactive";
                }
            }
        ];
    };

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        if (type === 'search') {
            page = 1;
        }
        let filter = this.state.filter;
        this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText, filter });
    }

    handleChange = (event) => {
        let filter = this.state.filter;
        filter[event.target.name] = event.target.value ? event.target.value : undefined;

        this.setState({
            filter: filter
        });
    }

    submitSearch = (evt) => {
        evt.preventDefault();
        const { filter } = this.state;
        this.props.paginate({ filter });
    }

    resetSearch = () => {
        this.setState({
            filter: {}
        })
        const filter = {};
        this.props.paginate({ filter });
    }

    render() {
        let { data, meta, loading } = this.props.doctor_list;

        return <div>
            <Helmet>
                <title>List Associate Doctors</title>
            </Helmet>
            <Content title="Associate Doctors">
                <Box box="solid">
                    <Box.Body>
                        <form onSubmit={this.submitSearch} onReset={this.resetSearch}>
                            <div className="row">
                                <div className="col-md-3 form-group">
                                    <label>Status</label>
                                    <select name="status" className="form-control" onChange={this.handleChange}>
                                        <option value="">All</option>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </select>
                                </div>
                                <div className="col-md-12 btn-toolbar">
                                    <Button className="btn pull-right" bsStyle="primary" type="submit"><i className="fa fa-search"></i> Search</Button>
                                    <Button className="btn pull-right" bsStyle="danger" type="reset"><i className="fa fa-repeat"></i> Reset</Button>
                                </div>
                            </div>
                        </form>
                    </Box.Body>
                </Box>

                <Box>
                    <Box.Body>
                        <DataTable
                            loading={loading}
                            columns={this.columns()}
                            data={data}
                            page={parseInt(meta.current_page)}
                            sizePerPage={parseInt(meta.per_page)}
                            totalSize={parseInt(meta.total)}
                            onTableChange={this.handleTableChange}
                        />
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    doctor_list: state.doctor_list,
});

const mapActionsToProps = ({
    paginate: AssociateDoctorAction.list,
})

export default connect(mapStateToProps, mapActionsToProps)(ListAssociateDoctorPage);