import React from "react";
import { Modal,FormGroup, ControlLabel, FormControl, Col } from 'react-bootstrap';
import * as CommunityAction from '../../actions/communityAction';
import { connect } from 'react-redux';

class EditCommunitiesView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            community_name: '',
            old_community_name:'',
            old_parish_name:'',
            showModal: true
        };
    }
    componentDidMount() {
        const { communityData } = this.props; 
        this.setState({
            name: communityData.parish_name,
            community_name: communityData.community_name,
            old_community_name: communityData.community_name,
            old_parish_name: communityData.parish_name
        });
    }

    onChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    validateAndSubmit = (e) => {
        e.preventDefault();
        const { name, community_name, old_community_name, old_parish_name } = this.state;
        const requestData = {
            parish_name       : name,
            community_name    : community_name,
            old_community_name: old_community_name,
            old_parish_name   : old_parish_name
        };
        console.log('requestData', requestData);
        this.props.dispatch(CommunityAction.communityadd(requestData));
        this.setState({ showModal: false });    
        this.props.handleCommunityInformation();  
        setTimeout(() => {
            window.location.reload();
        }, 1500);
    }

    render() {
        return (
            <Modal centered show={this.props.show} onHide={this.props.handleCommunityInformation} className="additional-health-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Community </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={this.validateAndSubmit}>
                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Parish:  <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                    <FormControl
                                        name='name'
                                        type="text"
                                        value={this.state.name}
                                        autoComplete="none"
                                        readOnly={true}
                                    />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Community:  <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                    <FormControl
                                        name='community_name'
                                        type="text"
                                        value={this.state.community_name}
                                        onChange={this.onChange}
                                        autoComplete="none"
                                    />
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={6} md={6}>
                                        <button type='submit' className='btn btn-primary'>Save</button>
                                    </Col>
                                </FormGroup>
                            </form>
                </Modal.Body >
            </Modal >
        );
    }
}

export default connect() (EditCommunitiesView);
