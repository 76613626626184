import React from 'react';
import CountBox from './CountBox';
import { Box } from '../Elements';
import CovidDashboardService from '../../services/covidDashboardService';

class CovidTestCount extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            total: '...',
            positive: '...',
            negative: '...'
        }
    }

    componentDidMount() {
        CovidDashboardService.covidTestCount().then(res => {
            this.setState({
                total: res.data.total,
                positive: res.data.positive,
                negative: res.data.negative,
            });
        });
    }

    render() {
        return (
            <Box box={"solid box-success"}>
                <Box.Head title="Total Test Conducted" />
                <Box.Body>
                    <div className="row">
                        <div className="col-sm-4">
                            <CountBox title="Total" count={this.state.total} />
                        </div>
                        <div className="col-sm-4">
                            <CountBox title="Positive" count={this.state.positive} />
                        </div>
                        <div className="col-sm-4">
                            <CountBox title="Negative" count={this.state.negative} />
                        </div>
                    </div>
                </Box.Body>
            </Box>
        );
    }
}

export default CovidTestCount;
