import * as types from './actionTypes';
import DoctorService from '../services/doctorService';
import LocationService from '../services/locationService';

export function list({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: types.DOCTOR_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText } });
        DoctorService.list({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.DOCTOR_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function otherDoctorsList({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: types.DOCTOR_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText } });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });
        DoctorService.otherDoctorsList({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.DOCTOR_LIST_SUCCESS, ...data });
                dispatch({ type: types.ALERT_CLEAR });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}


export function doctoradd(statedata, history) {
    return dispatch => {
        dispatch({ type: types.ADD_DOCTOR });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        DoctorService.doctoradd(statedata, history)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.ADD_DOCTOR, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: 'Doctor added successfully' });
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                    history.push('/doctors/' + data.service_provider_id);
                }, 2500);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function fetchdoctor(serviceProviderId) {

    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR, isloading: true });
        DoctorService.fetchdoctor(serviceProviderId)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.FETCH_DOCTOR, ...data });
                dispatch({ type: types.ALERT_CLEAR });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}


export function doctorupdate(serviceProviderId, statedata, history) {

    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR, isloading: true });
        DoctorService.doctorupdate(serviceProviderId, statedata)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.UPDATE_DOCTOR, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: 'Doctor updated successfully' });
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                    history.push("/doctors/" + serviceProviderId);
                }, 3000);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function locationupdate(serviceProviderId, statedata, history) {

    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR, isloading: true });
        LocationService.updateLocation(serviceProviderId, statedata)
            .then(resp => {
                dispatch({ type: types.ALERT_SUCCESS, message: 'Locations updated successfully' });
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                    history.push("/doctors/" + serviceProviderId);
                }, 3000);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function deletedoctor(id) {

    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR, isloading: true });
        DoctorService.deletedoctor(id)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.DELETE_DOCTOR, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: data.message });

                DoctorService.list(1, 5, null, null)
                    .then(resp => {
                        let data = resp.data;
                        dispatch({ type: types.DOCTOR_LIST_SUCCESS, ...data });
                        setTimeout(() => { dispatch({ type: types.ALERT_CLEAR }); }, 2000);
                    })
                    .catch(error => {
                        if (error.response && error.response.status) {
                            let errorData = error.response.data;
                            dispatch({ type: types.ALERT_ERROR, ...errorData });
                        } else {
                            dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                        }
                    });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}