import React from 'react';
import CountBox from './CountBox';
import { Box } from '../Elements';
import AirlineDashboardService from '../../services/airlineDashboardService';

class AirlineCardSwipeCount extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            card_swipe_count: '...',
        }
    }

    componentDidMount() {
        AirlineDashboardService.getAirlineCardSwipeCount().then(res => {
            this.setState({
                card_swipe_count: res.data.card_swipe_count,
            });
        });
    }

    render() {
        return (
            <Box box={"solid box-warning"}><Box.Head title="Card Swiped" />
                <Box.Body>
                    <div className="row">
                        <div className="col-sm-5">
                            <CountBox title="Total" count={this.state.card_swipe_count} />
                        </div>
                    </div>     
                </Box.Body>
            </Box>
        );
    }
}

export default AirlineCardSwipeCount;
