import React from 'react';
import { Box } from './Elements';
import { Col, FormGroup, ControlLabel, ButtonToolbar, Button } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
import UserAccountService from '../services/userAccountService';
import Notification from '../utils/notification'

class SettleAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'is_amt_waive': false,
            'payment_amount': '',
            'discount_amount': 0,
            'payment_details': '',
            'waiver_reason': '',
            'service_provider_id': this.props.service_provider_id,
            'payment_mode': "",
            isloading: true,
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange = e => {
        if (e.target.name === "is_amt_waive") {
            this.setState({
                [e.target.name]: e.target.checked
            })
        } else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        UserAccountService.makePayment(this.state).then(resp => {
            Notification.show('success', resp.data)
            this.setState({
                'is_amt_waive': false,
                'payment_amount': '',
                'discount_amount': 0,
                'waiver_reason': '',
                'payment_details': '',
                'service_provider_id': this.props.service_provider_id,
                'payment_mode': "",
                isloading: false,
            })
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    resetForm = e => {
        this.setState({
            'is_amt_waive': false,
            'payment_amount': '',
            'discount_amount': 0,
            'waiver_reason': '',
            'payment_details': '',
            'service_provider_id': this.props.service_provider_id,
            'payment_mode': ""
        })
    }

    render() {
        return (
            <Box>
                <Box.Head title="Settle Account" />
                <Box.Body >
                    <form className="form-horizontal" onSubmit={this.handleSubmit} onReset={this.resetForm}>
                        <div className="form-group">
                            <label htmlFor="payment_amount" className="col-sm-2 control-label">Settle Amount * : </label>
                            <div className="col-sm-3">
                                <input type="number" className="form-control" name="payment_amount" value={this.state.payment_amount} onChange={this.handleChange} />
                                {this.validator.message('payment_amount', this.state.payment_amount, 'required|numeric')}
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col-sm-5">
                                <ControlLabel> Do you want to waive any amount? : &nbsp;&nbsp;&nbsp;</ControlLabel>
                                <input type="checkbox" value={this.state.is_amt_waive} name="is_amt_waive" onChange={this.handleChange} />
                                <label htmlFor='is_amt_waive'>&nbsp;&nbsp;Yes</label>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="discount_amount" className="col-sm-2 control-label">Waiver Amount : </label>
                            <div className="col-sm-3">
                                <input type="number" className="form-control" name="discount_amount" value={this.state.discount_amount} onChange={this.handleChange} disabled={!this.state.is_amt_waive} />
                                {this.validator.message('discount_amount', this.state.discount_amount, 'numeric')}
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="waiver_reason" className="col-sm-2 control-label">Reason for Waiver : </label>
                            <div className="col-sm-3" >
                                <textarea className="form-control" name="waiver_reason" value={this.state.waiver_reason} onChange={this.handleChange} disabled={!this.state.is_amt_waive} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="payment_mode" className="col-sm-2 control-label">Mode of Payment * : </label>
                            <div className="col-sm-3">
                                <select className="form-control" name="payment_mode" value={this.state.payment_mode} onChange={this.handleChange}>
                                    <option defaultValue value="">Select Payment Mode</option>
                                    <option value="cash">Cash</option>
                                    <option value="cheque">Cheque</option>
                                    <option value="online-payment">Online</option>
                                </select>
                                {this.validator.message('payment_mode', this.state.payment_mode, 'required')}
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="payment_details" className="col-sm-2 control-label">Payment Details : </label>
                            <div className="col-sm-3">
                                <textarea className="form-control" name="payment_details" value={this.state.payment_details} onChange={this.handleChange} />
                            </div>
                        </div>
                        <FormGroup className="col-sm-offset-2" controlId="">
                            <Col sm={3} md={3}>
                                <ButtonToolbar>
                                    <Button bsStyle="primary" type='submit' >Submit</Button>
                                    <button type="reset" className="btn btn-btn btn-danger"><i className="fa fa-repeat"></i> Reset</button>
                                </ButtonToolbar>
                            </Col>
                        </FormGroup>
                    </form>
                </Box.Body>

            </Box>
        )
    }
}
export default SettleAccount