import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function generate_cards(state = initialState.generate_cards, { type, ...payload }) {
    switch (type) {
        case types.GENERATE_PATIENT_CARDS:
            return {
                ...state,
                ...payload
            };
        default:
            return state;
    }
}