import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class AdminUserService {
    static list({ page, sizePerPage, sortBy, searchText }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText)
        };

        return axios.get(apiRoute('/v1/admins'), requestOptions);
    }

    static adminuseradd(statedata) {

        let paramsdata = JSON.stringify(statedata);

        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute('/v1/admins'), paramsdata, requestOptions);
    }

    static fetchadminuser(id) {

        
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/admins/' + id), requestOptions);

    }

    static deleteadminuser(id) {

        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.delete(apiRoute('/v1/admins' + id), requestOptions);

    }

    static adminuserupdate(statedata) {

        let paramsdata = JSON.stringify({
            first_name: statedata.first_name,
            last_name: statedata.last_name,
            email: statedata.email,
            contact_number: statedata.contact_number,
            gender: statedata.gender,
            name_title: statedata.name_title,
            trn_number: statedata.trn_number,
            date_of_birth: statedata.date_of_birth,
            assigned_role: statedata.assigned_role,
            status: statedata.status,
        });

        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.patch(apiRoute('/v1/admins/' + statedata.id), paramsdata, requestOptions);
    }

    static superadminuserupdate(statedata) {

        let paramsdata = JSON.stringify({
            status: statedata.status,
        });

        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.put(apiRoute('/v1/admins/super_admin/' + statedata.id), paramsdata, requestOptions);
    }

    static changePassword(statedata) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        let params = JSON.stringify({
            'current_password': statedata.current_password,
            'password': statedata.new_password,
            'password_confirmation': statedata.confirm_password
        });
        return axios.post(apiRoute('/v1/change-password'), params, requestOptions);
    }
}

export default AdminUserService;
