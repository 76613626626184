import React from 'react';
import moment from "moment";
import _ from 'lodash';
import { Box } from '../Elements';
import BarGraphBox from './BarGraphBox';
import CovidDashboardService from '../../services/covidDashboardService';
import DateRangePicker from "react-bootstrap-daterangepicker";

import { CSVLink, CSVDownload } from "react-csv";

const headers = [
    { label: "", key: "label" },
    { label: "Total", key: "total" },
    { label: "Positive", key: "positive" },
    { label: "Negative", key: "negative" }
];

class CovidResultFemaleGraph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            view: "month",
            covid_test_data: {
                x_axis_label: [],
                counts: [{
                    label: "Total",
                    backgroundColor: "#0000ff",
                    data: []
                }, {
                    label: "Positive",
                    backgroundColor: "#ff0000",
                    data: []
                }, {
                    label: "Negative",
                    backgroundColor: "#008000",
                    data: []
                }]
            },
            show_start_date: new Date(),
            show_end_date: new Date(),
            start_date: "",
            end_date: "",
            graph_download: []
        }

        this.handleEvent = this.handleEvent.bind(this);
    }

    componentDidMount() {
        this.getData(this.state.view);
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.getData(this.state.view);
        }
    }

    getData = (view) => {
        let start_date = this.state.start_date;
        let end_date = this.state.end_date  ;

        CovidDashboardService.getCovidTestGender({ gender: 'female', start_date, end_date, view }).then(resp => {
            if (resp.data.counts) {
                const graph_data = {}
                graph_data.x_axis_label = resp.data.x_axis_label;
                let total = _.map(resp.data.counts, 'total');
                let positive = _.map(resp.data.counts, 'positive');
                let negative = _.map(resp.data.counts, 'negative');
                let counts = [];
                counts.push({ label: "Total", backgroundColor: "#0000ff", data: total })
                counts.push({ label: "Positive", backgroundColor: "#ff0000", data: positive })
                counts.push({ label: "Negative", backgroundColor: "#008000", data: negative })
                graph_data.counts = counts;
                this.setState({
                    covid_test_data: graph_data
                })

                const graph_download = [];
                resp.data.x_axis_label.forEach(label => {
                    graph_download.push({ "label": label })
                })
                resp.data.counts.forEach((d, i) => {
                    graph_download[i]['total'] = d.total;
                    graph_download[i]['positive'] = d.positive;
                    graph_download[i]['negative'] = d.negative;
                })
                this.setState({
                    graph_download: graph_download
                })
            }
        });
    }

    handleView = (view) => {
        this.setState({ view: view }, () => {
            this.getData(view);
        })
    }

    handleEvent = (event, picker) => {
        this.setState({
            start_date: (picker.startDate._d) ? moment(picker.startDate._d).format('YYYY-MM-DD') : '',
            end_date: (picker.endDate._d) ? moment(picker.endDate._d).format('YYYY-MM-DD') : '',
            show_start_date: picker.startDate._d,
            show_end_date: picker.endDate._d,
        }, () => {
            this.getData(this.state.view);
        });

    }

    resetDate = () => {
        this.setState({
            start_date: '',
            end_date: '',
            show_start_date: new Date(),
            show_end_date: new Date(),
        }, () => {
            this.getData(this.state.view);
        });
    }

    render() {
        return (
            <Box box={"solid box-danger"}>
                <Box.Head title="Covid Test : Female" />
                <Box box={"solid"}>
                    <Box.Body>
                        <div className="col-sm-2">
                            <strong>Filter</strong>
                        </div>
                        <div className="col-sm-10">
                            <form className="form-inline">
                                <DateRangePicker
                                    startDate={this.state.show_start_date}
                                    onApply={this.handleEvent}
                                    endDate={this.state.show_end_date}
                                >
                                    <div class="form-group ml-2">
                                        <label for="start_date"> From: </label>
                                        <input type="text" className="form-control" name="start_date" autoComplete="off" value={this.state.start_date ? moment(this.state.start_date).format('DD-MM-YYYY') : ''} />
                                    </div>
                                    <div class="form-group ml-2">
                                        <label for="end_date"> To: </label>
                                        <input type="text" className="form-control" name="end_date" autoComplete="off" value={this.state.end_date ? moment(this.state.end_date).format('DD-MM-YYYY') : ''} />
                                    </div>

                                </DateRangePicker>

                                <button className="btn btn-danger ml-2" type="button" onClick={this.resetDate}> Reset </button>

                                <CSVLink className="btn btn-default ml-2" filename={"Covid-Test-Female-" + moment().format("DD-MM-YYYY") + ".csv"} data={this.state.graph_download} headers={headers}>
                                    Download
                                </CSVLink>

                            </form>
                        </div>
                    </Box.Body>
                </Box>
                <Box.Body>
                    <BarGraphBox
                        yScaleLabel="Test Count"
                        datasetLabels={this.state.covid_test_data.x_axis_label}
                        datasetCounts={this.state.covid_test_data.counts}
                        view={this.state.view}
                        handleViewChange={this.handleView}
                    />
                </Box.Body>
            </Box>
        )
    }
}

export default CovidResultFemaleGraph;