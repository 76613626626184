import React from 'react';
import Owner from './Owner';
import { Button, ButtonToolbar, Well } from 'react-bootstrap';
import { includes } from 'lodash';

class AddMultiOwner extends React.Component {
    addMoreOwnerBlock = () => {
        let owners = this.props.owners.concat([{
            name_title: "",
            first_name: "",
            last_name: "",
            email: "",
            contact_number: "",
            alt_contact_number: "",
            gender: "male",
            date_of_birth: "",
        }]);
        this.props.addMoreOwnerBlock(owners);
    }
    removeOwnerBlock = () => {
        let owners = this.props.owners.slice(0, -1)
        this.props.removeOwnerBlock(owners)
    }
    handleOwnerChange = (event, idx) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        const owners = this.props.owners.map((owner, sidx) => {
            if (idx !== sidx) return owner;
            let gender = ['male', 'female', 'other']

            if (includes(gender, value)) {
                owner['gender'] = event.currentTarget.value;
            } else
                owner[name] = value;
            return owner;
        });
        this.props.handleOwnerChange(owners)
    }
    render() {
        const { owners } = this.props;
        return (
            owners ? <React.Fragment>
                <Well bsSize="small">
                    {owners && owners.map((owner, idx) => (
                        <Owner
                            idx={idx}
                            key={idx}
                            owner={owner}
                            handleOwnerChange={this.handleOwnerChange}
                            validator={this.props.validator} />
                    ))}
                </Well>
                {!this.props.hideAddMore && <ButtonToolbar>
                    {owners.length < 4 && <Button bsStyle="success" bsSize="xsmall" onClick={this.addMoreOwnerBlock} className="pull-right"><i className="fa fa-plus"></i> Add More</Button>}
                    {owners.length > 1 && <Button bsStyle="danger" bsSize="xsmall" onClick={this.removeOwnerBlock} className="pull-right"><i className="fa fa-times"></i> Remove</Button>}
                </ButtonToolbar>}
            </React.Fragment> : null
        )
    }
}
export default AddMultiOwner