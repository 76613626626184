import * as types from './actionTypes';
import CommunityService from '../services/communityService';
import LocationService from '../services/locationService';

export function newCommunitylist({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: types.COMMUNITY_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText } });
        CommunityService.newCommunitylist({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.COMMUNITY_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function communityadd(statedata) {
    return dispatch => {
        dispatch({ type: types.ADD_COMMUNITY });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        CommunityService.communityadd(statedata)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.ADD_COMMUNITY, ...data });
                if(data){
                    dispatch({ type: types.ALERT_SUCCESS, message: 'Community added successfully' });
                }else{
                    dispatch({ type: types.ALERT_ERROR, message: 'Community already exists' });
                }
               
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                }, 2500);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}