import * as types from './actionTypes';
import PathologyService from '../services/pathologyService';
import LocationService from '../services/locationService';

export function list({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: types.PATHOLOGY_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText } });
        PathologyService.list({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.PATHOLOGY_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function pathologyadd(statedata, history) {
    return dispatch => {
        dispatch({ type: types.ADD_PATHOLOGY });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        PathologyService.pathologyadd(statedata, history)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.ADD_PATHOLOGY, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: 'Pathology added successfully' });
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                    history.push('/pathologies/' + data.service_provider_id);
                }, 2500);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function fetchpathology(serviceProviderId) {

    return dispatch => {
        PathologyService.fetchpathology(serviceProviderId)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.FETCH_PATHOLOGY, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}


export function locationupdate(serviceProviderId, statedata, history) {

    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR, isloading: true });
        LocationService.updateLocation(serviceProviderId, statedata)
            .then(resp => {
                dispatch({ type: types.ALERT_SUCCESS, message: 'Locations updated successfully' });
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                    history.push("/pathologies/" + serviceProviderId);
                }, 3000);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function pathologyupdate(serviceProviderId, statedata, history) {

    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR, isloading: true });
        PathologyService.pathologyupdate(serviceProviderId, statedata)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.UPDATE_PATHOLOGY, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: 'Pathology updated successfully' });
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                    if (data.trail === true) {
                        history.push('/trial/account/view/' + data.service_provider_id);
                    } else {
                        history.push('/pathologies/' + data.service_provider_id);
                    }
                }, 3000);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function deletepathology(serviceProviderId) {

    return dispatch => {
        PathologyService.deletepathology(serviceProviderId)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.DELETE_PATHOLOGY, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: 'Pathology deleted successfully' });
                setTimeout(() => { dispatch({ type: types.ALERT_CLEAR }); }, 2500);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}