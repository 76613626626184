import * as types from './actionTypes';
import ReferralService from "../services/referralService";
import Notification from '../utils/notification';

export function list(page, sizePerPage, sortBy, searchText, filter) {
    return dispatch => {
        dispatch({ type: types.REFERRAL_REPORT_REQUEST, request: { page, sizePerPage, sortBy, searchText, filter } });

        ReferralService.getReferralReportLst(page, sizePerPage, sortBy, searchText, filter)
            .then(resp => {
                let result = resp.data;
                result = result.data.map((row) => ({
                    "id": row.id,
                    "date_received": row.sample_collected_on,
                    "pathology_id": row.service_provider.id,
                    "pathology_name": row.service_provider.name,
                    "patient_name": row.name,
                    "sample_nos": row.sample_number,
                    "referred_doctor": row.doctor_name,
                    "sample_collected_at": row.sample_collected_at,
                    "first_verified": row.verified1.verified === true ? row.verified1.name : "",
                    "second_verified": row.verified2.verified === true ? row.verified2.name : "",
                    "status": row.status,
                    "action": "View"
                }));
                const data = { 'data': result, 'meta': resp.data.meta, 'links': resp.data.links };
                dispatch({ type: types.REFERRAL_REPORT_SUCCESS, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER ' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}