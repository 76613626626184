import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function patient(state = initialState.patient, { type, ...payload }) {
    switch (type) {
        case types.ADD_PATIENT:
            return {
                ...state,
                ...payload
            };
        case types.DELETE_PATIENT:
            return {
                ...state,
                ...payload
            };
        case types.FETCH_PATIENT:
            return {
                ...state,
                ...payload
            };
        case types.UPDATE_PATIENT:
            return {
                ...state,
                ...payload
            }; 
        default:
            return state;
    }
}