import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class CardService {
    static list({page, sizePerPage, sortBy, searchText,filter}) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText, filter)
        };

        return axios.get(apiRoute('/v1/cards'), requestOptions);
    }
    
    static requestCardBatch(number_of_cards){
        let postData = JSON.stringify({
            'request_count' : number_of_cards 
        });
        
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute('/v1/cards/batches'), postData, requestOptions);
    }

    static listCardBatch({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText, filter)
        };

        return axios.get(apiRoute('/v1/cards/batches'), requestOptions);
    }

    static resendEmailCardBatch(batch_id)
    {
        let postData = JSON.stringify({
            'batch_id': batch_id
        });

        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute('/v1/cards/batches/email'), postData, requestOptions);
    }

    static resendEmailReissueCardBatch(batch_id)
    {
        let postData = JSON.stringify({
            'batch_id': batch_id
        });

        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute('/v1/cards/batches/reissue'), postData, requestOptions);
    }

    static reIssueCardRequest(statedata) {
        let paramsdata = JSON.stringify(statedata);
        
        const requestOptions = {
            headers: getApiHeader()
        };
        
        return axios.post(apiRoute("/v1/patients/reissue_card_request"), paramsdata, requestOptions);
    }

    static listReIssueCardRequest({page, sizePerPage, sortBy, searchText,filter}) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText, filter)
        };
    
        return axios.get(apiRoute('/v1/cards/reissue_requests'), requestOptions);
    }

    static processReIssueCard(postData) {
        let paramsdata = JSON.stringify(postData);

        const requestOptions = {
            headers: getApiHeader(),
        };
    
        return axios.post(apiRoute('/v1/cards/reissue_requests/status'), paramsdata, requestOptions);
    }

    static assignReIssueCard(postData) {
        let paramsdata = JSON.stringify(postData);

        const requestOptions = {
            headers: getApiHeader(),
        };
    
        return axios.post(apiRoute('/v1/cards/assign'), paramsdata, requestOptions);
    }

    static verifyCard(cardObj) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute('/v1/cards/available'), cardObj, requestOptions);
    }

    static updateReissueCardLocation(data) {
        let paramsdata = JSON.stringify(data);

        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute("/v1/patients/reissue_card_update"), paramsdata, requestOptions);
    }
}

export default CardService;
