import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function radiology_list(state = initialState.pagination, { type, ...payload }) {
    switch (type) {
        case types.RADIOLOGY_LIST_REQUEST:
            return {
                ...state,
                ...payload,
                loading: true
            };
        case types.RADIOLOGY_LIST_SUCCESS:
            return {
                ...state,
                data: payload.data,
                meta: payload.meta,
                status: 'updated',
                loading: false
            };
        case types.RADIOLOGY_LIST_FAILED:
            return {
                ...state,
                ...payload,
                loading: false
            };
        default:
            return state;
    }
}