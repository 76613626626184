import React from 'react';
import { Row, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import overlayFactory from 'react-bootstrap-table2-overlay';

const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ml-2">
        Showing {from} to {to} of {size} Results
    </span>
);


export default function DataTable({ columns = [], data = [], page = 0, sizePerPage = 0, onTableChange, totalSize = 0, loading = false, noDataIndication = "No Record Exists", showSearchBar = true }) {

    const tableChange = (type, paginateData) => {
        if (type === 'search') {
            page = 1;
        }

        onTableChange(type, paginateData)
    }

    return (
        <ToolkitProvider
            keyField="id"
            data={data}
            columns={columns}
            search
        >
            {
                toolkitprops => (
                    <Row>
                        <Col sm={12}>
                            {showSearchBar && <div className="pull-right">
                                <Search.SearchBar {...toolkitprops.searchProps} delay={1000} />
                            </div>
                            }
                        </Col>
                        <Col sm={12}>
                            <BootstrapTable
                                {...toolkitprops.baseProps}
                                remote
                                loading={loading}
                                onTableChange={tableChange}
                                pagination={paginationFactory({ page, sizePerPage, totalSize, showTotal: true, paginationTotalRenderer: customTotal })}
                                noDataIndication={loading ? "Loading..." : noDataIndication}
                                overlay={overlayFactory({ spinner: true })}
                            />
                        </Col>
                    </Row>
                )
            }
        </ToolkitProvider >
    )
}
