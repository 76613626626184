import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.card_batches, { type, ...payload }) {
    switch (type) {
        case types.CARD_BATCH_LIST_RESET:
            return {
                ...initialState.card_batches,
            };
        case types.CARD_BATCH_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
                status: 'request',
                ...payload
            };
        case types.CARD_BATCH_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                status: 'success',
                ...payload
            };
        case types.CARD_BATCH_LIST_FAILED:
            return {
                ...state,
                isLoading: false,
                status: 'error',
                ...payload
            };
        default:
            return state;
    }
}