import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import _ from "lodash";
import { Box, Content } from '../../components/Elements';
import CovidCenterService from '../../services/covidCenterService';
import * as LoaderAction from '../../actions/loaderAction';

class ViewPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            covid_center: '',
        };
    }
    componentDidMount() {
        const { match: { params } } = this.props;
        const serviceProviderId = params.serviceProviderId;
        this.props.showLoader();
        CovidCenterService.get(serviceProviderId).then(resp => {
            this.setState({
                covid_center: resp.data
            })
            this.props.hideLoader();
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }
    render() {
        const { covid_center } = this.state;
        let details, locations, owners;

        if (covid_center && covid_center.locations) {
            locations = <Table responsive striped>
                <thead>
                    <tr>
                        <th>Location Id</th>
                        <th>Location Name</th>
                        <th>Address</th>
                        <th>Parish</th>
                        <th>Community</th>
                        <th>Zip Code</th>
                    </tr>
                    {covid_center.locations.map((location) =>
                        <tr key={location.user_location_id}>
                            <td>{location.user_location_id}</td>
                            <td>{location.name}</td>
                            <td>{location.address.address_line_1 !== null ? location.address.address_line_1 : ''}
                                {location.address.address_line_2 !== null ? ', ' + location.address.address_line_2 : ''}
                                {location.address.address_line_3 !== null ? ', ' + location.address.address_line_3 : ''}</td>
                            <td>{location.address.parish}</td>
                            <td>{location.address.city}</td>
                            <td>{location.address.pincode}</td>
                        </tr>
                    )}
                </thead>
            </Table>;
        }

        if (covid_center && covid_center.owners) {
            owners = <Table responsive striped>
                <thead>
                    <tr>
                        <th>Full Name</th>
                        <th>Email</th>
                        <th>Contact</th>
                        <th>Gender</th>
                        <th>Status</th>
                    </tr>
                    {covid_center.owners.map((owner, index) =>
                        <tr key={index}>
                            <td>{owner.full_name}</td>
                            <td>{owner.email}</td>
                            <td>{owner.contact_number}</td>
                            <td>{owner.gender}</td>
                            <td>{owner.is_active ? 'Active' : 'In active'}</td>
                        </tr>
                    )}
                </thead>
            </Table>;
        }

        if (covid_center && covid_center.billing_address && covid_center.service_provider_id) {

            details = <Table responsive striped>
                <tbody>
                    <tr>
                        <th>Center Name</th>
                        <td>{covid_center.billing_name}</td>
                    </tr>
                    <tr>
                        <th>Registration Number</th>
                        <td>{covid_center.license_number}</td>
                    </tr>
                    <tr>
                        <th>Commission Type</th>
                        <td>{covid_center.commission_type ? _.startCase(covid_center.commission_type) : "No Commission"}</td>
                    </tr>
                    <tr>
                        <th>Commission Rate</th>
                        <td>{covid_center.commission_rate}</td>
                    </tr>
                    {covid_center.commission_type === "pay_per_use" && <tr>
                        <th>Currency</th>
                        <td>{covid_center.currency}</td>
                    </tr>}
                    <tr>
                        <th>Address Line 1</th>
                        <td>{covid_center.billing_address.address_line_1}</td>
                    </tr>
                    <tr>
                        <th>Address Line 2</th>
                        <td>{covid_center.billing_address.address_line_2}</td>
                    </tr>
                    <tr>
                        <th>Address Line 3</th>
                        <td>{covid_center.billing_address.address_line_3}</td>
                    </tr>
                    <tr>
                        <th>Community</th>
                        <td>{covid_center.billing_address.city}</td>
                    </tr>
                    <tr>
                        <th>Zip Code</th>
                        <td>{covid_center.billing_address.pincode}</td>
                    </tr>
                </tbody></Table>;
        }

        return <div>
            <Helmet>
                <title>View Covid Center</title>
            </Helmet>
            <Content title="Covid Center">
                <Box>
                    <Box.Head title="Covid Center Details"><Link to="/covid-centers" className="btn btn-info pull-right">Back</Link></Box.Head>
                    <Box.Body>
                        {this.state.covid_center && <div>
                            <ul className="nav nav-tabs">
                                <li className="active"><a data-toggle="tab" href="#basic_details"> Basic Details</a></li>
                            </ul>
                            <div className="tab-content">
                                {<div id="basic_details" className="tab-pane fade in active">
                                    {details}
                                    <h4>Owner</h4>
                                    {owners}
                                    <h4 className="box-title">Location Details</h4>
                                    {locations}
                                </div>}
                            </div>
                        </div>}
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}
const mapActionsToProps = ({
    showLoader: LoaderAction.loaderShow,
    hideLoader: LoaderAction.loaderHide,
});

export default connect(null, mapActionsToProps)(ViewPage);