import axios from 'axios';
import { apiRoute, getApiHeader, createPaginateQueryString } from '../utils/helpers';

class AirlineDashboardService {

    /**
     * 
     */
    static getAirlineCardSwipeCount() {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/count/airline-card-swipe'), requestOptions);
    }

    /**
    * 
    */
    static getAirlineUsersCount(request) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(request.page, request.sizePerPage, request.sortBy, request.searchText)
        };

        return axios.get(apiRoute('/v1/count/airline-users'), requestOptions);
    }

    /**
    *
    */
     static getAirlineCardSwipeGraphData({ view, start_date, end_date, service_provider_id, user_location_id }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: {
                start_date: start_date,
                end_date: end_date,
                group_by: view,
                serviceProvider: service_provider_id,
                userLocation: user_location_id === "all" ? "" : user_location_id
            }
        };

        return axios.get(apiRoute('/v1/graph/airline-card-swipe'), requestOptions);
    }
}

export default AirlineDashboardService;