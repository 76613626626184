import React from 'react'
import { Table } from 'react-bootstrap';
import { fullAddressStr } from '../../utils/helpers';
import { INSURANCE } from '../../utils/config';
import { has, filter, isEmpty } from 'lodash';
import moment from "moment";

export const PatientDetailsMatching = ({ old_data, new_data, merge_data, handleMergeData, onSubmit }) => {
    return <React.Fragment>
        <Table responsive striped id = "patient-matching">
            <thead>
                <tr>
                    <th>Parameters</th>
                    <th>Old Data</th>
                    <th>New Data</th>
                    <th>Final Data</th>
                </tr>
                <tr>
                    <td>Title</td>
                    <td className="col-sm-3"><input type="text" value={old_data.name_title} readOnly className="form-control no-border" onClick={(e) => handleMergeData('name_title', old_data)} /></td>
                    <td className="col-sm-3"><input type="text" value={new_data.name_title} readOnly className="form-control no-border" onClick={(e) => handleMergeData('name_title', new_data)} /></td>
                    <td>{merge_data.name_title}</td>
                </tr>
                <tr>
                    <td>First Name</td>
                    <td className="col-sm-3"><input type="text" value={old_data.first_name} readOnly className="form-control no-border" onClick={(e) => handleMergeData('first_name', old_data)} /></td>
                    <td className="col-sm-3"><input type="text" value={new_data.first_name} readOnly className="form-control no-border" onClick={(e) => handleMergeData('first_name', new_data)} /></td>
                    <td>{merge_data.first_name}</td>
                </tr>
                <tr>
                    <td>Last Name</td>
                    <td className="col-sm-3"><input type="text" value={old_data.last_name} readOnly className="form-control no-border" onClick={(e) => handleMergeData('last_name', old_data)} /></td>
                    <td className="col-sm-3"><input type="text" value={new_data.last_name} readOnly className="form-control no-border" onClick={(e) => handleMergeData('last_name', new_data)} /></td>
                    <td>{merge_data.last_name}</td>
                </tr>
                <tr>
                    <td>Maiden's Name</td>
                    <td className="col-sm-3"><input type="text" value={old_data.maiden_name} readOnly className="form-control no-border" onClick={(e) => handleMergeData('maiden_name', old_data)} /></td>
                    <td className="col-sm-3"><input type="text" value={new_data.maiden_name} readOnly className="form-control no-border" onClick={(e) => handleMergeData('maiden_name', new_data)} /></td>
                    <td>{merge_data.maiden_name}</td>
                </tr>
                <tr>
                    <td>Date of Birth</td>
                    <td className="col-sm-3"><input type="text" value={moment(old_data.date_of_birth).format('DD-MM-YYYY')} readOnly className="form-control no-border" onClick={(e) => handleMergeData('date_of_birth', old_data)} /></td>
                    <td className="col-sm-3"><input type="text" value={moment(new_data.date_of_birth).format('DD-MM-YYYY')} readOnly className="form-control no-border" onClick={(e) => handleMergeData('date_of_birth', new_data)} /></td>
                    <td>{merge_data.date_of_birth && moment(merge_data.date_of_birth).format('DD-MM-YYYY')}</td>
                </tr>
                <tr>
                    <td>Email</td>
                    <td className="col-sm-3"><input type="text" value={old_data.email} readOnly className="form-control no-border" onClick={(e) => handleMergeData('email', old_data)} /></td>
                    <td className="col-sm-3"><input type="text" value={new_data.email} readOnly className="form-control no-border" onClick={(e) => handleMergeData('email', new_data)} /></td>
                    <td>{merge_data.email}</td>
                </tr>
                <tr>
                    <td>Contact Number</td>
                    <td className="col-sm-3"><input type="text" value={old_data.contact_number} readOnly className="form-control no-border" onClick={(e) => handleMergeData('contact_number', old_data)} /></td>
                    <td className="col-sm-3"><input type="text" value={new_data.contact_number} readOnly className="form-control no-border" onClick={(e) => handleMergeData('contact_number', new_data)} /></td>
                    <td>{merge_data.contact_number}</td>
                </tr>
                <tr>
                    <td>Alternate Number</td>
                    <td className="col-sm-3"><input type="text" value={old_data.alt_contact_number} readOnly className="form-control no-border" onClick={(e) => handleMergeData('alt_contact_number', old_data)} /></td>
                    <td className="col-sm-3"><input type="text" value={new_data.alt_contact_number} readOnly className="form-control no-border" onClick={(e) => handleMergeData('alt_contact_number', new_data)} /></td>
                    <td>{merge_data.alt_contact_number}</td>
                </tr>
                <tr>
                    <td>Place of Birth</td>
                    <td className="col-sm-3"><input type="text" value={old_data.place_of_birth} readOnly className="form-control no-border" onClick={(e) => handleMergeData('place_of_birth', old_data)} /></td>
                    <td className="col-sm-3"><input type="text" value={new_data.place_of_birth} readOnly className="form-control no-border" onClick={(e) => handleMergeData('place_of_birth', new_data)} /></td>
                    <td>{merge_data.place_of_birth}</td>
                </tr>
                <tr>
                    <td>Blood Group</td>
                    <td className="col-sm-3"><input type="text" value={old_data.blood_group} readOnly className="form-control no-border" onClick={(e) => handleMergeData('blood_group', old_data)} /></td>
                    <td className="col-sm-3"><input type="text" value={new_data.blood_group} readOnly className="form-control no-border" onClick={(e) => handleMergeData('blood_group', new_data)} /></td>
                    <td>{merge_data.blood_group}</td>
                </tr>
                <tr>
                    <td>Height</td>
                    <td className="col-sm-3"><input type="text" value={old_data.height} readOnly className="form-control no-border" onClick={(e) => handleMergeData('height', old_data)} /></td>
                    <td className="col-sm-3"><input type="text" value={new_data.height} readOnly className="form-control no-border" onClick={(e) => handleMergeData('height', new_data)} /></td>
                    <td>{merge_data.height}</td>
                </tr>
                <tr>
                    <td>Insurance</td>
                    <td className="col-sm-3"><input type="text" value={getInsurance(old_data)} readOnly className="form-control no-border" onClick={(e) => handleMergeData('insurance_type', old_data)} /></td>
                    <td className="col-sm-3"><input type="text" value={getInsurance(new_data)} readOnly className="form-control no-border" onClick={(e) => handleMergeData('insurance_type', new_data)} /></td>
                    <td>{!isEmpty(merge_data) && merge_data.insurance_type && (getInsurance(merge_data))}</td>
                </tr>
                <tr>
                    <td>Address</td>
                    <td className="col-sm-3"><input type="text" value={fullAddressStr(old_data.address)} readOnly className="form-control no-border" onClick={(e) => handleMergeData('address', old_data)} /></td>
                    <td className="col-sm-3"><input type="text" value={fullAddressStr(new_data.address)} readOnly className="form-control no-border" onClick={(e) => handleMergeData('address', new_data)} /></td>
                    <td>{fullAddressStr(merge_data.address)}</td>
                </tr>
                <tr>
                    <td>Kin Name</td>
                    <td className="col-sm-3"><input type="text" value={old_data.kin_name} readOnly className="form-control no-border" onClick={(e) => handleMergeData('kin_name', old_data)} /></td>
                    <td className="col-sm-3"><input type="text" value={new_data.kin_name} readOnly className="form-control no-border" onClick={(e) => handleMergeData('kin_name', new_data)} /></td>
                    <td>{merge_data.kin_name}</td>
                </tr>
            </thead>
        </Table>
        <button onClick={onSubmit} className="btn btn-primary pull-right">Merge Final Data</button>
    </React.Fragment>
}
function getInsurance(patient) {
    let insurance = patient.insurance_type.map(value => {
        return has(INSURANCE, value) ? INSURANCE[value] : ''
    })
    return filter(insurance).join(', ')
}