export default {
    alert: {
        isloading: false,
        status: '',
        type: ''
    },
    nav: {
        parent: 'dashboard',
        child: ''
    },
    role: {},
    patient: {},
    pagination: {
        loading: false,
        request: {},
        data: [],
        meta: {
            current_page: 0,
            per_page: 0,
            total: 0,
        }
    },
    patient_list: {
        request: {},
        data: [],
        meta: {
            current_page: 0,
            per_page: 0,
            total: 0,
        }
    },
    pharmacy_list: {
        request: {},
        data: [],
        meta: {
            current_page: 0,
            per_page: 0,
            total: 0,
        }
    },
    prescriptions: {
        request: {},
        data: [],
        meta: {
            current_page: 0,
            per_page: 0,
            total: 0,
        }
    },
    doctor: {},
    community: {},
    pharmacy: {},
    drugs: {},
    admin_user: {},
    nurse: {},
    receptionist: {},
    drug_sku_price: {},
    search_patient: {},
    generate_cards: {},
    radiologist_list: {},
    radiology: {},
    pathology_list: {},
    pathology: {},
    pathologyTest: {},
    radiologyTest: {},
    card_batches: {
        isLoading: false,
        status: '',
        request: {},
        response: {},
        error: {}
    },
    reissue_card_request: {},
    trial_account: {}
}