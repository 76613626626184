import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class PathologyLimsCategoryService {
    static limsCategorylist({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText, filter)
        };

        return axios.get(apiRoute('/v1/pathology/lims-categories'), requestOptions);
    }

    static pathologyLimsCategoryAdd(statedata) {
        let paramsdata = JSON.stringify(statedata);

        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute('/v1/pathology/lims-categories'), paramsdata, requestOptions);
    }

    static fetchPathologyLimsCategory(id) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/pathology/lims-categories/' + id), requestOptions);

    }

    static pathologyLimsCategoryUpdate(id, postData) {
        let paramsdata = JSON.stringify(postData);
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.put(apiRoute('/v1/pathology/lims-categories/' + id), paramsdata, requestOptions);
    }

}

export default PathologyLimsCategoryService;

