import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class AirlineService {
    static store(postData) {
        let paramsdata = JSON.stringify(postData);

        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/airlines'), paramsdata, requestOptions);
    }

    static get(serviceProviderId) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/airlines/' + serviceProviderId), requestOptions);
    }

    static update(serviceProviderId, postData) {
        let paramsdata = JSON.stringify(postData);

        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.patch(apiRoute('/v1/airlines/' + serviceProviderId), paramsdata, requestOptions);
    }

    static list({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText, filter)
        };

        return axios.get(apiRoute('/v1/airlines'), requestOptions);
    }

    static resendEmail(email) {
        let paramsdata = JSON.stringify({
            email: email
        });
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute("/v1/users/re-verify"), paramsdata, requestOptions);
    }
}
export default AirlineService