import * as types from './actionTypes';
import AdminUserService from '../services/adminuserService';

export function changePassword(statedata) {
    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR });

        AdminUserService.changePassword(statedata).then(resp => {
            dispatch({ type: types.ALERT_SUCCESS, message: 'Password has been changed successfully' });
        }).catch(error => {
            if (error.response && error.response.status) {
                let errorData = error.response.data;
                dispatch({ type: types.ALERT_ERROR, ...errorData });
            } else {
                dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
            }
        });

    }
}
