import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { ButtonToolbar } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import * as PathologyLimsCategoryAction from '../../actions/pathologyLimsCategoryActions';
import { Helmet } from 'react-helmet';
import Alert from '../../components/Alert';
import * as HELPER from "../../utils/helpers";
import * as LoaderAction from '../../actions/loaderAction';

class ListPathologyLimsCategorysPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}

        this.props.paginate({ sortBy: { sortField: 'id', sortOrder: 'desc' } });
        this.handleChange = this.handleChange.bind(this);
    }

    columns = (data) => {
        return [{
            dataField: 'id',
            text: 'number',
            hidden: true
        },
        {
            dataField: 'name',
            text: 'Category',
        },{
            dataField: 'status',
            text: 'Status',
            formatter: this.statusFormatter
        },
        {
            dataField: 'actions',
            text: 'Actions',
            isDummyField: true,
            formatter: this.actionFormatter
        }
        ];
    };

    statusFormatter = (cell, row) => {
        if (row.status === 1) {
            return 'Active';
        }
        else if (row.status === 0) {
            return 'Inactive';
        }
    }

    actionFormatter = (cell, row) => {
        return <ButtonToolbar>
            {HELPER.hasPermission(this.props.admin, ['pathology-lims-category-edit']) && <Link className="btn btn-warning" to={'/lims-categories/' + row.id + '/edit'} title="Edit"><i className="fa fa-pencil" aria-hidden="true"></i></Link>}
           </ButtonToolbar>;
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        if (type === "search") {
            page = 1;
        }

        this.props.paginate({ page, sizePerPage, sortBy: { sortField: 'id', sortOrder: 'desc' }, searchText });
    };

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    render() {
        let { data, meta } = this.props.pathologytest_list;

        return <div>
            <Helmet>
                <title>Lims Category</title>
            </Helmet>
            <Content title="Lims Category List">
                <Box>
                    <Box.Body>
                        <Alert />
                        <span className="mr-2">{HELPER.hasPermission(this.props.admin, ['pathology-lims-category-add']) && <Link to="/lims-categories/add" className="btn btn-success"><span className="fa fa-plus"></span> Add Category</Link>}</span>
                        <ToolkitProvider
                            search
                            keyField="id"
                            data={data}
                            columns={this.columns()}
                        >
                            {
                                props => [
                                    <div className="pull-right"><Search.SearchBar {...props.searchProps} delay={1000} /></div>,
                                    <BootstrapTable
                                        {...props.baseProps}
                                        remote
                                        onTableChange={this.handleTableChange}
                                        pagination={paginationFactory({
                                            page: parseInt(meta.current_page),
                                            sizePerPage: parseInt(meta.per_page),
                                            totalSize: parseInt(meta.total)
                                        })}
                                    />
                                ]
                            }
                        </ToolkitProvider>

                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    pathologytest_list: state.pathologytest_list,
    alert: state.alert,
    admin: state.auth.admin
});

const mapActionsToProps = ({
    paginate: PathologyLimsCategoryAction.limsCategorylist,
    showLoader: LoaderAction.loaderShow,
    hideLoader: LoaderAction.loaderHide,
})

export default connect(mapStateToProps, mapActionsToProps)(ListPathologyLimsCategorysPage);
