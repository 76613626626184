import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import { routerMiddleware } from 'connected-react-router';

export const history = createBrowserHistory();
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const resetEnhancer = rootReducer => (state, action) => {
    if (action.type !== 'RESET_ALL') return rootReducer(state, action);

    const newState = rootReducer(undefined, {});
    newState.auth = state.auth;
    newState.alert = state.alert;
    return newState;
};

export default function configureStore(initialState = {}) {
    return createStore(
        resetEnhancer(rootReducer(history)),
        initialState,
        composeEnhancers(applyMiddleware(routerMiddleware(history), thunkMiddleware))
    );
}