import React from 'react'
import { Table } from 'react-bootstrap';
import { getDateFormat } from '../../utils/helpers';
import { capitalize } from 'lodash';

const NewRecord = ({ patient }) => {
    return <Table responsive striped>
        <tbody>
            <tr>
                <th>First Name</th>
                <td>{patient.first_name} </td>
            </tr>
            <tr>
                <th>Last Name</th>
                <td>{patient.last_name} </td>
            </tr>
            <tr>
                <th>Maiden Name</th>
                <td>{patient.maiden_name} </td>
            </tr>
            <tr>
                <th>Date Of Birth</th>
                <td>{getDateFormat(patient.date_of_birth)}</td>
            </tr>
            <tr>
                <th>Place Of Birth</th>
                <td>{patient.place_of_birth ? capitalize(patient.place_of_birth) : '-'}</td>
            </tr>
            <tr>
                <th>Contact Number</th>
                <td>{patient.contact_number}</td>
            </tr>
        </tbody>
    </Table>
}
export default NewRecord