import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { ButtonToolbar } from 'react-bootstrap';
import * as GenericDrugAction from '../../actions/genericdrugActions';
import { Helmet } from 'react-helmet';
import * as HELPER from '../../utils/helpers';
import DataTable from '../../components/DataTable';

class ListGenericDrugPage extends React.Component {

    constructor(props) {
        super(props);

        this.props.paginate();
    }

    columns = () => {
        return [{
            dataField: 'id',
            text: 'number',
            hidden: true
        }, {
            dataField: 'name',
            text: 'Name',
            sort: true
        }, {
            dataField: 'actions',
            text: 'Actions',
            isDummyField: true,
            formatter: this.actionFormatter
        }
        ];
    }

    actionFormatter = (cell, row) => {
        return <ButtonToolbar>
            {HELPER.hasPermission(this.props.admin, ['generic-drugs-edit']) && <Link className="btn btn-sm btn-warning" to={'/genericdrug/' + row.id + '/edit'}><i className="fa fa-pencil" aria-hidden="true"></i></Link>}
        </ButtonToolbar>;
    }

    actionButtonClick = (id, actionType) => {
        if (actionType === 'edit') {
            this.props.history.push('/genericdrug/' + id + '/' + actionType);
        } else {
            this.props.history.push('/genericdrug/' + id);
        }

    }

    deleteItem = (id) => {
        if (id) {
            this.props.delete_generic_drug(id);
        }
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {

        if (type === 'search') {
            page = 1;
        }

        this.props.paginate(page, sizePerPage, { sortField, sortOrder }, searchText);
    }


    render() {
        let { data, meta, loading } = this.props.generic_drug_list;
        return <div>
            <Helmet>
                <title>List Generic Drugs</title>
            </Helmet>
            <Content title="Generic Drugs">
                <Box>
                    <Box.Head title="List Generic Drugs">
                        {HELPER.hasPermission(this.props.admin, ['generic-drugs-add']) && <Link to="/genericdrug/add" className="btn btn-success pull-right"><span className="fa fa-plus"></span> Add Generic Drug</Link>}
                    </Box.Head>
                    <Box.Body>
                        <DataTable
                            loading={loading}
                            columns={this.columns()}
                            data={data}
                            page={parseInt(meta.current_page)}
                            sizePerPage={parseInt(meta.per_page)}
                            totalSize={parseInt(meta.total)}
                            onTableChange={this.handleTableChange}
                        />
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    generic_drug_list: state.generic_drugs_list,
    admin: state.auth.admin
});

const mapActionsToProps = ({
    paginate: GenericDrugAction.list,
    delete_generic_drug: GenericDrugAction.delete_generic_drug
})

export default connect(mapStateToProps, mapActionsToProps)(ListGenericDrugPage);