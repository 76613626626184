import React from 'react';
import { Link } from 'react-router-dom';
import { FormControl, Col, Table, FormGroup, Alert } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import * as PathologyAction from '../../actions/pathologyAction';
import * as CommissionAction from "../../actions/commissionAction";
import { Box, Content } from '../../components/Elements';
import * as HELPER from "../../utils/helpers";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import _ from "lodash";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";

class ViewPathologistPage extends React.Component {

    constructor(props) {
        super(props);

        const { match: { params } } = this.props;
        const serviceProviderId = params.serviceProviderId;

        this.state = {
            active_tab: 'basic_details',
            filter: {
                giver_service_provider_id: serviceProviderId,
                start_date: "",
                end_date: "",
                show_start_date: new Date(),
                show_end_date: new Date()
            }
        };

        this.props.fetchpathology(serviceProviderId);
        this.props.resetCommission();
    }

    columns = () => {
        return [
            {
                dataField: "prescription_id",
                text: "Prescription Id",
                sort: true
            },
            {
                dataField: "prescription_date",
                text: "Prescription Date",
                sort: true,
                formatter: this.dateFormatter
            },
            {
                dataField: "order_id",
                text: "Order ID",
                sort: true
            },
            {
                dataField: "order_date",
                text: "Order Date",
                sort: true,
                formatter: this.dateFormatter
            },
            {
                dataField: "giver_user_location.name",
                text: "Pathology Location",
                sort: false
            },
            {
                dataField: "receiver_service_provider.id",
                text: "Doctor ID",
                sort: false
            },
            {
                dataField: "receiver_service_provider.name",
                text: "Doctor Name",
                sort: false
            },
            {
                dataField: "receiver_user_location.name",
                text: "Doctor Location",
                sort: false
            },
            {
                dataField: "order_amount",
                text: "Order Value",
                sort: true
            },
            {
                dataField: "giver_commission",
                text: "Gross Commission Value",
                sort: true
            },
            {
                dataField: "giver_commission",
                text: "Consultancy Fees",
                sort: true
            },
            {
                dataField: "commission_earned",
                text: "Net Commission Earned",
                sort: true
            },
            {
                dataField: "commission_type",
                text: "Commission Type",
                sort: false
            }
        ];
    };

    dateFormatter = (cell, row) => {
        return HELPER.getDateFormat(cell);
    }

    loadCommission = () => {
        let filter = this.state.filter;

        this.setState({
            active_tab: "commission"
        });

        this.props.paginateCommission({ filter });
    }

    loadBasicDetails = () => {
        this.setState({
            active_tab: "basic_details"
        });
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        if (type === "search") {
            page = 1;
        }

        let filter = this.state.filter;

        this.props.paginateCommission({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText, filter });
    };

    handleChange = (e) => {
        let filter = this.state.filter;
        filter[e.target.name] = e.target.value;

        this.setState({
            filter: filter
        });
    }

    handleEvent = (event, picker) => {
        this.setState({
            filter: {
                giver_service_provider_id: this.state.filter.giver_service_provider_id,
                giver_user_location_id: this.state.filter.giver_user_location_id,
                start_date: (picker.startDate._d) ? moment(picker.startDate._d).format('YYYY-MM-DD') : '',
                end_date: (picker.endDate._d) ? moment(picker.endDate._d).format('YYYY-MM-DD') : '',
                show_start_date: picker.startDate._d,
                show_end_date: picker.endDate._d,
            }
        });
    }

    handleReset = () => {
        const filter = this.state.filter;
        filter["giver_service_provider_id"] = this.state.filter.giver_service_provider_id;
        filter['start_date'] = '';
        filter["end_date"] = "";
        filter["giver_user_location_id"] = '';
        filter["show_start_date"] = new Date();
        filter["show_end_date"] = new Date();
        this.setState({
            filter: filter
        });
        this.props.paginateCommission({ filter: this.state.filter });
    }

    handleFilterSubmit = () => {
        let filter = this.state.filter;

        this.props.paginateCommission({ filter });
    }

    render() {
        const { pathology, alert } = this.props;
        let details;
        let locations;
        let owners;

        if (pathology && pathology.locations) {
            locations = <Table responsive striped>
                <thead>
                    <tr>
                        <th>Location Id</th>
                        <th>Location Name</th>
                        <th>Address</th>
                        <th>Parish</th>
                        <th>Community</th>
                        <th>Zip Code</th>
                    </tr>
                    {pathology.locations.map((location) =>
                        <tr>
                            <td>{location.user_location_id}</td>
                            <td>{location.name}</td>
                            <td>{location.address.address_line_1 !== null ? location.address.address_line_1 : ''}
                                {location.address.address_line_2 !== null ? ', ' + location.address.address_line_2 : ''}
                                {location.address.address_line_3 !== null ? ', ' + location.address.address_line_3 : ''}</td>
                            <td>{location.address.parish}</td>
                            <td>{location.address.city}</td>
                            <td>{location.address.pincode}</td>
                        </tr>
                    )}
                </thead>
            </Table>;
        }

        if (pathology && pathology.owners) {
            owners = <Table responsive striped>
                <thead>
                    <tr>
                        <th>Full Name</th>
                        <th>Email</th>
                        <th>Contact</th>
                        <th>Gender</th>
                        <th>Status</th>
                    </tr>
                    {pathology.owners.map((owner) =>
                        <tr>
                            <td>{owner.full_name}</td>
                            <td>{owner.email}</td>
                            <td>{owner.contact_number}</td>
                            <td>{owner.gender}</td>
                            <td>{owner.is_active ? 'Active' : 'In active'}</td>
                        </tr>
                    )}
                </thead>
            </Table>;
        }

        if (pathology && pathology.billing_address && pathology.service_provider_id) {
            details = <Table responsive striped>
                <tbody>
                    <tr>
                        <th>Pathology Name</th>
                        <td>{pathology.billing_name}</td>
                    </tr>
                    <tr>
                        <th>Registration Number</th>
                        <td>{pathology.license_number}</td>
                    </tr>
                    <tr>
                        <th>Commission Type</th>
                        <td>{pathology.commission_type ? _.startCase(pathology.commission_type) : "No Commission"}</td>
                    </tr>
                    <tr>
                        <th>Commission Rate</th>
                        <td>{pathology.commission_rate}</td>
                    </tr>
                    {pathology.commission_type === "pay_per_use" && <tr>
                        <th>Currency</th>
                        <td>{pathology.currency}</td>
                    </tr>}
                    <tr>
                        <th>Address Line 1</th>
                        <td>{pathology.billing_address.address_line_1}</td>
                    </tr>
                    <tr>
                        <th>Address Line 2</th>
                        <td>{pathology.billing_address.address_line_2}</td>
                    </tr>
                    <tr>
                        <th>Address Line 3</th>
                        <td>{pathology.billing_address.address_line_3}</td>
                    </tr>
                    <tr>
                        <th>Community</th>
                        <td>{pathology.billing_address.city}</td>
                    </tr>
                    <tr>
                        <th>Zip Code</th>
                        <td>{pathology.billing_address.pincode}</td>
                    </tr>
                </tbody></Table>;
        }

        return <div>
            <Helmet>
                <title>View Pathology</title>
            </Helmet>
            <Content title="Pathology">
                <Box>
                    <Box.Head title="Pathology Details"><Link to="/pathologies" className="btn btn-info pull-right">Back</Link></Box.Head>
                    <Box.Body>
                        {alert && alert.message &&
                            <Alert bsStyle={alert.type}>{alert.message}</Alert>
                        }
                        <div>
                            <ul className="nav nav-tabs">
                                <li className="active"><a data-toggle="tab" href="#basic_details" onClick={this.loadBasicDetails}> Basic Details</a></li>
                                {HELPER.hasPermission(this.props.admin, ['pathology-view-commission']) && <li className=""><a data-toggle="tab" href="#commission" onClick={this.loadCommission}> Commission </a></li>}
                            </ul>
                            <div className="tab-content">
                                {this.state.active_tab === 'basic_details' && <div id="basic_details" className="tab-pane fade in active">
                                    {details}
                                    <h4>Owners</h4>
                                    {owners}
                                    <h4 className="box-title">Location Details</h4>
                                    {locations}
                                </div>}
                                {this.state.active_tab === 'commission' && <div id="commission" className="tab-pane fade in active">
                                    <div className="table-responsive">
                                        <Box.Body>
                                            <FormGroup>
                                                <Col sm={3}>
                                                    <FormControl name="giver_user_location_id" componentClass="select" value={this.state.filter.giver_user_location_id} onChange={this.handleChange}>
                                                        <option value="">Select Location</option>
                                                        {pathology.locations && pathology.locations.map((location) =>
                                                            <option value={location.user_location_id}>{location.name}</option>
                                                        )}
                                                    </FormControl>
                                                </Col>
                                                <Col sm={6}>
                                                    <DateRangePicker startDate={this.state.filter.show_start_date} onApply={this.handleEvent} endDate={this.state.filter.show_end_date} >
                                                        <Col sm={6}>
                                                            <input type="text" className='form-control search-input' value={this.state.filter.start_date ? moment(this.state.filter.start_date).format('DD-MM-YYYY') : ''} name="start_date" autoComplete='off' placeholder="Start Date"></input></Col>
                                                        <Col sm={6}><input type="text" className='form-control search-input' value={this.state.filter.end_date ? moment(this.state.filter.end_date).format('DD-MM-YYYY') : ''} name="end_date" autoComplete='off' placeholder="End Date"></input></Col>
                                                    </DateRangePicker>
                                                </Col>
                                                <Col sm={3}>
                                                    <button className="btn btn-primary" type="submit" onClick={this.handleFilterSubmit}>Filter</button>&nbsp;
                                                    <button className="btn btn-primary" type="reset" onClick={this.handleReset}>Reset</button>
                                                </Col>
                                            </FormGroup>
                                        </Box.Body>
                                        {_.isEmpty(this.props.commission.data) && <span>No Commissions Yet!</span>}
                                        {HELPER.hasPermission(this.props.admin, ['pathology-view-commission']) && !_.isEmpty(this.props.commission.data) &&
                                            <ToolkitProvider
                                                search
                                                keyField="id"
                                                data={this.props.commission.data}
                                                columns={this.columns()}
                                            >
                                                {
                                                    props => [
                                                        <div className="pull-right"><Search.SearchBar {...props.searchProps} delay={1000} /></div>,
                                                        <BootstrapTable
                                                            {...props.baseProps}
                                                            remote
                                                            onTableChange={this.handleTableChange}
                                                            pagination={paginationFactory({
                                                                page: parseInt(this.props.commission.meta.current_page),
                                                                sizePerPage: parseInt(this.props.commission.meta.per_page),
                                                                totalSize: parseInt(this.props.commission.meta.total),
                                                            })}
                                                            rowStyle={this.rowStyle}
                                                        />
                                                    ]
                                                }
                                            </ToolkitProvider>
                                        }
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = state => ({
    pathology: state.pathology,
    alert: state.alert,
    commission: state.commission
});

const mapActionsToProps = ({
    fetchpathology: PathologyAction.fetchpathology,
    paginateCommission: CommissionAction.list,
    resetCommission: CommissionAction.resetList
});

export default connect(mapStateToProps, mapActionsToProps)(ViewPathologistPage);