import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function patient_list(state = initialState.pagination, { type, ...payload }) {
    switch (type) {
        case types.PATIENT_LIST_REQUEST:
            return {
                ...state,
                ...payload,
                loading: true
            };
        case types.PATIENT_LIST_SUCCESS:
            return {
                ...state,
                data: payload.data,
                meta: payload.meta,
                loading: false
            };
        case types.PATIENT_LIST_FAILED:
            return {
                ...state,
                ...payload,
                loading: false
            };
        default:
            return state;
    }
}
