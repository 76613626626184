import * as types from './actionTypes';
import PharmacyService from '../services/pharmacyService';
import LocationService from '../services/locationService';

export function list({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: types.PHARMACY_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText } });
        PharmacyService.list({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.PHARMACY_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function pharmacyadd(statedata, history) {
    return dispatch => {
        dispatch({ type: types.ADD_PHARMACY });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        PharmacyService.pharmacyadd(statedata, history)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.ADD_PHARMACY, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: 'Pharmacy added successfully' });
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                    history.push('/pharmacies/' + data.service_provider_id);
                }, 2500);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function fetchpharmacy(serviceProviderId) {

    return dispatch => {
        PharmacyService.fetchpharmacy(serviceProviderId)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.FETCH_PHARMACY, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function locationupdate(serviceProviderId, statedata, history) {

    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR, isloading: true });
        LocationService.updateLocation(serviceProviderId, statedata)
            .then(resp => {
                dispatch({ type: types.ALERT_SUCCESS, message: 'Locations updated successfully' });
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                    history.push("/pharmacies/" + serviceProviderId);
                }, 3000);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}


export function pharmacyupdate(serviceProviderId, statedata, history) {

    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR, isloading: true });
        PharmacyService.pharmacyupdate(serviceProviderId, statedata)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.UPDATE_PHARMACY, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: 'Pharmacy updated successfully' });
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                    if (data.trail === true) {
                        history.push('/trial/account/view/' + data.service_provider_id);
                    } else {
                        history.push("/pharmacies/" + data.service_provider_id);
                    }
                }, 3000);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function deletepharmacy(serviceProviderId) {

    return dispatch => {
        PharmacyService.deletepharmacy(serviceProviderId)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.DELETE_PHARMACY, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: 'Pharmacy deleted successfully' });
                setTimeout(() => { dispatch({ type: types.ALERT_CLEAR }); }, 2500);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}