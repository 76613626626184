import * as types from './actionTypes';
import CardService from '../services/cardService';

export function list({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: types.CARD_MANAGEMENT_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText } });

        CardService.list({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.CARD_MANAGEMENT_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                dispatch({ type: types.CARD_MANAGEMENT_LIST_FAILED });
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }

}

export function listCardBatch({ page, sizePerPage, sortBy, searchText }) {
    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR });
        dispatch({ type: types.CARD_BATCH_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText } });

        CardService.listCardBatch({ page, sizePerPage, sortBy, searchText })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.CARD_BATCH_LIST_SUCCESS, response: {...data } });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }

}

export function requestCardBatch(number_of_cards, history) {
    return dispatch => {
        dispatch({ type: types.GENERATE_PATIENT_CARDS });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        CardService.requestCardBatch(number_of_cards, history)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.ALERT_SUCCESS, message: data.message });
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                }, 1500);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function resendEmailCardBatch(batch_id) {
    return dispatch => {
        dispatch({ type: 'EMAIL_CARD_BATCH_REQUEST', request: { batch_id: batch_id } });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        CardService.resendEmailCardBatch(batch_id)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.ALERT_SUCCESS, message: data.message });
                dispatch({ type: 'EMAIL_CARD_BATCH_SUCCESS', data: { message: data.message } });
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                }, 1500);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function resendEmailReissueCardBatch(batch_id) {
    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        CardService.resendEmailReissueCardBatch(batch_id)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.ALERT_SUCCESS, message: data.message });
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR, isloading: false });
                }, 1500);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function reIssueCardRequest(data) {
    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        CardService.reIssueCardRequest(data)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.ALERT_SUCCESS, message: data.message });
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR, isloading: false });
                }, 1500);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function listReIssueCardRequest({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: types.REISSUE_CARD_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText } });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        CardService.listReIssueCardRequest({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.REISSUE_CARD_LIST_SUCCESS, ...data });
                dispatch({ type: types.ALERT_CLEAR, isloading: false });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }

}

export function updateReissueCardLocation(data) {
    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        CardService.updateReissueCardLocation(data)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.ALERT_SUCCESS, message: data.message });
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR, isloading: false });
                }, 1500);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}