import React from 'react';
import { Box } from "../components/Elements";
import { Col, Table, Button, ButtonToolbar } from "react-bootstrap";
import * as HELPER from "../utils/helpers"
import _ from "lodash";

export const SelectCard = ({ patients, onSelect, onPatientSubmit, selectedCard }) => {
    return <Box box="solid">
        <Box.Head title="Select Card to Deactivate"></Box.Head>
        <Box.Body>
            <Table responsive bordered>
                <thead>
                    <tr>
                        <th></th>
                        <th>Patient Code</th>
                        <th>Onboarder By</th>
                        <th>Onboarder Date</th>
                    </tr>
                </thead>
                <tbody>
                    {patients.map(patient => {
                        return (<tr>
                            <td>
                                <input
                                    label="1"
                                    name="radio_inp"
                                    type='radio'
                                    id={1}
                                    onClick={() => onSelect(patient)}
                                    checked={_.isEmpty(selectedCard) ? false : true}
                                />
                            </td>
                            <td>{patient.patientCode}</td>
                            <td>{'Pooja Loyalty'}</td>
                            <td>{HELPER.getDateFormat(patient.createdAt)}</td>
                        </tr>)
                    })}
                </tbody>
            </Table>

            {(patients.length == 1 && patients[0].card_request == "new-request") && (
                <Button
                    bsStyle="primary"
                    type="button"
                    className="pull-right"
                    onClick={() => onPatientSubmit('reissue')}
                >Submit</Button>
            )}

            {(patients.length == 1 && (patients[0].card_request != "pending" && patients[0].card_request != "new-request")) && (
                <><Button
                    bsStyle="primary"
                    type="button"
                    className="pull-right"
                    onClick={() => onPatientSubmit('reissue')}
                >Submit</Button>
                </>
            )}
        </Box.Body>
    </Box>
}

export const SelectPlatform = ({ patient, handleReIssueCard, handleCancel, handleBack }) => {

    return <Box box="solid">
        <Box.Body>
            <h4 className='text-bold'> Patient Information</h4>
            <hr />

            <Col sm={4}> <b>Patient Code:</b> {patient.patientCode}</Col>
            <Col sm={4}> <b>Onboarded By: </b>{'Pooja Loyalty'}</Col>
            <Col sm={4}> <b>Onboarded Date: </b> {HELPER.getDateFormat(patient.createdAt)}</Col>

            <hr />
                <Col sm={12} className="pull-right">
                    <ButtonToolbar className='mt-2 pull-right'>
                        <Button
                            bsStyle="primary"
                            type="button"
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                        <Button
                            bsStyle="primary"
                            type="button"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            bsStyle="primary"
                            type="button"
                            onClick={handleReIssueCard}
                        >
                            Re-Issue Gift Card
                        </Button>

                    </ButtonToolbar>
                </Col>
        </Box.Body>
    </Box>
}