import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

class PublicLayout extends React.Component {
    componentDidMount() {
        document.body.classList.add('login-page');
    }
    componentWillUnmount() {
        document.body.classList.remove('login-page');
    }

    render() {
        const { component: Component, alert, ...rest } = this.props;

        return (
            <Route {...rest} render={props => (
                <React.Fragment>
                    {alert && alert.isloading && <div className="loading">Loading</div>}
                    <NotificationContainer />
                    <Component {...props} />
                </React.Fragment>
            )} />
        );
    }
}

const mapStateToProps = (state) => ({
    alert: state.alert
})

export default connect(mapStateToProps, null)(PublicLayout);