import React from 'react';
import CountBox from './CountBox';
import { Box } from '../../components/Elements';
import DashboardService from '../../services/dashboardService';

class PatientCount extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            count: '...'
        }
    }
    
    componentDidMount() {
        DashboardService.getPatientCount().then(res => {
            this.setState({
                count: res.data.patient_count,
            });
        });
    }

    render() {
        return (
            <Box box={"solid box-info"}><Box.Head title="Number Of Users" />
                <Box.Body>
                    <div className="row">
                        <div className="col-sm-12">
                            <CountBox title="Patient" count={this.state.count} />
                        </div>
                    </div>     
                </Box.Body>
            </Box>
        );
    }
}

export default PatientCount;
