import React from 'react';
import CountBox from './CountBox';
import { Box } from '../Elements';
import AirlineDashboardService from '../../services/airlineDashboardService';
import DataTable from './TableBox';

class AirlineUsersCount extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: false,
            meta: {
                current_page: 1,
                per_page: 10,
                total: 0,
            }
        }
    }

    componentDidMount() {
        this.fetchAirlineUsersCount({ page: this.state.meta.current_page, sizePerPage: this.state.meta.per_page });
    }

    fetchAirlineUsersCount = (request) => {
        this.setState({ loading: true });
        AirlineDashboardService.getAirlineUsersCount(request).then(res => {
            console.log(res)
            this.setState({ loading: false })
            this.setState({
                data: res.data.data,
            });
        });
    };

    columns = () => {
        return [{
            dataField: 'name',
            text: 'Name'
        }, {
            dataField: 'users_count',
            text: 'Users'
        }]
    };

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        if (type === 'search') {
            page = 1;
        }
        this.fetchAirlineUsersCount({ page, sizePerPage, sortby: { sortField, sortOrder }, searchText });
    }

    render() {

        let { data, meta } = this.state;

        return (
            <Box box={"solid box-info"}><Box.Head title="Authority Access" />
                <Box.Body>
                    <div className="row">
                        <div className="col-sm-12">
                            <DataTable
                                loading={this.state.loading}
                                columns={this.columns()}
                                data={data}
                                page={parseInt(meta.current_page)}
                                sizePerPage={parseInt(meta.per_page)}
                                totalSize={parseInt(meta.total)}
                                onTableChange={this.handleTableChange}
                                search = {false}
                            />
                        </div>
                    </div>
                </Box.Body>
            </Box>
        );
    }
}

export default AirlineUsersCount;