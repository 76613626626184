import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { FormGroup, ControlLabel, FormControl, Radio, Col, Button, ButtonToolbar } from 'react-bootstrap';
import * as DoctorAction from '../../actions/doctorActions';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from 'react-helmet';
import * as CONFIG from "../../utils/config";
import Alert from '../../components/Alert';
import _ from "lodash";
import Notification from '../../utils/notification';
import * as HELPER from '../../utils/helpers';
import EditMultiLocation from "../../components/Location/EditMultiLocation";
import EditCommission from '../../components/Commission/EditCommission';
import CreatableSelect from 'react-select/creatable';
import CommunityService from '../../services/communityService';

class EditDoctorPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            service_provider_id: '',
            first_name: "",
            last_name: "",
            email: "",
            contact_number: "",
            alt_contact_number: "",
            gender: "male",
            date_of_birth: "",
            degree: "",
            specialization: "",
            license_number: "",
            no_commission: "",
            commission_rate: "",
            commission_type: "",
            address_line_1: "",
            address_line_2: "",
            address_line_3: "",
            city: "",
            pincode: "",
            parish: "",
            other_degree: false,
            other_specialization: false,
            trn_checked: '',
            is_trn_applicable: false,
            no_commission: false,
            trn_number: '',
            other_degree_name: '',
            other_specialization_name: '',
            location_details: '',
            flag: true,
            user_status: false,
            communities: [],
            selectedParisCommunity: []
        };

        //this.validator = new SimpleReactValidator();
        this.validator = new SimpleReactValidator({
            validators: {
                birthdate: {
                    message: 'The date of birth must be less than todays date',
                    rule: (val, params) => {
                        return (new Date(val) > new Date(params) ? false : true)
                    },
                    required: true
                }
            }
        });
        const { match: { params } } = this.props;
        const serviceProviderId = params.serviceProviderId;
        this.props.fetchDoctor(serviceProviderId);
        this.handleChange = this.handleChange.bind(this);
        this.validateAndSubmit = this.validateAndSubmit.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.getCommunity()
    }

    getCommunity = () => {
        CommunityService.list().then(resp => {
            this.setState({
                communities: resp.data.data,
            }, () => {
                this.setSelectedParisCommunity(this.state.parish)
            })
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    componentWillReceiveProps = (props, state) => {
        if (this.state.flag === true) {

            const is_other_degree = CONFIG.DOCTORDEGREE.includes(props.doctor.degree);
            const is_other_specialization = CONFIG.SPECIALIZATION.includes(props.doctor.specialization);
            this.setState({
                service_provider_id: props.doctor.service_provider_id,
                name_title: props.doctor.name_title,
                first_name: props.doctor.first_name,
                last_name: props.doctor.last_name,
                email: props.doctor.email,
                contact_number: props.doctor.contact_number,
                alt_contact_number: props.doctor.alt_contact_number,
                gender: props.doctor.gender,
                date_of_birth: props.doctor.date_of_birth,
                degree: !is_other_degree ? "other_degree" : props.doctor.degree,
                other_degree: !is_other_degree,
                other_degree_name: !is_other_degree ? props.doctor.degree : '',
                specialization: !is_other_specialization ? "other_specialization" : props.doctor.specialization,
                other_specialization: !is_other_specialization,
                other_specialization_name: !is_other_specialization ? props.doctor.specialization : '',
                license_number: props.doctor.license_number,
                no_commission: props.doctor.no_commission,
                commission_rate: props.doctor.commission_rate,
                commission_type: props.doctor.commission_type,
                currency: props.doctor.currency,
                billing_name: props.doctor.billing_name,
                trn_number: props.doctor.trn_number,
                is_trn_applicable: props.doctor.is_trn_applicable,
                no_commission: props.doctor.no_commission,
                trn_checked: props.doctor.is_trn_applicable === true ? "checked" : '',
                no_commission_checked: props.doctor.no_commission === true ? "checked" : '',
                address_line_1: props.doctor.billing_address ? props.doctor.billing_address.address_line_1 : '',
                address_line_2: props.doctor.billing_address ? props.doctor.billing_address.address_line_2 : '',
                address_line_3: props.doctor.billing_address ? props.doctor.billing_address.address_line_3 : '',
                city: props.doctor.billing_address ? props.doctor.billing_address.city : '',
                pincode: props.doctor.billing_address ? props.doctor.billing_address.pincode : '',
                parish: props.doctor.billing_address ? props.doctor.billing_address.parish : '',
                location_details: props.doctor.locations,
                flag: false,
                user_status: props.doctor.status
            });

        } else {
            return state;
        }
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        if (name === "is_trn_applicable") {
            if (event.target.checked) {
                this.setState({
                    is_trn_applicable: true,
                    trn_checked: 'checked'
                });
            } else {
                this.setState({
                    is_trn_applicable: false,
                    trn_checked: ''
                });
            }
        }

        if (name === "degree") {
            if (value === "other_degree") {
                this.setState({
                    other_degree: true,
                })
            } else {
                this.setState({
                    other_degree: false,
                })
            }
        }

        if (name === "specialization") {
            if (value === "other_specialization") {
                this.setState({
                    other_specialization: true,
                })
            } else {
                this.setState({
                    other_specialization: false
                });
            }
        }

        if (name === "parish") {
            this.setSelectedParisCommunity(value)
            this.setCommunity(null)
        }
    }

    handleCommissionChange = (key, value) => {

        if (key === "no_commission") {
            value = !this.state.no_commission
        }
        this.setState({
            [key]: value
        }
        )
    }

    handleRadioChange = (event) => {
        this.setState({
            gender: event.currentTarget.value
        })
    };

    validateAndSubmit(e) {
        e.preventDefault();
        this.validator.showMessages();
        console.log("val:" , this.validator)
        if (!this.validator.allValid()) {
            console.log("val:" , 1)
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        if (this.state.is_trn_applicable === true) {
            if (_.isEmpty(this.state.trn_number)) {
                Notification.show('error', { message: "Please enter TRN Number" });
                return false;
            }
        }

        if (HELPER.hasRole(this.props.admin, ['super-admin', 'admin', 'business-development-head']) && _.isEmpty(this.state.commission_type) && !this.state.no_commission) {
            Notification.show('error', { message: "Please fill Commission details" });
            return false;
        }

        let postData = {
            name_title: this.state.name_title,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            gender: this.state.gender,
            date_of_birth: this.state.date_of_birth,
            trn_number: this.state.trn_number,
            contact_number: this.state.contact_number,
            alt_contact_number: this.state.alt_contact_number,
            email: this.state.email,
            license_number: this.state.license_number,
            is_trn_applicable: this.state.is_trn_applicable,
            no_commission: this.state.no_commission,
            specialization: this.state.other_specialization ? this.state.other_specialization_name : this.state.specialization,
            degree: this.state.other_degree ? this.state.other_degree_name : this.state.degree,
            billing_address: {
                address_line_1: this.state.address_line_1,
                address_line_2: this.state.address_line_2,
                address_line_3: this.state.address_line_3,
                city: this.state.city,
                pincode: this.state.pincode,
                parish: this.state.parish
            },
            billing_name: this.state.billing_name,
            no_commission: this.state.no_commission,
        };

        if (this.state.no_commission === false) {
            postData['commission_type'] = this.state.commission_type
            postData['commission_rate'] = this.state.commission_rate

            if (this.state.commission_type === "pay_per_use") {
                postData['currency'] = this.state.currency
            }
        } else {
            postData['commission_rate'] = 0
        }

        this.props.doctorupdate(this.state.service_provider_id, postData, this.props.history);
    }

    setSelectedParisCommunity = (parish) => {
        if (parish) {
            let selectedCommunities = _.filter(this.state.communities, (items) => items.parish_name == parish)
            selectedCommunities = selectedCommunities.map((items) => {
                return { 'label': items.community_name, 'value': items.community_name };
            })
            this.setState({
                selectedParisCommunity: selectedCommunities
            });
        } else {
            this.setState({
                selectedParisCommunity: []
            });
        }
    }

    setCommunity = (community) => {
        this.setState({ city: community ? community.value : '' });
    }

    createCommunity = (community) => {
        let selectedParisCommunity = this.state.selectedParisCommunity;
        selectedParisCommunity.push({ label: community, value: community });
        this.setState({ city: community, selectedParisCommunity: selectedParisCommunity });
    }

    render() {
        return <div>
            <Helmet>
                <title>Edit Doctor</title>
            </Helmet>
            <Content title="Edit Doctor">
                <Box>
                    <Box.Head title="Edit Doctor Form"></Box.Head>
                    <Box.Body>
                        <Alert />
                        {this.state && this.props.doctor.service_provider_id &&
                            <div>
                                <ul className="nav nav-tabs">
                                    <li className="active"><a data-toggle="tab" href="#basic_details"> Basic Details</a></li>
                                    <li className=""><a data-toggle="tab" href="#locations"> Location </a></li>
                                </ul>
                                <div className="tab-content">
                                    <div id="basic_details" className="tab-pane fade in active">
                                        <form onSubmit={this.validateAndSubmit}>
                                            <h4>Personal Details</h4>
                                            <hr />

                                            <div className="row form-group">
                                                <div className="col-xs-3">
                                                    <ControlLabel>Name Title: <span>*</span></ControlLabel>
                                                    <FormControl name="name_title" componentClass="select" value={this.state.name_title} onChange={this.handleChange} placeholder="Select Degree">
                                                        <option value="" disabled>Select Title</option>
                                                        <option value="Dr.">Dr.</option>
                                                    </FormControl>
                                                    {this.validator.message('name_title', this.state.name_title, 'required')}
                                                </div>

                                                <div className="col-xs-4">
                                                    <ControlLabel>First Name: <span>*</span></ControlLabel>
                                                    <FormControl
                                                        name='first_name'
                                                        type="text"
                                                        value={this.state.first_name}
                                                        placeholder="Enter your first name"
                                                        onChange={this.handleChange}
                                                        autoComplete="none"
                                                    />
                                                    {this.validator.message('first_name', this.state.first_name, ['required', 'string', { regex: HELPER.personNameRegex() }])}
                                                </div>

                                                <div className="col-xs-4">
                                                    <ControlLabel>Last Name: <span>*</span></ControlLabel>
                                                    <FormControl
                                                        name='last_name'
                                                        type="text"
                                                        value={this.state.last_name}
                                                        placeholder="Enter your last name"
                                                        onChange={this.handleChange}
                                                        autoComplete="none"
                                                    />
                                                    {this.validator.message('last_name', this.state.last_name, ['required', 'string', { regex: HELPER.personNameRegex() }])}
                                                </div>
                                            </div>

                                            <div className="row form-group">
                                                <div className="col-xs-5">
                                                    <ControlLabel>Email: <span>*</span></ControlLabel>
                                                    <FormControl
                                                        name='email'
                                                        type="email"
                                                        value={this.state.email}
                                                        placeholder="Enter your email"
                                                        onChange={this.handleChange}
                                                        autoComplete="none"
                                                        readOnly={this.state.user_status ? 'readOnly' : ''}
                                                    />
                                                    {this.validator.message('email', this.state.email, 'required|email')}
                                                </div>

                                                <div className="col-xs-3">
                                                    <ControlLabel>Contact Number:</ControlLabel>
                                                    <FormControl
                                                        name='contact_number'
                                                        type="number"
                                                        value={this.state.contact_number}
                                                        placeholder="Enter your contact number"
                                                        onChange={this.handleChange}
                                                    />
                                                    {this.validator.message('contact_number', this.state.contact_number, 'phone')}
                                                </div>

                                                <div className="col-xs-3">
                                                    <ControlLabel>Alternate Number/Landline Number:</ControlLabel>
                                                    <FormControl
                                                        name='alt_contact_number'
                                                        type="number"
                                                        value={this.state.alt_contact_number}
                                                        placeholder="Enter your Alternate Number/Landline Number"
                                                        onChange={this.handleChange}
                                                    />
                                                    {this.validator.message('alternate_number', this.state.alt_contact_number, 'phone')}
                                                </div>
                                            </div>

                                            <div className="row form-group">
                                                <div className="col-xs-3">
                                                    <ControlLabel>Gender: <span>* </span></ControlLabel>
                                                    <div className="radio">
                                                        <Radio name='gender' value='male' checked={this.state.gender === 'male'} onChange={this.handleRadioChange} inline> Male </Radio>
                                                        <Radio name='gender' value='female' checked={this.state.gender === 'female'} onChange={this.handleRadioChange} inline> Female </Radio>
                                                        <Radio name='gender' value='other' checked={this.state.gender === 'other'} onChange={this.handleRadioChange} inline> Other </Radio>
                                                    </div>
                                                </div>

                                                <div className="col-xs-4">
                                                    <ControlLabel>Date of Birth:</ControlLabel>
                                                    <FormControl
                                                        name='date_of_birth'
                                                        type="date"
                                                        value={this.state.date_of_birth}
                                                        onChange={this.handleChange}
                                                        min='1800-01-01'
                                                        max="2999-12-31"
                                                    />
                                                    {this.validator.message('date_of_birth', this.state.date_of_birth, 'birthdate:' + new Date())}
                                                </div>

                                                <div className="col-xs-4">
                                                    <ControlLabel>Registration Number: *</ControlLabel>
                                                    <FormControl
                                                        name='license_number'
                                                        type="text"
                                                        value={this.state.license_number}
                                                        placeholder="Enter your Registration Number"
                                                        onChange={this.handleChange}
                                                        autoComplete="none"
                                                    />
                                                    {this.validator.message('registration_number', this.state.license_number, 'required')}
                                                </div>
                                            </div>

                                            <h4>Professional Details</h4>
                                            <hr />

                                            <div className="row form-group">
                                                <div className="col-xs-5">
                                                    <ControlLabel>Select Degree: <span>*</span></ControlLabel>
                                                    <FormControl name="degree" componentClass="select" value={this.state.other_degree ? "other_degree" : this.state.degree} onChange={this.handleChange} placeholder="Select Degree">
                                                        <option value="">Select Degree</option>
                                                        {CONFIG.DOCTORDEGREE.map((value) => {
                                                            return <option value={value}>{value}</option>
                                                        })}
                                                        <option value="other_degree">Other</option>
                                                    </FormControl>
                                                    {this.validator.message('degree', this.state.degree, 'required')}
                                                </div>

                                                <div className="col-xs-6">
                                                    <ControlLabel>Select Specialization:</ControlLabel>
                                                    <FormControl name='specialization' componentClass="select" value={this.state.other_specialization ? "other_specialization" : this.state.specialization} onChange={this.handleChange} placeholder="Select specialization">
                                                        <option value="">Select Specialization</option>
                                                        {CONFIG.SPECIALIZATION.map((value) => {
                                                            return <option value={value}>{value}</option>
                                                        })}
                                                        <option value="other_specialization">Other</option>
                                                    </FormControl>
                                                    {this.validator.message('specialization', this.state.specialization, 'required')}
                                                </div>
                                            </div>
                                            <div className="row form-group">
                                                {this.state.other_degree &&
                                                    <div className="col-xs-5">
                                                        <FormControl
                                                            name='other_degree_name'
                                                            type="text"
                                                            placeholder="Enter degree name"
                                                            onChange={this.handleChange}
                                                            autoComplete="none"
                                                            value={this.state.other_degree_name}
                                                        />
                                                        {this.validator.message('degree_name', this.state.other_degree_name, 'required')}
                                                    </div>
                                                }
                                                {!this.state.other_degree && <div className="col-xs-5"></div>}
                                                {this.state.other_specialization &&
                                                    <div className="col-xs-6">
                                                        <FormControl
                                                            name='other_specialization_name'
                                                            type="text"
                                                            placeholder="Enter specialization name"
                                                            onChange={this.handleChange}
                                                            autoComplete="none"
                                                            value={this.state.other_specialization_name}
                                                        />
                                                        {this.validator.message('specialization_name', this.state.other_specialization_name, 'required')}
                                                    </div>
                                                }
                                            </div>

                                            <h4>Billing Details</h4>
                                            <hr />

                                            <div className="row form-group">
                                                <div className="col-xs-5">
                                                    <ControlLabel>TRN Number: </ControlLabel>
                                                    <FormControl
                                                        name='trn_number'
                                                        type="text"
                                                        value={this.state.trn_number}
                                                        placeholder="Enter your TRN number"
                                                        onChange={this.handleChange}
                                                        autoComplete="none"
                                                    />
                                                    {this.state.is_trn_applicable ? this.validator.message('trn_number', this.state.trn_number, 'required|max:15,string') : ''}
                                                </div>

                                                <div className="col-xs-6">
                                                    <ControlLabel>Bill Name: <span>*</span></ControlLabel>
                                                    <FormControl
                                                        name='billing_name'
                                                        type="text"
                                                        value={this.state.billing_name}
                                                        placeholder="Enter your bill name"
                                                        onChange={this.handleChange}
                                                        autoComplete="none"
                                                    />
                                                    {this.validator.message('billing_name', this.state.billing_name, 'required|string|max:100,string')}
                                                </div>
                                            </div>

                                            <div className="row form-group">
                                                <div className="col-xs-4">
                                                    <ControlLabel>Address Line 1: *</ControlLabel>
                                                    <FormControl
                                                        name='address_line_1'
                                                        type="text"
                                                        value={this.state.address_line_1}
                                                        placeholder="Enter your address line"
                                                        onChange={this.handleChange}
                                                        autoComplete="none"
                                                    />
                                                    {this.validator.message('address_line_1', this.state.address_line_1, 'required|string|max:255,string')}
                                                </div>

                                                <div className="col-xs-4">
                                                    <ControlLabel>Address Line 2:</ControlLabel>
                                                    <FormControl
                                                        name='address_line_2'
                                                        type="text"
                                                        value={this.state.address_line_2}
                                                        placeholder="Enter your address line"
                                                        onChange={this.handleChange}
                                                        autoComplete="none"
                                                    />
                                                    {this.validator.message('address_line_2', this.state.address_line_2, 'string|max:255,string')}
                                                </div>

                                                <div className="col-xs-3">
                                                    <ControlLabel>Address Line 3:</ControlLabel>
                                                    <FormControl
                                                        name='address_line_3'
                                                        type="text"
                                                        value={this.state.address_line_3}
                                                        placeholder="Enter your address line"
                                                        onChange={this.handleChange}
                                                        autoComplete="none"
                                                    />
                                                    {this.validator.message('address_line_3', this.state.address_line_3, 'string|max:255,string')}
                                                </div>
                                            </div>

                                            <div className="row form-group">
                                                <div className="col-xs-3">
                                                    <ControlLabel>Parish: *</ControlLabel>
                                                    <FormControl name="parish" componentClass="select" value={this.state.parish} onChange={this.handleChange} placeholder="Select Parish">
                                                        <option value=''>Select Parish</option>
                                                        {CONFIG.PARISH.map((value) => {
                                                            return <option value={value}>{value}</option>
                                                        })}
                                                    </FormControl>
                                                    {this.validator.message('parish', this.state.parish, 'required')}
                                                </div>

                                                <div className="col-xs-4">
                                                    <ControlLabel>Community: *</ControlLabel>
                                                    <CreatableSelect key={this.state.selectedParisCommunity.length} isClearable options={this.state.selectedParisCommunity} value={{ value: this.state.city, label: this.state.city }} onChange={(newValue) => this.setCommunity(newValue)} onCreateOption={this.createCommunity} />
                                                    {this.validator.message('community', this.state.city, 'required|string|max:100,string')}
                                                </div>

                                                <div className="col-xs-4">
                                                    <ControlLabel>Zip Code: *</ControlLabel>
                                                    <FormControl
                                                        name='pincode'
                                                        type="text"
                                                        value={this.state.pincode}
                                                        placeholder="Enter your Zip Code"
                                                        onChange={this.handleChange}
                                                        autoComplete="none"
                                                    />
                                                    {this.validator.message('pincode', this.state.pincode, 'alpha_num|max:7')}
                                                </div>
                                            </div>

                                            <div className="row form-group">
                                                <div className="col-xs-3">
                                                    <ControlLabel>Is TRN applicable: &nbsp;&nbsp;</ControlLabel>
                                                    <input type="checkbox" id='is_trn_applicable' name="is_trn_applicable" value={this.state.is_trn_applicable} onChange={this.handleChange} checked={this.state.trn_checked} />
                                                    <label htmlFor='is_trn_applicable'>&nbsp;&nbsp;Yes</label>
                                                </div>
                                            </div>

                                            {HELPER.hasRole(this.props.admin, ['super-admin', 'admin', 'business-development-head']) && (<>
                                                <h4>Commission Details</h4>
                                                <hr />
                                                <EditCommission
                                                    no_commission={this.state.no_commission}
                                                    commission_type={this.state.commission_type}
                                                    commission_rate={this.state.commission_rate}
                                                    currency={this.state.currency}
                                                    handleCommissionChange={this.handleCommissionChange}
                                                    validator={this.validator}
                                                />
                                            </>)}

                                            <FormGroup className="row" controlId="">
                                                <Col sm={4} md={4}>
                                                    <ButtonToolbar>
                                                        <Button bsStyle="primary" type='submit'>Submit</Button>
                                                        <button type="reset" className="btn btn-btn btn-danger"><i className="fa fa-repeat"></i> Reset</button>
                                                    </ButtonToolbar>
                                                </Col>
                                            </FormGroup>
                                        </form>
                                    </div>
                                    <div id="locations" className="tab-pane fade">
                                        <h4>Location Details</h4>
                                        <hr />
                                        {this.state.location_details && <EditMultiLocation
                                            type='doctors'
                                            location_details={this.state.location_details}
                                            locationupdate={this.props.locationupdate}
                                            history={this.props.history}
                                            service_provider_id={this.state.service_provider_id} />}
                                    </div>
                                </div>
                            </div>
                        }
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    doctor: state.doctor,
    admin: state.auth.admin
});

const mapActionsToProps = ({
    fetchDoctor: DoctorAction.fetchdoctor,
    doctorupdate: DoctorAction.doctorupdate,
    locationupdate: DoctorAction.locationupdate,
});

export default connect(mapStateToProps, mapActionsToProps)(EditDoctorPage);
