import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class ActivityLogService {
    static fetchActivityLogs(service_provider_type, { page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText, filter)
        };

        return axios.get(apiRoute(`/v1/activity-logs/${service_provider_type}`), requestOptions);
    }
}
export default ActivityLogService