import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { capitalize } from 'lodash';
import PatientFinalReportDetails from "../../components/lims/PatientFinalReportDetails";
import { Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import ReferralService from "../../services/referralService";
import Notification from "../../../src/utils/notification";
import moment from "moment";
import * as loaderActions from '../../actions/loaderAction';
import { Box, Content } from '../../components/Elements';

class ReferralReportView extends Component {
    constructor(props) {
        super(props);
        const params = this.props.match.params;
        this.state = {
            formData: {
                name: "",
                phone: "",
                doctor: "",
                sample_collected_at: "",
                address: "",
                age: "",
                doctor_contact: "",
                lab_number: "",
                sample_collected_on: "",
                created_on: "",
            },
            testsData: [],
            testDetailsValues: [],
            id: params.id,
        };
        this.testDetailsRef = React.createRef();
    }

    componentDidMount() {
        const id = this.state.id;
        const today_date = moment().format("YYYY-MM-DD");
        this.props.loaderShow();

        ReferralService.getReferralById(id).then((resp) => {
            const refData = resp.data;
            let address = refData.address;
            address =
                (address.address_line_1 ? address.address_line_1 + ", " : "") +
                (address.address_line_2 ? address.address_line_2 + ", " : "") +
                (address.address_line_3 ? address.address_line_3 + ", " : "") +
                (address.parish ? address.parish + ", " : "") +
                (address.city ? address.city + ", " : "") +
                (address.pincode ? address.pincode : "");
            this.setState({
                formData: {
                    name: refData.name,
                    phone: refData.contact_number,
                    doctor: refData.doctor_name,
                    sample_collected_at: refData.sample_collected_at,
                    address: address,
                    age: refData.date_of_birth,//getAgeString(refData.date_of_birth, today_date),
                    patient_weight: refData.patient_weight,
                    patient_height: refData.patient_height,
                    patient_bmi: refData.patient_bmi,
                    doctor_contact: refData.doctor_contact_number,
                    sample_collected_on: moment(refData.sample_collected_on).format(
                        "YYYY-MM-DD"
                    ),
                    created_on: moment(refData.verified2.verified_at).format(
                        "YYYY-MM-DD"
                    ),
                },
                testsData: refData.referral_tests
            });
            this.props.loaderHide();
        }).catch((error) => {
            Notification.show("error", error);
            this.props.loaderHide();
        });
    }

    getReferenceRange = (lowerLimit = null, upperLimit = null) => {
        if (lowerLimit && upperLimit) {
            return `${lowerLimit} - ${upperLimit}`;
        } else {
            return "-";
        }
    };

    render() {
        const { testsData } = this.state;
        return (
            <React.Fragment>
                <Helmet>
                    <title>Referral Report</title>
                </Helmet>
                <Content title="Referral Report">
                    <Box>
                        <Box.Head><Link to="/referral-report" className="btn btn-info pull-right">Back</Link></Box.Head>
                        <Box.Body>
                            <PatientFinalReportDetails formData={this.state.formData} />
                            <br />
                            <Table className="test-details">
                                <thead>
                                    <tr>
                                        <th>Test Category</th>
                                        <th>Result</th>
                                        <th>Reference Range</th>
                                        <th>Flag</th>
                                        <th>Unit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {testsData &&
                                        testsData.map((row) => (
                                            <tr>
                                                <td>{row.lims_test.name}</td>
                                                <td>{row.lims_test.type == "select" ? capitalize(row.value) : row.value}</td>
                                                <td>{this.getReferenceRange(row.lims_test.lower_limit, row.lims_test.upper_limit)}</td>
                                                <td>{row.flag ? capitalize(row.flag) : ""}</td>
                                                <td>{row.lims_test.unit_of_measurement ? row.lims_test.unit_of_measurement : "-"}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </Table>
                        </Box.Body>
                    </Box>
                </Content>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    admin: state.auth.admin
});

const mapActionsToProps = {
    loaderShow: loaderActions.loaderShow,
    loaderHide: loaderActions.loaderHide,
};

export default connect(mapStateToProps, mapActionsToProps)(ReferralReportView);