import * as types from './actionTypes';
import CommissionService from '../services/commissionService';



export function resetList() {
    return dispatch => {
        dispatch({ type: types.LIST_COMMISSIONS_CLEAR});
    }
}

export function list({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: types.REQUEST_COMMISSIONS, request: { page, sizePerPage, sortBy, searchText, filter } });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        CommissionService.list({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                dispatch({ type: types.ALERT_CLEAR, isloading: false });
                let data = resp.data;
                dispatch({ type: types.LIST_COMMISSIONS, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}
