import React from 'react';
import { FormGroup, ControlLabel, FormControl, Col, Button, Alert } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import * as AdminChangePasswordAction from '../../actions/adminChangePasswordAction';
import SimpleReactValidator from 'simple-react-validator';
import { Box, Content } from '../../components/Elements';


class ChangePasswordPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            current_password: '',
            new_password: '',
            reset_password: '',
            err_msg: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.validator = new SimpleReactValidator({
            validators: {
                current_password: {
                    required: true
                },
                new_password: {
                    message: 'The password must contain atleast 1 uppercase letter, 1 special character and must be of 8 digits',
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/) && params.indexOf(val) === -1
                    },
                    required: true
                },
                confirm_password: {
                    rule: {
                        required: true
                    }

                }
            }
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        } else if (this.state.new_password === this.state.confirm_password) {
            this.setState({
                err_msg: ''
            });
            this.props.changePassword(this.state);
        } else {
            this.setState({
                err_msg: 'The new password and confirm password does not match'
            });
        }

    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    render() {

        const { alert } = this.props;

        return <div>
            <Helmet>
                <title>Change Password</title>
            </Helmet>
            <Content title="Admin User">

                <Box>
                    <Box.Head title="Change Password" />
                    <Box.Body>
                        {alert && alert.message &&
                            <Alert bsStyle={alert.type}>{alert.message}</Alert>
                        }
                        {this.state.err_msg &&
                            <Alert bsStyle='danger'>{this.state.err_msg}</Alert>
                        }

                        <form onSubmit={this.handleSubmit} >

                            <FormGroup className="row" controlId="old_password">
                                <Col sm={2}>
                                    <ControlLabel>Old Password: <span>*</span></ControlLabel>
                                </Col>
                                <Col sm={6}>
                                    <FormControl
                                        name='current_password'
                                        type="password"
                                        value={this.state.current_password}
                                        placeholder="Enter current password"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('current_password', this.state.current_password, 'required')}
                                </Col>
                            </FormGroup>

                            <FormGroup className="row input-tooltip" controlId="new_password">
                                <Col sm={2}>
                                    <ControlLabel>New Password: <span>*</span></ControlLabel>
                                </Col>
                                <Col sm={6}>
                                    <FormControl
                                        name='new_password'
                                        type="password"
                                        value={this.state.new_password}
                                        placeholder="Enter New password"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('new_password', this.state.new_password, 'required|new_password')}
                                </Col>
                                 <span className="tooltip-custom">
                                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                                    <span className="tooltiptext">
                                        Choose a password between 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.
                                    </span>
                                </span>
                            </FormGroup>

                            <FormGroup className="row" controlId="confirm_password">
                                <Col sm={2}>
                                    <ControlLabel>Confirm Password: <span>*</span></ControlLabel>
                                </Col>
                                <Col sm={6}>
                                    <FormControl
                                        name='confirm_password'
                                        type="password"
                                        value={this.state.confirm_password}
                                        placeholder="Confirm password"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('confirm_password', this.state.confirm_password, 'required')}
                                </Col>
                            </FormGroup>

                            <FormGroup className="row" controlId="">
                                <Col sm={6} md={6}>
                                    <Button type='submit' className='btn btn-primary'>Save</Button>
                                </Col>
                            </FormGroup>
                        </form>
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    alert: state.alert
});

const mapActionsToProps = ({
    changePassword: AdminChangePasswordAction.changePassword
});

export default connect(mapStateToProps, mapActionsToProps)(ChangePasswordPage);