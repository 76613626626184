import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { FormGroup, ControlLabel, FormControl, Col, Button } from 'react-bootstrap';
import Alert from '../../components/Alert';
import * as AdminUserAction from '../../actions/adminuserActions';
import * as Authaction from '../../actions/authActions';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from 'react-helmet';
import * as CONFIG from '../../utils/config';
import RoleManagementService from '../../services/roleManagementService';
import { personNameRegex } from '../../utils/helpers'

class AddAdminUserPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            contact_number: '',
            trn_number: '',
            gender: '',
            name_title: '',
            date_of_birth: '',
            roles: '',
            assigned_role: ''
        };

        this.validator = new SimpleReactValidator({
            validators: {
                birthdate: {
                    message: 'The date of birth must be less than todays date',
                    rule: (val, params) => {
                        return (new Date(val) > new Date(params) ? false : true)
                    },
                    required: true
                }
            }
        });

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
    }

    componentDidMount() {
        this.props.navaction("user_management", "admins");
        RoleManagementService.getRoleName().then(resp => {
            this.setState({
                roles: resp.data.data
            })
        }).catch(e => {
            console.log()
        })
    }


    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleRadioChange = (event) => {
        this.setState({
            gender: event.currentTarget.value
        })
    };

    handleSubmit(e) {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        const postData = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            contact_number: this.state.contact_number,
            gender: this.state.gender,
            date_of_birth: this.state.date_of_birth,
            name_title: this.state.name_title,
            trn_number: this.state.trn_number,
            assigned_role: this.state.assigned_role,
        };
        this.props.adminuseradd(postData, this.props.history);
    }

    resetForm() {
        this.setState({
            first_name: '',
            last_name: '',
            email: '',
            contact_number: '',
            trn_number: '',
            name_title: '',
            gender: '',
        });
    }

    render() {
        return <div>
            <Helmet>
                <title>Add Admin User</title>
            </Helmet>
            <Content title="Add Admin User">
                <Box>
                    <Box.Head title="Add Admin User Form" backTo="/admins"></Box.Head>
                    <form onSubmit={this.handleSubmit} >
                        <Box.Body>
                            <Alert />

                            <FormGroup className="row" controlId="title">
                                <Col sm={2}>
                                    <ControlLabel>Select Name Title: <span>*</span></ControlLabel>
                                </Col>
                                <Col sm={6}>
                                    <FormControl name="name_title" componentClass="select" value={this.state.name_title} onChange={this.handleChange}>
                                        <option value="">Select Title</option>
                                        {CONFIG.NAMETITLE.map((value, index) => {
                                            return <option value={value} key={index}>{value}</option>
                                        })}
                                    </FormControl>
                                    {this.validator.message('name_title', this.state.name_title, 'required')}
                                </Col>
                            </FormGroup>

                            <FormGroup className="row" controlId="fname">
                                <Col sm={2}>
                                    <ControlLabel>First Name: <span>*</span></ControlLabel>
                                </Col>
                                <Col sm={6}>
                                    <FormControl
                                        name='first_name'
                                        type="text"
                                        value={this.state.first_name}
                                        placeholder="Enter first name"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('first_name', this.state.first_name, ['required', 'string', { regex: personNameRegex() }])}
                                </Col>
                            </FormGroup>

                            <FormGroup className="row" controlId="lname">
                                <Col sm={2}>
                                    <ControlLabel>Last Name: <span>*</span></ControlLabel>
                                </Col>
                                <Col sm={6}>
                                    <FormControl
                                        name='last_name'
                                        type="text"
                                        value={this.state.last_name}
                                        placeholder="Enter last name"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('last_name', this.state.last_name, ['required', 'string', { regex: personNameRegex() }])}
                                </Col>
                            </FormGroup>

                            <FormGroup className="row" controlId="email">
                                <Col sm={2}>
                                    <ControlLabel>Email: <span>*</span></ControlLabel>
                                </Col>
                                <Col sm={6}>
                                    <FormControl
                                        name='email'
                                        type="email"
                                        value={this.state.email}
                                        placeholder="Enter email"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('email', this.state.email, 'required')}
                                </Col>
                            </FormGroup>

                            <FormGroup className="row" controlId="">
                                <Col sm={2}>
                                    <ControlLabel>Contact Number: </ControlLabel>
                                </Col>
                                <Col sm={6}>
                                    <FormControl
                                        name='contact_number'
                                        type="number"
                                        value={this.state.contact_number}
                                        placeholder="Enter contact number"
                                        onChange={this.handleChange}
                                    />
                                    {this.validator.message('contact_number', this.state.contact_number, 'phone')}
                                </Col>
                            </FormGroup>

                            <FormGroup className="row" controlId="">
                                <Col sm={2}>
                                    <ControlLabel>Gender: <span>*</span></ControlLabel>
                                </Col>
                                <Col sm={6}>
                                    <FormControl
                                        name='gender'
                                        type="text"
                                        value={this.state.gender}
                                        placeholder=''
                                        onChange={this.handleChange}
                                        componentClass="select"
                                    >
                                        <option>Select Gender</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="other">Other</option>
                                    </FormControl>
                                    {this.validator.message('gender', this.state.gender, 'required')}
                                </Col>
                            </FormGroup>

                            <FormGroup className="row" controlId="">
                                <Col sm={2}>
                                    <ControlLabel>Date Of Birth: <span>*</span></ControlLabel>
                                </Col>
                                <Col sm={6}>
                                    <FormControl
                                        name='date_of_birth'
                                        type="date"
                                        value={this.state.date_of_birth}
                                        placeholder=''
                                        min='1800-01-01'
                                        max="2999-12-31"
                                        onChange={this.handleChange}
                                    />
                                    {this.validator.message('date_of_birth', this.state.date_of_birth, 'birthdate:' + new Date())}
                                </Col>
                            </FormGroup>

                            <FormGroup className="row" controlId="">
                                <Col sm={2}>
                                    <ControlLabel>TRN Number: *</ControlLabel>
                                </Col>
                                <Col sm={6}>
                                    <FormControl
                                        name='trn_number'
                                        type="text"
                                        value={this.state.trn_number}
                                        placeholder="Enter your TRN Number"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('trn_number', this.state.trn_number, 'required')}
                                </Col>
                            </FormGroup>

                            <FormGroup className="row" controlId="title">
                                <Col sm={2}>
                                    <ControlLabel>Select Role: <span>*</span></ControlLabel>
                                </Col>
                                <Col sm={6}>
                                    <FormControl name="assigned_role" componentClass="select" value={this.state.assigned_role} onChange={this.handleChange}>
                                        <option value="" disabled defaultValue>Select Role</option>
                                        {this.state.roles && this.state.roles.map((role, index) => (
                                            <option value={role.role_id} key={index}>{role.display_name}</option>
                                        ))}
                                    </FormControl>
                                    {this.validator.message('role', this.state.assigned_role, 'required')}
                                </Col>
                            </FormGroup>
                        </Box.Body>
                        <Box.Foot>
                            <Button type='submit' bsStyle="primary">Save Admin User</Button>
                        </Box.Foot>
                    </form>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    admin_user: state.admin_user,
    admin: state.auth.admin
});

const mapActionsToProps = ({
    adminuseradd: AdminUserAction.adminuseradd,
    navaction: Authaction.navaction
});

export default connect(mapStateToProps, mapActionsToProps)(AddAdminUserPage);
