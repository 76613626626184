import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import * as RadiologyTestAction from '../../actions/radiologyTestActions';
import { Box, Content } from '../../components/Elements';
import Alert from '../../components/Alert';

class ViewRadiologyTestPage extends React.Component {

    constructor(props) {
        super(props);

        const { match: { params } } = this.props;
        const id = params.id;

        this.props.fetchRadiologyTest(id);
    }

    render() {
        const { radiologyTest } = this.props;
        return <React.Fragment>
            <Helmet>
                <title>View Radiology Test</title>
            </Helmet>
            <Content title="View Radiology Test">
                <Box>
                    <Box.Head title="View Radiology Test"><Link to="/radiology/tests" className="btn btn-info pull-right">Back</Link></Box.Head>
                    <Box.Body>
                        <Alert />
                        {<Table responsive striped>
                            <tbody>
                                <tr>
                                    <th>Test Name</th>
                                    <td>{radiologyTest.test_name}</td>
                                </tr>
                                <tr>
                                    <th>Test category</th>
                                    <td>{radiologyTest.test_category ? radiologyTest.test_category : '-'}</td>
                                </tr>
                                <tr>
                                    <th>Description</th>
                                    <td><p>{radiologyTest.test_description}</p></td>
                                </tr>
                                <tr>
                                    <th>Price</th>
                                    <td>{radiologyTest.price && radiologyTest.price.price}</td>
                                </tr>
                                <tr>
                                    <th>Status</th>
                                    <td>{radiologyTest.test_status && radiologyTest.test_status === 1 ? 'Active' : 'Inactive'}</td>
                                </tr>
                            </tbody>
                        </Table>}
                    </Box.Body>
                </Box>
            </Content>
        </React.Fragment>;
    }
}

const mapStateToProps = (state) => ({
    radiologyTest: state.radiologyTest
});

const mapActionsToProps = ({
    fetchRadiologyTest: RadiologyTestAction.fetchRadiologyTest
});

export default connect(mapStateToProps, mapActionsToProps)(ViewRadiologyTestPage);