import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { FormGroup, FormControl, ControlLabel, Col, Button, Checkbox } from 'react-bootstrap';
import * as RadiologyTestAction from '../../actions/radiologyTestActions';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from 'react-helmet';
import Alert from '../../components/Alert';
import _ from 'lodash';
import { RADIOLOGY_TEST_CATEGORIES } from '../../utils/config';
import * as HELPER from "../../utils/helpers";

class RadiologyTestPricePage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            test_category: '',
            testData: null,
            flag: 'true',
            editCount: 0
        };

        this.validator = new SimpleReactValidator({
            validators: {
                futureStartDate: {
                    message: 'The future start date must be greater than today date',
                    rule: (val, params) => {
                        return (new Date(params) > new Date()) ? true : false;
                    },
                    required: true
                }
            }
        });

        this.handleChange = this.handleChange.bind(this);
        this.onChange = this.onChange.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (state.flag === 'true') {
            return {
                flag: 'update'
            };
        } else if (state.flag === 'update') {
            return {
                testData: props.radiologytest_list.data.map(value => {
                    return {
                        is_update: 'no',
                        test_id: value.id,
                        test_category: value.test_category ? value.test_category : '-',
                        test_name: value.test_name,
                        price: value.price ? value.price.price : '',
                        future_price: value.future_price ? value.future_price.price : '',
                        future_start_date: value.future_price ? value.future_price.start_date : '',
                    }
                }),
                flag: 'updated'
            };
        } else {
            return state;
        }

    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            flag: 'true',
            [name]: value,
        });

        this.props.fetchAllRadiologyTest(value);

    }

    onChange(index, e) {

        const target = e.target;
        const value = target.value;
        const name = target.name;

        let tests = this.state.testData;

        tests[index][name] = value;

        this.setState({
            testData: tests
        });
    }

    handleCheckbox = (index, evt) => {

        const target = evt.target;
        const name = target.name;
        let checked = target.checked;

        let tests = this.state.testData;
        let editCount = this.state.editCount;

        tests[index][name] = (checked) ? 'yes' : 'no';
        editCount = (checked) ? ++editCount : --editCount;

        this.setState({
            testData: tests,
            editCount: editCount
        });

    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        let postData = {
            radiology_test_prices: _.compact(this.state.testData.map(value => {

                if (value.is_update === 'yes') {
                    return {
                        test_id: value.test_id,
                        future_price: value.future_price,
                        future_start_date: value.future_start_date,
                    }
                } else {
                    return false;
                }
            })
            )
        };

        this.props.updateRadiologyTestPrice(postData, this.props.history);
    }

    handleCheckbox = (index, evt) => {

        const target = evt.target;
        const name = target.name;
        let checked = target.checked;

        let tests = this.state.testData;
        let editCount = this.state.editCount;

        tests[index][name] = (checked) ? 'yes' : 'no';
        editCount = (checked) ? ++editCount : --editCount;

        this.setState({
            testsData: tests,
            editCount: editCount
        });

    }

    render() {
        return <div>
            <Helmet>
                <title>Radiology Test Price</title>
            </Helmet>
            <Content title="Radiology Test Price">
                <Box>
                    <Box.Head title="Radiology Test Price Form"></Box.Head>
                    <Box.Body>
                        <Alert />
                        <form onSubmit={this.handleSearch} >

                            <FormGroup controlId="">
                                <Col sm={2}>
                                    <ControlLabel>Test category: <span>*</span></ControlLabel>
                                </Col>
                                <Col sm={6}>
                                    <FormControl name="test_category" componentClass="select" value={this.state.test_category} onChange={this.handleChange} placeholder="Select Test category">
                                        <option value="" disabled={true}>select test category</option>
                                        {RADIOLOGY_TEST_CATEGORIES.map((value) => {
                                            return <option key={value} value={value}>{value}</option>
                                        })}
                                    </FormControl>
                                    {this.state.test_type === 'Test' ? this.validator.message('test_category', this.state.test_category, 'required') : ''}
                                </Col>
                            </FormGroup>
                        </form>
                    </Box.Body>
                </Box>
                {this.state.testData && <Box box="solid">
                    <Box.Body>
                        <form onSubmit={this.handleSubmit}>
                            <table className="table">
                                <thead>
                                    <tr>
                                        {HELPER.hasPermission(this.props.admin, ['radiology-test-price-edit']) && <th>Edit</th>}
                                        <th>Test Name</th>
                                        <th>Test category</th>
                                        <th>Test Current Price</th>
                                        <th>New Price</th>
                                        <th>New Start Date</th>
                                    </tr></thead>
                                <tbody>
                                    {this.state.testData && this.state.testData.map((data, index) => (
                                        <tr>
                                            {HELPER.hasPermission(this.props.admin, ['radiology-test-price-edit']) && <td>
                                                <FormGroup>
                                                    <Checkbox name="is_update" onChange={(evt) => this.handleCheckbox(index, evt)}></Checkbox>
                                                </FormGroup>
                                            </td>}
                                            <td>
                                                {data.test_name ? data.test_name : ''}
                                            </td>
                                            <td>
                                                {data.test_category ? data.test_category : ''}
                                            </td>
                                            <td>
                                                {data.price}
                                            </td>
                                            <td>
                                                <FormControl
                                                    name='future_price'
                                                    type="number"
                                                    value={data.future_price}
                                                    autoComplete="none"
                                                    onChange={(e) => this.onChange(index, e)}
                                                    disabled={data.is_update === 'no' ? true : false}
                                                />
                                                {data.is_update === 'yes' && this.validator.message('future_price', data.future_price, 'required|numeric')}
                                            </td>
                                            <td>
                                                <FormControl
                                                    name='future_start_date'
                                                    type="date"
                                                    value={data.future_start_date}
                                                    autoComplete="none"
                                                    min={new Date(Date.now() + 1 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10)}
                                                    max="2999-12-31"
                                                    onChange={(e) => this.onChange(index, e)}
                                                    disabled={data.is_update === 'no' ? true : false}
                                                />
                                                {data.is_update === 'yes' && this.validator.message('future_start_date', data.future_start_date, 'futureStartDate:' + new Date(data.future_start_date))}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {!_.isNull(this.state.testData) && _.isEmpty(this.state.testData) &&
                                <div className="alert alert-warning"> No Radiology Test Found</div>
                            }
                            <div>
                                <Button className="btn btn-primary" disabled={(this.state.editCount > 0) ? false : true} type='submit'>Save</Button>
                            </div>

                        </form>

                    </Box.Body>
                </Box>}
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    radiologytest_list: state.radiologytest_list,
    alert: state.alert,
    admin: state.auth.admin
});

const mapActionsToProps = ({
    fetchAllRadiologyTest: RadiologyTestAction.fetchAllRadiologyTest,
    updateRadiologyTestPrice: RadiologyTestAction.updateRadiologyTestPrice
});

export default connect(mapStateToProps, mapActionsToProps)(RadiologyTestPricePage);