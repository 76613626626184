import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function generic_drugs(state = initialState.drugs, { type, ...payload }) {
    switch (type) {
        case types.ADD_GENERIC_DRUG:
            return {
                ...state,
                ...payload
            };
        case types.DELETE_GENERIC_DRUG:
            return {
                ...state,
                ...payload
            };
        case types.FETCH_GENERIC_DRUG:
            return {
                ...state,
                ...payload
            };
        case types.UPDATE_GENERIC_DRUG:
            return {
                ...state,
                ...payload
            }; 
        default:
            return state;
    }
}