import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function commissions(state = initialState.pagination, { type, ...payload }) {
    switch (type) {
        case types.LIST_COMMISSIONS:
            return {
                ...state,
                ...payload
            };
        case types.LIST_COMMISSIONS_CLEAR:
            return {
                ...state,
                data: {}
            };
        default:
            return state;
    }
}
