import React, { Component } from 'react'

export class Footer extends Component {
    render() {
        return (
            <footer className="main-footer">
                <strong>Copyright 2019-{new Date().getFullYear()} Daisy-Health.</strong>  All Rights Reserved.
            </footer>
        )
    }
}

export default Footer
