import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { FormGroup, ControlLabel, FormControl, Col, Grid } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from 'react-helmet';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import Alert from '../../components/Alert';
import * as LoaderAction from '../../actions/loaderAction';
import Notification from "../../utils/notification";
import IcdService from '../../services/icdService';

class AddIcdPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            icd_code: '',
            short_description: '',
            validity: '',
            full_description: '',
            status: '',
        };
        this.validator = new SimpleReactValidator();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        const data = {
            icd_code: this.state.icd_code,
            short_description: this.state.short_description ? this.state.short_description : null,
            validity: this.state.validity ? this.state.validity : null,
            full_description: this.state.full_description ? this.state.full_description : null,
            status: this.state.status ? this.state.status : null,
        }

        this.props.showLoader();
        IcdService.create(data).then(resp => {
            this.props.hideLoader();
            if (resp.data.icd_code) {
                this.props.history.push('/icds/' + resp.data.icd_code)
            }
        }).catch(error => {
            Notification.show('error', error.response.data)
            this.props.hideLoader();
        })

    }

    render() {
        return <div>
            <Helmet>
                <title>Add ICD</title>
            </Helmet>
            <Content title="Add ICD">

                <Box>
                    <Box.Head title="Add ICD" backTo="/icds" />
                    <Box.Body>
                        <Alert />
                        <Grid>
                            <form onSubmit={this.handleSubmit}>
                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>ICD Code: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='icd_code'
                                            type="text"
                                            value={this.state.icd_code}
                                            placeholder="Enter ICD code"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('icd_code', this.state.icd_code, 'required|alpha_num')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Short Description: </ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='short_description'
                                            componentClass="textarea"
                                            value={this.state.short_description}
                                            placeholder="Enter short description"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('short_description', this.state.short_description, 'string')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Validity: </ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='validity'
                                            componentClass="textarea"
                                            value={this.state.validity}
                                            placeholder="Enter Validity"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('validity', this.state.validity, 'required|string')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Full Description: </ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='full_description'
                                            componentClass="textarea"
                                            value={this.state.full_description}
                                            placeholder="Enter full description"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('full_description', this.state.full_description, 'string')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Status: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='status'
                                            type="text"
                                            value={this.state.status}
                                            placeholder="Enter status"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('status', this.state.status, 'required|number')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={6} md={6}>
                                        <button type='submit' className='btn btn-primary'>Add</button>
                                    </Col>
                                </FormGroup>
                            </form>
                        </Grid>
                    </Box.Body>
                </Box>
            </Content>
        </div >;
    }
}

const mapStateToProps = (state) => ({
});

const mapActionsToProps = ({
    showLoader: LoaderAction.loaderShow,
    hideLoader: LoaderAction.loaderHide,
})

export default connect(mapStateToProps, mapActionsToProps)(AddIcdPage);
