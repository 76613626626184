import React from 'react';
import { Box } from './Elements';
import * as HELPER from "../utils/helpers";
import { Col, FormGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class PathologyInfo extends React.Component {
    render() {
        const { data } = this.props
        return (
            <Box>
                <Box.Head title="Pathology's Quick Info"> <Link to={'/pathologies/' + data.service_provider_id} className="pull-right"> View Profile </Link> </Box.Head>
                <Box.Body>
                    <FormGroup>
                        <Col sm={4}>
                            <div><b>Pathology Name : </b> {data.service_provider_name}</div>
                        </Col>
                        <Col sm={4} >
                            <div><b>Contact Number : </b>{data.user.contact_number}</div>
                        </Col>
                        <Col sm={4}>
                            <div><b>Address : </b>{HELPER.fullAddressStr(data.billing_address)}</div>
                        </Col>
                        <Col sm={4}>
                            <div><b>Onboard Date : </b>{HELPER.getDateFormat(data.created_at)}</div>
                        </Col>
                        <Col sm={4}>
                            <div><b>Email : </b>{data.user.email}</div>
                        </Col>
                    </FormGroup>
                </Box.Body>
            </Box>
        )
    }
}
export default PathologyInfo