import React from 'react';
import { FormControl, Col } from 'react-bootstrap';
import Select from 'react-select';

class NDCBlock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            drug_coverage: '',
            drugcoverage_data: [
                { value: 'jadep', label: 'JADEP' },
                { value: 'nhf', label: 'NHF' },
                { value: 'goj', label: 'GOJ' }
            ]
        }
    }

    _handleChange = (evt) => {
        const { ndc } = this.props;

        let fieldName = evt.target.name;
        let fieldValue = evt.target.value;

        ndc[fieldName] = fieldValue;

        this.props.handleChangeNDC(this.props.idx, ndc);
    }

    _handleSelectChange = (e) => {
        let value = e;
        this.props.handleDrugCoverageChange(this.props.idx, value);
    }

    render() {
        return (
            <div className='row' data-id={this.props.idx}>
                <Col sm={2}>
                    <FormControl
                        name='ndc_code'
                        type="text"
                        value={this.props.ndc.ndc_code}
                        placeholder="NDC Code"
                        onChange={this._handleChange}
                        autoComplete="none"
                    />
                </Col>
                <Col sm={2}>
                    <FormControl
                        name='package_size'
                        type="text"
                        value={this.props.ndc.package_size}
                        placeholder="Package Size"
                        onChange={this._handleChange}
                        autoComplete="none"
                    />
                    {this.props.validator.message('package_size', this.props.ndc.package_size, 'required|numeric|min:1,num|max:9999,num')}
                </Col>
                <Col xs={2}>
                    <FormControl
                        name='min_sell_quantity'
                        type="text"
                        value={this.props.ndc.min_sell_quantity}
                        placeholder="Min. Sale Quantity"
                        onChange={this._handleChange}
                        autoComplete="none"
                    />
                    {this.props.validator.message('min_sell_quantity', this.props.ndc.min_sell_quantity, 'required|numeric|min:1,num|max:9999,num')}
                </Col>
                <Col sm={2}>
                    <FormControl
                        name='manufacturer_name'
                        type="text"
                        value={this.props.ndc.manufacturer_name}
                        placeholder="manufacturer name"
                        onChange={this._handleChange}
                        autoComplete="none"
                    />
                    {this.props.validator.message('manufacturer_name', this.props.ndc.manufacturer_name, 'alpha_num_dash_space')}
                </Col>
                {!this.props.ndc.id && <Col sm={2}>
                    <FormControl
                        name='price'
                        type="number"
                        value={this.props.ndc.price}
                        placeholder="Price"
                        onChange={this._handleChange}
                        autoComplete="none"
                    />
                    {this.props.validator.message('price', this.props.ndc.price, 'required|numeric')}
                </Col>}
                <Col sm={2} className="insurance_sec">
                    <Select
                        value={this.props.ndc.drug_coverage}
                        isMulti={true}
                        onChange={this._handleSelectChange}
                        options={this.state.drugcoverage_data}
                    />
                </Col>
                <Col sm={2}>
                    {(this.props.idx > 0) ? <button className='btn btn-danger' data-id={this.props.idx} onClick={this.props.handleRemoveNDC}> - </button> : ''}
                </Col>
            </div>
        );
    }
}

export default NDCBlock;
