import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function orders(state = initialState.pagination, { type, ...payload }) {
    switch (type) {
        case types.PRESCRIPTION_ORDERS:
            return {
                ...state,
                ...payload
            };
        default:
            return state;
    }
}