import React from 'react';
import Owner from './Owner';
import { Button, ButtonToolbar, Well } from 'react-bootstrap';
import OwnerUserService from "../../services/ownerUserService";
import Notification from "../../utils/notification";
import SimpleReactValidator from 'simple-react-validator';
import { Link } from "react-router-dom";
import { includes } from 'lodash';

class EditMultiOwner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            owners: ''
        }
        this.ownerValidator = new SimpleReactValidator({
            validators: {
                birthdate: {
                    message: 'The date of birth must be less than todays date',
                    rule: (val, params) => {
                        return (new Date(val) > new Date(params) ? false : true)
                    },
                    required: true
                }
            }
        });
    }

    componentDidMount() {
        this.setState({ owners: this.props.owners })
    }

    handleOwnerChange = (event, idx) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        const owners = this.state.owners.map((owner, sidx) => {
            if (idx !== sidx) return owner;
            let gender = ['male', 'female']
            if (includes(gender, value)) {
                owner['gender'] = event.currentTarget.value;
            } else
                owner[name] = value;
            return owner;
        });
        this.setState({ owners: owners });
    }

    addMoreOwnerBlock = () => {
        this.setState({
            owners: this.state.owners.concat([{
                user_profile_id: "",
                user_id: "",
                name_title: "",
                first_name: "",
                last_name: "",
                email: "",
                contact_number: "",
                alt_contact_number: "",
                gender: "",
                date_of_birth: "",
                license_number: "",
                is_active: ""
            }])
        })
    }
    updateOwnerBlock = (idx, userId = null, status, user_profile_id) => {
        let status_val = status === true ? 'deactivate' : 'activate';
        let confirm = window.confirm("Are you sure you want to " + status_val + " it?");

        if (confirm === true) {
            if (userId !== null) {

                OwnerUserService.updateOwnerProfileStatus(this.props.serviceProviderId, userId, user_profile_id)
                    .then(resp => {
                        let data = resp.data;
                        let ownersData = this.state.owners;
                        ownersData[idx] = data;
                        this.setState({ owners: ownersData });
                        Notification.show("success", { message: "owner " + status_val + "d successfully" });
                    })
                    .catch(error => {
                        if (error.response && error.response.status) {
                            let errorData = error.response.data;
                            Notification.show("error", errorData);
                        } else {
                            Notification.show('error', { message: "Something Went Wrong, Try Again" });
                        }
                    });
            }
        }
    }

    removeOwnerBlock = (idx, _locationId = null) => {
        let owners = this.state.owners;
        owners.splice(idx, 1);
        this.setState({ owners: owners });
    }

    validateOwnersSubmit = (e) => {
        e.preventDefault();
        if (!this.ownerValidator.allValid()) {
            this.ownerValidator.showMessages();
            this.forceUpdate();
            return false;
        }

        const postData = {
            owners: this.state.owners
        }
        this.props.showLoader();
        OwnerUserService.updateOwnerUsers(this.props.serviceProviderId, postData)
            .then(_resp => {
                this.props.hideLoader();
                Notification.show("success", { message: "Owner details has been updated successfully." });
                this.props.history.push('/' + this.props.type + '/' + this.props.serviceProviderId)
            })
            .catch(error => {
                this.props.hideLoader();
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    Notification.show("error", errorData);
                } else {
                    Notification.show('error', { message: "Something Went Wrong, Try Again" });
                }
            });
    }
    render() {
        const { owners } = this.state;
        return (
            owners ? <React.Fragment>
                <Well bsSize="small">
                    {this.state.owners && this.state.owners.map((owner, idx) => (
                        <div className="row form-group" id={"owners_div" + idx}>
                            <div className="col-xs-10">
                                <Owner key={owner.user_profile_id}
                                    idx={idx}
                                    owner={owner}
                                    handleOwnerChange={this.handleOwnerChange}
                                    validator={this.ownerValidator} />
                            </div>
                            <div className="col-xs-2">
                                {!(owner.user_id) &&
                                    <Link className="btn btn-link" onClick={this.removeOwnerBlock.bind(this, idx, owner.user_id, owner.is_active)} id={idx}>
                                        <span className="toggle-btn"><i className="fa fa-close" aria-hidden="true"></i></span>
                                    </Link>
                                }
                                {owner.user_id &&
                                    <Link className="btn btn-link" onClick={this.updateOwnerBlock.bind(this, idx, owner.user_id, owner.profile_status, owner.user_profile_id)} id={idx}>
                                        <span className="toggle-btn"><i className={owner.profile_status === true ? 'fa fa-toggle-on' : 'fa fa-toggle-off'} aria-hidden="true" ></i></span>
                                    </Link>
                                }
                            </div>
                        </div>
                    ))}
                </Well>
                {<ButtonToolbar>
                    {!this.props.hideAddMore && <Button bsStyle="success" bsSize="xsmall" onClick={this.addMoreOwnerBlock} className="pull-right"><i className="fa fa-plus"></i> Add More</Button>}
                    <Button bsStyle="info" bsSize="xsmall" className="pull-right" type="button" onClick={this.validateOwnersSubmit}><i className="fa fa-check"></i> Submit</Button>
                </ButtonToolbar>}
            </React.Fragment> : null
        )
    }
}
export default EditMultiOwner