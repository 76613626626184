import React from 'react';
import CountBox from './CountBox';
import { Box } from '../../components/Elements';
import DashboardService from '../../services/dashboardService';

class OnboardProviderCount extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            doctor_count: '...',
            pharmacy_count: '...',
            pathology_count: '...',
            radiology_count: '...',
            ambulance_count: '...',
            hospital_count: '...',
            associate_doctor_count: '...'
        }
    }

    componentDidMount() {
        DashboardService.getOnboardProviderCount().then(res => {
            this.setState({
                doctor_count: res.data.doctor_count,
                pharmacy_count: res.data.pharmacy_count,
                pathology_count: res.data.pathology_count,
                radiology_count: res.data.radiology_count,
                ambulance_count: res.data.ambulance_count,
                hospital_count: res.data.hospital_count,
                associate_doctor_count: res.data.associate_doctor_count
            });
        });
    }

    render() {
        return (
            <Box box={"solid box-success"}>
                <Box.Head title="Number Of Onboarded Users" />
                <Box.Body>
                    <div className="row">
                        <div className="col-sm-2">
                            <CountBox title="Doctor" count={this.state.doctor_count} />
                        </div>
                        <div className="col-sm-2 custom-width-initial">
                            <CountBox title="Associate Doctor" count={this.state.associate_doctor_count} />
                        </div>
                        <div className="col-sm-2">
                            <CountBox title="Pharmacy" count={this.state.pharmacy_count} />
                        </div>
                        <div className="col-sm-2">
                            <CountBox title="Pathology" count={this.state.pathology_count} />
                        </div>
                        <div className="col-sm-2">
                            <CountBox title="Radiology" count={this.state.radiology_count} />
                        </div>
                        <div className="col-sm-2">
                            <CountBox title="Ambulance" count={this.state.ambulance_count} />
                        </div>
                        <div className="col-sm-2">
                            <CountBox title="Hospital" count={this.state.hospital_count} />
                        </div>
                    </div>
                </Box.Body>
            </Box>
        );
    }
}

export default OnboardProviderCount;
