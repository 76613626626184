import axios from 'axios';
import { apiRoute, getApiHeader } from '../utils/helpers';

class LocationService {
    static updateLocation(serviceProviderId, postData) {
        let paramsdata = JSON.stringify(postData);

        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.put(apiRoute('/v1/service_providers/' + serviceProviderId + '/locations'), paramsdata, requestOptions);

    }

    static updateLocationStatus(serviceProviderId, locationId) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/service_providers/' + serviceProviderId + '/location-status/' + locationId), requestOptions);
    }

    static getCovidCenterServiceProvider() {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/service-providers/covid-center'), requestOptions);
    }

    static uploadLogo(file) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute(`/v1/upload/logos`), file, requestOptions);
    }

    static getLocationByServiceProviderId(service_provider_id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute(`/v1/service-providers/${service_provider_id}/locations`), requestOptions);
    }

    static getPathologyServiceProvider() {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/service-providers/pathology'), requestOptions);
    }
}

export default LocationService;