import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import * as PathologyTestAction from '../../actions/pathologyTestActions';
import { Box, Content } from '../../components/Elements';
import Alert from '../../components/Alert';

class ViewPathologyTestPage extends React.Component {

    constructor(props) {
        super(props);

        const { match: { params } } = this.props;
        const id = params.id;

        this.props.fetchPathologyTest(id);
    }

    render() {
        const { pathologyTest } = this.props;
        return <React.Fragment>
            <Helmet>
                <title>View Pathology Test</title>
            </Helmet>
            <Content title="View Pathology Test">
                <Box>
                    <Box.Head title="View Pathology Test"><Link to="/pathology/tests" className="btn btn-info pull-right">Back</Link></Box.Head>
                    <Box.Body>
                        <Alert />
                        {<Table responsive striped>
                            <tbody>
                                <tr>
                                    <th>Test Name</th>
                                    <td>{pathologyTest.test_name}</td>
                                </tr>
                                <tr>
                                    <th>Test Type</th>
                                    <td>{pathologyTest.test_type}</td>
                                </tr>
                                <tr>
                                    <th>Test category</th>
                                    <td>{pathologyTest.test_category ? pathologyTest.test_category : '-'}</td>
                                </tr>
                                <tr>
                                    <th>Description</th>
                                    <td><p>{pathologyTest.test_description}</p></td>
                                </tr>
                                <tr>
                                    <th>Price</th>
                                    <td>{pathologyTest.price && pathologyTest.price.price}</td>
                                </tr>
                                <tr>
                                    <th>Status</th>
                                    <td>{pathologyTest.test_status && pathologyTest.test_status === 1 ? 'Active' : 'Inactive'}</td>
                                </tr>
                            </tbody>
                        </Table>}
                    </Box.Body>
                </Box>
            </Content>
        </React.Fragment>;
    }
}

const mapStateToProps = (state) => ({
    pathologyTest: state.pathologyTest
});

const mapActionsToProps = ({
    fetchPathologyTest: PathologyTestAction.fetchPathologyTest
});

export default connect(mapStateToProps, mapActionsToProps)(ViewPathologyTestPage);