import React from 'react';
import { connect } from 'react-redux';
import * as authActions from '../actions/authActions';
import { withRouter } from 'react-router-dom';

class Header extends React.Component {

    onLogout(e) {
        e.preventDefault();
        this.props.logout(this.props.history);
    }

    render() {
        const { alert } = this.props;

        return (
            <React.Fragment>
            {alert && alert.isloading && <div className="loading">Loading</div>}
            <header className="main-header">
                <a className="logo">
                    <span className="logo-mini"><b>D</b>H</span>
                    <span className="logo-lg">Daisy Health</span>
                </a>
                <nav className="navbar navbar-static-top">
                    <div className="navbar-custom-menu">
                        <ul className="nav navbar-nav">
                            <li className="dropdown user user-menu">
                                <a href="/" className="dropdown-toggle" data-toggle="dropdown">
                                    <img src="/images/user.jpg" className="user-image" alt="User" />
                                    <span className="hidden-xs">{this.props.auth.admin ? this.props.auth.admin.name : ''}</span>
                                </a>
                                <ul className="dropdown-menu">
                                    <li className="user-body">
                                        <div className="row">
                                            <div className="col-xs-6 text-left">
                                                <a href="/changepassword" className="btn btn-default btn-flat">Change Password</a>
                                            </div>
                                            <div className="col-xs-6 text-right">
                                                <a href="/" onClick={this.onLogout.bind(this)} className="btn btn-default btn-flat">Sign out</a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
                </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    alert: state.alert
})

const mapActionsToProps = ({
    logout: authActions.logout,
})

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Header));
