import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../components/Elements';
import { FormGroup, FormControl, Col, Button, ControlLabel } from 'react-bootstrap';
import CardService from '../services/cardService';
import SimpleReactValidator from 'simple-react-validator';
import * as HELPER from '../utils/helpers';
import _ from "lodash";
import CardSwipe from 'react-card-swipe';
import Notification from '../utils/notification';

class AssignReIssueCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: this.props.patient,
            enable_submit: false,
        };

        CardSwipe.init({
            success: this.handleSwipeSuccess,
            debug: false
        });

        this.searchByCardBtn = React.createRef();
        this.validator = new SimpleReactValidator();
    }

    handleChange = e => {
        this.setState({[e.target.name]: e.target.value});
    }

    handleSwipeSuccess = (swipeData) => {
        this.setState({
            card: swipeData.line1
        });
        
        this.handleVerifyCard();
    }

    handleDebug = (e) => {
        if (e.target.value) {
            this.setState({
                card: e.target.value
            });

            this.handleVerifyCard();
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        let cardObj = HELPER.getCardObj(this.state.card);

        if (cardObj) {
            this.setState({
                card: cardObj.user_code
            });

            let postData = {
                request_id: this.state.data.request_id,
                user_code: cardObj.user_code,
                card_number: cardObj.card_number,
                hash: cardObj.hash,
                status: this.state.data.status
            };

            CardService.assignReIssueCard(postData)
                .then(resp => {
                    Notification.show("success", resp.data);
                    this.props.handleSubmit();
                })
                .catch(error => {
                    if (error.response && error.response.status) {
                        Notification.show("error", error.response.data);
                    }
                });
        } else {
            this.setState({
                card: ""
            });
            Notification.show("error", { message: "Invalid card." });
        }

    }

    handleVerifyCard = () => {
        let cardObj = HELPER.getCardObj(this.state.card);

        this.setState({
            user_code: cardObj.user_code,
            card_number: cardObj.card_number,
            hash: cardObj.hash,
            enable_submit: false,
            check_card_number: {
                status: 'default',
                message: 'checking...'
            }
        });

        CardService.verifyCard(cardObj)
        .then(resp => {
            let data = resp.data;

            this.setState({
                enable_submit: data.card_status === 'success' ? true : false,
                check_card_number: {
                    status: data.card_status === 'success' ? 'success' : 'danger',
                    message: data.message
                }
            });
        }).catch(error => {
            this.setState({
                enable_submit: false,
                check_card_number: {
                    status: 'info',
                    message: 'Try again!'
                }
            });
        });
    }

    render() {
        this.validator.purgeFields();
        return <div>
            <Content title="Assign Re-Issue Cards">
                <Box>
                    <Box.Body>
                        <form onSubmit={this.handleSubmit}>
                            <FormGroup className="row" controlId="">
                                <Col sm={3}>
                                    <ControlLabel>Patient Name: </ControlLabel>
                                </Col>
                                <Col sm={6}>
                                    <FormControl
                                        name='patient_name'
                                        type="text"
                                        value={this.state.data.patient_name}
                                        autoComplete="none"
                                        disabled
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row" controlId="">
                                <Col sm={3}>
                                    <ControlLabel>Service Provider Name: </ControlLabel>
                                </Col>
                                <Col sm={6}>
                                    <FormControl
                                        name='service_provider_name'
                                        type="text"
                                        value={this.state.data.service_provider_name}
                                        autoComplete="none"
                                        disabled
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row" controlId="">
                                <Col sm={3}>
                                    <ControlLabel>User Location Name: </ControlLabel>
                                </Col>
                                <Col sm={6}>
                                    <FormControl
                                        name='user_location_name'
                                        type="text"
                                        value={this.state.data.user_location_name}
                                        autoComplete="none"
                                        disabled
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row" controlId="">
                                <Col sm={3}>
                                    <ControlLabel>Status: </ControlLabel>
                                </Col>
                                <Col sm={6}>
                                    <FormControl
                                        name='status'
                                        type="text"
                                        value={_.capitalize(this.state.data.status)}
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                        placeholder="Swipe Card"
                                        disabled
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row" controlId="">
                                <Col sm={3}>
                                    <ControlLabel>Card: </ControlLabel>
                                </Col>
                                <Col sm={6}>
                                {HELPER.config('CARD_DEBUG') === 'false' ? 
                                <FormControl
                                    name='card'
                                    type="password"
                                    value={this.state.card}
                                    autoComplete="none"
                                    placeholder="Swipe Card"
                                    readOnly
                                />
                                :
                                <FormControl
                                    name='card'
                                    type="text"
                                    value={this.state.card}
                                    onChange={this.handleChange}
                                    autoComplete="none"
                                    placeholder="Swipe Card"
                                    onBlur={this.handleVerifyCard}
                                />}
                                {this.validator.message('card', this.state.card, 'required')}
                                {this.state.check_card_number && <small className={'text-' + this.state.check_card_number.status}>{this.state.check_card_number.message}</small>}
                                </Col>
                            </FormGroup>
                            <FormGroup className="row text-right" controlId="">
                                <Col sm={12}>
                                    <Button className="mr-2" type='submit' bsStyle='success' ref={this.searchByCardBtn} data-index="1" data-tooltip="Please swipe daisy health patient card here" disabled={this.state.enable_submit ? false : true}>Assign</Button>
                                    <Button onClick={this.props.handleCancelBtnClick}>Cancel</Button>
                                </Col>
                            </FormGroup>
                        </form>
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}


export default connect(null, null)(AssignReIssueCard);
