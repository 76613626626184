import * as types from './actionTypes';
import RadiologyService from '../services/radiologyService';
import LocationService from '../services/locationService';

export function list({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: types.RADIOLOGY_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText } });
        RadiologyService.list({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.RADIOLOGY_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function radiologyadd(statedata, history) {
    return dispatch => {
        dispatch({ type: types.ADD_RADIOLOGY });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        RadiologyService.radiologyadd(statedata, history)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.ADD_RADIOLOGY, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: 'Radiology added successfully' });
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                    history.push('/radiologies/' + data.service_provider_id);
                }, 2500);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function fetchradiology(serviceProviderId) {

    return dispatch => {
        RadiologyService.fetchradiology(serviceProviderId)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.FETCH_RADIOLOGY, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function locationupdate(serviceProviderId, statedata, history) {

    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR, isloading: true });
        LocationService.updateLocation(serviceProviderId, statedata)
            .then(resp => {
                dispatch({ type: types.ALERT_SUCCESS, message: 'Locations updated successfully' });
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                    history.push("/radiologies/" + serviceProviderId);
                }, 3000);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function radiologyupdate(serviceProviderId, statedata, history) {

    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR, isloading: true });
        RadiologyService.radiologyupdate(serviceProviderId, statedata)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.UPDATE_RADIOLOGY, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: 'Radiology updated successfully' });
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                    if (data.trail === true) {
                        history.push('/trial/account/view/' + data.service_provider_id);
                    } else {
                        history.push('/radiologies/' + data.service_provider_id);
                    }
                }, 3000);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function deleteradiology(serviceProviderId) {

    return dispatch => {
        RadiologyService.deleteradiology(serviceProviderId)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.DELETE_RADIOLOGY, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: 'Radiology deleted successfully' });
                setTimeout(() => { dispatch({ type: types.ALERT_CLEAR }); }, 2500);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}