import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { FormGroup, ControlLabel, FormControl, Col, Grid } from 'react-bootstrap';
import * as PathologyLimsTestAction from '../../actions/pathologyLimsTestActions';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from 'react-helmet';
import Alert from '../../components/Alert';
import { PATHOLOGY_LIMS_TEST_CATEGORIES } from '../../utils/config';
import PathologyLimsTestService from '../../services/pathologyLimsTestService';
import _ from 'lodash';


class AddPathologyLimsTestPage extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            name: '',
            category: '',
            lower_limit: '',
            upper_limit: '',
            unit_of_measurement: '',
            alternate_result: '',
            alternate_result_flag: '',
            type: '',
            status: '',
            activeCategory :[],
        };

        this.validator = new SimpleReactValidator();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
            PathologyLimsTestService.getAllPathologyCategory()
            .then(resp => {
                let data = resp.data.data;
                this.setState({
                    activeCategory: data
                })
            })
            .catch(error => {
               console.log(error);
               this.setState({ activeCategory: [] });
            });   
    }
   


    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        if (value === 'Profile') {
            this.setState({
                [name]: value,
                category: ''
            });
        } else {
            this.setState({
                [name]: value
            });
        }

    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        this.props.pathology_lims_test_add(this.state, this.props.history);
    }

    render() {
        const {activeCategory} = this.state;
        return <div>
            <Helmet>
                <title>Add Lims Test</title>
            </Helmet>
            <Content title="Add Lims Test">
                <Box>
                    <Box.Head title="" backTo="/lims-tests" />
                    <Box.Body>
                        <Alert />
                        <Grid>
                            <form onSubmit={this.handleSubmit}>
                            {this.state.activeCategory &&
                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Test Category <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                    <select className="form-control" value={this.state.category} id="category" name="category" onChange={this.handleChange}>
                                        <option value="">Select Category</option>
                                        {activeCategory.map((category) => (
                                            <option key={category.id} value={category.name}>
                                                {category.name}
                                            </option>
                                        ))}
                                        </select>
                                        {this.validator.message('category', this.state.category, 'required')}
                                    </Col>
                                </FormGroup>
                                }
                                
                          
                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Test Name <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='name'
                                            type="text"
                                            value={this.state.name}
                                            placeholder="Enter Test Name"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('name', this.state.name, 'required|string')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Lower Limit </ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='lower_limit'
                                            type="text"
                                            value={this.state.lower_limit}
                                            placeholder="Enter Lower Limit"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('lower limit', this.state.lower_limit, 'string')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Upper Limit </ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='upper_limit'
                                            type="text"
                                            value={this.state.upper_limit}
                                            placeholder="Enter Upper Limit"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('upper_limit', this.state.upper_limit, 'string')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Unit of Measurement</ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='unit_of_measurement'
                                            type="text"
                                            value={this.state.unit_of_measurement}
                                            placeholder="Enter Unit of Measurement"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('unit_of_measurement', this.state.unit_of_measurement, 'string')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Alternate Result</ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='alternate_result'
                                            type="text"
                                            value={this.state.alternate_result}
                                            placeholder="Enter Alternate Result"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                        <span className="small"> Note: Add Alternate Result Separated by "/" slash. Eg Positive/Negative</span>
                                        {this.validator.message('alternate_result', this.state.alternate_result, 'string')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Alternate Result Flag</ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='alternate_result_flag'
                                            type="text"
                                            value={this.state.alternate_result_flag}
                                            placeholder="Enter Alternate Result Flag"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('alternate_result_flag', this.state.alternate_result_flag, 'string')}
                                    </Col>
                                </FormGroup>

                                    <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Type: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl name="type" componentClass="select" value={this.state.type} onChange={this.handleChange} placeholder="Select Test Type">
                                            <option value="" disabled={true}>Select Type</option>
                                            <option value="text">Text</option>
                                            <option value="alpha-numeric">Alpha Numeric</option>
                                            <option value="select">Drop-down</option>
                                        </FormControl>
                                        {this.validator.message('status', this.state.status, 'required')}
                                    </Col>
                                    </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Status: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl name="status" componentClass="select" value={this.state.status} onChange={this.handleChange} placeholder="Select Test Type">
                                            <option value="" disabled={true}>Select Status</option>
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </FormControl>
                                        {this.validator.message('status', this.state.status, 'required')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={6} md={6}>
                                        <button type='submit' className='btn btn-primary'>Add</button>
                                    </Col>
                                </FormGroup>
                            </form>
                        </Grid>
                    </Box.Body>
                </Box>
            </Content>
        </div >;
    }
}

const mapStateToProps = (state) => ({
});

const mapActionsToProps = ({
    pathology_lims_test_add: PathologyLimsTestAction.pathologyLimsTestAdd
})

export default connect(mapStateToProps, mapActionsToProps)(AddPathologyLimsTestPage);
