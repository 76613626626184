import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function doctor(state = initialState.doctor, { type, ...payload }) {
    switch (type) {
        case types.ADD_DOCTOR:
            return {
                ...state,
                ...payload
            };
        case types.DELETE_DOCTOR:
            return {
                ...state,
                ...payload
            };
        case types.FETCH_DOCTOR:
            return {
                ...state,
                ...payload
            };
        case types.UPDATE_DOCTOR:
            return {
                ...state,
                ...payload
            }; 
        default:
            return state;
    }
}