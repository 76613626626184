import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export const Content = ({ title = '', children }) => {
  return (
    <div className="content-wrapper">
      {title !== '' ? (<section className="content-header"><h1>{title}</h1></section>) : ''}
      <section className="content">
        {children}
      </section>
    </div>
  )
};

export const BoxHead = ({ title = "", children, backTo = "" }) => {
  return (
    <div className="box-header with-border">
      {title && <h3 className="box-title">{title}</h3>}
      {children}
      {backTo && <BackButton backTo={backTo}/>}
    </div>
  )
};

export const BoxBody = ({ children }) => {
  return (
    <div className="box-body">
      {children}
    </div>
  )
};

export const BoxFoot = ({ children }) => {
  return (
    <div className="box-footer">
      {children}
    </div>
  )
};

export const BoxOverlay = () => {
  return (
    <div className="overlay dark">
      <i className="fa fa-refresh fa-spin"></i>
    </div>
  )
}

export class Box extends Component {
  static Head = BoxHead;
  static Body = BoxBody;
  static Foot = BoxFoot;
  static Overlay = BoxOverlay;

  static defaultProps = {
    box: "info",
    loading: false
  }

  render() {
    return (
      <div className={"box box-" + this.props.box}>
        {this.props.children}

        {this.props.loading && <BoxOverlay />}
      </div>
    );
  }
}

export const BackButton = ({ backTo }) => {
  return (
    <Link className="btn btn-default back-btn pull-right" to={backTo}>
      <i className="fa fa-arrow-left" aria-hidden="true" title="Back"></i> Back
    </Link>
  )
};