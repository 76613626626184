import * as types from "./actionTypes";
import PrescriptionService from '../services/prescriptionService';

export function list(page, sizePerPage, sortBy, searchText) {
    return dispatch => {
        dispatch({ type: types.PRESCRIPTION_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText } });
        PrescriptionService.list(page, sizePerPage, sortBy, searchText)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.PRESCRIPTION_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                dispatch({ type: types.PRESCRIPTION_LIST_FAILED });
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function fetchPrescriptionOrders(id) {
    return dispatch => {
        PrescriptionService.fetchPrescriptionOrders(id)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.PRESCRIPTION_ORDERS, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

// export function serviceProviderOrderList({ page, sizePerPage, sortBy, searchText, filter }) {
//     return dispatch => {
//         dispatch({ type: types.SERVICE_PROVIDER_ORDER_LIST, request: { page, sizePerPage, sortBy, searchText, filter } });
//         dispatch({ type: types.ALERT_CLEAR, isloading: true });
//         PrescriptionService.serviceProviderOrderList({ page, sizePerPage, sortBy, searchText, filter })
//             .then(resp => {
//                 let data = resp.data;
//                 dispatch({ type: types.SERVICE_PROVIDER_ORDER_LIST, ...data });
//                 dispatch({ type: types.ALERT_CLEAR, isloading: false });
//             })
//             .catch(error => {
//                 if (error.response && error.response.status) {
//                     let errorData = error.response.data;
//                     dispatch({ type: types.ALERT_ERROR, ...errorData });
//                 } else {
//                     dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
//                 }
//             });
//     }
// }