import React from 'react'

export default function CountBox({title = "", count = ""}) {
    return (
        <div className="small-box">
            <div className="inner">
                <h3>{count}</h3>
                <p>{title}</p>
            </div>
        </div>
    )
}
