import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Box, Content } from '../../components/Elements';
import Alert from '../../components/Alert';
import Notification from "../../utils/notification";
import IcdService from '../../services/icdService';
import * as LoaderAction from '../../actions/loaderAction';

class ViewIcdPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            icd_code: '',
            short_description: '',
            validity: '',
            full_description: '',
            status: '',
        };
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        const code = params.code;
        this.props.showLoader()
        IcdService.getByCode(code).then(resp => {
            this.setState({
                icd_code: resp.data.icd_code,
                short_description: resp.data.short_description,
                validity: resp.data.validity,
                full_description: resp.data.full_description,
                status: resp.data.status,
            })
            this.props.hideLoader();
        }).catch(error => {
            Notification.show('error', error.response.data)
            this.props.hideLoader();
        })
    }

    render() {
        return <React.Fragment>
            <Helmet>
                <title>View ICD</title>
            </Helmet>
            <Content title="View ICD">
                <Box>
                    <Box.Head title="View ICD"><Link to="/icds" className="btn btn-info pull-right">Back</Link></Box.Head>
                    <Box.Body>
                        <Alert />
                        {<Table responsive striped>
                            <tbody>
                                <tr>
                                    <th>ICD Code</th>
                                    <td>{this.state.icd_code}</td>
                                </tr>
                                <tr>
                                    <th>Short Description</th>
                                    <td>{this.state.short_description ? this.state.short_description : '-'}</td>
                                </tr>
                                <tr>
                                    <th>Full Description</th>
                                    <td><p>{this.state.full_description ? this.state.full_description : '-'}</p></td>
                                </tr>
                                <tr>
                                    <th>Validity</th>
                                    <td>{this.state.validity ? this.state.validity : '-'}</td>
                                </tr>
                                <tr>
                                    <th>Status</th>
                                    <td>{this.state.status}</td>
                                </tr>
                            </tbody>
                        </Table>}
                    </Box.Body>
                </Box>
            </Content>
        </React.Fragment>;
    }
}

const mapStateToProps = (state) => ({
});

const mapActionsToProps = ({
    showLoader: LoaderAction.loaderShow,
    hideLoader: LoaderAction.loaderHide,
});

export default connect(mapStateToProps, mapActionsToProps)(ViewIcdPage);