import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class CptService {
    static list({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText, filter)
        };

        return axios.get(apiRoute('/v1/cpts'), requestOptions);
    }

    static create(statedata) {
        let paramsdata = JSON.stringify(statedata);
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/cpts'), paramsdata, requestOptions);
    }

    static getByCode(code) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/cpts/' + code), requestOptions);
    }

    static update(code, statedata) {
        let paramsdata = JSON.stringify(statedata);
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.put(apiRoute('/v1/cpts/' + code), paramsdata, requestOptions);
    }
}

export default CptService;
