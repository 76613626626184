import React from "react";
import { isEmpty } from 'lodash'
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { CSVLink } from "react-csv";

const SelectServiceProvider = ({ service_providers, onChange, selectedProvider }) => {
    return <div className="col-md-4 form-group">
        <label>Select Service Provider</label>
        <select className="form-control" name="service_provider" onChange={onChange} >
            <option defaultValue selected value=''>Select Provider</option>
            {!isEmpty(service_providers) && service_providers.map(provider => (
                <option value={provider.service_provider_id} selected={provider.service_provider_id == selectedProvider ? "selected" : ""}>{provider.name}</option>
            ))}
        </select>
    </div>

}
const SelectProviderLocation = ({ provider_locations, onChange, selectedLocation }) => {
    return <div className="col-md-4 form-group">
        <label>Select Location</label>
        <select className="form-control" name="provider_locations" onChange={onChange}>
            <option defaultValue selected value=''>Select Location</option>
            {!isEmpty(provider_locations) && <option value='all' selected={selectedLocation == "all" ? "selected" : ""}>All</option>}
            {!isEmpty(provider_locations) && provider_locations.map(location => (
                <option value={location.user_location_id} selected={location.user_location_id == selectedLocation ? "selected" : ""}>{location.name}</option>
            ))}
        </select>
    </div>
}
const Buttons = ({ onSubmit, onReset }) => {
    return <div className="col-md-4 btn-toolbar mt-2">
        <button type="submit" className="btn btn-primary  pull-right" onClick={onSubmit}>Submit</button>
        <button type="reset" className="btn btn-danger  pull-right" onClick={onReset}> <i className="fa fa-repeat"></i> Reset</button>
    </div>
}
export const SelectDetail = ({ service_providers, provider_locations, onChange, onSubmit, onReset, selected_provider, selected_location }) => {
    return <form>
        <div className="row">
            <SelectServiceProvider service_providers={service_providers} onChange={onChange} selectedProvider={selected_provider} />
            <SelectProviderLocation provider_locations={provider_locations} onChange={onChange} selectedLocation={selected_location} />
            <Buttons onSubmit={onSubmit} onReset={onReset} />
        </div>
    </form>
}
export const Filter = ({ show_start_date, show_end_date, start_date, end_date, handleEvent, resetDate, graphHeader, graphData }) => {
    return <React.Fragment>
        <div className="col-sm-12">
            <form className="form-inline">
                <DateRangePicker
                    startDate={show_start_date}
                    onApply={handleEvent}
                    endDate={show_end_date}
                >
                    <div class="form-group ml-2">
                        <label for="start_date"> From : </label>
                        <input type="text" className="form-control" name="start_date" autoComplete="off" value={start_date ? moment(start_date).format('DD-MM-YYYY') : ''}
                        />
                    </div>
                    <div class="form-group ml-2">
                        <label for="end_date"> To : </label>
                        <input type="text" className="form-control" name="end_date" autoComplete="off" value={end_date ? moment(end_date).format('DD-MM-YYYY') : ''} />
                    </div>

                </DateRangePicker>

                <button className="btn btn-danger ml-2" type="button" onClick={resetDate}> Reset </button>
                <CSVLink className="btn btn-default ml-2" filename={"Authority-Access-Card-Swiped-" + moment().format("DD-MM-YYYY") + ".csv"} data={graphData} headers={graphHeader}>
                    Download
                </CSVLink>
            </form>
        </div>
    </React.Fragment>
}