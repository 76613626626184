import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function radiology(state = initialState.radiology, { type, ...payload }) {
    switch (type) {
        case types.ADD_RADIOLOGY:
            return {
                ...state,
                ...payload
            };
        case types.DELETE_RADIOLOGY:
            return {
                ...state,
                ...payload
            };
        case types.FETCH_RADIOLOGY:
            return {
                ...state,
                ...payload
            };
        case types.UPDATE_RADIOLOGY:
            return {
                ...state,
                ...payload
            };
        default:
            return state;
    }
}