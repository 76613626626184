import axios from 'axios';
import { apiRoute, getApiHeader } from '../utils/helpers';

class DashboardService {

    /**
     * 
     */
    static getPatientCount() {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/count/patients'), requestOptions);
    }
    
    /**
     * 
     */
    static getOnboardProviderCount() {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/count/onboard-providers'), requestOptions);
    }

    /**
     * 
     */
    static getTrialProviderCount() {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/count/trail-providers'), requestOptions);
    }

    /**
     * 
     */
    static getAmountReceviablePharmacy() {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/amount-receviable/pharmacy'), requestOptions);
    }

    /**
     * 
     */
    static getAmountReceviablePathology() {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/amount-receviable/pathology'), requestOptions);
    }

    /**
     * 
     */
    static getAmountReceviableRadiology() {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/amount-receviable/radiology'), requestOptions);
    }

    /**
     * 
     */
    static getAmountPayableDoctor() {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/amount-payable/doctors'), requestOptions);
    }

    static getPrescriptionCreatedGraph({ view, start_date, end_date })
    {
        const requestOptions = {
            headers: getApiHeader(),
            params: {
                start_date: start_date,
                end_date: end_date,
                group_by: view
            }
        };

        return axios.get(apiRoute('/v1/dashboard/graph/prescription-data'), requestOptions);
    }

    static getPharmacyOrderCreatedGraph({ view, start_date, end_date }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: {
                start_date: start_date,
                end_date: end_date,
                group_by: view
            }
        };

        return axios.get(apiRoute('/v1/dashboard/graph/pharmacy/prescription-order-data'), requestOptions);
    }

    static getPathologyOrderCreatedGraph({ view, start_date, end_date }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: {
                start_date: start_date,
                end_date: end_date,
                group_by: view
            }
        };

        return axios.get(apiRoute('/v1/dashboard/graph/pathology/prescription-order-data'), requestOptions);
    }

    static getRadiologyOrderCreatedGraph({ view, start_date, end_date }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: {
                start_date: start_date,
                end_date: end_date,
                group_by: view
            }
        };

        return axios.get(apiRoute('/v1/dashboard/graph/radiology/prescription-order-data'), requestOptions);
    }
}

export default DashboardService;