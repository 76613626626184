import React from 'react';
import { Content, Box } from '../../components/Elements';
import { Helmet } from 'react-helmet';
import { Tabs, Tab } from 'react-bootstrap';
import CovidCenter from '../../components/LogoManagement/CovidCenter';
import Pathology from '../../components/LogoManagement/Pathology';

class LogoManagementPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: {
                service_provider_type: "covid-center"
            },
        }
    }

    setKey = key => {
        let { filter } = this.state;
        filter.service_provider_type = key;
        this.setState({
            filter: filter
        })
    }

    render() {
        return <React.Fragment>
            <Helmet>
                <title>Logo Management</title>
            </Helmet>
            <Content title="Logo Management">
                <Box>
                    <Box.Body>
                        <Tabs defaultActiveKey="covid-center" onSelect={(k) => this.setKey(k)}>
                            <Tab eventKey="covid-center" title="Covid Center">
                                <Tab.Content className='pd-2 ml-2'>
                                    <CovidCenter />
                                </Tab.Content>
                            </Tab>
                            <Tab eventKey="pathology" title="Pathology">
                                <Tab.Content className='pd-2 ml-2'>
                                    <Pathology />
                                </Tab.Content>
                            </Tab>
                        </Tabs>
                    </Box.Body>
                </Box>
            </Content>
        </React.Fragment>
    }
}
export default LogoManagementPage

