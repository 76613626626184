import React from 'react';
import moment from "moment";
import _ from 'lodash';
import { Box } from '../Elements';
import BarGraphBox from './BarGraphBox';
import CovidDashboardService from '../../services/covidDashboardService';
import PrescriptionService from '../../services/prescriptionService';
import { SelectDetail, Filter } from './Filter';
import LocationService from "../../services/locationService";
import Notification from "../../utils/notification";

const headers = [
    { label: "", key: "label" },
    { label: "Conducted Test", key: "conducted" },
    { label: "Uploaded Test", key: "uploaded" }
];

class CovidAllTestGraph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            service_providers: [],
            provider_locations: [],
            view: "month",
            covid_test_data: {
                x_axis_label: [],
                counts: [{
                    label: "Conducted Test",
                    backgroundColor: "#0000ff",
                    data: []
                }, {
                    label: "Uploaded Test",
                    backgroundColor: "#ff0000",
                    data: []
                }]
            },
            filter: {
                service_provider_id: '',
                user_location_id: '',
                start_date: '',
                end_date: ''
            },
            isSubmitted: false,
            graph_download: []
        }

        this.handleEvent = this.handleEvent.bind(this);
    }

    componentDidMount() {
        PrescriptionService.getAllServiceProviders('covid-center').then(resp => {
            this.setState({
                service_providers: resp.data.data
            })
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.getData(this.state.view);
        }
    }

    getData = (view) => {
        let start_date = this.state.filter.start_date;
        let end_date = this.state.filter.end_date;
        let service_provider_id = this.state.filter.service_provider_id;
        let user_location_id = this.state.filter.user_location_id;

        CovidDashboardService.getAllCovidTest({ start_date, end_date, view, service_provider_id, user_location_id }).then(resp => {
            if (resp.data.counts) {
                const graph_data = {}
                graph_data.x_axis_label = resp.data.x_axis_label;
                let conducted = _.map(resp.data.counts, 'conducted');
                let uploaded = _.map(resp.data.counts, 'uploaded');
                let counts = [];
                counts.push({ label: "Conducted Test", backgroundColor: "#0000ff", data: conducted })
                counts.push({ label: "Uploaded Test", backgroundColor: "#ff0000", data: uploaded })
                graph_data.counts = counts;
                this.setState({
                    covid_test_data: graph_data
                })

                const graph_download = [];
                resp.data.x_axis_label.forEach(label => {
                    graph_download.push({ "label": label })
                })
                resp.data.counts.forEach((d, i) => {
                    graph_download[i]['conducted'] = d.conducted;
                    graph_download[i]['uploaded'] = d.uploaded;
                })
                this.setState({
                    graph_download: graph_download
                })
            }
        });
    }

    handleView = (view) => {
        this.setState({ view: view }, () => {
            this.getData(view);
        })
    }

    handleEvent = (_event, picker) => {
        let { filter } = this.state
        filter.start_date = (picker.startDate._d) ? moment(picker.startDate._d).format('YYYY-MM-DD') : '';
        filter.end_date = (picker.endDate._d) ? moment(picker.endDate._d).format('YYYY-MM-DD') : '';
        filter.show_start_date = picker.startDate._d;
        filter.show_end_date = picker.endDate._d;
        console.log(filter);
        this.getData(this.state.view);
    }

    resetFilter = (e) => {
        this.setState({
            filter: {
                service_provider_id: '',
                user_location_id: '',
                start_date: '',
                end_date: '',
                show_start_date: new Date(),
                show_end_date: new Date(),
            },
            isSubmitted: false,
            provider_locations: []
        });
    }

    resetDate = (e) => {
        e.preventDefault()
        let { filter } = this.state
        filter.start_date = ''
        filter.end_date = ''
        filter.show_start_date = new Date()
        filter.show_end_date = new Date()
        this.setState({
            filter, isSubmitted: true
        }, () => {
            this.getData(this.state.view);
        });
    }

    onChange = (e) => {
        let target = e.target;
        if (target.name === 'service_provider') {
            if (target.value === '') {
                this.setState({ provider_locations: [] })
            } else {
                LocationService.getLocationByServiceProviderId(target.value).then(resp => {
                    this.setState(prevState => ({
                        filter: {
                            ...prevState.filter,
                            service_provider_id: target.value,
                            user_location_id: ''
                        },
                        provider_locations: resp.data,
                    }))
                }).catch(error => {
                    Notification.show('error', error.response.data)
                })
            }
        }

        if (target.name === 'provider_locations') {
            if (!this.state.filter.service_provider_id) {
                Notification.show('error', { message: 'Kindly select service provider' })
            } else {
                let { filter } = this.state;
                filter['user_location_id'] = target.value;
                this.setState({ filter });
            }
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        if (!this.state.filter.service_provider_id || !this.state.filter.user_location_id) {
            Notification.show('error', { message: 'Kindly select Provider and Location' });
            return false
        } else {
            this.setState({ isSubmitted: true })
            this.getData(this.state.view);
        }
    }

    render() {
        const { filter } = this.state;
        return (
            <Box box={"solid box-warning"}>
                <Box.Head title="All Covid Test" />
                <Box box={"solid"}>
                    <Box.Body>
                        <div className="col-sm-12">

                            <SelectDetail service_providers={this.state.service_providers}
                                provider_locations={this.state.provider_locations}
                                onChange={this.onChange}
                                onSubmit={this.onSubmit}
                                onReset={this.resetFilter}
                                selected_provider={filter.service_provider_id}
                                selected_location={filter.user_location_id}
                            />

                        </div>
                    </Box.Body>
                </Box>
                {this.state.isSubmitted && <React.Fragment>
                    <Box box={"solid"}>
                        <Box.Body>
                            <Filter
                                show_start_date={filter.show_start_date}
                                show_end_date={filter.show_end_date}
                                start_date={filter.start_date}
                                end_date={filter.end_date}
                                handleEvent={this.handleEvent}
                                resetDate={this.resetDate}
                                graphHeader={headers}
                                graphData={this.state.graph_download}
                            />

                            <BarGraphBox
                                yScaleLabel="Test Count"
                                datasetLabels={this.state.covid_test_data.x_axis_label}
                                datasetCounts={this.state.covid_test_data.counts}
                                view={this.state.view}
                                handleViewChange={this.handleView}
                            />
                        </Box.Body>
                    </Box>



                </React.Fragment>}

            </Box>
        )
    }
}

export default CovidAllTestGraph;