import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class PharmacyService {
    static list({ page, sizePerPage, sortBy, searchText, filter}) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText, filter)
        };

        return axios.get(apiRoute('/v1/pharmacies'), requestOptions);
    }

    static pharmacyadd(statedata) {
        let paramsdata = JSON.stringify(statedata);

        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute('/v1/pharmacies'), paramsdata, requestOptions);
    }

    static fetchpharmacy(serviceProviderId) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/pharmacies/' + serviceProviderId), requestOptions);

    }

    static deletepharmacy(serviceProviderId) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.delete(apiRoute('/v1/pharmacies/' + serviceProviderId), requestOptions);

    }

    static pharmacyupdate(serviceProviderId, postData) {
        let paramsdata = JSON.stringify(postData);
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.patch(apiRoute('/v1/pharmacies/' + serviceProviderId), paramsdata, requestOptions);
    }

    static resendEmail(email) {
        let paramsdata = JSON.stringify({
            email: email
        });
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute("/v1/users/re-verify"), paramsdata, requestOptions);
    }

}


export default PharmacyService;
