import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function pathology(state = initialState.pathology, { type, ...payload }) {
    switch (type) {
        case types.ADD_PATHOLOGY:
            return {
                ...state,
                ...payload
            };
        case types.DELETE_PATHOLOGY:
            return {
                ...state,
                ...payload
            };
        case types.FETCH_PATHOLOGY:
            return {
                ...state,
                ...payload
            };
        case types.UPDATE_PATHOLOGY:
            return {
                ...state,
                ...payload
            };
        default:
            return state;
    }
}
