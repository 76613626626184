import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SideBar from '../components/Sidebar';
import { NotificationContainer } from 'react-notifications';
import * as HELPER from "../utils/helpers";
import _ from 'lodash';

class PrivateLayout extends React.Component {
    constructor(props) {
        super(props);

        document.body.classList.add("skin-blue-light");
        document.body.classList.add("sidebar-mini");

    }

    render() {
        const { component: Component, authenticated, admin, allowPermissions, allowRoles, ...rest } = this.props;
        if (!_.isEmpty(allowRoles) && !_.isEmpty(admin) && !HELPER.hasRole(admin, allowRoles))
            return (<Redirect to="/unauthorized" />)

        if (!_.isEmpty(allowPermissions) && !_.isEmpty(admin) && !HELPER.hasPermission(admin, allowPermissions)) {
            return (<Redirect to="/unauthorized" />)
        }

        return (
            <Route {...rest} render={props =>
                authenticated ?
                    (
                        <div className="wrapper">
                            <Header />
                            <SideBar />
                            <NotificationContainer />
                            <Component {...props} />
                            <Footer />
                        </div>
                    ) : (<Redirect to="/login" />)} />
        );
    }
}


const mapStateToProps = state => ({
    authenticated: state.auth.isAuthenticated,
    admin: state.auth.admin
})

export default connect(mapStateToProps, null)(PrivateLayout);