import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class RadiologyService {
    static list({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText, filter)
        };

        return axios.get(apiRoute('/v1/radiologies'), requestOptions);
    }

    static radiologyadd(statedata) {
        let paramsdata = JSON.stringify(statedata);

        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute('/v1/radiologies'), paramsdata, requestOptions);
    }

    static fetchradiology(service_provider_id) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/radiologies/' + service_provider_id), requestOptions);

    }

    static deleteradiology(service_provider_id) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.delete(apiRoute('/v1/radiologies/' + service_provider_id), requestOptions);

    }

    static radiologyupdate(service_provider_id, postData) {
        let paramsdata = JSON.stringify(postData);
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.patch(apiRoute('/v1/radiologies/' + service_provider_id), paramsdata, requestOptions);
    }

    static resendEmail(email) {
        let paramsdata = JSON.stringify({
            email: email
        });
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute("/v1/users/re-verify"), paramsdata, requestOptions);
    }

}


export default RadiologyService;
