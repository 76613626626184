import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class roleManagementService {
    static list({ page, sizePerPage, sortBy, searchText }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText)
        };

        return axios.get(apiRoute('/v1/roles'), requestOptions);
    }

    static view(id) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/roles/' + id), requestOptions);
    }

    static getPermissions() {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/permissions'), requestOptions);
    }

    static create(postData) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        const data = JSON.stringify(postData)

        return axios.post(apiRoute('/v1/roles'), data, requestOptions);
    }

    static update(postData, id) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        const data = JSON.stringify(postData)

        return axios.put(apiRoute('/v1/roles/' + id), data, requestOptions);
    }

    static getRoleName() {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/roles/list'), requestOptions);
    }
}

export default roleManagementService;