import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function admin_user(state = initialState.doctor, { type, ...payload }) {
    switch (type) {
        case types.ADD_ADMINUSER:
            return {
                ...state,
                ...payload
            };
        case types.DELETE_ADMINUSER:
            return {
                ...state,
                ...payload
            };
        case types.FETCH_ADMINUSER:
            return {
                ...state,
                ...payload
            };
        case types.UPDATE_ADMINUSER:
            return {
                ...state,
                ...payload
            }; 
        default:
            return state;
    }
}