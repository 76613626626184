import React from 'react';
import { Table, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Box, Content } from '../../components/Elements';
import Alert from '../../components/Alert';
import RoleManagementService from '../../services/roleManagementService';

class ViewRolePage extends React.Component {
    constructor(props) {
        super(props);
        const { match: { params } } = this.props;
        this.state = {
            id: params.id,
            loading: false,
            data: null,
            errorMessage: null
        }
    }

    componentDidMount() {
        this.setState({
            loading: true
        })

        RoleManagementService.view(this.state.id).then(resp => {
            this.setState({
                data: resp.data,
                loading: false
            })
        }).catch(error => {
            this.setState({
                errorMessage: error.message,
                loading: false
            })
        })
    }

    render() {
        const { data } = this.state;
        return <React.Fragment>
            <Helmet>
                <title>View Role</title>
            </Helmet>
            <Content title="Roles">
                <Box loading={this.state.loading}>
                    <Box.Head title="View Role" backTo="/roles" />
                    <Box.Body>
                        <Alert type="danger" message={this.state.errorMessage}/>
                        {data && <Table responsive striped>
                            <tbody>
                                <tr>
                                    <th>Name</th>
                                    <td>{data.display_name}</td>
                                </tr>
                                <tr>
                                    <th>Permissions</th>
                                    <td>
                                        {data.permissions && <Row>
                                            {data.permissions.map((permission) => (
                                                <Col sm={4}><input type="checkbox" disabled checked />{"   " + permission.display_name}</Col>
                                                
                                            ))}</Row>}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Status</th>
                                    <td>{data.status ? "Active" : "Inactive"}</td>
                                </tr>
                            </tbody>
                        </Table>}
                    </Box.Body>
                </Box>
            </Content>
        </React.Fragment>;
    }
}
export default ViewRolePage;
