import * as types from './actionTypes';
import GenericDrugService from '../services/genericdrugService';

export function list(page, sizePerPage, sortBy, searchText) {
    return dispatch => {
        dispatch({ type: types.GENERIC_DRUG_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText } });
        GenericDrugService.list(page, sizePerPage, sortBy, searchText)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.GENERIC_DRUG_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                dispatch({ type: types.GENERIC_DRUG_LIST_FAILED });
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}


export function generic_drug_add(statedata, history) {
    return dispatch => {
        dispatch({ type: types.ADD_GENERIC_DRUG });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        GenericDrugService.generic_drug_add(statedata, history)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.ADD_GENERIC_DRUG, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: 'Generic Drug added successfully' });
                history.push('/genericdrugs');
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function fetch_generic_drug(id) {

    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR, isloading: true });
        GenericDrugService.fetch_generic_drug(id)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.FETCH_GENERIC_DRUG, ...data });
                dispatch({ type: types.ALERT_CLEAR });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}


export function generic_drug_update(statedata, history) {

    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR, isloading: true });
        GenericDrugService.generic_drug_update(statedata)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.UPDATE_GENERIC_DRUG, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: 'Drug updated successfully' });
                history.push('/genericdrugs');
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function delete_generic_drug(id) {

    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR, isloading: true });
        GenericDrugService.delete_generic_drug(id)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.DELETE_GENERIC_DRUG, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: data.message });

                GenericDrugService.list(1, 5, null, null)
                    .then(resp => {
                        let data = resp.data;
                        dispatch({ type: types.GENERIC_DRUG_LIST_SUCCESS, ...data });
                        setTimeout(() => { dispatch({ type: types.ALERT_CLEAR }); }, 2000);
                    })
                    .catch(error => {
                        if (error.response && error.response.status) {
                            let errorData = error.response.data;
                            dispatch({ type: types.ALERT_ERROR, ...errorData });
                        } else {
                            dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                        }
                    });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}