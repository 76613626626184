import * as types from './actionTypes';
import DrugService from '../services/drugService';

export function list(page, sizePerPage, sortBy, searchText) {
    return dispatch => {
        dispatch({ type: types.DRUG_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText } });
        DrugService.list(page, sizePerPage, sortBy, searchText)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.DRUG_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                dispatch({ type: types.DRUG_LIST_FAILED });
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function otherDrugList({ page, sizePerPage, sortBy, searchText }) {
    return dispatch => {
        dispatch({ type: types.DRUG_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText } });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });
        DrugService.otherDrugList({ page, sizePerPage, sortBy, searchText })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.DRUG_LIST_SUCCESS, ...data });
                dispatch({ type: types.ALERT_CLEAR });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}


export function drugadd(postData, history) {
    return dispatch => {
        dispatch({ type: types.ADD_DRUG });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        DrugService.drugadd(postData, history)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.ADD_DRUG, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: 'Drug added successfully' });
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                    history.push('/drugs/' + data.id);
                }, 2500);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function fetchdrug(id) {
    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR, isloading: true });
        DrugService.fetchdrug(id)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.FETCH_DRUG, ...data });
                dispatch({ type: types.ALERT_CLEAR });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}


export function drugupdate(statedata, history) {

    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR, isloading: true });
        DrugService.drugupdate(statedata)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.UPDATE_DRUG, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: 'Drug updated successfully' });
                setTimeout(() => { dispatch({ type: types.ALERT_CLEAR }); }, 3000);
                history.push("/drugs/" + statedata.id);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function deletedrug(id) {

    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR, isloading: true });
        DrugService.deletedrug(id)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.DELETE_DRUG, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: data.message });

                DrugService.list(1, 5, null, null)
                    .then(resp => {
                        let data = resp.data;
                        dispatch({ type: types.DRUG_LIST_SUCCESS, ...data });
                        setTimeout(() => { dispatch({ type: types.ALERT_CLEAR }); }, 2000);
                    })
                    .catch(error => {
                        if (error.response && error.response.status) {
                            let errorData = error.response.data;
                            dispatch({ type: types.ALERT_ERROR, ...errorData });
                        } else {
                            dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                        }
                    });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function deleteOtherDrug(id) {
    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR, isloading: true });
        DrugService.deleteOtherDrug(id)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.DELETE_DRUG, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: data.message });

                DrugService.otherDrugList(1, 5, null, null)
                    .then(resp => {
                        let data = resp.data;
                        dispatch({ type: types.DRUG_LIST_SUCCESS, ...data });
                        setTimeout(() => { dispatch({ type: types.ALERT_CLEAR }); }, 2000);
                    })
                    .catch(error => {
                        if (error.response && error.response.status) {
                            let errorData = error.response.data;
                            dispatch({ type: types.ALERT_ERROR, ...errorData });
                        } else {
                            dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                        }
                    });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}