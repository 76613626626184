export const PARISH = ['Saint Andrew', 'Kingston', 'Saint Thomas', 'Portland', 'Saint Mary', 'Saint Ann', 'Trelawny', 'Saint James', 'Hanover', 'Westmoreland', 'Saint Elizabeth', 'Manchester', 'Clarendon', 'Saint Catherine'];

export const DOCTORDEGREE = ['MBBS', 'BDS', 'MD', 'MS', 'MDS', 'DM', 'DS', 'DNB'];

export const SPECIALIZATION = ['General Practitioner', 'Family Physician', 'Pediatrician', 'Cardiologist', 'Obstetrician/Gynecologist', 'Surgeon', 'Psychiatrist', 'Dermatologist', 'Orthopedic', 'Endocrinologist', 'Neurologist', 'Nephrologist', 'Gastroenterologist', 'Infectious Disease', 'Ophthalmologist', 'Otolaryngologist/ ENT', 'Pulmonologist', 'Radiologist', 'Oncologist', 'Anesthesiologist'];

export const NAMETITLE = ["Dr.", "Mr.", "Mrs.", "Ms.", "Miss.", "Mast."];

export const PATHOLOGY_TEST_CATEGORIES = ['CLINICAL CHEMISTRY (BLOOD)', 'HAEMATOLOGY & BLOOD BANK', 'ENDOCRINOLOGY', 'CANCER MARKER', 'URINE CHEMISTRY', 'DRUG OF ABUSE', 'THERAPUTIC DRUGS', 'MISCELLANEOUS', 'BACTERIOLOGY', 'SEROLOGY'];

export const RADIOLOGY_TEST_CATEGORIES = ['CT SCAN', 'CT ANGIOGRAM', 'X Ray', 'MRI (1.5 T)', 'MRA', 'MRV', 'ULTRASOUND', 'FLOUROSCOPY', 'INTERVENTIONAL PROCEDURES', 'NUCLEAR MEDICINE'];

export const INSURANCE = {
    "private-insurance-sagicore": 'Sagicor',
    "private-insurance-medicus": 'Medicus',
    "private-insurance-canopy": 'Canopy',
    'jadep': 'JADEP',
    'nhf': 'NHF',
    'goj': 'GOJ',
    'other': 'Other',
    'no': 'No'
}

export const PATHOLOGY_LIMS_TEST_CATEGORIES = ['CLINICAL CHEMISTRY (BLOOD)', 'HAEMATOLOGY & BLOOD BANK','SEROLOGY' ,'ENDOCRINOLOGY','Special','	Haematology','Serology/Immunology','Microbiology','Urine','CANCER MARKER', 'URINE CHEMISTRY', 'DRUG OF ABUSE', 'THERAPUTIC DRUGS', 'Miscellaneous','Profiles','Clinical Chemistry', 'BACTERIOLOGY'];
