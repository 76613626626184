import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Content, Box } from '../../components/Elements';
import { ButtonToolbar } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import * as RoleManagementAction from '../../actions/roleManagementAction';
import * as HELPER from '../../utils/helpers';
import DataTable from '../../components/DataTable';
import { Label } from 'react-bootstrap';

class RolesListPage extends React.Component {
    constructor(props) {
        super(props);

        this.props.paginate({});
    }

    columns = () => {
        return [
            {
                dataField: 'role_id',
                text: 'Id',
            },
            {
                dataField: 'display_name',
                text: 'Name',
            },
            {
                dataField: 'status',
                text: 'Status',
                formatter: cell => {
                    return cell ? <Label bsStyle="success">Active</Label> : <Label>Inactive</Label>
                }
            },

            {
                dataField: 'actions',
                text: 'Actions',
                isDummyField: true,
                formatter: this.actionFormatter
            }];
    };

    actionFormatter = (_cell, row) => {
        return <ButtonToolbar>
            {HELPER.hasPermission(this.props.admin, ['role-view']) && <Link className="btn btn-sm btn-info" to={'/roles/' + row.role_id}><i className="fa fa-eye" aria-hidden="true"></i></Link>}
            {/* {HELPER.hasPermission(this.props.admin, ['role-edit']) && <Link className="btn btn-warning" to={'/roles/' + row.role_id + '/edit'}><i className="fa fa-pencil"></i></Link>} */}
        </ButtonToolbar>;
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {

        if (type === 'search') {
            page = 1;
        }
        this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText });
    }

    render() {
        let { data, meta, loading } = this.props.list;
        return <div>
            <Helmet>
                <title>List Roles</title>
            </Helmet>
            <Content title="Roles">
                <Box>
                    <Box.Body>
                        <DataTable
                            loading={loading}
                            columns={this.columns()}
                            data={data}
                            page={parseInt(meta.current_page)}
                            sizePerPage={parseInt(meta.per_page)}
                            totalSize={parseInt(meta.total)}
                            onTableChange={this.handleTableChange}
                        />
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}
const mapStateToProps = state => ({
    list: state.role_management,
    admin: state.auth.admin
})

const mapActionsToProps = ({
    paginate: RoleManagementAction.list
})
export default connect(mapStateToProps, mapActionsToProps)(RolesListPage)
