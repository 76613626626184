import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function pharmacy_list(state = initialState.pharmacy_list, { type, ...payload }) {
    switch (type) {
        case types.PHARMACY_LIST_REQUEST:
            return {
                ...state,
                ...payload,
                loading: true
            };
        case types.PHARMACY_LIST_SUCCESS:
            return {
                ...state,
                data: payload.data,
                meta: payload.meta,
                loading: false
            };
        case types.PHARMACY_LIST_FAILED:
            return {
                ...state,
                ...payload,
                loading: false
            };
        default:
            return state;
    }
}
