import React, { Component } from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import * as AuthAction from '../../actions/authActions';
import { FormGroup, FormControl, Glyphicon } from 'react-bootstrap';
import Alert from '../../components/Alert';
import ReCAPTCHABlock from "../../components/ReCAPTCHABlock";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      useremail: ''
    };
    this.validator = new SimpleReactValidator({
      messages: {
        required: 'Please enter an email id',
        email: 'Please enter a valid email id'
      }
    });
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.recaptchaRef = React.createRef();
  }

  handleInputChange(evt) {
    let name = evt.target.name;
    let value = evt.target.value;
    this.setState({
      [name]: value
    });
    if (this.validator.fieldValid(name)) {
      this.validator.hideMessages();
    }
  }

  handleSubmit(evt) {
    evt.preventDefault();
    this.recaptchaRef.execute();
  }

  verifiedSubmit = () => {
    if (!this.validator.fieldValid('useremail')) {
      this.validator.showMessages();
      this.forceUpdate();
      return false;
    } else {
      this.props.forgotPassword(this.state.useremail, this.props.history);
    }
  }

  componentDidMount() {
    document.body.classList.add('login-page');
  }
  componentWillUnmount() {
    document.body.classList.remove('login-page');
  }

  render() {
    const { alert } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>Forgot Password</title>
        </Helmet>
        <ReCAPTCHABlock ref={el => this.recaptchaRef = el} onSubmit={this.verifiedSubmit} />
        <div className="login-box">
          <div className="login-logo">
            <a href="/"><img src={'/images/daisy-logo.svg'} alt="Logo" /></a>
          </div>
          <div className="login-box-body">
            <p className="login-box-msg"><strong>Forgot Password?</strong> Enter your email address to reset your password</p>
            {alert && alert.isloading && <div className="loading">Loading</div>}
            <Alert />
            <form onSubmit={this.handleSubmit}>
              <FormGroup className="has-feedback" controlId="useremail">
                <FormControl type="text" id="useremail" name="useremail" placeholder="Enter Your Email" value={this.state.useremail} onChange={this.handleInputChange} autoComplete="useremail" />
                <Glyphicon glyph="envelope" className="form-control-feedback" />
                {this.validator.message('useremail', this.state.useremail, 'required|email')}
              </FormGroup>
              <div className="row">
                <div className="col-xs-6">
                </div>
                <div className="col-xs-6">
                  <button type="submit" className="btn btn-primary btn-block btn-flat">Request Link</button>
                </div>
              </div>
            </form>
            <Link to="/login">I recalled my password</Link>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  alert: state.alert
});

const mapActionsToProps = ({
  forgotPassword: AuthAction.forgotPassword
})

export default connect(mapStateToProps, mapActionsToProps)(ForgetPasswordPage);
