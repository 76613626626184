import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class PathologyService {
    static list({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText, filter)
        };

        return axios.get(apiRoute('/v1/pathologies'), requestOptions);
    }

    static pathologyadd(statedata) {
        let paramsdata = JSON.stringify(statedata);

        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute('/v1/pathologies'), paramsdata, requestOptions);
    }

    static fetchpathology(serviceProviderId) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/pathologies/' + serviceProviderId), requestOptions);

    }

    static deletepathology(serviceProviderId) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.delete(apiRoute('/v1/pathologies/' + serviceProviderId), requestOptions);

    }

    static pathologyupdate(serviceProviderId, postData) {
        let paramsdata = JSON.stringify(postData);
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.patch(apiRoute('/v1/pathologies/' + serviceProviderId), paramsdata, requestOptions);
    }

    static resendEmail(email) {
        let paramsdata = JSON.stringify({
            email: email
        });
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute("/v1/users/re-verify"), paramsdata, requestOptions);
    }

}


export default PathologyService;
