import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class DoctorService {
    static list({page, sizePerPage, sortBy, searchText, filter}) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText, filter)
        };

        return axios.get(apiRoute('/v1/doctors'), requestOptions);
    }

    static otherDoctorsList({page, sizePerPage, sortBy, searchText, filter}) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText, filter)
        };

        return axios.get(apiRoute('/v1/other-doctors'), requestOptions);
    }

    static doctoradd(statedata){
        let paramsdata = JSON.stringify(statedata);
        
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute('/v1/doctors'), paramsdata, requestOptions);
    }

    static fetchdoctor(serviceProviderId) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/doctors/' + serviceProviderId), requestOptions);

    }

    static doctorupdate(serviceProviderId, postData){
        let paramsdata = JSON.stringify(postData);
        
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.patch(apiRoute('/v1/doctors/'+serviceProviderId), paramsdata, requestOptions);
    }

    static resendEmail(email) {
        let paramsdata = JSON.stringify({
            email: email
        });
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute("/v1/users/re-verify"), paramsdata, requestOptions);
    }
}

export default DoctorService;
