import React from 'react';
import { connect } from 'react-redux';
import {  Box } from '../components/Elements';
import {  ButtonToolbar } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import * as HELPER from '../utils/helpers';
import * as UserAccountAction from '../actions/useraccountAction';
import UserAccountService from '../services/userAccountService';
import Notification from '../utils/notification';

class Invoice extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filter: {},
        };
        this.props.paginate(this.props.service_provider_id, { sortBy: { sortField: 'updated_at', sortOrder: 'desc' } });
    }

    columns = () => {
        return [
            {
                dataField: "invoice_id",
                text: "Invoice Id",
                hidden: true,
            },
            {
                dataField: "",
                text: "",
                formatter: cell => {
                    return <input type="checkbox" />;
                },
                sort: false
            },
            {
                dataField: "invoice_number",
                text: "Invoice Number",
                sort: false
            },
            {
                dataField: "from_date",
                text: "From Date",
                sort: false,
                formatter: cell => {
                    return HELPER.getDateFormat(cell);
                }
            },
            {
                dataField: "to_date",
                text: "To Date",
                sort: false,
                formatter: cell => {
                    return HELPER.getDateFormat(cell);
                }
            },
            {
                dataField: "actions",
                text: "Actions",
                isDummyField: true,
                formatter: this.actionFormatter
            }
        ];
    };

    actionFormatter = (cell, row) => {
        return <ButtonToolbar>
            {<button className="btn btn-info"  onClick={e => this.download(row.invoice_id)}><i className="fa fa-eye" aria-hidden="true"></i></button>}
        </ButtonToolbar>;
    }

    download = (invoice_id) => {
        UserAccountService.viewServiceProviderInvoices(this.props.service_provider_id, invoice_id).then(resp => {
            window.open(resp.data.url, "_blank")
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        if (type === 'search') {
            page = 1;
        }
        let filter = this.state.filter;

        this.props.paginate(this.props.service_provider_id, { page, sizePerPage, sortBy: { sortField: 'updated_at', sortOrder: 'desc' }, searchText, filter });
    }

    render() {
        const { data, meta } = this.props.user_account;
        return (
            <Box>
                <Box.Body>
                    <ToolkitProvider
                        search
                        keyField="id"
                        data={data}
                        columns={this.columns()}
                    >
                        {
                            props => [
                                // <div className="pull-right">
                                //     <Search.SearchBar {...props.searchProps} delay={1000} />
                                //     </div>,
                                <BootstrapTable
                                    {...props.baseProps}
                                    remote
                                    onTableChange={this.handleTableChange}
                                    pagination={paginationFactory({
                                        page: parseInt(meta.current_page),
                                        sizePerPage: parseInt(meta.per_page),
                                        totalSize: parseInt(meta.total)
                                    })}
                                    rowStyle={this.rowStyle}
                                />
                            ]
                        }
                    </ToolkitProvider>
                </Box.Body>
            </Box>
        )
    }
}

const mapStateToProps = (state) => ({
    user_account: state.user_account,
    admin: state.auth.admin
});

const mapActionsToProps = ({
    paginate: UserAccountAction.invoiceList,
})

export default connect(mapStateToProps, mapActionsToProps)(Invoice);