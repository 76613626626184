import axios from "axios";
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class PrescriptionService {
    static list(page, sizePerPage, sortBy, searchText) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText)
        };

        return axios.get(apiRoute("/v1/prescriptions"), requestOptions);
    }
    static fetchPrescriptionOrders(id) {
        const requestOptions = {
            headers: getApiHeader(null, true)
        };

        return axios.get(apiRoute("/v1/prescriptions/" + id), requestOptions);
    }

    // static serviceProviderOrderList({ page, sizePerPage, sortBy, searchText, filter }) {
    //     const requestOptions = {
    //         headers: getApiHeader(),
    //         params: createPaginateQueryString(page, sizePerPage, sortBy, searchText, filter)
    //     };

    //     return axios.get(apiRoute("/v1/service-providers/orders"), requestOptions);
    // }

    static getAllServiceProviders(serviceProviderType) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute("/v1/service-providers?service_provider_type=" + serviceProviderType), requestOptions);
    }

    static downloadDocument(id) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute("/v1/service-providers/order-pdfs/" + id + "/download"), requestOptions);
    }
}
export default PrescriptionService;