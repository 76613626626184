import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function search_patient(state = initialState.search_patient, { type, ...payload}) {
    switch (type) {
        case types.PATIENT_SEARCH_SUCCESS:
            return {
                ...state,
                ...payload
            };
        case types.PATIENT_SEARCH_FAILED:
            return {
                ...state,
                ...payload
            };
        default:
            return state;
    }
}