import React from 'react';
import { Modal } from 'react-bootstrap';

const AddPatientModal = ({ show, handleModal, onSubmit }) => {
    return <Modal show={show} onHide={handleModal} centered backdrop="static">
        <Modal.Header>
            <Modal.Title>Add Patient</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to add this patient as a new patient?</Modal.Body>
        <Modal.Footer>
            <button type="button" class="btn btn-secondary wd-100" onClick={handleModal}>No</button>
            <button type="button" class="btn btn-primary wd-100" onClick={onSubmit}>Yes</button>
        </Modal.Footer>
    </Modal>
}
export default AddPatientModal