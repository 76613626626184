import React from 'react';
import { connect } from 'react-redux';
import { Content } from '../../components/Elements';
import { Link } from "react-router-dom";

class Error404 extends React.Component {

    render() {
        return <Content>
            <div className="error-page">
                <h2 className="headline text-yellow"> 404</h2>

                <div className="error-content">
                    <h3><i className="fa fa-warning text-yellow"></i> Oops! Page not found.</h3>
                    <p>We could not find the page you were looking for. Meanwhile, you may <Link to="/dashboard">return to dashboard</Link> </p>
                </div>
            </div>
        </Content>;
    }
}

export default connect(null, null)(Error404);
