import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { Alert } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import * as PathologyTestAction from '../../actions/pathologyTestActions';
import { Helmet } from 'react-helmet';
import * as HELPER from '../../utils/helpers';

class ListOtherOrderPathologyTestPage extends React.Component {

    constructor(props) {
        super(props);

        this.props.paginate({ sortBy: { sortField: 'updated_at', sortOrder: 'desc' } });
        this.state = {
            filter: {}
        };
    }

    columns = () => {
        return [{
            dataField: 'provider_name',
            text: 'Provider Name'
        }, {
            dataField: 'test_name',
            text: 'Test Name',
            sort: true,
        }, {
            dataField: 'created_at',
            text: 'Date',
            sort: true,
            formatter: cell => {
                return HELPER.getDateFormat(cell);
            }
        }];
    };

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {

        if (type === 'search') {
            page = 1;
        }

        let filter = this.state.filter;

        this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText, filter });
    }

    render() {
        let { data, meta, alert } = this.props;

        return <div>
            <Helmet>
                <title>List Other Order Pathology Test</title>
            </Helmet>
            <Content title="Other Order Pathology Test">
                <Box>
                    <Box.Body>
                        {alert && alert.message && <Alert bsStyle={alert.type}>{alert.message}</Alert>}
                        <ToolkitProvider
                            search
                            keyField="id"
                            data={data}
                            columns={this.columns()}
                        >
                            {
                                props => [
                                    <div className="pull-right"><Search.SearchBar {...props.searchProps} delay={1000} /></div>,
                                    <BootstrapTable
                                        {...props.baseProps}
                                        remote
                                        onTableChange={this.handleTableChange}
                                        pagination={paginationFactory({
                                            page: parseInt(meta.current_page),
                                            sizePerPage: parseInt(meta.per_page),
                                            totalSize: parseInt(meta.total),
                                        })}
                                        rowStyle={this.rowStyle}
                                    />
                                ]
                            }
                        </ToolkitProvider>

                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    ...state.pathologytest_list,
    alert: state.alert,
    admin: state.auth.admin
});

const mapActionsToProps = ({
    paginate: PathologyTestAction.otherOrderPathologyTestList
})

export default connect(mapStateToProps, mapActionsToProps)(ListOtherOrderPathologyTestPage);
