import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Content, Box } from '../../components/Elements';
import { Button, ButtonToolbar } from 'react-bootstrap';
import * as CommunityAction from '../../actions/communityAction';
import { Helmet } from 'react-helmet';
import * as HELPER from '../../utils/helpers';
import DataTable from '../../components/DataTable';
import EditCommunitiesView from "./EditCommunitiesView";

class ListNewCommunitiesPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: {},
            showModal: false,
            community_data: {},
            reload: false,
        };
    }

    componentDidMount() {
        this.props.paginate({});
    } 
    columns = () => {
        return [
            // {
            //     dataField: "id",
            //     text: "Id",
            //     hidden: false,
            //     sort: true
            // },
            {
                dataField: "parish_name",
                text: "Parish Name",
                sort: false
            },
            {
                dataField: "community_name",
                text: "Community Name",
                sort: false,               
            },            
            {
                dataField: "actions",
                text: "Actions",
                isDummyField: true,
                formatter: this.actionFormatter
            }
        ];
    };
    actionFormatter = (cell, row) => {
        const { filter } = this.state;
        return (
            <ButtonToolbar>               
                {filter.status === "0" && (
                    <div> 
                    <Link className="btn btn-warning">
                        <i className="fa fa-edit"  aria-hidden="true" onClick={e => this.openModel(row)}></i>
                    </Link>                  
                    <Link className="btn btn-success">                        
                        <i className="fa fa-check"  aria-hidden="true" onClick={() => this.handleSomeAction(row)}></i>
                    </Link>
                    </div>
                )}

                {(filter.status === '1' || filter.status != '0') && (
                    <p>Verified </p>
                )}
            </ButtonToolbar>
        );
    }
    openModel = (community_data) => {
        console.log('open model',community_data);
        this.setState({ community_data: community_data, showModal: true });
    };

    toggleModal = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal
        }));
    };

    handleSomeAction = (row) =>{
        this.props.communityadd(row);      
        // const filter = {};
        const newStatus = row.status === 'Verified' ? '1' : '0';
        const newFilter = { ...this.state.filter, status: newStatus };
        this.setState({ filter: newFilter });
        this.props.paginate({ filter: newFilter});
        setTimeout(() => {
            // Reload the window
            window.location.reload();
        }, 1500); 
    }
        
    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        if (type === 'search') {
            page = 1;
        }
        let filter = this.state.filter;
        this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText, filter });
    }

    handleChange = (event) => {
        let filter = this.state.filter;
        filter[event.target.name] = event.target.value ? event.target.value : undefined;

        this.setState({
            filter: filter
        });
        this.props.paginate({ filter });
    }

    submitSearch = (evt) => {
        evt.preventDefault();
        const { filter } = this.state;
        this.props.paginate({ filter });
    }

    resetSearch = () => {
        this.setState({
            filter: {}
        })
        const filter = {};
        this.props.paginate({ filter });
    }

    render() {
        let { data, meta, loading } = this.props.community_list;
        return <div>
            <Helmet>
                <title>List Community</title>
            </Helmet>
            <Content title="Community">
                <Box box="solid">
                    <Box.Body>
                        <form onSubmit={this.submitSearch} onReset={this.resetSearch}>
                            <div className="row">
                                <div className="col-md-3 form-group">
                                    <label>Status</label>
                                    <select name="status" className="form-control" onChange={this.handleChange}>
                                        <option value="1">Verified</option>
                                        <option value="0">Unverified</option>
                                    </select>
                                </div>
                                <div className="col-md-12 btn-toolbar">
                                    {/* <Button className="btn pull-right" bsStyle="primary" type="submit"><i className="fa fa-search"></i> Search</Button> */}
                                    <Button className="btn pull-right" bsStyle="danger" type="reset"><i className="fa fa-repeat"></i> Reset</Button>
                                </div>
                            </div>
                        </form>
                    </Box.Body>
                </Box>

                <Box>
                    <Box.Body>
                        <DataTable
                            loading={loading}
                            columns={this.columns()}
                            data={data}
                            page={parseInt(meta.current_page)}
                            sizePerPage={parseInt(meta.per_page)}
                            totalSize={parseInt(meta.total)}
                            onTableChange={this.handleTableChange}
                        />
                    </Box.Body>
                </Box>
            </Content>
            {this.state.showModal && (
                <EditCommunitiesView
                    show={this.state.showModal}
                    handleCommunityInformation={this.toggleModal}
                    communityData={this.state.community_data}
                />
            )}
        </div>;
    }
}

const mapStateToProps = (state) => ({
    community_list: state.community_list,
    alert: state.alert,
    admin: state.auth.admin
});
const mapActionsToProps = ({
    paginate: CommunityAction.newCommunitylist,
    communityadd: CommunityAction.communityadd,
})

export default connect(mapStateToProps, mapActionsToProps)(ListNewCommunitiesPage);