import React from 'react';
import { Link } from "react-router-dom";
import { Content, Box } from '../../components/Elements';
import { Helmet } from 'react-helmet';

class PermissionDeniedPage extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>401</title>
                </Helmet>
                <Content title="401 Unauthorized">
                    <Box box="solid">
                        <Box.Body>                   {/* <img src="images/403.png" /> */}
                            <h5>ACCESS DENIED...</h5>
                            <p>You don't have permission to access this page.</p>
                            <Link to={"/dashboard"} className="btn btn-primary mb-5">GO To Dashboard</Link>
                        </Box.Body>
                    </Box>
                </Content>

            </div>

        )
    }

}
export default PermissionDeniedPage