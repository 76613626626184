import axios from 'axios';
import { apiRoute, getApiHeader } from '../utils/helpers';
import * as authUtils from '../utils/auth';

class AuthService {
    static login(email, password) {
        const requestOptions = {
            headers: getApiHeader(null, false),
        };
        
        const body = JSON.stringify({ email: email, password: password });
        
        return axios.post(apiRoute('/v1/login'), body, requestOptions);
    }

    static logout() {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute('/v1/logout'), null, requestOptions);
    }

    static forgotPassword(email) {
        const requestOptions = {
            headers: getApiHeader(null, false),
        };

        const body = JSON.stringify({ email: email });

        return axios.post(apiRoute('/v1/forget-password'), body, requestOptions);
    }
    

    static resetPassword(email, token, password, password_confirmation) {
        const requestOptions = {
            headers: getApiHeader(null, false),
        };

        const body = JSON.stringify({
            email: email,
            token: token,
            password: password,
            password_confirmation: password_confirmation
        });

        return axios.post(apiRoute('/v1/reset-password'), body, requestOptions);
    }


    static newPassword(email, token, password, password_confirmation) {
        const requestOptions = {
            headers: getApiHeader(null, false),
        };

        const body = JSON.stringify({
            email: email,
            token: token,
            password: password,
            password_confirmation: password_confirmation
        });

        return axios.post(apiRoute("/v1/set-password"), body, requestOptions);
    }

    static resendEmail(email){
        let paramsdata = JSON.stringify({
            email: email
        });
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute("/v1/admins/re-verify"), paramsdata, requestOptions);
    }

    static refreshToken() {
        const requestOptions = {
            headers: getApiHeader(null, false),
        };

        const token = authUtils.getSessionToken();

        const body = JSON.stringify({
            refresh_token: token.refresh_token,
            access_token: token.access_token
        });

        return axios.post(apiRoute('/v1/refresh-token'), body, requestOptions);

    }

}

export default AuthService;
    