import React from 'react';
import { Content, Box } from '../../components/Elements';
import { FormGroup, ControlLabel, Col, Button, ButtonToolbar, Table } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';
import PathologyLimsTestService from '../../services/pathologyLimsTestService';
import Notification from "../../utils/notification";

class AddLimsSubTestPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            category: '',
            status: '',
            profile_tests: [],
            test_lists: [],
            selectedTest: ''
        };

        this.handleChange = this.handleChange.bind(this);
        const { match: { params } } = this.props;
        const id = params.id;
        this.fetchPathologyLimsTest(id);
        this.getAllPathologyLimsTest();
        this.validator = new SimpleReactValidator();
    }

    fetchPathologyLimsTest = (id) => {
        PathologyLimsTestService.fetchPathologyLimsTest(id)
            .then(resp => {
                let data = resp.data;
                this.setState({ ...data });
            })
            .catch(error => {
                console.log(error);
            });
    }

    getAllPathologyLimsTest = () => {
        PathologyLimsTestService.limslist({ sortBy: { sortField: 'id', sortOrder: 'desc' }, sizePerPage: 500 })
            .then(resp => {
                let options = [];
                resp.data.data.map(subTest => {
                    if (subTest.category != "Profiles") {
                        return options.push({ label: subTest.category + " - " + subTest.name, value: subTest.id });
                    }
                });
                this.setState({ test_lists: options });
            })
            .catch(error => {
                console.log(error);
            });
    }

    handleChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        if (value === 'Profile') {
            this.setState({
                [name]: value,
                category: ''
            });
        } else {
            this.setState({
                [name]: value
            });
        }
    }

    addProfileTest = () => {

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        let data = {
            "profile_test_id": this.state.id,
            "test_id": this.state.selectedTest.value,
            "status": 0
        }
        PathologyLimsTestService.addLimsTestProfile(data)
            .then(resp => {
                this.fetchPathologyLimsTest(this.state.id);
                this.setState({
                    selectedTest: ''
                });
                this.validator.hideMessages();
                this.forceUpdate();
                Notification.show('success', { message: "Test Added Successfully" });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    Notification.show('error', errorData);
                } else {
                    console.log(error);
                    Notification.show('error', { message: "Something Went Wrong, Try Again" });
                }
            });
    }

    changeProfileTestStatus = (test) => {
        let data = {
            "profile_test_id": this.state.id,
            "test_id": test.id,
            "status": !test.status
        }
        PathologyLimsTestService.updateLimsTestProfileStatus(data)
            .then(resp => {
                this.fetchPathologyLimsTest(this.state.id);
                Notification.show('success', { message: "Status Updated Successfully" });
            })
            .catch(error => {
                console.log(error);
            });
    }

    onTestSelect = (test) => {
        if (test) {
            this.setState({ selectedTest: { label: test.label, value: test.value } });
        } else {
            this.setState({ selectedTest: '' });
        }

    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Profile - Add Sub Test</title>
                </Helmet>
                <Content title="Profile - Add Sub Test">
                    <Box>
                        <Box.Body>
                            <FormGroup className="row" controlId="">
                                <Col sm={2}>
                                    <ControlLabel>Test category</ControlLabel>
                                </Col>
                                <Col sm={6}>{this.state.category}</Col>
                            </FormGroup>

                            <FormGroup className="row" controlId="">
                                <Col sm={2}>
                                    <ControlLabel>Test Name</ControlLabel>
                                </Col>
                                <Col sm={6}>{this.state.name}</Col>
                            </FormGroup>

                            <FormGroup className="row" controlId="">
                                <Col sm={2}>
                                    <ControlLabel>Search Test</ControlLabel>
                                </Col>
                                <Col sm={6}>
                                    <Select
                                        classNamePrefix="select"
                                        isSearchable={true}
                                        isClearable={true}
                                        name="profile_test"
                                        options={this.state.test_lists}
                                        value={this.state.selectedTest}
                                        onChange={this.onTestSelect}
                                    />
                                    {this.validator.message('test', this.state.selectedTest, 'required')}
                                </Col>
                            </FormGroup>

                            <FormGroup className="row" controlId="">
                                <Col sm={6} md={6}>
                                    <ButtonToolbar>
                                        <Button type='button' className="wd-100" onClick={e => this.addProfileTest()} bsStyle="primary">Add</Button>
                                        <a href="/lims-tests" className="btn btn-info wd-100">Back</a>
                                    </ButtonToolbar>
                                </Col>
                            </FormGroup>
                            <div className='mt-2'>
                                <Table responsive bordered>
                                    <thead>
                                        <tr>
                                            <th>Category</th>
                                            <th>Test</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.profile_tests.length ? this.state.profile_tests.map(subTest => {
                                            return (<tr>
                                                <td>{subTest.category}</td>
                                                <td>{subTest.name}</td>
                                                <td>{subTest.status ? "Active" : "Inactive"}</td>
                                                <td> <i className={subTest.status ? "fa fa-toggle-on" : "fa fa-toggle-off"} onClick={e => this.changeProfileTestStatus(subTest)} style={{ color: subTest.status ? "green" : "", fontSize: "15pt", cursor: "pointer" }} aria-hidden="true" title="Change Status"></i></td>
                                            </tr>)
                                        }) : <tr><td>No Records</td></tr>}
                                    </tbody>
                                </Table>
                            </div>
                        </Box.Body>
                    </Box>
                </Content>
            </div>
        );
    }
}


export default AddLimsSubTestPage;
