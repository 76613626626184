import React from 'react';
import CountBox from './CountBox';
import { Box } from '../Elements';
import CovidDashboardService from '../../services/covidDashboardService';

class CovidLocationCount extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            location_count: '...',
        }
    }

    componentDidMount() {
        CovidDashboardService.getCovidCenterLocationCount().then(res => {
            this.setState({
                location_count: res.data.location_count,
            });
        });
    }

    render() {
        return (
            <Box box={"solid box-danger"}><Box.Head title="Locations" />
                <Box.Body>
                    <div className="row">
                        <div className="col-sm-5">
                            <CountBox title="Total" count={this.state.location_count} />
                        </div>
                    </div>     
                </Box.Body>
            </Box>
        );
    }
}

export default CovidLocationCount;
