import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function pathologyTest(state = initialState.pathologyTest, { type, ...payload }) {
    switch (type) {
        case types.ADD_PATHOLOGY_TEST:
            return {
                ...state,
                ...payload
            };
        case types.DELETE_PATHOLOGY_TEST:
            return {
                ...state,
                ...payload
            };
        case types.FETCH_PATHOLOGY_TEST:
            return {
                ...state,
                ...payload
            };
        case types.UPDATE_PATHOLOGY_TEST:
            return {
                ...state,
                ...payload
            }; 
        default:
            return state;
    }
}