import * as types from './actionTypes';
import DrugPriceService from '../services/drugPriceService';

export function fetchDrugSku(searchterm) {
    
    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR, isloading: true});
        DrugPriceService.fetchDrugSku(searchterm)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.FETCH_DRUG_SKU_PRICE, ...data });
                dispatch({ type: types.ALERT_CLEAR});
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function updateDrugSkuPrice(postData) {

    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR, isloading: true});
        DrugPriceService.updateDrugSkuPrice(postData)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.UPDATE_DRUG_SKU_PRICE, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: 'Price updated successfully'});
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                }, 2000);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

