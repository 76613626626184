import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import PatientCount from '../../components/Dashboard/PatientCount';
import OnboardProviderCount from '../../components/Dashboard/OnboardProviderCount';
import TrailProviderCount from '../../components/Dashboard/TrailProviderCount.js';
import AmountReceviablePharmacy from '../../components/Dashboard/AmountReceviablePharmacy';
import AmountReceviablePathology from '../../components/Dashboard/AmountReceviablePathology';
import AmountReceviableRadiology from '../../components/Dashboard/AmountReceviableRadiology';
import AmountPayableDoctor from '../../components/Dashboard/AmountPayableDoctor';
import DoctorPrescriptionsCreatedGraph from '../../components/Dashboard/DoctorPrescriptionsCreatedGraph';
import PharmacyPrescriptionOrdersCreatedGraph from '../../components/Dashboard/PharmacyPrescriptionOrdersCreatedGraph';
import PathologyPrescriptionOrdersCreatedGraph from '../../components/Dashboard/PathologyPrescriptionOrdersCreatedGraph';
import RadiologyPrescriptionOrdersCreatedGraph from '../../components/Dashboard/RadiologyPrescriptionOrdersCreatedGraph';
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import * as helperUitls from '../../utils/helpers';
import { Helmet } from 'react-helmet';

class DashboardPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show_start_date: new Date(),
            show_end_date: new Date(),
            start_date: "",
            end_date: "",
        }
        this.handleEvent = this.handleEvent.bind(this);
    }

    handleEvent = (event, picker) => {
        this.setState({
            start_date: (picker.startDate._d) ? moment(picker.startDate._d).format('YYYY-MM-DD') : '',
            end_date: (picker.endDate._d) ? moment(picker.endDate._d).format('YYYY-MM-DD') : '',
            show_start_date: picker.startDate._d,
            show_end_date: picker.endDate._d,
        });
    }

    resetDate = () => {
        this.setState({
            start_date: '',
            end_date: '',
            show_start_date: new Date(),
            show_end_date: new Date(),
        });
    }

    render() {
        const user = this.props.user;

        const userOnboardVisibiltyPermission = ['super-admin', 'business-development-head', 'business-development-executive', 'finance-head', 'admin', 'service'];
        const trialAccountVisibiltyPermission = ['super-admin', 'business-development-head', 'finance-head', 'admin', 'service'];
        const createdPrescriptionVisibiltyPermission = ['super-admin', 'business-development-head', 'admin'];
        const amountPayableVisibiltyPermission = ['super-admin', 'business-development-head', 'finance-head', 'finance-executive'];
        const amountReceviableVisibiltyPermission = ['super-admin', 'finance-head', 'finance-executive'];

        return <Content title="Dashboard">
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <div className="row">
                <div className="col-sm-2">
                    {helperUitls.hasRole(user, userOnboardVisibiltyPermission) && <PatientCount />}
                </div>
                <div className="col-sm-10">
                    {helperUitls.hasRole(user, userOnboardVisibiltyPermission) && <OnboardProviderCount />}
                </div>
                <div className="col-sm-12">
                    {helperUitls.hasRole(user, trialAccountVisibiltyPermission) && <TrailProviderCount />}
                </div>

                <div className="col-sm-3">
                    {helperUitls.hasRole(user, amountPayableVisibiltyPermission) && <Box box={"solid box-danger"}><Box.Head title="Account Payable" />
                        <Box.Body><div className="row">
                            <AmountPayableDoctor className="col-sm-12" />
                        </div></Box.Body></Box>}
                </div>

                <div className="col-sm-9">
                    {helperUitls.hasRole(user, amountReceviableVisibiltyPermission) && <Box box={"solid box-primary"}><Box.Head title="Account Receivable" />
                        <Box.Body><div className="row">
                            <AmountReceviablePharmacy className="col-sm-4" />
                            <AmountReceviablePathology className="col-sm-4" />
                            <AmountReceviableRadiology className="col-sm-4" />
                        </div></Box.Body></Box>}
                </div>
            </div>

            {helperUitls.hasRole(user, createdPrescriptionVisibiltyPermission) && <>
                <Box box={"solid"}>
                    <Box.Body>
                        <div className="col-sm-2">
                            <strong>Filter</strong>
                        </div>
                        <div className="col-sm-10">
                            <form className="form-inline">
                                <DateRangePicker
                                    startDate={this.state.show_start_date}
                                    onApply={this.handleEvent}
                                    endDate={this.state.show_end_date}
                                >
                                    <div class="form-group ml-2">
                                        <label for="start_date"> From: </label>
                                        <input type="text" className="form-control" name="start_date" autoComplete="off" value={this.state.start_date ? moment(this.state.start_date).format('DD-MM-YYYY') : ''} />
                                    </div>
                                    <div class="form-group ml-2">
                                        <label for="end_date"> To: </label>
                                        <input type="text" className="form-control" name="end_date" autoComplete="off" value={this.state.end_date ? moment(this.state.end_date).format('DD-MM-YYYY') : ''} />
                                    </div>

                                </DateRangePicker>

                                <button className="btn btn-danger ml-2" onClick={this.resetDate}> Reset </button>
                            </form>
                        </div>
                    </Box.Body>
                </Box>

                <div className="row">
                    <div className="col-sm-12">
                        <DoctorPrescriptionsCreatedGraph
                            start_date={this.state.start_date}
                            end_date={this.state.end_date}
                        />
                    </div>
                    <div className="col-sm-12">
                        <PharmacyPrescriptionOrdersCreatedGraph
                            start_date={this.state.start_date}
                            end_date={this.state.end_date}
                        />
                    </div>
                    <div className="col-sm-12">
                        <PathologyPrescriptionOrdersCreatedGraph
                            start_date={this.state.start_date}
                            end_date={this.state.end_date}
                        />
                    </div>
                    <div className="col-sm-12">
                        <RadiologyPrescriptionOrdersCreatedGraph
                            start_date={this.state.start_date}
                            end_date={this.state.end_date}
                        />
                    </div>
                </div></>}
        </Content>;
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.admin,
});

export default connect(mapStateToProps, null)(DashboardPage);
