import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { FormGroup, ControlLabel, FormControl, Col, Grid } from 'react-bootstrap';
import * as RadiologyTestAction from '../../actions/radiologyTestActions';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from 'react-helmet';
import Alert from '../../components/Alert';
import { RADIOLOGY_TEST_CATEGORIES } from '../../utils/config';


class AddRadiologyTestPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            test_name: '',
            test_category: '',
            test_description: '',
            price: '',
            test_status: ''
        };

        this.validator = new SimpleReactValidator();

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });


    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        this.props.radiologyTestAdd(this.state, this.props.history);
    }

    render() {
        return <div>
            <Helmet>
                <title>Add Radiology Test</title>
            </Helmet>
            <Content title="Add Radiology Test">

                <Box>
                    <Box.Head title="Add Radiology Test" backTo="/pathology/tests" />
                    <Box.Body>
                        <Alert />
                        <Grid>
                            <form onSubmit={this.handleSubmit}>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Test category: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl name="test_category" componentClass="select" value={this.state.test_category} onChange={this.handleChange} placeholder="Select Test category">
                                            <option value="" disabled={true}>select test category</option>
                                            {RADIOLOGY_TEST_CATEGORIES.map((value) => {
                                                return <option key={value} value={value}>{value}</option>
                                            })}
                                        </FormControl>
                                        {this.validator.message('test_category', this.state.test_category, 'required')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Test Name: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='test_name'
                                            type="text"
                                            value={this.state.name}
                                            placeholder="Enter test name"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('test_name', this.state.test_name, 'required|string')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Test description: </ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='test_description'
                                            componentClass="textarea"
                                            value={this.state.test_description}
                                            placeholder="Enter test description"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('test_description', this.state.test_description, 'string')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Test Price: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='price'
                                            type="number"
                                            value={this.state.price}
                                            placeholder="Enter test price"
                                            onChange={this.handleChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('price', this.state.price, 'required|numeric')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Status: <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl name="test_status" componentClass="select" value={this.state.test_status} onChange={this.handleChange} placeholder="Select Test Type">
                                            <option value="" disabled={true}>select status</option>
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </FormControl>
                                        {this.validator.message('test_status', this.state.test_status, 'required')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={6} md={6}>
                                        <button type='submit' className='btn btn-primary'>Add</button>
                                    </Col>
                                </FormGroup>
                            </form>
                        </Grid>
                    </Box.Body>
                </Box>
            </Content>
        </div >;
    }
}

const mapStateToProps = (state) => ({
    //generic_drugs: state.drugs
});

const mapActionsToProps = ({
    radiologyTestAdd: RadiologyTestAction.radiologyTestAdd
})

export default connect(mapStateToProps, mapActionsToProps)(AddRadiologyTestPage);
