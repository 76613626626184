import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Box, Content } from '../../components/Elements';
import Alert from '../../components/Alert';
import Notification from "../../utils/notification";
import CptService from '../../services/cptService';
import * as LoaderAction from '../../actions/loaderAction';

class ViewCptPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cpt_code: '',
            short_description: '',
            medium_description: '',
            full_description: '',
            status: '',
        };
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        const code = params.code;
        this.props.showLoader()
        CptService.getByCode(code).then(resp => {
            this.setState({
                cpt_code: resp.data.cpt_code,
                short_description: resp.data.short_description,
                medium_description: resp.data.medium_description,
                full_description: resp.data.full_description,
                status: resp.data.status,
            })
            this.props.hideLoader();
        }).catch(error => {
            Notification.show('error', error.response.data)
            this.props.hideLoader();
        })
    }

    render() {
        return <React.Fragment>
            <Helmet>
                <title>View CPT</title>
            </Helmet>
            <Content title="View CPT">
                <Box>
                    <Box.Head title="View CPT"><Link to="/cpts" className="btn btn-info pull-right">Back</Link></Box.Head>
                    <Box.Body>
                        <Alert />
                        {<Table responsive striped>
                            <tbody>
                                <tr>
                                    <th>CPT Code</th>
                                    <td>{this.state.cpt_code}</td>
                                </tr>
                                <tr>
                                    <th>Short Description</th>
                                    <td>{this.state.short_description ? this.state.short_description : '-'}</td>
                                </tr>
                                <tr>
                                    <th>Medium Description</th>
                                    <td>{this.state.medium_description ? this.state.medium_description : '-'}</td>
                                </tr>
                                <tr>
                                    <th>Full Description</th>
                                    <td><p>{this.state.full_description ? this.state.full_description : '-'}</p></td>
                                </tr>
                                <tr>
                                    <th>Status</th>
                                    <td>{this.state.status}</td>
                                </tr>
                            </tbody>
                        </Table>}
                    </Box.Body>
                </Box>
            </Content>
        </React.Fragment>;
    }
}

const mapStateToProps = (state) => ({
});

const mapActionsToProps = ({
    showLoader: LoaderAction.loaderShow,
    hideLoader: LoaderAction.loaderHide,
});

export default connect(mapStateToProps, mapActionsToProps)(ViewCptPage);