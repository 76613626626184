import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function radiologytest_list(state = initialState.pagination, { type, ...payload }) {
    switch (type) {
        case types.RADIOLOGY_TEST_LIST_REQUEST:
            return {
                ...state,
                ...payload
            };
        case types.RADIOLOGY_TEST_LIST_SUCCESS:
            return {
                ...state,
                data: payload.data,
                meta: payload.meta,
                status: 'updated'
            };
        case types.RADIOLOGY_TEST_LIST_FAILED:
            return {
                ...state,
                ...payload
            };
        case types.RADIOLOGY_TEST_LIST_CLEAR:
            return {
                ...state,
                data: {}
            };
        default:
            return state;
    }
}
