import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { FormGroup, ControlLabel, FormControl, Col } from 'react-bootstrap';
import * as GenericDrugAction from '../../actions/genericdrugActions';
import Alert from '../../components/Alert';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from 'react-helmet';

class EditGenericDrugPage extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            id: '',
            name: '',
            flag: 'null'
        };

        const { match: { params } } = this.props;
        const id = params.id;

        this.props.fetch_generic_drug(id);
        this.validator = new SimpleReactValidator();

        this.onChange = this.onChange.bind(this)
        this.validateAndSubmit = this.validateAndSubmit.bind(this)
    }

    static getDerivedStateFromProps(props, state) {

        if (state.flag === "null") {
            return {
                flag: 'true'
            }
        }
        if (state.flag === 'true') {
            return {
                id: props.generic_drug.id,
                name: props.generic_drug.name,
                flag: 'false'
            };
        } else {
            return state;
        }

    }

    onChange(e) {
        const name = e.target.name
        const value = e.target.value

        this.setState({ [name]: value });
    }

    validateAndSubmit(e) {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        this.props.generic_drug_update(this.state, this.props.history);
    }

    render() {
        return <div>
            <Helmet>
                <title>Edit Generic Drug</title>
            </Helmet>
            <Content title="Generic Drugs">

                <Box>
                    <Box.Head title="Edit Generic Drug" backTo="/genericdrugs" />
                    <Box.Body>
                        <Alert />
                        {this.state && this.state.id &&
                            <form onSubmit={this.validateAndSubmit}>

                                <FormGroup className="row" controlId="">
                                    <Col sm={2}>
                                        <ControlLabel>Name:  <span>*</span></ControlLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FormControl
                                            name='name'
                                            type="text"
                                            value={this.state.name}
                                            placeholder="Enter generic drug name"
                                            onChange={this.onChange}
                                            autoComplete="none"
                                        />
                                        {this.validator.message('name', this.state.name, 'required|string')}
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row" controlId="">
                                    <Col sm={6} md={6}>
                                        <button type='submit' className='btn btn-primary'>Save</button>
                                    </Col>
                                </FormGroup>
                            </form>
                        }
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    generic_drug: state.generic_drugs,
});

const mapActionsToProps = ({
    fetch_generic_drug: GenericDrugAction.fetch_generic_drug,
    generic_drug_update: GenericDrugAction.generic_drug_update
});

export default connect(mapStateToProps, mapActionsToProps)(EditGenericDrugPage);