import React from "react";
import { connect } from "react-redux";
import { Content, Box } from "../../components/Elements";
import { Button, ButtonToolbar } from "react-bootstrap";
import * as TestResultReportAction from "../../actions/testResultReportAction";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import * as HELPER from "../../utils/helpers";
import DataTable from '../../components/DataTable';
import moment from "moment";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { capitalize } from 'lodash';
import * as CONFIG from "../../utils/config";
import ReferralService from '../../services/referralService';
import Notification from '../../utils/notification';
import AdditionalInformationView from "./AdditionalInformationView";

class TestResultReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lims_tests: [],
            sortBy: { sortField: "created_at", sortOrder: "desc" },
            date: '',
            filter: {
                location: "",
                parish: "",
                lab_name: "",
                start_date: null,
                end_date: null,
                flag: "",
                sex_at_birth: "",
                risk_category: "",
                lims_test: "",
                age_range: "",
                showModal: false,
                health_information: []
            }
        }
        this.getLimsTestList();
    }

    componentDidMount() {
        this.props.paginate({ sortBy: this.state.sortBy });
    }

    getLimsTestList = () => {
        ReferralService.getLimsTestList().then(response => {
            this.setState({ lims_tests: response.data.data })
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    actionFormatter = (cell, row) => {
        return <ButtonToolbar>
            <Link className="btn btn-sm btn-info" to={'/referral-report/' + row.id}><i className="fa fa-eye" aria-hidden="true"></i></Link>
        </ButtonToolbar>;
    }

    columns = () => {
        return [
            {
                dataField: "created_at",
                text: "Date",
                sort: true,
                formatter: this.dateFormatter,
            },
            {
                dataField: "lab_name",
                text: "Lab Name",
                formatter: this.labNameFormatter,
            },
            {
                dataField: "parish",
                text: "Parish"
            },
            {
                dataField: "location",
                text: "Location"
            },
            {
                dataField: "patient_name",
                text: "Patient Name"
            },
            {
                dataField: "patient_age.year",
                text: "Age"
            },
            {
                dataField: "patient_sex_at_birth",
                text: "Sex at Birth",
                formatter: this.genderFormatter
            },
            {
                dataField: "test_category",
                text: "Test Category",
                formatter: this.testCategoryFormatter,
            },
            {
                dataField: "test_name",
                text: "Test Name",
                formatter: this.testNameFormatter,
            },
            {
                dataField: "test_lower_range",
                text: "Test Lower Range",
                formatter: this.testLowerRangeFormatter,
            },
            {
                dataField: "test_upper_range",
                text: "Test Upper Range",
                formatter: this.testUpperRangeFormatter,
            },
            {
                dataField: "value",
                text: "Result",
                formatter: this.testResultFormatter,
            },
            {
                dataField: "unit_measurement",
                text: "Unit Measurement",
                formatter: this.unitMeasurementFormatter,
            },
            {
                dataField: "flag",
                text: "Flag",
                formatter: this.flagFormatter,
            },
            {
                dataField: "patient_risk_diabetes",
                text: "Risk Category",
                formatter: this.riskFormatter,
            },
            {
                dataField: "is_referred",
                text: "Is Referred",
            },
            {
                dataField: "actions",
                text: "Actions",
                formatter: this.actionFormatter,
            },
        ];
    };

    actionFormatter = (cell, row) => {
        return (
            <React.Fragment>
                <Link onClick={e => this.openModel(row.health_information)} title="View Health Information">
                    <i className="fa fa-file-text" aria-hidden="true"></i>
                </Link>
            </React.Fragment>
        );
    };

    openModel = (health_information) => {
        this.setState({ health_information: health_information, showModal: true });
    };

    toggleModal = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal
        }));
    };

    riskFormatter = (cell, row) => {
        let patient_risk_diabetes = row.patient_risk_diabetes ? row.patient_risk_diabetes : "--";
        let patient_risk_hypertension = row.patient_risk_hypertension ? row.patient_risk_hypertension : "--";
        return "Diabetes: " + patient_risk_diabetes + ",  Hypertension: " + patient_risk_hypertension;
    }

    labNameFormatter = (cell, row) => {
        return row.service_provider.name;
    }

    testCategoryFormatter = (cell, row) => {
        return row.lims_test.category;
    }

    testNameFormatter = (cell, row) => {
        return row.lims_test.name ? row.lims_test.name : "";
    }

    testLowerRangeFormatter = (cell, row) => {
        return row.lims_test.lower_limit ? row.lims_test.lower_limit : "";
    }

    testUpperRangeFormatter = (cell, row) => {
        return row.lims_test.upper_limit ? row.lims_test.upper_limit : "";
    }

    testResultFormatter = (cell, row) => {
        return row.lims_test.type == "select" ? capitalize(cell) : cell;
    }

    unitMeasurementFormatter = (cell, row) => {
        return row.lims_test.unit_of_measurement ? row.lims_test.unit_of_measurement : "";
    }

    flagFormatter = (cell, row) => {
        if (!cell) {
            return '';
        }
        return capitalize(cell)
    }

    genderFormatter = (cell, row) => {
        return capitalize(cell)
    }

    dateFormatter = (cell, row) => {
        return HELPER.getDateFormat(cell);
    }

    handleTableChange = (_type, { page, sizePerPage, sortField, sortOrder, searchText, filter }) => {
        let sortBy = sortField ? { sortField, sortOrder } : this.state.sortBy;
        this.props.paginate({ page, sizePerPage, sortBy, searchText, filter: this.state.filter });
    }

    handleSearch = (e) => {
        e.preventDefault();
        this.props.paginate({ sortBy: this.state.sortBy, filter: this.state.filter });
    }

    resetSearch = () => {
        this.setState({
            date: "",
            filter: {
                location: "",
                parish: "",
                lab_name: "",
                start_date: null,
                end_date: null,
                flag: "",
                sex_at_birth: "",
                risk_category: "",
                lims_test: "",
                age_range: ""
            }
        });
        this.props.paginate({ sortBy: this.state.sortBy });
    }

    handleDateApply = (_evt, picker) => {
        this.setState({
            ...this.state,
            date: picker.startDate.format('DD MMM YYYY') + ' - ' + picker.endDate.format('DD MMM YYYY'),
            filter: {
                ...this.state.filter,
                start_date: picker.startDate.format('YYYY-MM-DD'),
                end_date: picker.endDate.format('YYYY-MM-DD')
            }
        })
    }

    handleExport = () => {
        ReferralService.exportTestResultReport({ sortBy: this.state.sortBy, filter: this.state.filter }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', "TestResultReport.csv")
            document.body.appendChild(link)
            link.click()
            link.remove()
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    render() {
        let { data, meta, loading } = this.props.test_result_report;
        return <div>
            <Helmet>
                <title>Test Result Report </title>
            </Helmet>
            <Content title="Test Result Report">
                <Box box="solid">
                    <Box.Body>
                        {/* <form onReset={this.resetSearch}> */}
                        <div className="row">

                            <div className="col-md-3 form-group">
                                <label>Test Name</label>
                                <select name="lims_test" className="form-control" value={this.state.filter.lims_test} onChange={e => this.setState({ filter: { ...this.state.filter, lims_test: e.target.value } })}>
                                    <option value=''>Select</option>
                                    {this.state.lims_tests.map((test, idx) => {
                                        return <option value={test.id} key={idx}>{test.name}</option>
                                    })}
                                </select>
                            </div>

                            <div className="col-md-3 form-group">
                                <label>Age</label>
                                <select name="age_range" className="form-control" value={this.state.filter.age_range} onChange={e => this.setState({ filter: { ...this.state.filter, age_range: e.target.value } })}>
                                    <option value=''>Select</option>
                                    <option value={"0-10"} key={1}>0-10</option>
                                    <option value={"11-20"} key={2}>11-20</option>
                                    <option value={"21-30"} key={3}>21-30</option>
                                    <option value={"31-40"} key={4}>31-40</option>
                                    <option value={"41-50"} key={5}>41-50</option>
                                    <option value={"51-60"} key={6}>51-60</option>
                                    <option value={"61-70"} key={7}>61-70</option>
                                    <option value={"71-80"} key={8}>71-80</option>
                                    <option value={"81-90"} key={9}>81-90</option>
                                    <option value={"91-100"} key={10}>91-100</option>
                                    <option value={"100"} key={11}>{">100"}</option>
                                </select>
                            </div>

                            <div className="col-md-3 form-group">
                                <label>Sex at Birth</label>
                                <select name="sex_at_birth" className="form-control" value={this.state.filter.sex_at_birth} onChange={e => this.setState({ filter: { ...this.state.filter, sex_at_birth: e.target.value } })}>
                                    <option value=''>Select</option>
                                    <option value={'male'} key={1}>Male</option>
                                    <option value={'female'} key={2}>Female</option>
                                </select>
                            </div>

                            <div className="col-md-3 form-group">
                                <label>Flag</label>
                                <select name="flag" className="form-control" value={this.state.filter.flag} onChange={e => this.setState({ filter: { ...this.state.filter, flag: e.target.value } })}>
                                    <option value=''>Select</option>
                                    <option value={'normal'}>Normal</option>
                                    <option value={'low'}>Low</option>
                                    <option value={'high'}>High</option>
                                    <option value={'Positive'}>Positive</option>
                                    <option value={'Negative'}>Negative</option>
                                </select>
                            </div>

                            <div className="col-md-3 form-group">
                                <label>Risk Category </label>
                                <select name="risk_category" className="form-control" value={this.state.filter.risk_category} onChange={e => this.setState({ filter: { ...this.state.filter, risk_category: e.target.value } })}>
                                    <option value=''>Select</option>
                                    <option value={'Average'} key={1}>Average</option>
                                    <option value={'High'} key={2}>High</option>
                                </select>
                            </div>

                            <div className="col-md-3 form-group">
                                <label>Location</label>
                                <input type="text" class="form-control" onChange={e => this.setState({ filter: { ...this.state.filter, location: e.target.value } })} value={this.state.filter.location} placeholder="Location" />
                            </div>

                            {/* <div className="col-md-3 form-group">
                                <label>Parish</label>
                                <select name="parish" className="form-control" value={this.state.filter.parish} onChange={e => this.setState({ filter: { ...this.state.filter, parish: e.target.value } })}>
                                    <option value=''>Select</option>
                                    {CONFIG.PARISH.map((value, idx) => {
                                        return <option value={value} key={idx}>{value}</option>
                                    })}
                                </select>
                            </div> */}

                            <div className="col-md-3 form-group">
                                <label>Lab Name</label>
                                <input type="text" class="form-control" onChange={e => this.setState({ filter: { ...this.state.filter, lab_name: e.target.value } })} value={this.state.filter.lab_name} placeholder="Lab Name" />
                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Date</label>
                                    <DateRangePicker
                                        onApply={this.handleDateApply}
                                        autoApply={true}
                                        maxDate={moment()}
                                    >
                                        <div class="input-group">
                                            <input type="text" class="form-control" value={this.state.date} placeholder="DD MMM YYYY - DD MMM YYYY" />
                                            <div class="input-group-addon">
                                                <i class="fa fa-calendar"></i>
                                            </div>
                                        </div>
                                    </DateRangePicker>
                                </div>
                            </div>
                            <div className="col-md-12 btn-toolbar">
                                <Button className="btn pull-right" bsStyle="primary" type="submit" onClick={this.handleSearch}><i className="fa fa-search"></i> Search</Button>
                                <Button className="btn pull-right" bsStyle="danger" type="reset" onClick={this.resetSearch}><i className="fa fa-repeat"></i> Reset</Button>
                            </div>
                        </div>
                        {/* </form> */}
                    </Box.Body>
                </Box>
                <Box>
                    <Box.Head>
                        <button type="button" className="btn btn-primary" onClick={this.handleExport}><span className="fa fa-download"> Export as CSV</span></button>
                    </Box.Head>
                    <Box.Body>
                        <DataTable
                            key="test_result_report"
                            loading={loading}
                            columns={this.columns()}
                            data={data}
                            page={parseInt(meta.current_page)}
                            sizePerPage={parseInt(meta.per_page)}
                            totalSize={parseInt(meta.total)}
                            onTableChange={this.handleTableChange}
                            showSearchBar={false}
                        />


                    </Box.Body>
                </Box>

            </Content>
            {this.state.showModal && (
                <AdditionalInformationView
                    show={this.state.showModal}
                    handleAdditionalInformation={this.toggleModal}
                    getData={this.state.health_information}
                />
            )}
        </div>;
    }
}

function mapStateToProps(state) {
    return {
        test_result_report: state.test_result_report,
        admin: state.auth.admin
    };
};

const mapActionsToProps = ({
    paginate: TestResultReportAction.list
});

export default connect(mapStateToProps, mapActionsToProps)(TestResultReport);
