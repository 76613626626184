import axios from 'axios';
import { apiRoute, getApiHeader } from '../utils/helpers';

class DrugPriceService {
    
    static fetchDrugSku(searchterm) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/drug-skus/prices?drug_name=' + encodeURIComponent(searchterm)), requestOptions);
    }

    static updateDrugSkuPrice(postData) {
        
        const requestOptions = {
            headers: getApiHeader()
        };

        let data = JSON.stringify(postData);

        return axios.post(apiRoute('/v1/drug-skus/prices'), data, requestOptions);
    }

    static updateAllDrugPrices(postData) {
        
        const requestOptions = {
            headers: getApiHeader()
        };

        let data = JSON.stringify(postData);

        return axios.post(apiRoute('/v1/update/all-drug-sku/prices'), data, requestOptions);
    }
}

export default DrugPriceService;
