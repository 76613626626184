import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class GenericDrugService {
    static list(page, sizePerPage, sortBy, searchText) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString(page, sizePerPage, sortBy, searchText)
        };

        return axios.get(apiRoute('/v1/generic-drugs'), requestOptions);
    }

    static generic_drug_add(statedata) {
        let paramsdata = JSON.stringify({
            name: statedata.name
        });

        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute('/v1/generic-drugs'), paramsdata, requestOptions);
    }

    static fetch_generic_drug(id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/generic-drugs/' + id), requestOptions);
    }

    static delete_generic_drug(id) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.delete(apiRoute('/v1/generic-drugs/' + id), requestOptions);
    }

    static generic_drug_update(statedata) {
        let paramsdata = JSON.stringify({
            name: statedata.name
        });

        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.patch(apiRoute('/v1/generic-drugs/' + statedata.id), paramsdata, requestOptions);
    }
}

export default GenericDrugService;
