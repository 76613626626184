import React from 'react';
import { Modal } from 'react-bootstrap';

const MergePatientModal = ({ show, handleModal, handleSubmit }) => {
    return <Modal show={show} onHide={handleModal} centered backdrop="static">
        <Modal.Header>
            <Modal.Title>Merge Patient</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to merge this record?</Modal.Body>
        <Modal.Footer>
            <button type="button" class="btn btn-secondary wd-100" onClick={handleModal}>No</button>
            <button type="button" class="btn btn-primary wd-100" onClick={handleSubmit}>Yes</button>
        </Modal.Footer>
    </Modal>
}
export default MergePatientModal