import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function icdList(state = initialState.pagination, { type, ...payload }) {
    switch (type) {
        case types.ICD_LIST_REQUEST:
            return {
                ...state,
                ...payload
            };
        case types.ICD_LIST_SUCCESS:
            return {
                ...state,
                data: payload.data,
                meta: payload.meta,
            };
        case types.ICD_LIST_FAILED:
            return {
                ...state,
                ...payload
            };
        default:
            return state;
    }
}