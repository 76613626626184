import * as types from './actionTypes';
import AdminUserService from '../services/adminuserService';

export function list({ page, sizePerPage, sortBy, searchText }) {
    return dispatch => {
        dispatch({ type: types.ADMINUSER_LIST_REQUEST, request: { page, sizePerPage, sortBy, searchText } });
        AdminUserService.list({ page, sizePerPage, sortBy, searchText })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.ADMINUSER_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}


export function adminuseradd(statedata, history) {
    return dispatch => {
        dispatch({ type: types.ADD_ADMINUSER });
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        AdminUserService.adminuseradd(statedata, history)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.ADD_ADMINUSER, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: 'User added successfully' });
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                    history.push('/admin/' + data.admin_id);
                }, 2500);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function fetchadminuser(id) {

    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        AdminUserService.fetchadminuser(id)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.FETCH_ADMINUSER, ...data });
                dispatch({ type: types.ALERT_CLEAR, isloading: false });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_WARNING, message: error.message });
                }
            });
    }
}


export function adminuserupdate(statedata, history) {

    return dispatch => {
        dispatch({ type: types.ALERT_CLEAR, isloading: true });

        AdminUserService.adminuserupdate(statedata)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.UPDATE_ADMINUSER, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: 'User updated successfully' });
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                    history.push("/admin/" + statedata.id);
                }, 3000);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function deleteadminuser(id) {

    return dispatch => {
        AdminUserService.deleteadminuser(id)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.DELETE_ADMINUSER, ...data });
                dispatch({ type: types.ALERT_SUCCESS, message: data.message });

                AdminUserService.list(1, 5, null, null)
                    .then(resp => {
                        let data = resp.data;
                        dispatch({ type: types.ADMINUSER_LIST_SUCCESS, ...data });
                        setTimeout(() => { dispatch({ type: types.ALERT_CLEAR }); }, 2000);
                    })
                    .catch(error => {
                        if (error.response && error.response.status) {
                            let errorData = error.response.data;
                            dispatch({ type: types.ALERT_ERROR, ...errorData });
                        } else {
                            dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                        }
                    });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}